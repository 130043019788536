import { createSlice } from "@reduxjs/toolkit";
import { USER_REDUCERS } from "./UserProfileReducer";
import { TUserDataSlice } from "../../../types/TUserDataSlice";

const initialState: TUserDataSlice = {
  USER_DATA: {
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    gender: { value: "", warning: false },
    age: { value: "", warning: false },
    address1: { value: "", warning: false },
    address2: { value: "", warning: false },
    pincode: { value: "", warning: false },
    city: { value: "", warning: false },
  },
};

export const UserProfileSlice = createSlice({
  name: "User",
  initialState,
  reducers: USER_REDUCERS,
});

export default UserProfileSlice.reducer;
