import { FormControl, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { COLORS } from "../../../SupportingFiles/colors";

const RadioText = ({
  attrName,
  onChange,
  value,
  defaultValue = "",
  disabled = false,
  options,
  variant = "default",
  orientation = "row",
  count = "1",
}: {
  orientation?: "row" | "column";
  attrName: string;
  onChange: Function;
  value: string;
  defaultValue?: string;
  disabled?: boolean;
  options: Array<{ value: string; label: string }>;
  variant?: "default" | "large" | "medium";
  count?: "1" | "2" | "3" | "4" | "AUTO";
}) => {
  const checkedLabelColor = "#141414";
  const uncheckedLabelColor = "#595959";
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup
        row={orientation === "row"}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        sx={{ gap: "16px", alignSelf: "stretch" }}
      >
        {options.map((data, index) => (
          <FormControlLabel
            id={`${attrName}`}
            sx={{
              ".MuiRadio-root": {
                display: "none",
              },
              "&:hover": {
                // backgroundColor: "#FEF7E9",
                backgroundColor: "rgba(252, 224, 189, 1)",
              },
              "&:active": {
                backgroundColor: "rgba(252, 224, 189, 1)",
              },
              flexGrow: count === "AUTO" ? "0" : "1",
              width:
                count === "1"
                  ? "100%"
                  : count === "2"
                  ? "40%"
                  : count === "3"
                  ? "25%"
                  : count === "4"
                  ? "20%"
                  : "auto",
              border: "1px solid #B5B5B5",
              borderRadius: "100px",
              padding:
                variant === "default"
                  ? "5px"
                  : variant === "medium"
                  ? "5px 12px"
                  : variant === "large"
                  ? "7px 12px"
                  : "7px 16px",
              margin: "0px",
              justifyContent: "center",
              backgroundColor:
                data.value === value ? "rgba(252, 224, 189, 1)" : "#ffffff",
            }}
            value={data.value}
            control={
              <Radio
                onChange={(e) => {
                  onChange(attrName, e.target.value);
                }}
                onClick={(e: any) => {
                  if (value === e.target._wrapperState.initialValue) {
                    onChange(attrName, e.target._wrapperState.initialValue);
                  }
                }}
                checked={data.value === value}
                disabled={disabled}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: COLORS.lightgrey,
                }}
              >
                {data.label}
              </span>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioText;
