import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const BlogWhyDoesComp = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "why-does-a-‘comprehensive’-car-insurance-cost-more-than-a-‘third-party-liability’-car-insurance?",
    blogtitle:
      "Why does a ‘Comprehensive’ car insurance cost more than a ‘Third-Party Liability’ car insurance?",
    img_url: "/images/blog4.webp",
    main_desc: (
      <>
        <p className="mb-4">
          When renewing or buying a new car insurance policy, one question which
          always comes to our mind is which policy should we buy - comprehensive
          or third-Party Liability car insurance?{" "}
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        {/* <h6 className="mb-3">
          Why does a ‘Comprehensive’ car insurance cost more than a
          ‘Third-Party Liability’ car insurance?
        </h6> */}
        <p className="mb-5">
          Both these policies help us protect our car from associated risks in
          their own way. The decision on which type of policy to go ahead
          depends on various factors. A comprehensive insurance is more
          expensive than a third-party insurance because of the below reasons:
        </p>
        <ul className="mb-5">
          <li>
            Comprehensive includes third-party insurance, which means
            comprehensive has two parts to it - own damage and third-party.{" "}
          </li>
          <li>
            Comprehensive gives a broader coverage and covers more risks. It
            covers your car damage due to accident or mishap.
          </li>
          <li>
            Third-party only offers protection against the harm done to another
            person or their property and not to your car.
          </li>
          <li>
            You will have the option of covering natural calamities like
            cyclones, earthquakes, etc. in a comprehensive policy.
          </li>
          <li>
            Extra add-ons are available with a comprehensive policy and not with
            a third-party policy.
          </li>
        </ul>
        <p className="mb-5">
          As the above reasons suggest, a comprehensive insurance policy offers
          better and wider coverage, hence is more expensive than a third-party
          Insurance policy.
        </p>

        <h6 className="mb-3">
          How to know what type of insurance is best for you?
        </h6>
        <p className="mb-3">
          A comprehensive car insurance policy is what you need if you are
          looking for protection for both you and your car. Given that it also
          includes third-party protections, the plan offers a wider range of
          coverage. It is a good idea to acquire a comprehensive policy that
          provides maximum protection for your vehicle if you possess a new or
          expensive car. To make sure your car is well-protected, you can also
          select extra coverings like engine protection and other add-ons. For
          an old car that is no longer in good condition, a stand-alone
          third-party cover may be chosen.
        </p>
        <p className="mb-5">
          At{" "}
          <span style={{ fontWeight: "900" }}>
            Jio Insurance Broking Limited
          </span>{" "}
          we give you various options to choose from across various products and
          across various Insurance companies. Reach out to us to know more!{" "}
        </p>
      </>
    ),
  };



  const blogsJsonLd = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${FRONTEND_DOMAIN}/blog/why-does-a-comprehensive-car-insurance-cost-more-than-a-third-party-liability-car-insurance`
    },
    "headline": current_blog_data?.blogtitle,
    "image": `${FRONTEND_DOMAIN}${current_blog_data?.img_url}`,  
    "author": {
      "@type": "Organization",
      "name": "Jio Insurance Broking Ltd.",
      "url": `${FRONTEND_DOMAIN}/about-us`
    },  
    "datePublished": "2024-10-22",
    "dateModified": "2024-10-22"
  }, null, 2);  // Added 'null, 2' to format it with indentation



  return (
    <Box className="blogsWrapper">
      <HelmetComponent showCanonicalTag={true} scripts={[blogsJsonLd]}/>
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogWhyDoesComp;
