import { Box, Link } from "@mui/material";
import React, { useState } from "react";

function AddonCard({
  data,
  setOpenAddonPopup,
  setDataAddonPopup,
}: {
  data: {
    img_url: string;
    addontitle: string;
    addon_desc: string;
  };
  setDataAddonPopup: any;
  setOpenAddonPopup: Function;
}) {
  return (
    <Box className="addon_box">
      <Box
        className="addonCard"
        width="100%"
        onClick={() => {
          setOpenAddonPopup(true);
          setDataAddonPopup(data);
        }}
      >
        <img src={data.img_url} alt="" />
        <Box className="cardInfo p-6" borderRadius="0px!important">
          <h5 className="mb-2">{data.addontitle}</h5>
          <p>{data.addon_desc}</p>
          <Link
            style={{
              color: "#9B6817",
              fontWeight: "bold",
              display: "inline-block",
              marginTop: "4px",
            }}
          >
            Read more
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default AddonCard;
