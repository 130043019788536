import { useState, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../../SCSS/ModalPopup.scss";
import { COLORS } from "../../../../SupportingFiles/colors";

const PolicyCancellation = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  return (
    <Modal open={open}>
      <Box
        className="modalWrapper"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="modalContent mdWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid xs={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={2}
            alignItems="flex-start"
          >
            <Grid xs={12}>
              <Box>
                <h3
                  style={{
                    fontWeight: "900",
                    textAlign: "center",
                    marginBottom: "16px",
                  }}
                >
                  Policy Cancellation & Refund
                </h3>
                <p>
                  'Policy cancellation and refund of the premium shall be as per
                  the terms and conditions of the policy. The refunds are
                  processed by the Insurance Company directly. You are requested
                  to contact the toll free number of your Insurance Company or
                  refer the respective section of your Policy terms and
                  conditions. You can also write a mail to us at'{" "}
                  <Link
                    sx={{ color: COLORS.primary }}
                    href="mailto:sales.force@jioinsure.in"
                  >
                    sales.force@jioinsure.in
                  </Link>
                  '. We shall be available to guide/assist you.'
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default PolicyCancellation;
