import { SendEventToApp } from "./SendEventToApp";

// Check if the DOM is available
const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

// Object to store pending promises keyed by type
const pendingPromises: Record<
  string,
  {
    resolve: (value: unknown) => void;
    reject: (reason?: any) => void;
  }
> = {};

// Handles responses by resolving the promise for the given resolveKey
export const handleResponse = (dataObj: object, resolveKey: string) => {
  const promiseEntry = pendingPromises[resolveKey];

  // Check if the promise entry exists
  if (!promiseEntry) {
    return;
  }

  const { resolve } = promiseEntry;

  console.log("Data object:", dataObj); // Log data object

  // Resolve the promise with the data object
  resolve(dataObj);

  // Clean up resolved promise
  delete pendingPromises[resolveKey];
};

export const getJFSJwtToken = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error("Request timed out"));
    }, 5000); // 5 seconds timeout

    // Store the promise in pendingPromises
    pendingPromises["getJwtAuthToken"] = { resolve, reject };

    SendEventToApp(
      {
        type: "getJwtAuthToken",
        microAppID: "INSURANCE",
      },
      (response: Record<string, any>) => {
        clearTimeout(timeout); // Clear timeout on success
        console.log("Received JWT token response:", response);
        resolve(response);
      }
    );
  });
};

// Declare the sendJwtAuthToken property on the global window object
declare global {
  interface Window {
    sendJwtAuthToken: (data: any) => void;
  }
}

// Check if DOM is available and define window function for receiving JWT token
if (canUseDOM) {
  window.sendJwtAuthToken = (data: any) => {
    console.log("JWT token received from native app:", data);
    handleResponse(data, "getJwtAuthToken");
  };
}
