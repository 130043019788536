import { createSlice } from "@reduxjs/toolkit";
import { TNonTermSlice } from "../../../types/TNonTermSlice";
import { NON_TERM_REDUCERS } from "./NonTermReducers";

const initialState: TNonTermSlice = {
  QUOTE_LOADER: false,
  QUOTE_LOAD_FIRST_TIME: true,
  PROPOSER_DATA: {
    payer_selected: false,
    PCB: false,
    payer_relationship: { value: "", warning: false },
    pincode: { value: "", warning: false },
    qualification: { value: "", warning: false },
    diabetic: false,
    premium_loader: false,
    sum_assured: { value: "", warning: false },
    education: { value: "", warning: false },
    is_proposer_is_insured_member: true,
    full_name: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    aadhar_number: { value: "", warning: false },
    emergency_number: { value: "", warning: false },
    marital_status: { value: "", warning: false },
    annual_income: { value: "", warning: false },
    occupation: { value: "", warning: false },
    occupation_desc: { value: "", warning: false },
    org_description: { value: "", warning: false },
    org_name: { value: "", warning: false },
    my_profession: { value: "", warning: false },
    industry_type: { value: "", warning: false },
    industry_type_desc: { value: "", warning: false },
    org_type: { value: "", warning: false },
    org_type_desc: { value: "", warning: false },
    obj_of_buying_policy: { value: "", warning: false },
    pan_no: { value: "", warning: false },
    nationality: "indian",
    insured_name: { value: "", warning: false },
    insured_mobile: { value: "", warning: false },
    insured_email: { value: "", warning: false },
    insured_gender: { value: "", warning: false },
    insured_dob: { value: "", warning: false },
    insured_occupation: { value: "", warning: false },
    insured_qualification: { value: "", warning: false },
    insured_pincode: { value: "", warning: false },
  },

  ADD_FORM: {
    email: { value: "", warning: false },
    gender: { value: "M", warning: false },
    age: { value: "", warning: false },
    mobile: { value: "", warning: false },
    investAmount: { value: "", warning: false },
    investmentMode: { value: "", warning: false },
    annualIncome: { value: "", warning: false },
    lifeStage: { value: "", warning: false },
    pincode: { value: "", warning: false },
    goals: { value: "Retirement", warning: false },
    riskProfile: { value: "Aggressive", warning: false },
  },
  DROPDOWN_DATA: {
    investmentMode: [
      { value: "12", label: "Monthly" },
      { value: "4", label: "Quarterly" },
      { value: "2", label: "Half-yearly" },
      { value: "1", label: "Yearly" },
      { value: "5", label: "Single Pay" },
    ],
    ANNUAL_INCOME: [],
    LIFE_STAGE: [
      { label: "Single", value: "single" },
      { label: "Married", value: "married" },
      { label: "Married with Children", value: "marriedChildren" },
      { label: "Nearing Retirement", value: "nearlyRetirement" },
    ],
    GOALS: [
      { label: "Retirement", value: "Retirement" },
      { label: "Child Plan", value: "ChildPlan" },
      { label: "Savings", value: "Savings" },
      { label: "Protection", value: "Protection" },
    ],
    RETURN_TYPE: [
      { label: "Guranteed", value: "Guranteed" },
      { label: "ULIP", value: "ULIP" },
    ],
    RISK_PROFILE: [
      { label: "Aggressive", value: "Aggressive" },
      { label: "Growth", value: "Growth" },
      { label: "Balanced", value: "Balanced" },
      { label: "Risk Averse", value: "RiskAverse" },
      { label: "Conservative", value: "Conservative" },
    ],
    PAY_UPTO: [],
    POLICY_TERM: [],
    INCOME_PERIOD: [],
    OCCUPATION_DATA: [
      { label: "Salaried", value: "salaried" },
      { label: "Self-enployeed", value: "self-enployeed" },
    ],
    OCCUPATION_MAX_LIFE_DATA: [],
    QUALIFICATION_MAX_LIFE_DATA: [],
    QUALIFICATION_DATA: [
      { label: "10th pass", value: "10th pass" },
      { label: "Graduate & Above", value: "Graduate & Above" },
      { label: "12 pass", value: "12 pass" },
    ],
  },
  UPDATE_RESPONSE: {
    pincode: "",
    city: "",
    state: "",
    annual_income: "",
    sum_assured: 500000,
    age: 0,
    gender: "",
    policy_term: "",
    policy_paying_term: "",
    dob_date: "",
    dob_month: "",
    dob_year: "",
    payment_mode: "",
    dob: "",
  },
  ADD_FORM_RESPONSE: {
    quote_no: "",
    module: "",
    insurance_type: "",
    pincode: "",
    name: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    source_url: "",
    utm_source: "",
    utm_medium: "",
    utm_medium_m: "",
    utm_medium_d: "",
    utm_campaign: "",
    utm_keyword: "",
    sum_assured: 6000000,
    status: 1,
    ip_address: "",
    gender: "",
    age: "",
    dob_date: "",
    dob_month: "",
    dob_year: "",
    payment_mode: "",
    policy_term: "",
    policy_paying_term: "",
    plan_type: "",
    dob: "",
    header_code_id: 2,
    header_code_desc: "",
    _csrfToken: "",
  },
  QUOTE_FIELDS_DATA: {
    investAmount: "500000",
    investmentMode: "12",
    payUpto: "10",
    policyTerm: "10",
    returnType: "Guranteed",
    incomePeriod: "10",
  },
  SELECTED_QUOTE_DATA: {
    premiumDetails: {
      EBI_PDF_url: "",
      maturityBenefitApi: 0,
      sumAssured: "",
      premiumWithGST: 0,
      premium: 0,
      incomeApi: 0,
      appno: "",
      status: "",
      maturity_benefit: 0,
      risk_cover_cal: "",
      pay_term: "",
      term: 0,
      InvestmentAmount: "",
      redirectionUrl: "",
    },
    fundDetails: {
      maturityBenefitApi: 0,
      sumAssured: "",
      premiumWithGST: 0,
      premium: 0,
      incomeApi: 0,
      appno: "",
      status: "",
      maturity_benefit: 0,
      risk_cover_cal: "",
      pay_term: "",
      term: 0,
      InvestmentAmount: "",
      redirectionUrl: "",
    },
    companyDetails: {
      company_code: "",
      name: "",
      short_desc: "",
      logo: "",
      claim_ratio: "",
    },
    SpecialFeatureLists: [],
    productDetailLists: null,
    buyOnlineLink: {
      method: "",
      buy_online_link: "",
    },
    productDetails: {
      id: 0,
      product_name: "",
      product_type_code: "",
      brochure: "",
      product_code: "",
      rate_calc_method: "",
      proposal_method: "",
      is_online: 0,
      tax_calc_method: "",
      discount_calc_method: null,
      rating: 0,
      product_desc: "",
      buy_online_code: null,
      special_feature_codes: "",
      investment_form_validation: {
        frequency: 0,
        modal_factor: 0,
        min_age: 0,
        max_age: 0,
        min_p_cess_age: 0,
        max_p_cess_age: 0,
      },
      CompanyProductMaps: {
        company_code: "",
        product_type: "",
        insurer_name_code: "",
        insurer_pcode: "",
        insured_product_id: "",
        insured_product_source: "",
      },
    },
    sumAssured: "0",
    QuoteNo: "",
    totalInvestment: 0,
  },
  RIDERS_BENEFITS: {
    riders_benefits_response: {
      // redirectUrl: "",
      // base_rate: 0,
      // modal_factor: "",
      // conversion_factor: 0,
      // premiumAmount: 0,
      // final_premium: 0,
      // maturity_benefit: 0,
      // risk_cover_cal: 0,
      // InvestmentAmount: 0,
      // pay_term: "",
      // term: "",
      // appno: "",
      // quote_id: "",
      // uwtype: "",
      // premium: 0,
      // redirectionUrl: "",
      // quote_no: "",
      // sum_insured: "",
      // frequency: "",
      // riders: {
      //   TF001: {
      //     sum_assured: 0,
      //     min_sa_percentage: 0,
      //     max_sa_percentage: 0,
      //     sa_percentage: 0,
      //     interval: 0,
      //     premium: 0,
      //     min_sum_assured: 0,
      //     max_sum_assured: 0,
      //     description: "",
      //     free: 0,
      //     rider_pay_term: "",
      //     rider_term: "",
      //     show_sum_insured: "",
      //     status: "",
      //   },
      //   TF002: {
      //     sum_assured: 0,
      //     min_sa_percentage: 0,
      //     max_sa_percentage: 0,
      //     sa_percentage: 0,
      //     interval: 0,
      //     premium: 0,
      //     min_sum_assured: 0,
      //     max_sum_assured: 0,
      //     description: "",
      //     free: 0,
      //     rider_pay_term: "",
      //     rider_term: "",
      //     show_sum_insured: "",
      //     status: "",
      //   },
      //   TF003: {
      //     sum_assured: 0,
      //     min_sa_percentage: 0,
      //     max_sa_percentage: 0,
      //     sa_percentage: 0,
      //     interval: 0,
      //     premium: 0,
      //     min_sum_assured: 0,
      //     max_sum_assured: 0,
      //     description: "",
      //     free: 0,
      //     rider_pay_term: "",
      //     rider_term: "",
      //     show_sum_insured: "",
      //     status: "",
      //   },
      //   TF004: {
      //     sum_assured: 0,
      //     min_sa_percentage: 0,
      //     max_sa_percentage: 0,
      //     sa_percentage: 0,
      //     interval: 0,
      //     premium: 427,
      //     min_sum_assured: 0,
      //     max_sum_assured: 0,
      //     description: "",
      //     free: 0,
      //     rider_pay_term: "",
      //     rider_term: "",
      //     show_sum_insured: "",
      //     status: "",
      //   },
      //   TF005: {
      //     sum_assured: 0,
      //     min_sa_percentage: 0,
      //     max_sa_percentage: 0,
      //     sa_percentage: 0,
      //     interval: 0,
      //     premium: 427,
      //     min_sum_assured: 0,
      //     max_sum_assured: 0,
      //     description: "",
      //     free: 0,
      //     rider_pay_term: "",
      //     rider_term: "",
      //     show_sum_insured: "",
      //     status: "",
      //   },
      // },

      maturityBenefitApi: 0,
      sumAssured: "",
      premiumWithGST: 0,
      premium: 0,
      incomeApi: 0,
      appno: "",
      status: "",
      maturity_benefit: 0,
      risk_cover_cal: "6084593",
      pay_term: "",
      term: 0,
      InvestmentAmount: 0,
      riders: {
        TF001: {
          sum_assured: 0,
          premium: 0,
          title: "",
          description: "",
          rider_name: "",
          min_sa_percentage: "",
          max_sa_percentage: "",
          interval_value: "",
          interval: "",
          change_term: "",
          status: "",
        },
        TF002: {
          sum_assured: 0,
          premium: 0,
          title: "",
          description: "",
          rider_name: "",
          min_sa_percentage: "",
          max_sa_percentage: "",
          interval_value: "",
          interval: "",
          change_term: "",
          status: "",
        },
        TF003: {
          sum_assured: 0,
          premium: 0,
          title: "",
          description: "",
          rider_name: "",
          min_sa_percentage: "",
          max_sa_percentage: "",
          interval_value: "",
          interval: "",
          change_term: "",
          status: "",
        },
        TF004: {
          sum_assured: 0,
          premium: 0,
          title: "",
          description: "",
          rider_name: "",
          min_sa_percentage: "",
          max_sa_percentage: "",
          interval_value: "",
          interval: "",
          change_term: "",
          status: "",
        },
        TF005: {
          sum_assured: 0,
          premium: 0,
          title: "",
          description: "",
          rider_name: "",
          min_sa_percentage: "",
          max_sa_percentage: "",
          interval_value: "",
          interval: "",
          change_term: "",
          status: "",
        },
      },
      product_code: "",
      redirectionUrl: "",
    },
    local_value: {
      tf001Status: false,
      tf001Value: "₹ 10,000",
      tf002Status: false,
      tf002Value: "₹ 4,00,000",
      tf003Status: false,
      tf003Value: "₹ 10,000",
      tf004Status: false,
      tf004Value: "₹ 10,000",
      tf005Status: false,
      tf005Value: "₹ 10,000",
      BNF001Status: false,
      BNF002Status: false,
      BNF003Status: false,
    },
  },
  PRODUCT_LIST: [],
};

let annualIncome_data = [];
for (let incomeValue = 200000; incomeValue <= 10000000; incomeValue += 100000) {
  let label;

  if (incomeValue === 10000000) {
    label = "1 Crore";
  } else {
    label = `${incomeValue / 100000} Lakh`;
  }

  annualIncome_data.push({ label: label, value: `${incomeValue}` });
}
initialState.DROPDOWN_DATA.ANNUAL_INCOME = annualIncome_data;

const payUpto_data = [];
for (let i = 1; i <= 75; i++) {
  payUpto_data.push({
    label: `${i} Year${i > 1 ? "s" : ""}`,
    value: `${i}`,
  });
}

initialState.DROPDOWN_DATA.PAY_UPTO = payUpto_data;
const policyTerm_data = [];
for (let i = 1; i <= 75; i++) {
  policyTerm_data.push({
    label: `${i} Year${i > 1 ? "s" : ""}`,
    value: `${i}`,
  });
}

initialState.DROPDOWN_DATA.POLICY_TERM = policyTerm_data;

const income_period_data = [];
for (let i = 1; i <= 45; i++) {
  income_period_data.push({
    label: `${i}`,
    value: `${i}`,
  });
}

initialState.DROPDOWN_DATA.INCOME_PERIOD = income_period_data;
export const NonTermSlice = createSlice({
  name: "NonTerm",
  initialState,
  reducers: NON_TERM_REDUCERS,
});

export default NonTermSlice.reducer;
