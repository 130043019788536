import { createSlice } from "@reduxjs/toolkit";
import { TProductPageSlice } from "../../../types/TProductPageSlice";
import { PRODUCT_PAGES_REDUCERS } from "./ProductPagesReducer";

const initialState: TProductPageSlice = {
  HEALTH_INSURANCE: {
    attributes: {
      data: {
        title: "",
        desc: "",
        gradient_box_title: "",
        gradient_note: "",
        list_cont_note: "",
        gradient_2_note: "",
        number_box_note: "",
        product_info: "",
        faq_note: "",
        disclaimer: "",
        section_type: "Health Insurance",
        primary_info: { title: "", desc: "" },
        gradient_data: [],
        buy_quote: { title: "", desc: "" },
        list_cont_heading: { title: "", desc: "" },
        list_cont_data: [],
        gradient_2_heading: { title: "", desc: "" },
        gradint_2_data: [],
        number_box_heading: { title: "", desc: "" },
        number_box_data: [],
        img_gradient_box_heading: { title: "", desc: "" },
        img_gradient_box_data: [],
        faq_heading: { title: "", desc: "" },
        faq_data: [],
      },
    },
  },
  CAR_INSURANCE: {
    attributes: {
      data: {
        title: "",
        desc: "",
        gradient_box_title: "",
        gradient_note: "",
        list_cont_note: "",
        gradient_2_note: "",
        number_box_note: "",
        product_info: "",
        faq_note: "",
        disclaimer: "",
        section_type: "Car Insurance",
        primary_info: { title: "", desc: "" },
        gradient_data: [],
        buy_quote: { title: "", desc: "" },
        list_cont_heading: { title: "", desc: "" },
        list_cont_data: [],
        gradient_2_heading: { title: "", desc: "" },
        gradint_2_data: [],
        number_box_heading: { title: "", desc: "" },
        number_box_data: [],
        img_gradient_box_heading: { title: "", desc: "" },
        img_gradient_box_data: [],
        faq_heading: { title: "", desc: "" },
        faq_data: [],
      },
    },
  },
  BIKE_INSURANCE: {
    attributes: {
      data: {
        title: "",
        desc: "",
        gradient_box_title: "",
        gradient_note: "",
        list_cont_note: "",
        gradient_2_note: "",
        number_box_note: "",
        product_info: "",
        faq_note: "",
        disclaimer: "",
        section_type: "Bike Insurance",
        primary_info: { title: "", desc: "" },
        gradient_data: [],
        buy_quote: { title: "", desc: "" },
        list_cont_heading: { title: "", desc: "" },
        list_cont_data: [],
        gradient_2_heading: { title: "", desc: "" },
        gradint_2_data: [],
        number_box_heading: { title: "", desc: "" },
        number_box_data: [],
        img_gradient_box_heading: { title: "", desc: "" },
        img_gradient_box_data: [],
        faq_heading: { title: "", desc: "" },
        faq_data: [],
      },
    },
  },
  TERM_INSURANCE: {
    attributes: {
      data: {
        title: "",
        desc: "",
        gradient_box_title: "",
        gradient_note: "",
        list_cont_note: "",
        gradient_2_note: "",
        number_box_note: "",
        product_info: "",
        faq_note: "",
        disclaimer: "",
        section_type: "Term Insurance",
        primary_info: { title: "", desc: "" },
        gradient_data: [],
        buy_quote: { title: "", desc: "" },
        list_cont_heading: { title: "", desc: "" },
        list_cont_data: [],
        gradient_2_heading: { title: "", desc: "" },
        gradint_2_data: [],
        number_box_heading: { title: "", desc: "" },
        number_box_data: [],
        img_gradient_box_heading: { title: "", desc: "" },
        img_gradient_box_data: [],
        faq_heading: { title: "", desc: "" },
        faq_data: [],
      },
    },
  },
};
export const ProductPagesSlice = createSlice({
  name: "ProductPages",
  initialState: initialState,
  reducers: PRODUCT_PAGES_REDUCERS,
});
