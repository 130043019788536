import EastIcon from "@mui/icons-material/East";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import styles from "../../../SCSS/CMSPages.module.scss";
import { COLORS } from "../../../SupportingFiles/colors";

function Career() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const careerSlider = [
    { imgUrl: "./images/culture-1.jpg" },
    { imgUrl: "./images/culture-2.jpg" },
    { imgUrl: "./images/culture-3.jpg" },
    { imgUrl: "./images/culture-4.jpg" },
  ];

  return (
    <Box className={styles.cmsWrapper}>
      <NavBar />

      {/* Hero Banner */}
      <Box
        sx={{ marginTop: "-40px", marginBottom: "64px" }}
        className="heroBanner"
      >
        <Box sx={{ width: "100%", position: "relative", marginBottom: "12px" }}>
          <img
            className="bgimg"
            src="./images/career-banner.webp"
            width="100%"
            style={{
              minHeight: "650px",
              maxHeight: "62vh",
              height: "62vh",
              objectPosition: "center top",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              width: "100%",
              color: "#fff",
              transform: "translateY(-50%)",
              zIndex: "9",
            }}
          >
            <Grid container spacing={3}>
              <Grid
                xs={12}
                md={8}
                display="flex"
                gap="32px"
                flexDirection="column"
              >
                <h2>Give wings to your career at Jio Insurance Broking</h2>
                <h6>
                  Mail your resume to apply for one of the best work
                  opportunities.
                </h6>
                <JDSButtons
                  text={"Apply now"}
                  variant={"text"}
                  className={"primaryBtn"}
                  fullWidth={false}
                  onClick={() => {
                    window.location.href = "mailto:human.resource@jioinsure.in";
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* Hero Banner End */}

      <Grid container className="row" columnSpacing={3} marginBottom={"64px"}>
        <Grid xs={12} textAlign={"center"}>
          <h2 style={{ marginBottom: "16px" }}>Life at Jio</h2>
          <p style={{ color: COLORS.lightgrey }}>
            Jio Insurance Broking aims to create a sustainable, nimble, and
            forward-looking ‘talent-intensive’ organisation fostering long term
            value creation for all stakeholders and an ‘enriching place to work’
            globally.
          </p>
        </Grid>

        {/* <Grid xs={12}>
          <h2 style={{ marginBottom: "16px" }}>Our work culture</h2>
          <h6 style={{ color: COLORS.lightgrey, marginBottom: "24px" }}>
            See through our lens and have a glimpse at our work culture.
          </h6>
        </Grid>
        <Grid xs={12} className="partners-carousel">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            partialVisible={false}
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            containerClass={`custom-carousel-track ${styles.customCarouselTrack}`}
            customTransition="transform 0.5s ease-in-out"
          >
            {careerSlider.map((data, index) => {
              return (
                <Box
                  sx={{
                    borderRadius: "24px",
                    overflow: "hidden",
                    width: "278px",
                    height: "278px",
                    marginBottom: "32px",
                  }}
                >
                  <img src={data.imgUrl} style={{ width: "100%" }} />
                </Box>
              );
            })}
          </Carousel>
        </Grid> */}
      </Grid>

      {/* <Box className={styles.career_section}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box className={styles.career_openingBox}>
              <Box flex={"1 0 0"}>
                <p
                  style={{
                    color: "#FDE0B7",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                  }}
                >
                  CAREERS @ JIO
                </p>
                <h1
                  className="mb-3"
                  style={{
                    color: "#FDE0B7",
                    fontSize: "40px",
                    lineHeight: "40px",
                  }}
                >
                  Work with us
                </h1>
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: "24px",
                    fontWeight: "500px",
                    lineHeight: "32px",
                    marginBottom: "32px",
                  }}
                >
                  Join us in reshaping the insurance landscape and making a
                  difference in people's lives
                </p>
                <JDSButtons
                  text={
                    <>
                      {`human.resource@jioinsure.in`} <EastIcon />
                    </>
                  }
                  variant={"text"}
                  className={"primaryBtn large"}
                  fullWidth={false}
                  onClick={() => {
                    window.location.href = "mailto:human.resource@jioinsure.in";
                  }}
                />
              </Box>
              <Box flex={"1 0 0"}>
                <img src="./images/work-with-us.jpg" width="100%" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box> */}

      <Footer />
    </Box>
  );
}

export default Career;
