import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function Disclaimer() {
  const isMobile = useIsMobile();
  return (
    <Box className={styles.cmsWrapper}>
      {isMobile ? <MNavBar title={"Legal Disclaimer"} /> : <NavBar />}

      <Grid container spacing={3} className={styles.cmsPages}>
        <Grid xs={12}>
          <h2>Legal Disclaimer</h2>
          <p>
            The contents of this site, including but not limited to the text,
            graphics, images herein and their arrangement, belongs to and is the
            property of Jio Insurance Broking Ltd. (JIBL). JIBL has launched
            this website to provide general information for its users. We
            request you to read the conditions before using this site as JIBL
            can at any time and at its sole discretion modify the terms and
            conditions contained herein. By using this website, you consent to
            be bound by the terms and conditions of use without limitation or
            qualification.
          </p>
          <p>
            JIBL allows the use of information available on this site only for
            your personal or informational and non-commercial purposes and not
            for being copied or posted on any network computer or to broadcast
            in or any form of media. JIBL will not be responsible or liable for
            the accuracy, reliability, copyright, compliance, legality or
            decency of any content or any interruption in service. The
            information and descriptions contained herein are not intended to be
            complete descriptions of all terms, exclusions, and conditions
            applicable to the products and services, but are provided solely for
            general informational purposes.
          </p>
          <h3>No Warranties</h3>
          <p>
            This website, information and materials on the site, and any
            software made available on the web site, are provided "as is"
            without any representation or warranty, express or implied, of any
            kind, including, but not limited to, warranties of merchantability,
            non-infringement, or fitness for any particular purpose or warranty
            of any kind, express or implied, regarding third party content. In
            spite of JIBL's best endeavours, there is no warranty of the web
            site being free of any computer viruses.
          </p>
          <p>
            Nothing on this website should be construed or treated as legal
            advice. Whilst we endeavour to ensure that the information on this
            website is correct, we do not warrant its completeness or accuracy;
            nor do we commit to ensuring that the website remains available or
            that the material on the website is kept up to date. To the maximum
            extent permitted by applicable law we exclude all representations,
            warranties and conditions relating to this website and the use of
            this website (including, without limitation, any warranties implied
            by law of satisfactory quality, fitness for purpose and/or the use
            of reasonable care and skill).
          </p>
          <h3>Limitation of Damages</h3>
          <p>
            In no event shall JIBL or any of its subsidiaries or affiliates be
            liable to any entity and/or individual for any direct, indirect,
            special, consequential or other damages (including, without
            limitation, any lost profits, business interruption, loss of
            information or programs or other data on your information handling
            system) that are related to the use of, or the inability to use, the
            content, materials, and functions of this web site or any linked web
            site, even if JIBL is expressly advised of the possibility of such
            damages.
          </p>
          <h3>Unlawful Use of this Website</h3>
          <p>
            We reserve the right to investigate complaints or reported
            violations of this website and to take any action as deemed
            appropriate including but not limited to reporting any suspected
            unlawful activity to law enforcement officials, regulators, or other
            third parties and disclosing any information necessary or
            appropriate to such persons, or entities relating to user’s
            profiles, e-mails, addresses, usage history, posted materials, IP
            addresses etc.
          </p>
          <p>
            You may view, download for caching purposes only, and print pages
            from the website for your own personal use, subject to the
            restrictions below.
          </p>
          <p>
            You must not (a) republish material from this web site; (b) sell,
            rent or otherwise sub-license material from the website; (c) show
            any material from the website in public; (d) reproduce, duplicate,
            copy or otherwise exploit material on our website for a commercial
            purpose; (e) edit or otherwise modify any material on the website;
            or (f) redistribute material from this website.
          </p>
          <h3>Governing Law and Jurisdiction</h3>
          <p>
            JIBL intends to provide clear and unbiased information about
            insurance products and services and information on the website
            should not be construed as technical advice of any manner. By
            viewing it you are deemed to agree to jurisdiction of the courts at
            Mumbai, India in respect of any action arising there from or related
            thereto.
          </p>
          <h3>Ownership, License and Restrictions on Use</h3>
          <p>
            All right, title and interest (including all copyrights, trademarks,
            and other intellectual property rights) in this site belong to us.
            We grant you a non-transferable, limited license to view and use
            this site only for your personal information, education, or other
            non-commercial use. No part of this site may be reproduced,
            republished, copied, transmitted, or distribute in any manner
            without our express written permission.
          </p>
          <h3>Links</h3>
          <p>
            While visiting our Web site(s), you may leave the site and access
            certain sites other than JIBL’s, such as web sites of JIBL’s
            affiliates or third-party vendors, etc. We do not endorse and are
            not responsible for the content and accuracy of these sites. We also
            do not warrant that these sites are free from any claims of
            copyright, trademark, or other infringement of the rights of third
            parties, or that such sites are free of computer viruses.
          </p>
          <p>
            This disclaimer (together with our privacy policy and terms and
            conditions) constitutes the entire agreement between you and us in
            relation to your use of our website and supersedes all previous
            agreements in respect of your use of this website.
          </p>
          <h3>Scope of the Legal disclaimer</h3>
          <p>
            Wherever a reference of this disclaimer has been provided by JIBL
            with or without the web-link, the user/reader is expected to take
            complete cognizance of the disclaimer and its applicability as to
            the subject matter where such reference is provided.
          </p>
          <h3>Product Related Disclaimers</h3>
          <h4>• Insurance Cover</h4>
          <p>
            Insurance related information should not be considered exhaustive,
            and the user should read and understand the product information,
            including the scope of cover, terms, conditions, exclusions,
            limitations and other risk factors carefully. For more details on
            risk factors, terms and conditions please read sales brochure
            carefully before concluding a sale.
          </p>
          <h4>• Claims</h4>
          <p>
            Admission and settlement of insurance claim is the sole discretion
            the Insurer. JIBL shall only assist the client in knowing the status
            of the claims provided by the respective Insurers. As a condition
            for providing this information, you agree that neither JIBL nor any
            other person shall be liable for any direct, indirect, special,
            incidental, consequential, punitive, or exemplary damages, including
            lost profits arising in any way from the information contained
            herein. JIBL will not be liable to you, in any form or manner
            whatsoever, for any claims, disputes, proceedings which may arise.
          </p>
          <p>Insurance is a subject matter of solicitation.</p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Disclaimer;
