import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useState } from "react";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  calculateAge,
  formatNumberToLakhOrCrores,
  formatNumberWithCurrencySymbol,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../../SupportingFiles/colors";
import { TSelectedQuote } from "../../../../../types/TTermSlice";
import "./TermQuotePolicyDetail.scss";

function TermQuotePolicyDetail({
  open,
  setOpen,
  data,
  BUY_ACTION,
}: {
  open: boolean;
  setOpen: any;
  data: TSelectedQuote;
  BUY_ACTION: Function;
}) {
  const { QUOTE_FIELDS_DATA, ADD_FORM, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Term
  );
  const exclusions = [
    "In case of death due to suicide within 12 months from the date of inception of the Policy, the Nominee of the Policyholder shall be entitled to Fund Value, as available on the date of death. Any charges recovered subsequent to the date of death shall be paid-back to Nominee along with death benefit.",
  ];
  const [openEmailQuotes, setOpenEmailQuotes] = useState<boolean>(false);
  const download = (value: string) => {
    window.open(value, "_blank");
  };
  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: COLORS.lightbg,
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "96px ",
            overflowY: "auto",
            zIndex: "999",
          }}>
          <Box
            sx={{
              backgroundColor: COLORS.primary,
              height: "72px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 12px #ddd",
            }}>
            <Grid container spacing={3} alignItems="center" width="100%">
              <Grid xs={5} display={"flex"} alignItems={"center"}>
                <Link
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    paddingRight: "4px",
                    color: COLORS.black,
                  }}
                  onClick={() => setOpen(false)}>
                  <ArrowBackIosNewRoundedIcon />
                </Link>
                <img
                  src="../images/jio_insurance_logo.svg"
                  alt="Jio Insurance Brokers"
                  height="16px"
                />
              </Grid>
              {/* <Grid xs={7} textAlign="right">
                <Link
                  sx={{
                    color: "#2F2311",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                    textDecoration: "none",
                    padding: "8px 16px",
                  }}
                >
                  <img src="../images/share-icon.svg" height="24px" />
                  Share
                </Link>
              </Grid> */}
            </Grid>
          </Box>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid xs={12}>
              <h3>Plan features</h3>
            </Grid>
            <Grid xs>
              <Box
                sx={{
                  backgroundColor: COLORS.lightMariGold20,
                  borderRadius: "24px 24px 0px 0px",
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}>
                <Box display="flex" alignItems="center" gap="8px" width="100%">
                  <img src={data?.CompanyDetails.logo} height="50px" />
                  <Box>
                    <p style={{ fontWeight: "bold" }}>
                      {data.CompanyDetails.short_desc}
                    </p>
                    <p>
                      <small>{data.productDetails.product_name}</small>
                    </p>
                  </Box>
                </Box>
                {/* <Box>
                  <BorderButton
                    text="Share"
                    variant="small"
                    icon={
                      <ShareIcon
                        style={{ fontSize: "16px", marginRight: "14px" }}
                      />
                    }
                    onClick={() => {}}
                  />
                </Box> */}
              </Box>
              <Box className="featureTabs">
                <Box padding="24px 24px 8px 24px">
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Eligibility
                  </h6>
                  <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}>
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.CompanyDetails.claim_ratio}%
                      </p>
                      <p style={{ color: COLORS.lightgrey }}>
                        <small>
                          Claim Settlement <br />
                          Ratio
                        </small>
                      </p>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}>
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {QUOTE_FIELDS_DATA.payMode === "12"
                          ? "Monthly"
                          : QUOTE_FIELDS_DATA.payMode === "4"
                          ? "Quaterly"
                          : QUOTE_FIELDS_DATA.payMode === "2"
                          ? "Half-yearly"
                          : QUOTE_FIELDS_DATA.payMode === "1"
                          ? "Yearly"
                          : QUOTE_FIELDS_DATA.payMode === "5"
                          ? "Single Pay"
                          : null}
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}>
                        <small>
                          Premium <br />
                          Frequency
                        </small>
                      </p>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}>
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {
                          data?.productDetails?.term_form_validation
                            ?.max_p_cess_age
                        }{" "}
                        Years
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}>
                        <small>
                          Max. Maturity <br />
                          Age
                        </small>
                      </p>
                    </Box>
                  </Box>
                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Tax Benefit
                  </h6>
                  <p
                    style={{
                      display: "flex",
                      gap: "8px",
                      color: COLORS.lightgrey,
                    }}>
                    <img
                      src="../images/success_icon.svg"
                      height="18px"
                      style={{ position: "relative", top: "2px" }}
                    />{" "}
                    Income tax benefit on the premium paid as per Section 80C
                    and on the claims received as per Section 10(10D) of the
                    Income Tax Act.
                  </p>
                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Policy Exclusion
                  </h6>
                  <ul>
                    {exclusions.map((item, index) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}>
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}>
                          <img
                            src="../images/cross-icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid xs maxWidth="408px">
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}>
                <h3>Summary</h3>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  Cover Amount:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatNumberToLakhOrCrores(
                      parseInt(QUOTE_FIELDS_DATA.sumAssured)
                    )}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  Member:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {ADD_FORM.gender.value === "M" ? "Male" : "Female"} -{" "}
                    {`${calculateAge(ADD_FORM.age.value)} yrs, `}
                    {ADD_FORM.smoke === "Y" ? "Smoker" : "Non Smoker"}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  Policy Paying Term:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {data.premiumDetails.pay_term} Years
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  Final Premium:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatNumberWithCurrencySymbol(
                      data.premiumDetails?.finalPremium
                    )}
                  </span>
                </h6>
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                  }}>
                  <Box>
                    {/* <BorderButton
                      text="Back"
                      onClick={() => {
                        setOpen(false);
                      }}
                    /> */}
                    <JDSButtons
                      className="secondaryBtn large"
                      text="Back"
                      variant="text"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </Box>
                  <JDSButtons
                    className="primaryBtn large"
                    text="Next"
                    variant="text"
                    onClick={() => {
                      BUY_ACTION(data);
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  marginTop: "32px",
                }}>
                <h6 style={{ fontWeight: "bold" }}>Plan Brochures/Document</h6>
                <Box>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                    }}
                    onClick={() => {
                      download(data.productDetails.brochure);
                    }}>
                    Brochure{" "}
                    <img src="../images/download-icon.svg" width="24px" />
                  </Link>
                  {isEmpty(data.premiumDetails.EBI_PDF_url) ? null : (
                    <Link
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: COLORS.darkgrey,
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        paddingBottom: "12px",
                        marginBottom: "12px",
                      }}
                      onClick={() => {
                        download(data.premiumDetails.EBI_PDF_url);
                      }}>
                      Benefit Illustration{" "}
                      <img src="../images/download-icon.svg" width="24px" />
                    </Link>
                  )}
                  {/*  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                    }}
                  >
                    Policy Wordings{" "}
                    <img src="../images/download-icon.svg" width="24px" />
                  </Link> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
}

export default TermQuotePolicyDetail;
