import React from "react";
import { Box, Button } from "@mui/material";
import "./JDSButtons.scss";
import Loader from "../../Global/Loader/Loader";

interface ButtonProps {
  onClick?: Function;
  text: React.ReactNode;
  variant: "text" | "outlined" | "contained";
  className: string;
  fullWidth?: boolean;
  loader?: boolean;
}

const JDSButtons: React.FC<ButtonProps> = ({
  onClick = () => {},
  text,
  variant,
  className,
  fullWidth = true,
  loader = false,
}) => {
  return (
    <Box className={`${className} ${loader ? "loading" : ""}`}>
      <Button
        id={`${text}`}
        variant={variant}
        onClick={() => {
          onClick();
        }}
        disableRipple
        fullWidth={fullWidth}
      >
        {loader ? <Loader size="small" /> : text}
      </Button>
    </Box>
  );
};

export default JDSButtons;
