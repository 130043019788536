import { toast } from "react-toastify";
import { JWT_SECRET_KEY } from "../URLCollection/Domain";

function base64UrlDecode(str: string): string {
  try {
    const base64 = str.replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    let result = "";

    for (let i = 0; i < rawData.length; ++i) {
      result += String.fromCharCode(rawData.charCodeAt(i));
    }

    return result;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";

    toast.error(`Error decoding base64 URL: ${errorMessage}`);
    throw error;
  }
}

function base64UrlEncode(str: string): string {
  try {
    const base64 = btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
    return base64;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";
    toast.error(`Error encoding base64 URL: ${errorMessage}`);
    throw error;
  }
}

function parseJwt(token: string) {
  try {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("JWT must have 3 parts");
    }

    const header = JSON.parse(base64UrlDecode(parts[0]));
    const payload = JSON.parse(base64UrlDecode(parts[1]));
    const signature = parts[2];

    return { header, payload, signature };
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";
    toast.error(`Error parsing JWT: ${errorMessage}`);
    throw error;
  }
}

export async function verifyJwt(
  token: string
): Promise<{ valid: boolean; payload?: any; expired?: boolean }> {
  try {
    const secret = JWT_SECRET_KEY;

    const { header, payload, signature } = parseJwt(token);
    const encodedHeader = base64UrlEncode(JSON.stringify(header));
    const encodedPayload = base64UrlEncode(JSON.stringify(payload));
    const data = `${encodedHeader}.${encodedPayload}`;

    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(secret),
      { name: "HMAC", hash: { name: "SHA-256" } },
      false,
      ["sign", "verify"]
    );

    const signatureArrayBuffer = await crypto.subtle.sign(
      "HMAC",
      cryptoKey,
      new TextEncoder().encode(data)
    );

    const signatureUint8Array = new Uint8Array(signatureArrayBuffer);
    let signatureBase64 = "";

    for (let i = 0; i < signatureUint8Array.length; i++) {
      signatureBase64 += String.fromCharCode(signatureUint8Array[i]);
    }

    signatureBase64 = base64UrlEncode(signatureBase64);

    const isSignatureValid = signatureBase64 === signature;

    if (!isSignatureValid) {
      return { valid: false };
    }

    const currentTime = Math.floor(Date.now() / 1000);
    const isExpired = payload.exp && payload.exp < currentTime;

    return { valid: !isExpired, payload, expired: isExpired };
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";
    toast.error(`Error verifying JWT: ${errorMessage}`);
    return { valid: false };
  }
}
