import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect } from "react";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import "../../../../../SCSS/ModalPopup.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  calculateAge,
  formatToCurrency,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../../SupportingFiles/colors";
import "./../../../Term/TermQuote/TermQuotePolicyDetail/TermQuotePolicyDetail.scss";
import { EProductId } from "../../../../../Enum/ENonTermComapnyCode";

function NonTermQuotePolicyDetail({
  open,
  setOpen,
  data,
  BUY_ACTION,
  openEbi,
  setOpenEbi,
}: {
  open: boolean;
  setOpen: any;
  data: any;
  BUY_ACTION: any;
  openEbi: any;
  setOpenEbi: any;
}) {
  const { ADD_FORM, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.NonTerm
  );
  useEffect(() => {
    // Apply overflow hidden when the modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = ""; // Reset overflow when modal is closed
    }
  }, [open]);


  const keyFeatures = [
    "Maturity Benefit : If the Policy is In Force and the Life Assured survives to the Date of Maturity, the Maturity Benefit shall be payable.",
    "Maturity Benefit = Base Sum Assured + Loyalty Addition + accrued Guaranteed Additions",
    "If the Policy is Paid Up and the Life Assured survives to the Date of Maturity, the Maturity Benefit payable shall be an amount equal to Paid up Sum Assured plus accrued guaranteed additions (if any).",
    "Once the Policy becomes paid-up, it will no longer be eligible for future Guaranteed Additions and Loyalty Addition.",
    "The Policy will terminate upon payment of the Maturity Benefit",
  ];
  const taxBenefits = [
    "Tax Benefit: Tax benefit may be available as per prevailing tax laws and may differ basis the Life Cover multiple (7 or 11) chosen at policy inception. Choice of life cover multiple is available only for regular pay and limited pay.",
    "It is recommended that you obtain professional advice for applicability of Income Tax benefit on premiums paid and benefits received.",
    "Goods & Services Tax, Cess (if any) and any other Statutory levy will be charged extra on Premiums as per prevailing rates. Tax laws are subject to amendments from time to time.",
    "The Company does not assume responsibility on tax implication mentioned anywhere in this document.",
  ];
  const exclusions = [
    "In case of death due to suicide within 12 months, from the date of commencement of risk under the policy or from the date of revival of the policy, as applicable the nominee or beneficiary of the policyholder shall be entitled to 100% of the Total Premiums Paid till the date of death or the surrender value as available on date of death, whichever is higher, provided the policy is in-force.",
  ];
  const openInNewTab = (url: any) => {
    window.open(`${url}`, "_blank");
  };

  const download = (value: string) => {
    window.open(value, "_blank");
  };

  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: COLORS.lightbg,
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "96px ",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS.primary,
              height: "72px",
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 12px #ddd",
            }}
          >
            <Grid
              container
              className="row"
              spacing={3}
              alignItems="center"
              width="100%"
            >
              <Grid xs={5} display={"flex"} alignItems={"center"}>
                <Link
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    paddingRight: "4px",
                    color: COLORS.black,
                  }}
                  onClick={() => setOpen(false)}
                >
                  <ArrowBackIosNewRoundedIcon />
                </Link>
                <img
                  src="../images/jio_insurance_logo.svg"
                  alt="Jio Insurance Brokers"
                  height="16px"
                />
              </Grid>
              <Grid xs={7} textAlign="right">
                {/* <Link
                  sx={{
                    color: "#2F2311",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                    textDecoration: "none",
                    padding: "8px 16px",
                  }}
                >
                  <img src="../images/share-icon.svg" height="24px" />
                  Share
                </Link> */}
              </Grid>
            </Grid>
          </Box>
          <Grid className="row" container spacing={3}>
            <Grid xs={12}>
              <h3>Plan features</h3>
            </Grid>
            <Grid xs>
              <Box
                sx={{
                  backgroundColor: COLORS.lightMariGold20,
                  borderRadius: "24px 24px 0px 0px",
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <Box display="flex" alignItems="center" gap="8px" width="100%">
                  <img
                    src={data?.quotationDetail?.companyDetails?.logo}
                    height="50px"
                  />
                  <Box>
                    <p style={{ fontWeight: "bold" }}>
                      {data?.quotationDetail?.productDetails.product_name}
                    </p>
                    {/* <p>
                      <small>{data.productDetails.product_name}</small>
                    </p> */}
                  </Box>
                </Box>
                <Box>
                  {/* <JDSButtons
                    className="secondaryBtn small"
                    text={
                      <>
                        <ShareIcon
                          style={{ fontSize: "16px", marginRight: "14px" }}
                        />
                        Share
                      </>
                    }
                    variant="text"
                  /> */}
                </Box>
              </Box>

              <Box className="featureTabs">
                <Box padding="24px 24px 8px 24px">
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Eligibility
                  </h6>
                  {/* <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    {data?.quotationDetail?.specialFeatures?.premiumPayingTerm ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.premiumPayingTerm}
                        </p>
                        <p style={{ color: COLORS.lightgrey }}>
                          <small>Premium Paying Term</small>
                        </p>
                      </Box>
                    ) : null}
                    {data?.quotationDetail?.premiumDetails.risk_cover_cal ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {formatToCurrency(
                            data?.quotationDetail?.premiumDetails.risk_cover_cal
                          )}
                        </p>
                        <p style={{ color: COLORS.lightgrey }}>
                          <small>Risk Cover</small>
                        </p>
                      </Box>
                    ) : null}
                    {data?.quotationDetail?.specialFeatures?.policyTerm ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.policyTerm}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Policy Term</small>
                        </p>
                      </Box>
                    ) : null}
                    {data?.quotationDetail?.specialFeatures?.payFrequency ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.payFrequency}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Premium Frequency</small>
                        </p>
                      </Box>
                    ) : null}

                    {data?.quotationDetail?.specialFeatures?.maximumMaturityAge ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          {data?.quotationDetail?.specialFeatures?.maximumMaturityAge}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Max Maturity Age</small>
                        </p>
                      </Box>
                    ) : null}

                    {data?.quotationDetail?.specialFeatures?.risk_cover_cal ? (
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "16px",
                          padding: "8px 12px",
                          textAlign: "center",
                          flex: 1,
                          minWidth: "140px",
                        }}
                      >
                        <img src="../images/tick-icon.svg" height="21px" />
                        <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                          1.5 Lac
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            lineHeight: "16px",
                          }}
                        >
                          <small>Risk Cover</small>
                        </p>
                      </Box>
                    ) : null}
                  </Box> */}

                  <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}
                    >
                      <img alt="" src="../jio/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.quotationDetail?.companyDetails?.claim_ratio}%
                      </p>
                      <p style={{ color: COLORS.lightgrey }}>
                        <small>
                          Claim Settlement <br />
                          Ratio
                        </small>
                      </p>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}
                    >
                      <img alt="" src="../jio/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {QUOTE_FIELDS_DATA.investmentMode === "12"
                          ? "Monthly"
                          : QUOTE_FIELDS_DATA.investmentMode === "4"
                            ? "Quarterly"
                            : QUOTE_FIELDS_DATA.investmentMode === "2"
                              ? "Half-yearly"
                              : QUOTE_FIELDS_DATA.investmentMode === "1"
                                ? "Yearly"
                                : QUOTE_FIELDS_DATA.investmentMode === "5"
                                  ? "Single Pay"
                                  : null}
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                      >
                        <small>
                          Premium <br />
                          Frequency
                        </small>
                      </p>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 24px",
                        textAlign: "center",
                      }}
                    >
                      <img alt="" src="../jio/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {
                          data?.quotationDetail?.productDetails
                            ?.InvestmentFormValidations?.max_p_cess_age
                        }{" "}
                        Years
                      </p>
                      <p
                        style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                      >
                        <small>
                          Max. Maturity <br />
                          Age
                        </small>
                      </p>
                    </Box>
                  </Box>

                  {/* <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  /> */}
                  {/* <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Key Features
                  </h6> */}
                  {/* <ul>
                    {data?.quotationDetail?.specialFeatures?.map((item: any, index: any) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}
                        >
                          <img
                            src="../images/success_icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item.featuresBenefits}
                        </p>
                      </li>
                    ))}
                  </ul> */}
                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "8px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Tax Benefit
                  </h6>
                  {/* <ul>
                    {data?.quotationDetail?.specialFeatures?.map((item: any, index: any) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}
                        >
                          <img
                            src="../images/success_icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item.taxBenefits}
                        </p>
                      </li>
                    ))}
                  </ul> */}
                  <hr
                    style={{
                      borderColor: "#E0E0E0",
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      marginTop: "8px",
                      marginBottom: "24px",
                    }}
                  />
                  <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                    Policy Exclusion
                  </h6>
                  {/* <ul>
                    {data?.quotationDetail?.specialFeatures?.map((item: any, index: any) => (
                      <li
                        style={{
                          paddingBottom: "16px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            gap: "8px",
                            color: COLORS.lightgrey,
                          }}
                        >
                          <img
                            src="../images/cross-icon.svg"
                            height="18px"
                            style={{ position: "relative", top: "2px" }}
                          />{" "}
                          {item.suicideExclusions}
                        </p>
                      </li>
                    ))}
                  </ul> */}
                </Box>
              </Box>
            </Grid>
            <Grid xs maxWidth="408px">
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <h3>Summary</h3>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Policy Terms:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {QUOTE_FIELDS_DATA.policyTerm} Years
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Pay Upto:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {QUOTE_FIELDS_DATA.payUpto} Years
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Investment Amount:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatToCurrency(ADD_FORM.investAmount.value)}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Maturity Amount:{" "}
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {formatToCurrency(
                      data?.quotationDetail?.premiumDetails?.maturity_benefit
                    )}
                  </span>
                </h6>
                <h6
                  style={{
                    color: COLORS.lightgrey,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Member:
                  <span style={{ color: COLORS.black, fontWeight: "bold" }}>
                    {ADD_FORM.gender.value === "M" ? "Male" : "Female"},{" "}
                    {`${calculateAge(ADD_FORM.age.value)} Years`}
                  </span>
                </h6>
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <Box>
                    <JDSButtons
                      className="secondaryBtn large"
                      text="Back"
                      variant="text"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </Box>
                  <JDSButtons
                    className="primaryBtn large"
                    text="Next"
                    variant="text"
                    onClick={() => {
                      if (data?.quotationDetail?.productDetails?.id === EProductId.ICICI_Pru_Gold_Deferred_Income ||
                        data?.quotationDetail?.productDetails?.id === EProductId.ICICI_Pru_Gold_Immediate_Income_with_Booster ||
                        data?.quotationDetail?.productDetails?.id === EProductId.ICICI_Pru_Gift_Pro_Level_Income ||
                        data?.quotationDetail?.productDetails?.id === EProductId.ICICI_Pru_Gold_Immediate_Income ||
                        data?.quotationDetail?.productDetails?.id === EProductId.ICICI_Pru_Gift_Pro_Increasing_Income && data?.quotationDetail?.premiumDetails?.EBI_PDF_url) {
                        setOpenEbi(true);
                      } else {
                        BUY_ACTION(data?.quotationDetail);
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  marginTop: "32px",
                }}
              >
                <h6 style={{ fontWeight: "bold" }}>Plan Brochures/Document</h6>
                <Box>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                    }}
                    onClick={() =>
                      openInNewTab(
                        data?.quotationDetail?.productDetails?.brochure
                      )
                    }
                  >
                    Brochure{" "}
                    <img src="../images/download-icon.svg" width="24px" />
                  </Link>
                  {isEmpty(
                    data?.quotationDetail?.premiumDetails?.EBI_PDF_url
                  ) ? null : (
                    <Link
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: COLORS.darkgrey,
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E0E0E0",
                        paddingBottom: "12px",
                        marginBottom: "12px",
                      }}
                      onClick={() => {
                        download(
                          data.quotationDetail?.premiumDetails.EBI_PDF_url
                        );
                      }}
                    >
                      Benefit Illustration{" "}
                      <img src="../images/download-icon.svg" width="24px" />
                    </Link>
                  )}
                  <Modal open={openEbi} onClose={() => setOpenEbi(false)}>
                    <Box className="modalWrapper">
                      <Box
                        sx={{
                          transform: "translate(0,0)",
                          transition: "trapnsform 0.3s",
                          minHeight: "100vh",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box className="modalContent ">
                          <Grid
                            className="topbar"
                            container
                            alignItems="center"
                          >
                            <Grid xs={12} textAlign={"right"}>
                              <Button
                                disableRipple
                                className="closePopup"
                                onClick={() => {
                                  setOpenEbi(false);
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} alignContent="flex-start">
                            <Grid xs={12} textAlign={"center"}>
                              <iframe
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  data.quotationDetail?.premiumDetails
                                    .EBI_PDF_url
                                )}&embedded=true`}
                                style={{
                                  minWidth: "500px",
                                  width: "100%",
                                  height: "700px",
                                }}
                                title="PDF Viewer"
                              ></iframe>
                            </Grid>
                            <Grid
                              xs={12}
                              textAlign={"center"}
                              marginTop={"12px"}
                              display={"flex"}
                              gap={"16px"}
                              justifyContent={"center"}
                            >
                              <JDSButtons
                                className="primaryBtn "
                                text="Continue"
                                variant="text"
                                onClick={() =>
                                  BUY_ACTION(data?.quotationDetail)
                                }
                                fullWidth={false}
                              />
                              <JDSButtons
                                className="primaryBtn "
                                text={
                                  <>
                                    <DownloadIcon />
                                  </>
                                }
                                variant="text"
                                onClick={() => {
                                  download(
                                    data?.quotationDetail?.premiumDetails
                                      ?.EBI_PDF_url
                                  );
                                }}
                                fullWidth={false}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                  {/* <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    Prospectus{" "}
                    <img src="../images/download-icon.svg" width="24px" />
                  </Link>
                  <Link
                    sx={{
                      display: "flex",
                                  
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                    }}
                  >
                    Policy Wordings{" "}
                    <img src="../images/download-icon.svg" width="24px" />
                  </Link> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
}

export default NonTermQuotePolicyDetail;
