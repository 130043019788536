import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HealthForm from "../../../Page/Desktop/Health/HealthForm/HealthForm";
import MHealthForm from "../../../Page/Mobile/Health/MHealthForm/MHealthForm";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_FORM_SERVICES } from "../../../Services/HealthServices/HealthFormServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { child_gender } from "../../../SupportingFiles/HealthHelper";
import {
  detectWebViewEnvironment,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { THealthAddForm } from "../../../types/Health/THealthSlice";
import { useLocation } from "react-router-dom";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";

function HealthFormContainer({
  open,
  setOpen,
  loader,
}: {
  open: any;
  setOpen: Function;
  loader: boolean;
}) {
  const isMobile = useIsMobile();
  const { Health } = useAppSelector((state) => state);
  const { ADD_FORM, REDIRECTED } = useAppSelector((state) => state.Health);
  const [formData, setFormData] = useState<THealthAddForm>(ADD_FORM);
  const [showLoader, setShowLoader] = useState(false);
  const [utmMedium, setutmMedium] = useState<string | null>("");
  const [utmSource, setUtmSource] = useState<string | null>("");
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utm_medium = queryParams.get("utm_medium");
    const utm_source = queryParams.get("utm_source");
    setutmMedium(utm_medium);
    setUtmSource(utm_source);
  }, []);
  const removeDobValuesIfNeeded = (data: THealthAddForm) => {
    const updatedData = { ...data };
    const totalChildrenCount =
      (updatedData.son_status ? parseInt(updatedData.son_count.value) : 0) +
      (updatedData.daughter_status
        ? parseInt(updatedData.daughter_count.value)
        : 0);
    // Clear DOB fields for children exceeding the total count
    if (totalChildrenCount < 4)
      updatedData.child_four_dob = { value: "", warning: false };
    if (totalChildrenCount < 3)
      updatedData.child_three_dob = { value: "", warning: false };
    if (totalChildrenCount < 2)
      updatedData.child_two_dob = { value: "", warning: false };
    if (totalChildrenCount < 1)
      updatedData.child_one_dob = { value: "", warning: false };

    return updatedData;
  };

  const updateMasterState = (attrName: any, value: any) => {
    setFormData((prev: any) => {
      let updatedData = { ...prev };

      switch (attrName) {
        case "whom_to_insure":
        case "children":
        case "self":
          updatedData[attrName] = value;
          break;

        case "son_status":
          if (value === true) {
            if (parseInt(prev.daughter_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.son_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.son_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;
        case "spouse_status":
          if (value === true) {
            updatedData[attrName] = value;
          } else {
            updatedData[attrName] = value;
            updatedData.spouse_dob = { value: "", warning: false };
            updatedData.spouse_relationship = { value: "", warning: false };
          }
          break;

        case "daughter_status":
          if (value === true) {
            if (parseInt(prev.son_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.daughter_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.daughter_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;

        case "pincode":
          updatedData[attrName] = {
            value: value,
            warning: !validatePincode(value),
          };
          break;

        case "mobile":
          updatedData[attrName] = {
            value: value,
            warning: !validateMobileNumber(value),
          };
          break;

        case "son_subtract":
          const newSonCount = Math.max(parseInt(prev.son_count.value) - 1, 0);
          if (newSonCount === 0) {
            updatedData.son_status = false;
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          } else {
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          }

          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_subtract":
          const newDaughterCount = Math.max(
            parseInt(prev.daughter_count.value) - 1,
            0
          );
          if (newDaughterCount === 0) {
            updatedData.daughter_status = false;
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          } else {
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          }
          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.daughter_count = {
              value: (parseInt(prev.daughter_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        case "son_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.son_count = {
              value: (parseInt(prev.son_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        default:
          updatedData[attrName] = { value: value, warning: isEmpty(value) };
      }

      return updatedData;
    });
  };

  const validateForm = () => {
    let data: THealthAddForm = { ...formData };
    const total_no_of_child =
      parseInt(data.daughter_count.value) + parseInt(data.son_count.value);

    data = {
      ...data,
      self_dob: {
        ...data.self_dob,
        warning: isEmpty(data.self_dob.value),
      },
      spouse_dob: {
        ...data.spouse_dob,
        warning: data.spouse_status ? isEmpty(data.spouse_dob.value) : false,
      },
      spouse_relationship: {
        ...data.spouse_relationship,
        warning: data.spouse_status
          ? isEmpty(data.spouse_relationship.value)
          : false,
      },
      child_one_dob: {
        ...data.child_one_dob,
        warning:
          total_no_of_child > 0 ? isEmpty(data.child_one_dob.value) : false,
      },
      child_two_dob: {
        ...data.child_two_dob,
        warning:
          total_no_of_child > 1 ? isEmpty(data.child_two_dob.value) : false,
      },
      child_three_dob: {
        ...data.child_three_dob,
        warning:
          total_no_of_child > 2 ? isEmpty(data.child_three_dob.value) : false,
      },
      child_four_dob: {
        ...data.child_four_dob,
        warning:
          total_no_of_child > 3 ? isEmpty(data.child_four_dob.value) : false,
      },
    };
    setFormData({ ...data });

    if (
      !data.self_dob.warning &&
      !data.spouse_dob.warning &&
      !data.spouse_relationship.warning &&
      !data.child_one_dob.warning &&
      !data.child_two_dob.warning &&
      !data.child_three_dob.warning &&
      !data.child_four_dob.warning
    ) {
      ADD_FORM_DETAILS(data);
    }
  };

  const ADD_FORM_DETAILS = (data: THealthAddForm) => {
    const onSuccess = (res: any) => {
      setShowLoader(false);
      const response = res.response;
      const error = res.error;

      if (!error) {
        const quote_no = response.quote_no;
        if (detectWebViewEnvironment()) {
          sessionStorage.setItem(
            SESSION_CONSTANTS.OPEN_HEALTH_FORM,
            JSON.stringify(true)
          );
          sessionStorage.setItem(
            SESSION_CONSTANTS.HEALTH_FORM_DATA,
            JSON.stringify(data)
          );
        }
        window.location.href = `${FRONTEND_DOMAIN}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${quote_no}&redirected=${REDIRECTED}`;
      } else {
        toast.error("Something went wrong");
      }
    };
    const onError = (error: any) => {
      setShowLoader(false);
    };

    let params = {
      healthSumInsured: "1000000",
      healthCover: `${data?.spouse_status ? "2A" : "1A"}${
        (parseInt(data?.son_count?.value) || 0) +
          (parseInt(data?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(data?.son_count?.value) || 0) +
              (parseInt(data?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      healthAdult: `${data?.spouse_status ? "2" : "1"}`,
      children: `${
        parseInt(data?.son_count?.value) + parseInt(data?.daughter_count?.value)
      }`,
      healthAge: data.self_dob.value,
      healthRelation: "self",
      healthGender: data.gender.value,
      health2Age: data.spouse_dob.value,
      health2Relation: data.spouse_relationship.value,
      childgender_1: child_gender("1", data),
      childage_1: data.child_one_dob.value,
      childrelation_1:
        child_gender("1", data) === "M"
          ? "son"
          : child_gender("1", data) === "F"
          ? "daughter"
          : "",
      childgender_2: child_gender("2", data),
      childage_2: data.child_two_dob.value,
      childrelation_2:
        child_gender("2", data) === "M"
          ? "son"
          : child_gender("2", data) === "F"
          ? "daughter"
          : "",
      childgender_3: child_gender("3", data),
      childage_3: data.child_three_dob.value,
      childrelation_3:
        child_gender("3", data) === "M"
          ? "son"
          : child_gender("3", data) === "F"
          ? "daughter"
          : "",
      childgender_4: child_gender("4", data),
      childage_4: data.child_four_dob.value,
      childrelation_4:
        child_gender("4", data) === "M"
          ? "son"
          : child_gender("4", data) === "F"
          ? "daughter"
          : "",
      healthName: "",
      healthMobile: data.mobile.value,
      healthEmail: data.email.value,
      healthPincode: data.pincode.value,
      utm_source: REDIRECTED ? "JFS" : "DIRECT",
    };
    setShowLoader(true);
    HEALTH_FORM_SERVICES.ADD_HEALTH_FORM(onSuccess, onError, params);
  };

  useEffect(() => {
    EVENT_TRACKER();
  }, [Health?.ADD_FORM]);

  const EVENT_TRACKER = () => {
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      gender: Health.ADD_FORM.gender.value,
      mobile_no: Health.ADD_FORM.mobile.value,
      email: Health.ADD_FORM.email.value,
      insurance_type: `Health Insurance`,
      name: Health.ADD_FORM.name.value,
      pincode: Health.ADD_FORM.pincode.value,
      sum_insured: Health.ADD_FORM_RESPONSE.sum_insured,
      state: Health.ADD_FORM_RESPONSE?.state,
      city: Health.ADD_FORM_RESPONSE?.city,
      cover: Health.ADD_FORM_RESPONSE?.cover,
      age: Health.ADD_FORM?.age?.value,
      dob: Health.ADD_FORM?.self_dob.value,
      utm_medium: utmMedium || "",
      utm_source: utmSource || "",
      utm_campaign: "",
      utm_keyword: "",
      cron_status: "",
    };
    HEALTH_FORM_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
  };

  return isMobile ? (
    <MHealthForm
      open={open}
      setOpen={setOpen}
      formData={formData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      loader={showLoader}
    />
  ) : (
    <HealthForm
      open={open}
      setOpen={setOpen}
      formData={formData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      loader={showLoader}
      EVENT_TRACKER={EVENT_TRACKER}
    />
  );
}

export default HealthFormContainer;
