import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "./NonTermFooter.scss";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import CustomButton from "../../CustomButton/CustomButton";

const NonTermFooter = ({
  forward,
  value,
  disableButton,
  backward,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { QUOTE_LOADER } = useAppSelector((state) => state.NonTerm);
  const path = window.location.pathname;
  const navigate = useNavigate();
  const handleClick = () => {
    if (!QUOTE_LOADER) {
      navigate(-1);
    }
  };
  return (
    <Box className="proposalFooter">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <Link
            className={`backStep ${QUOTE_LOADER ? "disabled" : ""}`}
            onClick={() => {
              if (typeof backward === "function") {
                backward(); // Call backward function if it exists
              } else {
                handleClick(); // Fallback to handleClick if backward is undefined or not a function
              }
            }}
            href={QUOTE_LOADER ? undefined : "#"}
            style={QUOTE_LOADER ? { pointerEvents: "none" } : {}}
          >
            <ArrowBackIcon /> {value === 0 ? " Back to Quotes" : "Back"}
          </Link>
          <div className="ctaBtn">
            {/* <Button
              className="greenMdBtn"
              onClick={() => {
                value_update(attrName, value + 1);
              }}
            >
              {value === 7 ? " Make Payment" : "Continue"}
            </Button> */}
            <CustomButton
              text_name={textName}
              class_name="greenMdBtn"
              onClickFunction={() => {
                forward && forward();
                value_update && value_update(attrName, value + 1);
              }}
              loading={loader}
              disabled={disableButton}
            />
            {/* This button will display only on preview page */}
            {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NonTermFooter;
