import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../../SCSS/ModalPopup.scss";
import ContentRadioButton from "../../../../../Component/InputFields/ContentRadioButton/ContentRadioButton";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import CustomCheckbox from "../../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import { COLORS } from "../../../../../SupportingFiles/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MNonTermRiders = ({
  open,
  setOpen,
  quoteListData,
  setQuoteListRiders,
}: {
  open: boolean;
  setOpen: Function;
  quoteListData: any;
  setQuoteListRiders: Function;
}) => {
  const [addonsArray, setAddonsArray] = useState([
    {
      label: "Accidental Death",
      attrName: "TF003",
      value: false,
    },
    {
      label: "Waiver of Premium",
      attrName: "TF004",
      value: false,
    },
    // {
    //   label: "Partial Withdrawal",
    //   attrName: "partialWithdrawal",
    //   value: false,
    // },
    {
      label: "Accidental Disability",
      attrName: "TF002",
      value: false,
    },
    // {
    //   label: "Family Income Benefit",
    //   attrName: "familyIncomeBenefit",
    //   value: false,
    // },
    {
      label: "Critical illness",
      attrName: "TF001",
      value: false,
    },
  ]);

  const handleAddonsCheckboxChange = (attrName: string, value: boolean) => {
    const index = addonsArray.findIndex((item) => item.attrName === attrName);
    if (index !== -1) {
      setAddonsArray((prev) => {
        const newArray = [...prev];
        newArray[index] = { ...newArray[index], value: value };
        return newArray;
      });
    }
  };

  const applyRidersFilter = () => {
    const filteredQuotes = quoteListData.filter((quote: any) => {
      const riders = quote.premiumDetails?.riders;
      return addonsArray.every((addon) => {
        return !addon.value || riders?.hasOwnProperty(addon.attrName);
      });
    });
    if (!Object.values(addonsArray).some((status) => status)) {
      setQuoteListRiders(quoteListData);
    } else {
      setQuoteListRiders(filteredQuotes);
    }
    setOpen(false);
  };

  useEffect(() => {
    setQuoteListRiders(quoteListData);
  }, [quoteListData]);

  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Box className="topbar">
            <Grid container className="row" alignItems="center">
              <Grid xs={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="scrollable-area">
            <Grid
              container
              className="row"
              columnSpacing={3}
              marginBottom={"24px"}
            >
              <Grid xs={12}>
                <h3 className="mb-5">Filter plans by</h3>
              </Grid>
              <Grid xs={12}>
                <Box
                  sx={{
                    padding: "0px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    marginBottom: "16px",
                  }}
                >
                  <Box>
                    <h6
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "JioType",
                        color: COLORS.darkgrey,
                      }}
                    >
                      Riders
                    </h6>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "JioType",
                        color: COLORS.lightgrey,
                      }}
                    >
                      You can check plans as per your desired benefits
                    </p>
                  </Box>

                  {addonsArray?.map((data: any, index: number) => (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "10px 14px",
                      }}
                    >
                      <CustomCheckbox
                        key={index}
                        label={data.label}
                        attrName={data.attrName}
                        value_update={handleAddonsCheckboxChange}
                        value={data.value}
                        defaultChecked={false}
                        disabled={false}
                      />
                    </Box>
                  ))}

                  {/* <Box marginTop={"12px"}>
                    <h6
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "JioType",
                        color: COLORS.darkgrey,
                        marginBottom: "12px",
                      }}
                    >
                      Growth
                    </h6>
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "10px 14px",
                      }}
                    >
                      <CustomCheckbox
                        label={"Standard 8%"}
                        attrName={""}
                        value_update={() => {}}
                      />
                    </Box>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className="footerbar">
            <JDSButtons
              text={"Apply"}
              variant={"text"}
              className={"primaryBtn large"}
              onClick={() => applyRidersFilter()}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MNonTermRiders;
