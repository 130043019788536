import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import "./FAQ.scss";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";

const FAQ = ({
  data,
  section_name,
}: {
  data: { title: string; desc: any }[];
  section_name?: string;
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);

      pushDataLayer(
        "jioinsure_home_faq",
        section_name === "1"
          ? "General"
          : section_name === "2"
          ? "Two wheeler"
          : section_name === "3"
          ? "Car"
          : section_name === "4"
          ? "Health"
          : "Term",
        `${data[Number(panel)].title}`.substring(0, 95),
        "Insure website"
      );
      const eventProps = {action:section_name === "1"
        ? "General"
        : section_name === "2"
        ? "Two wheeler"
        : section_name === "3"
        ? "Car"
        : section_name === "4"
        ? "Health"
        : "Term",click:`${data[Number(panel)].title}`.substring(0, 95),EntrySource: "Insure website"};
      sendEventToNativeApp("jioinsure_home_faq", eventProps);
    };
  return (
    <Box className="faq">
      {data.map((data, index) => (
        <Accordion
          expanded={expanded === `${index}`}
          onChange={handleChange(`${index}`)}>
          <AccordionSummary
            expandIcon={expanded === `${index}` ? <RemoveIcon /> : <AddIcon />}
            aria-controls={`${index}` + "bh-content"}
            id={`${index}` + "bh-header"}>
            <Typography>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.desc}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
