import React, { useState, useEffect } from "react";
import { Box, Button, Input, Link, Slider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./../../../../SCSS/ModalPopup.scss";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import SelectDropdown from "../../../InputFields/SelectDropdown/SelectDropdown";
import { COLORS } from "../../../../SupportingFiles/colors";

function MPolicyCancellation({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  return (
    <>
      {open == true ? (
        <Box className="popupBgOverlay">
          <Box className="contentWrapper">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="scrollable-area">
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <Box>
                    <h3 className="mb-5" style={{ fontWeight: "900" }}>
                      Policy Cancellation & Refund
                    </h3>
                    <p>
                      'Policy cancellation and refund of the premium shall be as
                      per the terms and conditions of the policy. The refunds
                      are processed by the Insurance Company directly. You are
                      requested to contact the toll free number of your
                      Insurance Company or refer the respective section of your
                      Policy terms and conditions. You can also write a mail to
                      us at'{" "}
                      <Link
                        sx={{ color: COLORS.primary }}
                        href="mailto:sales.force@jioinsure.in"
                      >
                        sales.force@jioinsure.in
                      </Link>
                      '. We shall be available to guide/assist you.'
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MPolicyCancellation;
