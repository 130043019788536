import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import JDSButtons from "../../../../InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../../InputFields/TextInputField/TextInputField";
import "./../../../../../SCSS/ModalPopup.scss";

const NeedHelpPopup = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const [currentStep, setCurrentStep] = useState(1); // State to manage the current step

  const handleRequestCallback = () => {
    // Function to handle "Request Call Back" button click and switch to step 2
    setCurrentStep(2);
    // You can also perform additional actions here if needed
  };
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent xsWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid xs={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
          {/* Step 1 */}
          <Grid
            container
            spacing={2}
            style={{ display: currentStep === 1 ? "block" : "none" }}>
            <Grid xs={12}>
              <h3>Need help?</h3>
              <p>We are happy to help you</p>
            </Grid>
            <Grid xs={12}>
              <TextInputField title="" />
            </Grid>
            <Grid xs={12} className="mt-2">
              <JDSButtons
                text={"Request Call Back"}
                variant={"text"}
                className={"primaryBtn large"}
                onClick={handleRequestCallback}
              />
            </Grid>
            <Grid xs={12}>
              <p style={{ fontWeight: "300", textAlign: "center" }}>
                You can also call on these helpline number
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: COLORS.primary,
                }}>
                +xx xx-xxxx-xxxx
              </p>
            </Grid>
          </Grid>

          {/* Step 2 */}
          <Grid
            container
            spacing={2}
            style={{ display: currentStep === 2 ? "block" : "none" }}>
            <Grid xs={12} textAlign="center">
              <CheckCircleIcon
                sx={{
                  height: "68px",
                  width: "68px",
                  color: COLORS.primary,
                  marginBottom: "8px",
                }}
              />
              <h3 style={{ width: "100%" }}>
                Thank you <br /> for requesting a call!
              </h3>
            </Grid>
            <Grid xs={12} textAlign="center">
              <h5
                style={{
                  fontWeight: "500",
                  color: COLORS.lightgrey,
                }}>
                We have received your request. <br />
                Our team will call you soon!
              </h5>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default NeedHelpPopup;
