import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import GenderRadio from "../../../Component/Global/GenderRadio/GenderRadio";
import BackLeft from "../../../Component/InputFields/BackLeft";
import BorderCheckBox from "../../../Component/InputFields/BorderCheckBox/BorderCheckBox";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePickerInput from "../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import SearchSelectDropdown from "../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import styles from "../../../SCSS/CMSPages.module.scss";
import { HEALTH_FORM_SERVICES } from "../../../Services/HealthServices/HealthFormServices";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { COLORS } from "../../../SupportingFiles/colors";
import { child_gender } from "../../../SupportingFiles/HealthHelper";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  detectWebViewEnvironment,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import { verifyJwt } from "../../../SupportingFiles/JWTDecoder";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import {
  changeHeaderFooterVisibility,
  isIOS,
} from "../../../SupportingFiles/SendEventToApp";
import { THealthAddForm } from "../../../types/Health/THealthSlice";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { pushDataLayer } from "../../../utils/analytics";
import HealthProductPageForm from "./HealthProductPageForm/HealthProductPageForm";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { getJFSJwtToken } from "../../../SupportingFiles/SendwtAuthToken";

function HealthProductPage() {
  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCards, setBlogCards] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCards(blog_data);
  }, []);

  const [utmMedium, setutmMedium] = useState<string | null>("");
  const [utmSource, setUtmSource] = useState<string | null>("");
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utm_medium = queryParams.get("utm_medium");
    const utm_source = queryParams.get("utm_source");
    setutmMedium(utm_medium);
    setUtmSource(utm_source);
  }, []);

  const blogCardsArray = blogCards.slice(0, 3);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { Health } = useAppSelector((state) => state);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);
  const defaultShow = 5;
  const [showAll, setShowAll] = useState(false);
  const [redirected, setRedirected] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [formData, setFormData] = useState<THealthAddForm>(
    HealthSlice.getInitialState().ADD_FORM
  );
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useAppDispatch();
  const [userMobileNumber, setUserMobileNumber] = useState("");


  const whySectionArray = [
    {
      iconUrl: "../images/right-cover.svg",
      heading: "Right cover",
      desc: "We recommend right cover amount as per your financial needs.",
    },
    {
      iconUrl: "../images/choose-best.svg",
      heading: "Help to choose the best",
      desc: "We help you choose plans from the best general and health insurance companies.",
    },
    {
      iconUrl: "../images/porting.svg",
      heading: "Porting",
      desc: "We help you to port your existing health insurance to save cost.",
    },
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Tax benefits",
      desc: "Get tax benefit under Section 80D.",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const testimonialsSlider = [
    {
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partnersSlider = [
    {
      imgUrl:
        "./images/insurerlogo/HDFC-ERGO-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Niva-Max-Bupa-Health-Insurance-Company-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/aditya_birla_capital.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    },
  ];
  const insuranceTypeArray = [
    {
      heading: "Individual health insurance",
      desc: "This type of insurance allows you to cover your parents, spouse, children, and yourself. It often covers a wide range of medical expenses, including hospital stays, surgical procedures, hospital room rent, and more. Each member in an individual health insurance plan has their own insured amount. For instance, if you have an individual plan with a sum insured of INR 9 lakhs for yourself, your spouse, and both parents, each of you can claim a maximum of Rs. 9 lakhs per policy year.",
    },
    {
      heading: "Family floater health insurance",
      desc: "A family floater plan covers all family members under one policy, sharing the total insured amount. These plans are often cost-effective than individual insurance. For example, if you and your spouse choose a family floater plan with a 9 lakh rupee sum insured, you can only make claims totaling INR 9 lakhs in a single policy year. Your spouse might file claims totaling INR 7 lakhs, while you might file claims totaling INR 2 lakhs, or vice versa. Family floater plans work best for young nuclear families.",
    },
    {
      heading: "Critical illness insurance",
      desc: "With the rising incidence of lifestyle-related diseases like cancer, stroke, kidney failure, and cardiac illnesses, long-term treatment and management costs can be substantial. Critical illness insurance provides coverage for specific high-cost conditions. It can be acquired as a standalone plan or as an add-on to your existing health insurance policy. In the event of diagnosis, these policies often pay out a lump sum amount and provide coverage for specific, well-defined illnesses.",
    },
    {
      heading: "Senior citizens health insurance",
      desc: "Designed with the specific medical needs of senior individuals in mind, these policies typically offer additional coverage, including mental health benefits and domiciliary hospitalisation. They may be more expensive than standard insurance policies and often require a comprehensive medical examination due to the higher health risks associated with older age.",
    },

    {
      heading: "Group health insurance",
      desc: "Group health insurance plans, unlike individual and family floater policies, can be obtained by a group manager for a large number of individuals. For example, a company may purchase group insurance for all its employees, or a building administrator may do the same for all the tenants in a building. While these plans are affordable, they usually cover basic medical conditions. Employers often purchase these policies as an additional employee benefit.",
    },
  ];
  const chooseInsuranceArray = [
    {
      heading: "Check the sum insured",
      desc: "Many insurance companies impose a cap on the highest sum insured you can select. It's crucial to choose a health insurance plan that aligns with your needs. A good rule of thumb is to have coverage that is at least 50% of your annual income. If you plan to start a family in the near future, ensure that maternity expenses are covered, as maternity benefits typically have longer waiting periods.",
    },
    {
      heading: "Examine the exclusions and other clauses",
      desc: "Every insurance policy has specific medical treatments, conditions, and exclusions that it will not cover. Before you buy a plan, make sure to check what is and isn’t covered. Look to see if there is a co-pay provision, the amount of the co-pay, and any applicable waiting periods. The ideal scenario includes shorter waiting times and voluntary co-pays.",
    },
    {
      heading: "Look for additional benefits",
      desc: "As the insurance industry is highly competitive, different policies offer various benefits. Some of the most popular ones include no-claim bonuses and the restoration of your insured amount. Always ensure that your chosen insurance plan offers these advantages. Continuously search for insurance products that provide you with extra perks.",
    },
    {
      heading: "Check the fine print",
      desc: "There are many limits and sub-limits in every health insurance policy. To determine the precise amount of coverage you will receive for any procedure or hospital stay, you must carefully review the policy documentation. For instance, certain plans might contribute to covering the cost of hospitalisation each day, but only up to INR 2,000 per day. If you were in a hospital where the monthly room rent was INR 4,000, you would be required to pay for half of the room cost. Check the pre- and post-hospitalization spending caps as well. Some plans cover only 30 days before hospitalisation and 60 days following hospitalisation, while others cover 60 and 90 days, respectively.",
    },
    {
      heading: "Access to network hospitals",
      desc: "Different insurance companies have their own network of hospitals who provide cashless hospitalisation facility. Find a policy that allows cashless claims at the best hospitals in your area. Verify that your preferred hospital is included in the network to simplify the process of receiving care.",
    },
  ];
  const howtoBuyData = [
    {
      image: "./images/ic_form_add.svg",
      title: "Fill in your details",
      description:
        "Just fill in your details and get insurance policy premium quotes from top insurers in a click.",
    },
    {
      image: "./images/ic_comparison.svg",
      title: "Compare quotes, select insurance",
      description:
        "Select from available quotes that suits your requirements and budget.",
    },
    {
      image: "./images/ic_quick_payment.svg",
      title: "Fill in proposal & make the payment",
      description:
        "Complete the fields in the proposal form and continue with the payments.",
    },
    {
      image: "./images/ic_sofa.svg",
      title: "Sit back & relax",
      description: "Relax as your policy will reach right away in your inbox!.",
    },
  ];

  // Modify Vehicle Detail Popup
  const [isEnterDetailModalOpen, setEnterDetailModalOpen] = useState(false);

  const faqs = [
    {
      title: "What is health insurance?",
      desc: "In simple terms, health insurance is a type of insurance that covers medical expenses that arise due to unexpected illness or accidents. These expenses could be related to hospitalisation costs, surgeries, day care procedures, cost of medicines,etc",
    },
    {
      title: "What types of health insurance products are available?",
      desc: (
        <>
          Below is the list of available health insurance products:
          <br /> • Individual health insurance. <br />• Family health insurance.
          <br /> • Top-Up health insurance.
          <br /> • Senior citizen health insurance.
          <br /> • Critical illness insurance.
          <br /> • Hospital daily cash.
        </>
      ),
    },
    {
      title: "What all is covered in a health insurance policy?",
      desc: (
        <>
          A health insurance plan offers comprehensive medical coverage against:
          <br />• Hospitalisation charges like bed charges, nursing charges,
          operation theatre expenses, etc.
          <br /> • Pre-hospitalization charges like ambulance expenses,
          diagnostic tests, etc.
          <br /> • Post-hospitalization charges like medicines, consulting, etc.
        </>
      ),
    },
    {
      title:
        "What is pre and post hospitalisation benefit in a health insurance policy?",
      desc: "Whenever you plan to have a surgery/medical procedure that requires hospitalisation, there are certain pre-hospitalization expenses such as diagnosis cost, Doctor consultations, etc. Similarly, post-discharge, there could be similar expenses to monitor the health of the insured patient; such expenses are termed as pre and post hospitalisation expenses.",
    },
    {
      title:
        "How many family members can be covered in a health insurance policy?",
      desc: (
        <>
          Below family members can be covered depending on the insurance plan:
          <br />
          <br /> • Spouse
          <br /> • Dependent children
          <br /> • Dependent legitimate or legally adopted children
          <br /> • Parents/Parents-in-law
        </>
      ),
    },
    {
      title: "What is the recommended age to buy health insurance?",
      desc: "You should buy health insurance as soon as you start earning. Your age is one of the most important factors that determine your health insurance premium; lower the age, lower the premium. This allows you to accumulate the cumulative benefits (for every claim-free year) by the time you are older, as you can avail for a higher coverage.",
    },
    {
      title: "What are the benefits of buying health insurance at a young age?",
      desc: "You get higher coverage, i.e., the sum assured at a lower premium. Probability of having a pre-existing illness is low hence waiting periods to start coverage of pre-existing cover will also not impact you. Also, common illnesses such as flu and accidental injury can happen at any age so staying covered even when you are young is important.",
    },
    {
      title:
        "What do you mean by pre-existing diseases with regards to a health insurance policy?",
      desc: "Pre-existing disease is a medical condition that already exists when one is purchasing a health insurance policy. Medical conditions such as high blood pressure, diabetes, thyroid, asthma, depression, etc. are considered as pre-existing ailments.",
    },
    {
      title:
        "Will my pre-existing diseases be covered in a health insurance policy?",
      desc: "Every insurer excludes coverage for pre-existing illnesses for a certain tenure, which is generally 3 years from the start of the policy. While buying an insurance policy, check if the pre-existing condition is temporarily not covered (pre-existing disease for the first few years) or it is completely excluded from the scope of coverage.",
    },
    {
      title:
        "What do you mean by Network/Non-network hospital with regards to a health insurance policy?",
      desc: "Network hospitals, often referred to as hospitals with cashless claim option, are tied up with your insurance company via a TPA (third party administrator) wherein you can avail the cashless hospitalisation benefit. However, as you get admitted at a non-network hospital, you have to pay the bills first and later claim for reimbursement.",
    },
  ];

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const removeDobValuesIfNeeded = (data: THealthAddForm) => {
    const updatedData = { ...data };
    const totalChildrenCount =
      (updatedData.son_status ? parseInt(updatedData.son_count.value) : 0) +
      (updatedData.daughter_status
        ? parseInt(updatedData.daughter_count.value)
        : 0);
    // Clear DOB fields for children exceeding the total count
    if (totalChildrenCount < 4)
      updatedData.child_four_dob = { value: "", warning: false };
    if (totalChildrenCount < 3)
      updatedData.child_three_dob = { value: "", warning: false };
    if (totalChildrenCount < 2)
      updatedData.child_two_dob = { value: "", warning: false };
    if (totalChildrenCount < 1)
      updatedData.child_one_dob = { value: "", warning: false };

    return updatedData;
  };

  const updateMasterState = (attrName: any, value: any) => {
    setFormData((prev: any) => {
      let updatedData = { ...prev };

      switch (attrName) {
        case "whom_to_insure":
        case "children":
        case "self":
          updatedData[attrName] = value;
          break;

        case "son_status":
          if (value === true) {
            if (parseInt(prev.daughter_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.son_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.son_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;
        case "spouse_status":
          if (value === true) {
            updatedData[attrName] = value;
          } else {
            updatedData[attrName] = value;
            updatedData.spouse_dob = { value: "", warning: false };
            updatedData.spouse_relationship = { value: "", warning: false };
          }
          break;

        case "daughter_status":
          if (value === true) {
            if (parseInt(prev.son_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.daughter_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.daughter_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;

        case "pincode":
          updatedData[attrName] = {
            value: value,
            warning: !validatePincode(value),
          };
          break;

        case "mobile":
          updatedData[attrName] = {
            value: value,
            warning: !validateMobileNumber(value),
          };
          break;

        case "son_subtract":
          const newSonCount = Math.max(parseInt(prev.son_count.value) - 1, 0);
          if (newSonCount === 0) {
            updatedData.son_status = false;
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          } else {
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          }

          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_subtract":
          const newDaughterCount = Math.max(
            parseInt(prev.daughter_count.value) - 1,
            0
          );
          if (newDaughterCount === 0) {
            updatedData.daughter_status = false;
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          } else {
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          }
          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.daughter_count = {
              value: (parseInt(prev.daughter_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        case "son_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.son_count = {
              value: (parseInt(prev.son_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        default:
          updatedData[attrName] = { value: value, warning: isEmpty(value) };
      }

      return updatedData;
    });
  };
  // const { Health } = useAppSelector((state) => state);
  const validateHealth = () => {
    // debugger;
    let data: THealthAddForm = { ...formData };
    let Hdata: any = { ...Health.ADD_FORM };

    if (currentStep === 0) {
      Hdata = {
        ...Hdata,
        pincode: {
          ...Hdata.pincode,
          warning: !validatePincode(`${Hdata.pincode.value}`),
        },
        mobile: {
          ...Hdata.mobile,
          warning: !validateMobileNumber(Hdata.mobile.value),
        },
        gender: {
          ...Hdata.gender,
          warning: isEmpty(Hdata.gender.value),
        },
        // email: {
        //   ...Hdata.email,
        //   warning: isEmpty(Hdata.email.value),
        // },
      };

      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          ADD_FORM: {
            ...Health.ADD_FORM,
            mobile: Hdata.mobile,
            pincode: Hdata.pincode,
            gender: Hdata.gender,
            // email: Hdata.email,
          },
        })
      );

      // Update Hdata with validation results

      if (
        !Hdata.pincode.warning &&
        Hdata.pincode.value &&
        !Hdata.mobile.warning &&
        Hdata.mobile.value &&
        !Hdata.gender.warning &&
        Hdata.gender.value 
        // !Hdata.email.warning &&
        // Hdata.email.value
      ) {
        pushDataLayer(
          "jioinsure_products_internal",
          "Health Insurance",
          `Get free quotes`,
          `${redirected ? "JFS app" : "Insure website"}`
        );
        handleNext();
        EVENT_TRACKER();
      }
    }

    if (currentStep === 2) {
      const total_no_of_child =
        parseInt(data.daughter_count.value) + parseInt(data.son_count.value);

      let isValid = !isEmpty(formData.self_dob.value);

      setFormData((prevData) => ({
        ...prevData,
        self_dob: { ...prevData.self_dob, warning: !isValid },
      }));

      data = {
        ...data,
        self_dob: {
          ...data.self_dob,
          warning: data.self_dob ? isEmpty(data.self_dob.value) : false,
        },
        spouse_dob: {
          ...data.spouse_dob,
          warning: data.spouse_status ? isEmpty(data.spouse_dob.value) : false,
        },
        spouse_relationship: {
          ...data.spouse_relationship,
          warning: data.spouse_status
            ? isEmpty(data.spouse_relationship.value)
            : false,
        },
        child_one_dob: {
          ...data.child_one_dob,
          warning:
            total_no_of_child > 0 ? isEmpty(data.child_one_dob.value) : false,
        },
        child_two_dob: {
          ...data.child_two_dob,
          warning:
            total_no_of_child > 1 ? isEmpty(data.child_two_dob.value) : false,
        },
        child_three_dob: {
          ...data.child_three_dob,
          warning:
            total_no_of_child > 2 ? isEmpty(data.child_three_dob.value) : false,
        },
        child_four_dob: {
          ...data.child_four_dob,
          warning:
            total_no_of_child > 3 ? isEmpty(data.child_four_dob.value) : false,
        },
      };
      setFormData({ ...data });
    }

    console.log("dddaaata", data);

    const mergedData = {
      ...data,
      pincode: Hdata.pincode,
      mobile: Hdata.mobile,
      gender: Hdata.gender,
      // emial: Hdata.email,
    };

    if (
      !data.self_dob.warning &&
      !data.spouse_dob.warning &&
      !data.spouse_relationship.warning &&
      !data.child_one_dob.warning &&
      !data.child_two_dob.warning &&
      !data.child_three_dob.warning &&
      !data.child_four_dob.warning &&
      !Hdata.pincode.warning &&
      !Hdata.mobile.warning &&
      // !Hdata.email.warning &&
      !Hdata.gender.warning
    ) {
      if (currentStep === 2) {
        ADD_FORM_DETAILS(mergedData);
      }
    }
  };

  const ADD_FORM_DETAILS = (data: THealthAddForm) => {
    const onSuccess = (res: any) => {
      setShowLoader(false);
      const response = res.response;
      const error = res.error;

      if (!error) {
        const quote_no = response.quote_no;
        if (detectWebViewEnvironment()) {
          sessionStorage.setItem(
            SESSION_CONSTANTS.OPEN_HEALTH_FORM,
            JSON.stringify(true)
          );
          sessionStorage.setItem(
            SESSION_CONSTANTS.HEALTH_FORM_DATA,
            JSON.stringify(data)
          );
        }
        window.location.href = `${FRONTEND_DOMAIN}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${quote_no}&redirected=${redirected}`;
      } else {
        toast.error("Something went wrong");
      }
    };
    const onError = (error: any) => {
      setShowLoader(false);
    };

    let params = {
      healthSumInsured: "1000000",
      healthCover: `${data?.spouse_status ? "2A" : "1A"}${
        (parseInt(data?.son_count?.value) || 0) +
          (parseInt(data?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(data?.son_count?.value) || 0) +
              (parseInt(data?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      healthAdult: `${data?.spouse_status ? "2" : "1"}`,
      children: `${
        parseInt(data?.son_count?.value) + parseInt(data?.daughter_count?.value)
      }`,
      healthAge: data.self_dob.value,
      healthRelation: "self",
      healthGender: data.gender.value,
      health2Age: data.spouse_dob.value,
      health2Relation: data.spouse_relationship.value,
      childgender_1: child_gender("1", data),
      childage_1: data.child_one_dob.value,
      childrelation_1:
        child_gender("1", data) === "M"
          ? "son"
          : child_gender("1", data) === "F"
          ? "daughter"
          : "",
      childgender_2: child_gender("2", data),
      childage_2: data.child_two_dob.value,
      childrelation_2:
        child_gender("2", data) === "M"
          ? "son"
          : child_gender("2", data) === "F"
          ? "daughter"
          : "",
      childgender_3: child_gender("3", data),
      childage_3: data.child_three_dob.value,
      childrelation_3:
        child_gender("3", data) === "M"
          ? "son"
          : child_gender("3", data) === "F"
          ? "daughter"
          : "",
      childgender_4: child_gender("4", data),
      childage_4: data.child_four_dob.value,
      childrelation_4:
        child_gender("4", data) === "M"
          ? "son"
          : child_gender("4", data) === "F"
          ? "daughter"
          : "",
      healthName: "",
      healthMobile: data.mobile.value,
      healthPincode: data.pincode.value,
      utm_source: redirected ? "JFS" : "DIRECT",
    };

    HEALTH_FORM_SERVICES.ADD_HEALTH_FORM(onSuccess, onError, params);
  };

  useEffect(() => {
    if(userMobileNumber && detectWebViewEnvironment()){
      dispatch(
        HealthSlice.actions.setAddFormData({
          ...Health.ADD_FORM,
          mobile: {
            value: userMobileNumber,
            warning:false,
          },
        })
      );
    }

  },[userMobileNumber])

  useEffect(() => {
    changeHeaderFooterVisibility(0, (response) => {
      console.log("Native app response:", response);
    });

    getJFSJwtToken()
      .then((res) => {
        var modifiedStr = `${JSON.stringify(res)}`;
        let token = modifiedStr.slice(1, -1);
        if (!isIOS()) {
          const response = JSON.parse(res);
          token = response.data.jwtToken;
        } else {
          // try {
          //   toast.success("responseIostry" + `${token}`);
          // } catch (error) {
          //   toast.error("Error processing iOS token: " + error);
          // }
        }
        // const response = JSON.parse(res);
        // const token = response?.data?.jwtToken;
        verifyJwt(token).then((result) => {
          if (!isEmpty(result?.payload?.data)) {
            setUserMobileNumber(result?.payload?.data.slice(2))
            const mobile_no = result?.payload?.data;
            setContactFormFields((prev) => ({
              ...prev,
              contact: { value: mobile_no.slice(2), warn_status: false },
            }));
            setFormData((prev) => ({
              ...prev,
              mobile: { value: `${mobile_no.slice(2)}`, warning: false },
            }));

            dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));
            dispatch(
              TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState())
            );
            dispatch(
              TWSlice.actions.BULK_UPDATE({
                ...TWSlice.getInitialState(),
              })
            );
            // setTimeout(() => {
              dispatch(
                HealthSlice.actions.BULK_UPLOAD({
                  ...HealthSlice.getInitialState(),
                  ADD_FORM: {
                    ...HealthSlice.getInitialState().ADD_FORM,
                    mobile: { value: `${mobile_no.slice(2)}`, warning: false },
                  },
                  REDIRECTED: true,
                })
              );
            // }, 500);
            setRedirected(true);
          } else {
            setRedirected(false);
            setContactFormFields((prev) => ({
              ...prev,
              contact: { value: "", warn_status: false },
            }));
            setFormData((prev) => ({
              ...prev,
              mobile: { value: "", warning: false },
            }));
          }
        });
      })
      .catch((error) => {
        // toast.error(`Failed to get JWT token: ${error.message}`);
      });
  }, []);

  type TContactUs = {
    name: { value: string; warn_status: boolean };
    contact: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    lookingForIns: { value: string; warn_status: boolean };
  };

  const [contactformFields, setContactFormFields] = useState<TContactUs>({
    name: { value: "", warn_status: false },
    contact: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    lookingForIns: { value: "", warn_status: false },
  });

  useEffect(() => {
    // Exit early if not in WebView
    if (!detectWebViewEnvironment()) return;

    const healthFormData = sessionStorage.getItem(
      SESSION_CONSTANTS.HEALTH_FORM_DATA
    );
    const openHealthForm = sessionStorage.getItem(
      SESSION_CONSTANTS.OPEN_HEALTH_FORM
    );

    if (healthFormData) {
      try {
        const parsedFormData = JSON.parse(healthFormData);
        // Set a timeout to delay the dispatch
        const timer = setTimeout(() => {
          setFormData({ ...parsedFormData });
          // Dispatch the parsed car form data to Redux
          dispatch(
            HealthSlice.actions.BULK_UPLOAD({
              ...Health,
              REDIRECTED: true,
              ADD_FORM: {
                ...Health.ADD_FORM,
                ...parsedFormData, // Update only the ADD_FORM
              },
            })
          );

          
          // Clean up healthFormData from sessionStorage
          // sessionStorage.removeItem("healthFormData");
        }, 500); // Delay of 500ms
        
        if (openHealthForm) {
          setCurrentStep(2);
          sessionStorage.removeItem(SESSION_CONSTANTS.OPEN_HEALTH_FORM);
        }
        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
      } catch (error) {
        console.error(
          "Failed to parse healthFormData from sessionStorage:",
          error
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!detectWebViewEnvironment()) return;

    // Cleanup function to save data before unmounting or route change
    return () => {
      sessionStorage.setItem(
        SESSION_CONSTANTS.HEALTH_FORM_DATA,
        JSON.stringify(Health.ADD_FORM)
      );
    };
  }, [Health.ADD_FORM]);

  // useEffect(() => {
  //   EVENT_TRACKER();
  // }, [Health.ADD_FORM]);

  const EVENT_TRACKER = () => {
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      gender: Health.ADD_FORM.gender.value,
      mobile_no: Health.ADD_FORM.mobile.value,
      // email: Health.ADD_FORM.email.value,
      insurance_type: `Health Insurance`,
      name: Health.ADD_FORM.name.value,
      pincode: Health.ADD_FORM.pincode.value,
      sum_insured: Health.ADD_FORM_RESPONSE.sum_insured,
      state: Health.ADD_FORM_RESPONSE?.state,
      city: Health.ADD_FORM_RESPONSE?.city,
      cover: Health.ADD_FORM_RESPONSE?.cover,
      age: Health.ADD_FORM?.age,
      dob: Health.ADD_FORM?.self_dob.value,
      utm_medium: utmMedium || "",
      utm_source: utmSource || "",
      utm_campaign: "",
      utm_keyword: "",
      cron_status: "",
    };
    HEALTH_FORM_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
  };

  const breadcrumbJsonLd = JSON.stringify({
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item":`${FRONTEND_DOMAIN}`,
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Health Insurance",
      "item":`${FRONTEND_DOMAIN}/health-insurance` 
    }] 
  }, null, 2);  // Added 'null, 2' to format it with indentation

  const faqsJsonLd = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is health insurance?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In simple terms, health insurance is a type of insurance that covers medical expenses that arise due to unexpected illness or accidents. These expenses could be related to hospitalisation costs, surgeries, day care procedures, cost of medicines,etc"
      }
    },{
      "@type": "Question",
      "name": "What types of health insurance products are available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `Below is the list of available health insurance products:
        • Individual health insurance.
        • Family health insurance.
        • Top-Up health insurance.
        • Senior citizen health insurance.
        • Critical illness insurance.
        • Hospital daily cash.`
      }
    },{
      "@type": "Question",
      "name": "What all is covered in a health insurance policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `A health insurance plan offers comprehensive medical coverage against:
        • Hospitalisation charges like bed charges, nursing charges, operation theatre expenses, etc.
        • Pre-hospitalization charges like ambulance expenses, diagnostic tests, etc.
        • Post-hospitalization charges like medicines, consulting, etc.`
      }
    },{
      "@type": "Question",
      "name": "What is pre and post hospitalisation benefit in a health insurance policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Whenever you plan to have a surgery/medical procedure that requires hospitalisation, there are certain pre-hospitalization expenses such as diagnosis cost, Doctor consultations, etc. Similarly, post-discharge, there could be similar expenses to monitor the health of the insured patient; such expenses are termed as pre and post hospitalisation expenses."
      }
    },{
      "@type": "Question",
      "name": "How many family members can be covered in a health insurance policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `Below family members can be covered depending on the insurance plan:
        • Spouse
        • Dependent children
        • Dependent legitimate or legally adopted children
        • Parents/Parents-in-law`
      }
    },{
      "@type": "Question",
      "name": "What is the recommended age to buy health insurance?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You should buy health insurance as soon as you start earning. Your age is one of the most important factors that determine your health insurance premium; lower the age, lower the premium. This allows you to accumulate the cumulative benefits (for every claim-free year) by the time you are older, as you can avail for a higher coverage."
      }
    },{
      "@type": "Question",
      "name": "What are the benefits of buying health insurance at a young age?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You get higher coverage, i.e., the sum assured at a lower premium. Probability of having a pre-existing illness is low hence waiting periods to start coverage of pre-existing cover will also not impact you. Also, common illnesses such as flu and accidental injury can happen at any age so staying covered even when you are young is important."
      }
    },{
      "@type": "Question",
      "name": "What do you mean by pre-existing diseases with regards to a health insurance policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pre-existing disease is a medical condition that already exists when one is purchasing a health insurance policy. Medical conditions such as high blood pressure, diabetes, thyroid, asthma, depression, etc. are considered as pre-existing ailments."
      }
    },{
      "@type": "Question",
      "name": "Will my pre-existing diseases be covered in a health insurance policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Every insurer excludes coverage for pre-existing illnesses for a certain tenure, which is generally 3 years from the start of the policy. While buying an insurance policy, check if the pre-existing condition is temporarily not covered (pre-existing disease for the first few years) or it is completely excluded from the scope of coverage."
      }
    },{
      "@type": "Question",
      "name": "What do you mean by Network/Non-network hospital with regards to a health insurance policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Network hospitals, often referred to as hospitals with cashless claim option, are tied up with your insurance company via a TPA (third party administrator) wherein you can avail the cashless hospitalisation benefit. However, as you get admitted at a non-network hospital, you have to pay the bills first and later claim for reimbursement."
      }
    }]
  }, null, 2);  // Added 'null, 2' to format it with indentation

  const productsJsonLd = JSON.stringify({
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name": "Health Insurance",
    "image":`${FRONTEND_DOMAIN}images/jfslogofinal.svg`,
    "description": "Get the best health insurance online. Comprehensive coverage across India. Book now and get the right quotes! Jio Insurance Broking Ltd.",
    "brand": {
      "@type": "Brand",
      "name": "Jio Insurance"
    },
    "offers": {
      "@type": "AggregateOffer",
      "url":`${FRONTEND_DOMAIN}health-insurance`,
      "priceCurrency": "INR",
      "lowPrice": ""
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "ratingCount": "500"
    }
  }, null, 2);  // Added 'null, 2' to format it with indentation


  return (
    <>
      <HelmetComponent
        title="Buy Health Insurance plans at the best prices | JioInsure"
        description="Buy or renew your Health Insurance plan. We offer health insurance policy such as for individual, family, senior citizen, group and critical illness | JioInsure"
        showCanonicalTag={true}
        scripts={[breadcrumbJsonLd, faqsJsonLd, productsJsonLd]} // Pass multiple scripts here
      />

      <Box className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}>
        <HealthProductPageForm
          open={isEnterDetailModalOpen}
          setOpen={setEnterDetailModalOpen}
        />

        {isMobile ? <MNavBar /> : <NavBar />}

        <Box className="heroBanner">
          <img
            alt=""
            src="../images/health-banner.webp"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                md={6}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox"
              >
                <h1>Health Insurance</h1>
                <h2>Health cover to protect your wealth</h2>
                <Box>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Coverage for critical illness
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Cashless treatments
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Coverage for medical expenses
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    *Subject to availability
                  </p>
                </Box>
              </Grid>
              <Grid xs={12} md={6}>
                <Box className="formBox" gap="16px!important">
                  {currentStep === 0 && (
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid xs={12}>
                          <h3>
                            Healthy choices start here: compare health insurance
                            plans
                          </h3>
                        </Grid>
                        <Grid xs={12}>
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              textAlign: "left",
                              marginBottom: "8px",
                            }}
                          >
                            Select your gender
                          </p>
                          <GenderRadio
                            value={Health.ADD_FORM.gender.value}
                            attrName={"gender"}
                            defaultValue={Health.ADD_FORM.gender.value}
                            value_update={(a: any, v: any) => {
                              dispatch(
                                HealthSlice.actions.setAddFormData({
                                  ...Health.ADD_FORM,
                                  gender: {
                                    value: v,
                                    warning: isEmpty(v),
                                  },
                                })
                              );
                            }}
                            warn_status={Health.ADD_FORM.gender.warning}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <Box className="mobileNumber">
                            <Box
                              position="absolute"
                              left="12px"
                              bottom="13px"
                              zIndex="2"
                              fontSize="18px"
                              fontWeight="bold"
                              sx={{ color: COLORS.lightgrey }}
                            >
                              +91
                            </Box>
                            <TextInputField
                            disabled={redirected}
                              title="Enter mobile number"
                              placeholder="XXXXXXXXXX"
                              value={Health.ADD_FORM.mobile.value}
                              attrName={"mobile"}
                              value_update={(a: any, v: any) => {
                                dispatch(
                                  HealthSlice.actions.setAddFormData({
                                    ...Health.ADD_FORM,
                                    mobile: {
                                      value: v,
                                      warning: !validateMobileNumber(v),
                                    },
                                  })
                                );
                              }}
                              validation_type="NUMBER"
                              max_length={10}
                              warn_status={Health.ADD_FORM.mobile.warning}
                              error_message={
                                isEmpty(Health.ADD_FORM.mobile.value)
                                  ? "Enter Mobile Number"
                                  : "Enter valid mobile number"
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <TextInputField
                            title="Enter your pincode"
                            placeholder="XXXXXX"
                            value={Health.ADD_FORM.pincode.value}
                            attrName={"pincode"}
                            validation_type="NUMBER"
                            value_update={(a: any, v: any) => {
                              dispatch(
                                HealthSlice.actions.setAddFormData({
                                  ...Health.ADD_FORM,
                                  pincode: {
                                    value: v,
                                    warning: !validatePincode(v),
                                  },
                                })
                              );
                            }}
                            max_length={6}
                            warn_status={Health.ADD_FORM.pincode.warning}
                            error_message={
                              isEmpty(Health.ADD_FORM.pincode.value)
                                ? "Enter Pincode"
                                : "Enter valid pincode"
                            }
                          />
                        </Grid>
                        {/* <Grid xs={12}>
                          <Box>
                            <TextInputField
                              title="Enter your email"
                              placeholder="Eg: john.smith@email.com"
                              value={Health.ADD_FORM.email?.value}
                              attrName={"email"}
                              value_update={(a: any, v: any) => {
                                dispatch(
                                  HealthSlice.actions.setAddFormData({
                                    ...Health.ADD_FORM,
                                    email: {
                                      value: v,
                                      warning: isEmpty(v),
                                    },
                                  })
                                );
                              }}
                              warn_status={Health.ADD_FORM.email?.warning}
                              error_message={
                                isEmpty(Health.ADD_FORM.email?.value)
                                  ? "Enter E-mail"
                                  : "Enter valid email"
                              }
                              max_length={50}
                            />
                          </Box>
                        </Grid> */}
                        <Grid xs={12}>
                          <CustomCheckbox
                            label={
                              <>
                                I agree to{" "}
                                <Link
                                  style={{
                                    color: COLORS.primary,
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => {
                                    navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                                  }}
                                >
                                  Terms & Conditions
                                </Link>
                                ,{" "}
                                <Link
                                  style={{
                                    color: COLORS.primary,
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => {
                                    navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                  }}
                                >
                                  Privacy Policy
                                </Link>
                                {` and `}
                                <Link
                                  style={{
                                    color: COLORS.primary,
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => {
                                    navigate(COMMON_ROUTES.DISCLAIMER);
                                  }}
                                >
                                  Disclaimer
                                </Link>
                              </>
                            }
                            variant="large"
                            attrName="termsandconditions"
                            value={Health?.ADD_FORM?.term_cond?.value}
                            defaultChecked={Health?.ADD_FORM?.term_cond?.value}
                            value_update={(a: any, v: boolean) => {
                              dispatch(
                                HealthSlice.actions.setAddFormData({
                                  ...Health.ADD_FORM,
                                  term_cond: {
                                    value: v,
                                    warning: isEmpty(v),
                                  },
                                })
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {currentStep === 1 && (
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid xs={"auto"} className="pt-3">
                          <BackLeft onClick={handlePrev} />
                        </Grid>
                        <Grid xs>
                          <h3 className="mb-2">
                            Select the family members you want to insure
                          </h3>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid xs={6}>
                          <BorderCheckBox
                            title="One Adult"
                            attrName="spouse_status"
                            value_update={() =>
                              updateMasterState("spouse_status", false)
                            }
                            value={!formData?.spouse_status}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <BorderCheckBox
                            title="Two Adults"
                            attrName="spouse_status"
                            value_update={() =>
                              updateMasterState("spouse_status", true)
                            }
                            value={formData?.spouse_status}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <Box
                            sx={{
                              border: "1px solid #E0E0E0",
                              borderRadius: "12px",
                              padding: "6px 14px",
                              position: "relative",
                            }}
                          >
                            {formData.son_status && (
                              <Box
                                position="absolute"
                                right="8px"
                                top="11px"
                                display="flex"
                                alignItems="center"
                                gap="8px"
                                zIndex="9"
                              >
                                <Box
                                  onClick={() =>
                                    updateMasterState("son_subtract", "")
                                  }
                                  height="20px"
                                  width="20px"
                                  color="primary.main"
                                  textAlign="center"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <RemoveRoundedIcon
                                    sx={{
                                      width: "21px",
                                      height: "21px",
                                      color: "#e99e3e",
                                    }}
                                  />
                                </Box>
                                <Box
                                  height="20px"
                                  width="20px"
                                  color="#e99e3e"
                                  borderRadius="40px"
                                  textAlign="center"
                                  sx={{ backgroundColor: "#FEF7E9" }}
                                >
                                  {formData.son_count.value}
                                </Box>
                                <Box
                                  onClick={() =>
                                    updateMasterState("son_add", "")
                                  }
                                  height="20px"
                                  width="20px"
                                  color="primary.main"
                                  textAlign="center"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <AddRoundedIcon
                                    sx={{
                                      width: "21px",
                                      height: "21px",
                                      color: "#e99e3e",
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                            <CustomCheckbox
                              label="Son"
                              attrName="son_status"
                              value_update={() =>
                                updateMasterState(
                                  "son_status",
                                  !formData.son_status
                                )
                              }
                              value={formData?.son_status}
                              defaultChecked={false}
                              disabled={false}
                            />
                          </Box>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <Box
                            sx={{
                              border: "1px solid #E0E0E0",
                              borderRadius: "12px",
                              padding: "6px 14px",
                              position: "relative",
                            }}
                          >
                            {formData?.daughter_status && (
                              <Box
                                position="absolute"
                                right="8px"
                                top="11px"
                                display="flex"
                                alignItems="center"
                                gap="8px"
                                zIndex="9"
                              >
                                <Box
                                  onClick={() =>
                                    updateMasterState("daughter_subtract", "")
                                  }
                                  height="20px"
                                  width="20px"
                                  color="primary.main"
                                  textAlign="center"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <RemoveRoundedIcon
                                    sx={{
                                      width: "21px",
                                      height: "21px",
                                      color: "#e99e3e",
                                    }}
                                  />
                                </Box>
                                <Box
                                  height="20px"
                                  width="20px"
                                  color="#e99e3e"
                                  borderRadius="40px"
                                  textAlign="center"
                                  sx={{ backgroundColor: "#FEF7E9" }}
                                >
                                  {formData.daughter_count.value}
                                </Box>
                                <Box
                                  onClick={() =>
                                    updateMasterState("daughter_add", "")
                                  }
                                  height="20px"
                                  width="20px"
                                  color="primary.main"
                                  textAlign="center"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <AddRoundedIcon
                                    sx={{
                                      width: "21px",
                                      height: "21px",
                                      color: "#e99e3e",
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                            <CustomCheckbox
                              label="Daughter"
                              attrName="daughter_status"
                              value_update={() =>
                                updateMasterState(
                                  "daughter_status",
                                  !formData.daughter_status
                                )
                              }
                              value={formData?.daughter_status}
                              defaultChecked={false}
                              disabled={false}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {currentStep === 2 && (
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid xs={"auto"} className="pt-3">
                          <BackLeft onClick={handlePrev} />
                        </Grid>
                        <Grid xs>
                          <h3 className="mb-2">
                            Select age of your family members
                          </h3>
                        </Grid>
                      </Grid>

                      {/* */}

                      <Grid container justifyContent="center" spacing={2}>
                        <Grid xs={12} md={6}>
                          <DatePickerInput
                            title="1st Adult DOB"
                            attrName={"self_dob"}
                            value={formData?.self_dob?.value}
                            onChange={updateMasterState}
                            warn_status={formData?.self_dob?.warning}
                            min_date={110}
                            max_date={18}
                            date_validation_type="YEARS"
                            default_date={new Date("1990-01-01")}
                            error_message={"Select 1st adult DOB"}
                          />
                        </Grid>
                        {formData.spouse_status && (
                          <>
                            <Grid xs={12} md={6}>
                              <DatePickerInput
                                title="2nd Adult DOB"
                                attrName={"spouse_dob"}
                                onChange={updateMasterState}
                                value={formData?.spouse_dob?.value}
                                warn_status={formData?.spouse_dob?.warning}
                                min_date={110}
                                max_date={18}
                                date_validation_type="YEARS"
                                default_date={new Date("1990-01-01")}
                                error_message="Select 2nd adult DOB"
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <SearchSelectDropdown
                                title="Relationship with 1st Adult"
                                value={formData?.spouse_relationship?.value}
                                attrName={"spouse_relationship"}
                                value_update={updateMasterState}
                                options={DROPDOWN_DATA.ADULT2_RELATIONSHIP}
                                warn_status={
                                  formData?.spouse_relationship?.warning
                                }
                                error_message={
                                  "Select relationship with 1st adult"
                                }
                              />
                            </Grid>
                          </>
                        )}
                        {parseInt(formData.son_count.value) +
                          parseInt(formData.daughter_count.value) >=
                          1 && (
                          <Grid xs={12} md={6}>
                            <DatePickerInput
                              title="Child 1 DOB"
                              attrName={"child_one_dob"}
                              value={formData?.child_one_dob?.value}
                              onChange={updateMasterState}
                              warn_status={formData?.child_one_dob?.warning}
                              min_date={25}
                              max_date={0}
                              date_validation_type="YEARS"
                              default_date={new Date("2010-01-01")}
                              error_message="Select 1st child DOB"
                            />
                          </Grid>
                        )}
                        {parseInt(formData.son_count.value) +
                          parseInt(formData.daughter_count.value) >=
                          2 && (
                          <Grid xs={12} md={6}>
                            <DatePickerInput
                              title="Child 2 DOB"
                              attrName={"child_two_dob"}
                              value={formData?.child_two_dob?.value}
                              onChange={updateMasterState}
                              warn_status={formData?.child_two_dob?.warning}
                              min_date={25}
                              max_date={0}
                              date_validation_type="YEARS"
                              error_message="Select 2nd child DOB"
                              default_date={new Date("2010-01-01")}
                            />
                          </Grid>
                        )}
                        {parseInt(formData.son_count.value) +
                          parseInt(formData.daughter_count.value) >=
                          3 && (
                          <Grid xs={12} md={6}>
                            <DatePickerInput
                              title="Child 3 DOB"
                              attrName={"child_three_dob"}
                              value={formData?.child_three_dob?.value}
                              onChange={updateMasterState}
                              warn_status={formData?.child_three_dob?.warning}
                              min_date={25}
                              max_date={0}
                              date_validation_type="YEARS"
                              error_message="Select 3rd child DOB"
                              default_date={new Date("2010-01-01")}
                            />
                          </Grid>
                        )}
                        {parseInt(formData.son_count.value) +
                          parseInt(formData.daughter_count.value) ===
                          4 && (
                          <Grid xs={12} md={6}>
                            <DatePickerInput
                              title="Child 4 DOB"
                              attrName={"child_four_dob"}
                              value={formData?.child_four_dob?.value}
                              onChange={updateMasterState}
                              warn_status={formData?.child_four_dob?.warning}
                              min_date={25}
                              max_date={0}
                              date_validation_type="YEARS"
                              error_message="Select 4th child DOB"
                              default_date={new Date("2010-01-01")}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}

                  {/* Navigation Buttons */}
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box width="260px" margin="0px auto">
                      {currentStep === 2 ? (
                        <JDSButtons
                          onClick={() => {
                            validateHealth();
                          }}
                          className="primaryBtn large"
                          text="Submit"
                          variant="text"
                          // loader="true"
                        />
                      ) : (
                        currentStep === 0 && (
                          <JDSButtons
                            onClick={() => {
                              if (Health?.ADD_FORM?.term_cond?.value) {
                                validateHealth();
                              } else {
                                toast.error(
                                  "Please accept terms and conditions"
                                );
                              }
                            }}
                            className="primaryBtn"
                            text="Get Free Quotes"
                            variant="text"
                          />
                        )
                      )}

                      {currentStep === 1 && (
                        <JDSButtons
                          onClick={handleNext}
                          className="primaryBtn"
                          text="Continue"
                          variant="text"
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="why_jio_section">
          <Grid container className="row" columnSpacing={3} alignItems="center">
            <Grid xs={12}>
              <h2>Why Jio Insurance?</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                }}
              >
                Your health is of paramount importance, not just for you, but
                your dear ones as well. Hence, it is extremely important that
                you choose a plan that is the most beneficial to you in the hour
                of need.
              </h5>
            </Grid>
            <Grid xs={12} md={6}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1 mt-0"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="lightGoldBg px-3">
          <Grid container className="row" rowSpacing={3} columnSpacing={6}>
            <Grid xs={12} md={6}>
              <h2 className="mb-4 mt-6">What is health insurance?</h2>
              <p>
                Health insurance is a type of insurance that pays for a
                policyholder's medical and surgical costs. It either pays for
                the covered person directly or reimburses the costs incurred as
                a result of illness or injury.
              </p>
            </Grid>
            <Grid xs={12} md={6}>
              <img
                src="../images/health-insurance.webp"
                style={{ borderRadius: "24px" }}
                width="100%"
              />
            </Grid>
          </Grid>
        </Box>

        <Grid container className="row" rowSpacing={3} columnSpacing={6}>
          <Grid xs={12} lg={6} display="flex" flexDirection="column" gap="24px">
            <Box>
              <p
                style={{
                  textTransform: "uppercase",
                  lineHeight: "20px",
                  marginBottom: "4px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Knowledge Hub
              </p>
              <h2 style={{ textAlign: "left" }}>
                Benefits of health insurance
              </h2>
            </Box>
            <p style={{ color: COLORS.lightgrey }}>
              Having health insurance is crucial for several reasons. Here are
              the key advantages of our health insurance plans:
            </p>
            <ol
              style={{
                paddingLeft: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <li style={{ fontWeight: "900" }}>
                <h6 style={{ fontWeight: "900" }}>
                  Helps deal with rising medical costs
                </h6>
                <p
                  style={{
                    color: COLORS.lightgrey,
                    marginTop: "8px",
                    marginLeft: "-24px",
                  }}
                >
                  Health insurance can assist individuals in coping with the
                  increasing costs of medical care by covering a portion of
                  these expenses, negotiating discounted rates with healthcare
                  providers, and offering preventive care services. This can
                  make healthcare more affordable and accessible, helping
                  individuals save money on medical expenses.
                </p>
              </li>
              <li style={{ fontWeight: "900" }}>
                <h6 style={{ fontWeight: "900" }}>Critical illness cover</h6>
                <p
                  style={{
                    color: COLORS.lightgrey,

                    marginTop: "8px",
                    marginLeft: "-24px",
                  }}
                >
                  Many health insurance plans offer additional coverage for
                  severe conditions for an extra fee. This is a valuable
                  protection due to the growing prevalence of lifestyle-related
                  diseases. If you are diagnosed with one of the covered
                  critical illnesses, you will receive a lump sum amount,
                  helping you manage the high costs associated with these
                  conditions.
                </p>
              </li>
              <li style={{ fontWeight: "900" }}>
                <h6 style={{ fontWeight: "900" }}>Easy cashless claims</h6>
                <p
                  style={{
                    color: COLORS.lightgrey,

                    marginTop: "8px",
                    marginLeft: "-24px",
                  }}
                >
                  Health insurance companies often have partnerships with a
                  network of hospitals where policyholders can make claims
                  without making immediate payments. This makes it easier to
                  receive emergency medical care, as policyholders do not have
                  to pay for covered services at network hospitals. The insurer
                  covers the medical costs for legitimate claims, with
                  policyholders only responsible for deductibles and non-covered
                  fees.
                </p>
              </li>
              <li style={{ fontWeight: "900" }}>
                <h6 style={{ fontWeight: "900" }}>Added protection</h6>
                <p
                  style={{
                    color: COLORS.lightgrey,

                    marginTop: "8px",
                    marginLeft: "-24px",
                  }}
                >
                  You might wonder why you need your own health insurance policy
                  if you have coverage through a group health insurance plan.
                  Individual health insurance plans offer more comprehensive
                  coverage compared to group policies. Furthermore, leaving the
                  organisation that provides a group plan could jeopardise your
                  coverage, potentially putting your finances at risk.
                </p>
              </li>
              <li style={{ fontWeight: "900" }}>
                <h6 style={{ fontWeight: "900" }}>Tax savings</h6>
                <p
                  style={{
                    color: COLORS.lightgrey,

                    marginTop: "8px",
                    marginLeft: "-24px",
                  }}
                >
                  Section 80D of the Income Tax Act of 1961 allows for tax
                  deductions on premiums paid for health insurance coverage. You
                  can deduct up to INR 25,000 per year from your taxable income
                  for a policy covering yourself, your spouse, your children,
                  and parents under the age of 60. You can deduct an additional
                  INR 50,000 if you have purchased insurance for a parent older
                  than 60.
                </p>
              </li>
            </ol>
          </Grid>
          <Grid xs={12} lg={6}>
            <h2 style={{ textAlign: "left" }}>How to buy insurance with us?</h2>
            <ul
              style={{
                display: "flex",
                gap: "24px",
                flexDirection: "column",
                margin: "24px 0px",
              }}
            >
              {howtoBuyData.map((item, index) => (
                <li
                  key={index} // Use index as the key, but ideally, each item should have a unique identifier
                  style={{
                    position: "relative",
                    paddingLeft: "56px",
                  }}
                >
                  {index === howtoBuyData.length - 1 ? null : (
                    <span
                      style={{
                        backgroundColor: "#B5B5B5",
                        position: "absolute",
                        left: "20px",
                        top: "40px",
                        height: "100%",
                        width: "1px",
                      }}
                    ></span>
                  )}
                  <Box
                    sx={{
                      backgroundColor: COLORS.lightMariGold20,
                      height: "40px",
                      width: "40px",
                      borderRadius: "40px",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      padding: "6px",
                    }}
                  >
                    <img src={item.image} width="100%" alt="icon" />
                  </Box>
                  <h5 style={{ fontWeight: "bold" }}>{item.title}</h5>
                  <p
                    style={{
                      color: COLORS.lightgrey,
                      marginTop: "8px",
                    }}
                  >
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>

        {/* Blog articles */}
        {redirected ? null : (
          <Box className="blogs_section">
            <Grid
              container
              className="row"
              columnSpacing={3}
              marginBottom={"32px"}
            >
              <Grid xs={12}>
                {/* <h4
              style={{
                color: "#595959",
              }}
            >
              Resource Hub
            </h4> */}
                <h2>Blogs</h2>
                <h5
                  style={{
                    color: "#595959",
                    maxWidth: "824px",
                  }}
                >
                  At Jio Insurance Broking, we aim to help you make an informed
                  decision. Consider following scenarios before selecting an
                  insurance policy that suits your needs.
                </h5>
              </Grid>
              <Grid xs={12}>
                <ul>
                  {blogCardsArray?.map((data, index: any) => (
                    <BlogCard data={data} />
                  ))}
                </ul>
              </Grid>
              <Grid xs={12} display={"flex"} justifyContent={"center"}>
                <Box maxWidth={"384px"} width={"100%"}>
                  <JDSButtons
                    text="Read more blogs"
                    variant={"text"}
                    className={"secondaryBtn large"}
                    onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Testimonial */}
        <Box className="testimonial_section">
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
                Meet some of our happy customers
              </h2>
            </Grid>
          </Grid>
          <Grid container className="row" columnSpacing={0}>
            <Grid xs={12} className="testimonial-carousel">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* Partners */}
        <Box className="partners_section">
          <Grid container columnSpacing={3} marginBottom={"42px"}>
            <Grid xs={12}>
              <h2 style={{ color: "#001E2B" }}>Our Partners</h2>
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} justifyContent={"center"}>
            <Grid xs={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="lightGoldBg">
          <Box className="why_jio_section">
            <Grid container columnSpacing={3}>
              <Grid xs={12} textAlign={"center"}>
                <h2>Types of health insurance</h2>
                <h5 style={{ color: COLORS.lightgrey }}>
                  Every person is unique, each with their own set of needs.
                  One-size-fits-all health insurance packages cannot address the
                  individual requirements. Multiple types of health insurance
                  policies are available to cater to these unique needs. Here
                  are some examples:
                </h5>
              </Grid>
              <Grid xs={12}>
                <ul>
                  {insuranceTypeArray?.map((data, index) => (
                    <li key={index}>
                      <h6
                        className="mb-2"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "900",
                        }}
                      >
                        {data.heading}
                      </h6>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        {data.desc}
                      </p>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Grid container className="row" rowSpacing={3} columnSpacing={6}>
          <Grid xs={12}>
            <h2>How health insurance works?</h2>
          </Grid>
          <Grid xs={12}>
            <p className="mb-4">
              Health insurance functions like other types of insurance, helping
              you manage the financial impact of accidents or emergencies. The
              process begins with your application for a policy, which is
              followed by premium quotes based on your age, medical history,
              desired sum insured, and the chosen plan type. In some cases, the
              insurance company may require you to undergo medical exams before
              offering coverage.
            </p>
            <p className="mb-4">
              Once the terms and conditions are agreed upon, you receive a
              policy with specific waiting periods. Initially, the waiting
              period is short, such as a month, during which you cannot file
              non-emergency claims. After the waiting period, let's say you
              require surgery. If you receive treatment at one of the hospitals
              in the insurer's network, inform the insurer about the procedure,
              and they will coordinate payment with the hospital.
            </p>
            <p className="mb-4">
              Upon your release from the hospital, you will be responsible for
              paying only the voluntary co-pay amount, if applicable, and any
              additional expenses not covered by the policy. If you receive care
              at a hospital not in the insurance network, you can pay the full
              cost and then submit a claim for reimbursement. In both cases, you
              can obtain necessary medical care without financial stress.
            </p>

            <h4 className="mb-1">Need for health insurance</h4>
            <p className="mb-4">
              The cost of healthcare is increasing each year, with healthcare
              inflation outpacing that of food and other commodities. Healthcare
              costs often rise by double digits, whereas inflation for food and
              clothing typically remains in the single digits.
            </p>
            <p className="mb-4">
              Accumulating funds at the last moment can be challenging for those
              who haven't saved significantly. This poses a unique challenge for
              seniors since most health issues develop as individuals grow
              older.
            </p>

            <p className="mb-4">
              Obtaining health insurance is one strategy to cover unforeseen
              medical or health needs. Health insurance offers flexibility and
              covers numerous serious illnesses and surgical procedures.
              Regardless of the actual medical expenses, the policy continues to
              provide coverage even after benefits have been paid for certain
              conditions.
            </p>

            <p className="mb-4">
              Health insurance ensures a more secure future for both your health
              and your finances. This makes having health insurance policies
              essential, especially for those responsible for their family's
              financial well-being.
            </p>
          </Grid>
        </Grid>

        <Box className="lightGoldBg">
          <Box className="why_jio_section">
            <Grid container className="row" columnSpacing={3}>
              <Grid xs={12}>
                <h2>How to choose a health insurance plan?</h2>
                <h5 style={{ color: COLORS.lightgrey }}>
                  Various health insurance plans are available in the market. To
                  ensure seamless coverage that meets your specific needs,
                  consider the following when selecting a health insurance plan:
                </h5>
              </Grid>
              <Grid xs={12}>
                <ul>
                  {chooseInsuranceArray?.map((data, index) => (
                    <li key={index}>
                      <h6
                        className="mb-1"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "900",
                        }}
                      >
                        {data.heading}
                      </h6>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        {data.desc}
                      </p>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* faqs */}
        <Grid
          container
          className="row"
          rowSpacing={3}
          columnSpacing={6}
          justifyContent={"center"}
        >
          <Grid xs={12} className="textCenter">
            <h2>Frequently Asked Questions</h2>
          </Grid>
          <Grid xs={12}>
            <FAQ data={showAll ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12}>
            <JDSButtons
              onClick={() => setShowAll(!showAll)}
              className="secondaryBtn large"
              text={showAll ? "View less" : "View more"}
              variant="outlined"
              fullWidth={false}
              // loader="true"
            />
          </Grid>
        </Grid>

        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </>
  );
}

export default HealthProductPage;
