import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import "./NavBar.scss";

function LandingNavBar() {
  const navigate = useNavigate();
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box className={`navbar ${hasShadow ? "shadow" : ""}`}>
      <Box
        sx={{ background: COLORS.white, height: "60px", paddingTop: "14px" }}
      >
        <Grid container spacing={3}>
          <Grid xs={12}>
            <img
              alt=""
              src="/images/jfslogofinal.svg"
              height="32px"
              style={{ marginRight: "8px" }}
            />{" "}
          </Grid>
        </Grid>
      </Box>
      <Grid container columnSpacing={3} alignItems="center">
        <Grid xs={12}>
          <Link
            sx={{
              display: "inline-block",
              padding: "4px 0",
              margin: "16px 0px",
            }}
          >
            <img
              src="../images/jio_insurance_logo.svg"
              alt="jio-insurance-logo"
            />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LandingNavBar;
