import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import RKTextField from "../../../../Component/Amaze/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import SelectDropdown from "../../../../Component/Amaze/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../Component/Amaze/FieldTypes/DatePicker/DatePicker";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { useAppSelector } from "../../../../Store/hooks";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import NonTermFooter from "../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import { subDays } from "date-fns";
import MNonTermFooter from "../../../../Component/Amaze/NonTerm/MNonTermFooter/MNonTermFooter";
const MICICNonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
  isProposerSameInsured,
  setIsProposerSameInsured,
  isChecked,
  setChecked,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
  isProposerSameInsured: boolean;
  setIsProposerSameInsured: Function;
  isChecked: boolean;
  setChecked: Function;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);
  const [proposerSameInsured, setProposerSameInsured] = useState(true);
  const handleCheckboxChange = (attrName: string, isChecked: boolean) => {
    setChecked(isChecked);
    console.log(isChecked, "isChecked");
  };

  const qualification = [
    {
      label: "01",
      value: "10th Pass",
    },
    {
      label: "08",
      value: "12th Pass",
    },
    {
      label: "03",
      value: "Graduate & Above",
    },
  ];

  const handleClick = () => {
    setIsProposerSameInsured((prevState: boolean) => !prevState);
  };

  return (
    <>
      {isProposerSameInsured ? (
        <Box>
          <h5 className="sectionTitle">Proposer Details</h5>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <h6>
                <CustomCheckbox
                  label={"Proposer is also an insured member."}
                  attrName="validPuc"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                  variant="large"
                />
              </h6>
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.full_name.value}
                attrName={["full_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.full_name?.warning}
                error_message={
                  isEmpty(proposerFields.full_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={
                  UPDATE_RESPONSE?.dob
                    ? UPDATE_RESPONSE?.dob
                    : ADD_FORM.age.value
                }
                attrName={["dob"]}
                disabled={true}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={ADD_FORM.age.warning}
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={ADD_FORM.gender.value}
                attrName={["gender"]}
                value_update={fieldsUpdateState}
                data={[
                  { value: "Male", label: "M" },
                  { value: "Female", label: "F" },
                  { value: "Other", label: "O" },
                ]}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                disabled
                class_name="inputField"
                title={"Mobile"}
                value={ADD_FORM.mobile.value}
                attrName={["mobile"]}
                value_update={fieldsUpdateState}
                warn_status={ADD_FORM.mobile.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  !ADD_FORM.mobile.value ? "Enter mobile number" : ""
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.email.value}
                attrName={["email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.email?.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={ADD_FORM.pincode.value}
                attrName={["pincode"]}
                value_update={fieldsUpdateState}
                warn_status={ADD_FORM.pincode.warning}
                validation_type="NUMBER"
                max_length={6}
                disabled={true}
                error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Emergency Phone"}
                value={proposerFields.emergency_number.value}
                attrName={["emergency_number"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.emergency_number?.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.emergency_number.value)
                    ? "Enter Emergency Phone Number "
                    : "Enter Valid Emergency Phone Number"
                }
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={proposerFields?.occupation?.value}
                attrName={["occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Qualification"
                value={proposerFields?.qualification?.value}
                attrName={["qualification"]}
                value_update={fieldsUpdateState}
                // data={DROPDOWN_DATA.QUALIFICATION_DATA}
                data={qualification}
                warn_status={proposerFields?.qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>
          <MNonTermFooter
            loader={QUOTE_LOADER}
            forward={isChecked ? validateForm : handleClick}
          />
        </Box>
      ) : (
        <Box>
          <h5 className="sectionTitle">Insured Details</h5>
          <Grid container spacing={3}>
            <Grid xs={12}></Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.insured_name.value}
                attrName={["insured_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_name?.warning}
                error_message={
                  isEmpty(proposerFields.insured_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.insured_dob.value}
                attrName={["insured_dob"]}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={proposerFields.insured_dob.warning}
                min_date={50 * 12}
                max_date={3}
                date_validation_type="MONTHS"
                default_date={subDays(new Date(), 90)}
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={proposerFields.insured_gender.value}
                attrName={["insured_gender"]}
                value_update={fieldsUpdateState}
                data={[
                  { value: "Male", label: "M" },
                  { value: "Female", label: "F" },
                  { value: "Other", label: "O" },
                ]}
                warn_status={proposerFields?.insured_gender?.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Mobile"}
                value={proposerFields?.insured_mobile?.value}
                attrName={["insured_mobile"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.insured_mobile.warning}
                max_length={10}
                validation_type="NUMBER"
              // error_message={
              //   !ADD_FORM.mobile.value ? "Enter mobile number" : ""
              // }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.insured_email.value}
                attrName={["insured_email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_email?.warning}
                error_message={
                  isEmpty(proposerFields.insured_email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={proposerFields?.insured_pincode?.value}
                attrName={["insured_pincode"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_pincode?.warning}
                validation_type="NUMBER"
                max_length={6}
                error_message={
                  !proposerFields?.insured_pincode?.warning
                    ? "Enter Pincode"
                    : ""
                }
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={proposerFields?.insured_occupation?.value}
                attrName={["insured_occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.insured_occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Qualification"
                value={proposerFields?.insured_qualification?.value}
                attrName={["insured_qualification"]}
                value_update={fieldsUpdateState}
                // data={DROPDOWN_DATA.QUALIFICATION_DATA}
                data={qualification}
                warn_status={proposerFields?.qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>
          <MNonTermFooter
            loader={QUOTE_LOADER}
            forward={validateForm}
          // backward={handleClick}
          />
        </Box>
      )}
    </>
  );
};

export default MICICNonTermProposal;
