import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import FAQ from "../../../../Component/Global/FAQ/FAQ";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const BlogUnlockTheBenefits = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const faq_data: { title: string; desc: string }[] = [
    {
      title: "How do I open an e-insurance account?",
      desc: "Simply download the e-insurance account opening form from your chosen repository, fill it out, attach the required KYC documents, and submit them.",
    },
    {
      title: "Who is an authorized representative?",
      desc: "An authorized representative is an individual appointed by you to access your e-insurance account when needed.",
    },
    {
      title: "Can I open an e-insurance account without any existing policies?",
      desc: "Yes, you can open an e-insurance account even if you don’t have any insurance policies.",
    },
    {
      title:
        "Can I store commercial insurance plans in my e-insurance account?",
      desc: "Absolutely! You can add commercial insurance plans to your e-insurance account without restrictions.",
    },
    {
      title:
        "How long does it take to open the account after submitting documents?",
      desc: "Your e-insurance account will typically be opened within 7 days after submitting a complete application.",
    },
    {
      title: "Can I convert my existing plans to e-policies?",
      desc: "Yes, you can request your insurance company to convert existing plans into e-insurance plans.",
    },
    {
      title: "What happens to my account if I pass away?",
      desc: "Your appointed authorized representative will be able to access your e-insurance account to manage your policies.",
    },
  ];

  const current_blog_data = {
    id: "",
    blogtitle:
      "Unlocking the Benefits of Electronic Insurance Accounts (E-Insurance)",
    img_url: "/images/blog-12.jpg",
    main_desc: (
      <>
        <p className="mb-4">
          In an increasingly digital world, managing insurance has become more
          streamlined and secure with the advent of Electronic Insurance
          Accounts (E-Insurance). This innovative solution allows policyholders
          to store their insurance policies electronically, offering
          unparalleled convenience and safety.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h4 className="mb-3">What is E-Insurance?</h4>
        <p className="mb-5">
          E-Insurance refers to the digital storage of insurance policies,
          enabling policyholders to manage their insurance documents online.
          This system simplifies access to various insurance plans and helps
          ensure that vital documents are always secure and readily available.
        </p>
        <p className="mb-5">Key Benefits of E-Insurance Accounts:</p>
        <ul className="mb-5">
          <li>
            <span>Protection and Safety:</span> Storing your policies
            electronically minimizes the risk of loss or damage. You no longer
            need to worry about misplaced documents, as everything is securely
            stored in a digital format.
          </li>
          <li>
            <span>Single Consolidated View:</span> E-Insurance provides a
            unified platform to view all your policies, regardless of the
            insurer. Accessing your documents from multiple devices has never
            been easier.
          </li>
          <li>
            <span>Better Monitoring:</span> E-Insurance accounts come with
            automated alerts for policy renewals and premium payment deadlines,
            helping you manage your insurance more effectively and avoid lapses.
          </li>
          <li>
            <span>User-Friendly:</span> With just one e-insurance account, you
            can purchase and manage multiple policies from different insurers.
            Access your account anytime, anywhere, at your convenience.
          </li>
          <li>
            <span>Easy Premium Payments:</span> Once your e-insurance account is
            active, you can easily make premium payments online, simplifying the
            payment process.
          </li>
          <li>
            <span>Effortless Updates:</span> As an account holder, you can
            swiftly update your contact details and other information for all
            your policies. If you’re unhappy with your current insurer, you can
            change your insurance repository without hassle.
          </li>
          <li>
            <span>Open Even Without Insurance:</span> You can open an
            e-insurance account even if you don’t currently hold any insurance
            policies, making it accessible for everyone.
          </li>
          <li>
            <span>Convert Old Policies:</span> Existing policies can be
            converted into e-policies and stored in your e-insurance account,
            ensuring all your insurance documents are organized in one place.
          </li>
        </ul>
        <h4 className="mb-3">How to Open an E-Insurance Account</h4>
        <p className="mb-5">
          Opening an e-insurance account is straightforward. You’ll need to
          register with an Insurance Repository (IR), as mandated by the
          Insurance Regulatory and Development Authority of India (IRDAI). The
          authorized repositories include:
        </p>
        <ul className="mb-5">
          <li>NSDL National Insurance Repository (NIR)</li>
          <li>CAMS Repository Services Limited (CAMSRep)</li>
          <li>CDSL Insurance Repository Limited</li>
          <li>Karvy Insurance Repository Limited (Karvy)</li>
        </ul>
        <h4 className="mb-3">Steps to Open Your Account: </h4>
        <ul className="mb-5">
          <li>Choose your preferred Insurance Repository.</li>
          <li>Download the eIA opening form from their website.</li>
          <li>
            Fill out the form and attach self-attested KYC documents (identity
            proof, date of birth proof, and address proof).
          </li>
          <li>
            Provide personal and bank details (including a cancelled cheque).
          </li>
          <li>Include a recent passport-size photograph.</li>
          <li>Submit the completed form to your insurance company.</li>
        </ul>
        <h4 className="mb-3">Understanding Authorized Representatives</h4>
        <p className="mb-5">
          An authorized representative is appointed by the account holder to
          manage the e-insurance account in their absence. This person must be
          at least 21 years old and can access the account if the account holder
          is incapacitated or passes away.
        </p>
        <p className="mb-5">Important Features of E-Insurance Accounts</p>
        <ul className="mb-5">
          <li>Only one e-insurance account is allowed per individual.</li>
          <li>
            Multiple insurance policies from different insurers can be stored in
            one account.
          </li>
          <li>
            All types of electronic insurance policies, including life, health,
            and general insurance, can be consolidated.
          </li>
          <li>
            Upon account creation, a unique identification number will be
            assigned for future reference.
          </li>
        </ul>
        <h4 className="mb-3">Opting Out of the Insurance Repository</h4>
        <p className="mb-5">
          If you decide to opt out of the e-insurance repository, you must
          submit a written request to your insurer. After processing your
          request, you will receive confirmation within five working days.
        </p>
        <Box className="mfaq_section" marginBottom={"24px"}>
          <Grid
            container
            className="row"
            columnSpacing={3}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "28px",
                  fontWeight: 900,
                }}>
                Frequently asked questions
              </h3>
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={3}
            justifyContent={"center"}>
            <Grid xs={12} className={"faqTabs"}>
              <FAQ data={faq_data} />
            </Grid>
          </Grid>
        </Box>
      </>
    ),
  };

  const blogsJsonLd = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${FRONTEND_DOMAIN}/blog/unlocking-the-benefits-of-electronic-insurance-accounts`
    },
    "headline": "Unlocking the Benefits of Electronic Insurance Accounts (E-Insurance)",
    "image": `${FRONTEND_DOMAIN}${current_blog_data?.img_url}`,  
    "author": {
      "@type": "Organization",
      "name": "Jio Insurance Broking Ltd.",
      "url": `${FRONTEND_DOMAIN}/about-us`
    },  
    "datePublished": "2024-10-22",
    "dateModified": "2024-10-22"
  }, null, 2);  // Added 'null, 2' to format it with indentation

  return (
    <Box className="blogsWrapper">
      <HelmetComponent showCanonicalTag={true} scripts={[blogsJsonLd]}/>
      {isMobile ? <MNavBar title={"E-Insurance Account"} /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogUnlockTheBenefits;
