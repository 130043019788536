import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { COLORS } from "../../../SupportingFiles/colors";

const BorderCheckBox = ({
  title,
  attrName,
  onChange,
  value,
  disabled = false,
  checkedLabelColor = "#141414", // Set your desired colors
  uncheckedLabelColor = "#595959", // Set your desired colors
  value_update,
  label,
  defaultChecked = false,
}: {
  title?: string | any;
  label?: string | any;
  attrName?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  disabled?: boolean;
  checkedLabelColor?: string;
  uncheckedLabelColor?: string;
  value_update?: Function;
  defaultChecked?: boolean;
}) => {
  return (
    <FormControlLabel
      sx={{
        ".MuiCheckbox-root": {
          padding: "8px 8px 8px 10px",
        },
        width: "100%",
        border: "1px solid #E0E0E0",
        borderRadius: "12px",
        padding: "5px",
        margin: "0px",
        ".MuiButtonBase-root": {
          position: "relative",
          ".MuiSvgIcon-root": {
            display: "none",
          },
          "&:before": {
            content: '""',
            border: "1px solid",
            borderColor: COLORS.lightgrey,
            height: "14px",
            width: "14px",
            borderRadius: "4px",
          },
          "&.Mui-checked": {
            "&:before": {
              content: '""',
              backgroundColor: COLORS.primary,
              border: "1px solid",
              borderColor: COLORS.primary,
              height: "14px",
              width: "14px",
              borderRadius: "4px",
            },
            "&:after": {
              position: "absolute",
              top: "12px",
              left: "14px",
              content: '""',
              borderBottom: "1px solid",
              borderLeft: "1px solid",
              borderTop: "none",
              borderRight: "none",
              borderColor: COLORS.warmgold,
              transform: "rotate(-45deg)",
              height: "4px",
              width: "7px",
            },
          },
        },
      }}
      control={
        <Checkbox
          // icon={
          //   <img
          //     src="../images/uncheckCheckboxIcon.svg"
          //     height="16px"
          //     alt="Unchecked"
          //   />
          // }
          // checkedIcon={
          //   <img
          //     src="../images/checkedCheckboxIcon.svg"
          //     height="16px"
          //     alt="Checked"
          //   />
          // }
          id={`${title}`}
          onChange={(e) => {
            console.log(attrName, e.target.checked, "hhhhhh");
            value_update && value_update(attrName, e.target.checked);
          }}
          checked={value}
          disabled={disabled}
        />
      }
      label={
        <span
          style={{
            color: value ? checkedLabelColor : uncheckedLabelColor,
            position: "relative",
            top: "1px",
            fontSize: "14px",
            lineHeight: "18px",
            display: "inherit",
          }}
        >
          {label}
          {title}
        </span>
      }
    />
  );
};

export default BorderCheckBox;
