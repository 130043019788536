import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";

export const blog_data = [
  {
    img_url: "../images/blog-12.jpg",
    id: COMMON_ROUTES.BLOG_UNLOCK_THE_BENEFITS,
    blogtitle:
      "Unlocking the Benefits of Electronic Insurance Accounts (E-Insurance)",
    blog_desc:
      "In an increasingly digital world, managing insurance has become more streamlined and secure with the advent of Electronic Insurance Accounts (E-Insurance). This innovative solution allows policyholders to store their insurance policies electronically, offering unparalleled convenience and safety.",
  },
  {
    img_url: "../images/blog1.webp",
    id: COMMON_ROUTES.BLOG_FACTORS_TO_BE_CONSD,
    blogtitle:
      "Factors to be considered while buying personal accident insurance in India",
    blog_desc:
      "Health insurance has become a vital aspect of our lives because a medical emergency might occur at any time. One such emergency that can occur without prior notice is an accident. Sadly, if the incidence is severe, the cost of therapy may be expensive. ",
  },
  {
    img_url: "../images/blog2.webp",
    id: COMMON_ROUTES.BLOG_EVERYTHING_YOU_NEED,
    blogtitle: "Everything you need to know about Personal Accident Cover",
    blog_desc:
      "Regardless of how unpleasant it sounds; unfortunate events cause all happiness to vanish in a couple of seconds. A sudden accident might cause you to suffer from physical agony, emotional stress and financial burdens when you are wanting to enjoy a good and carefree life",
  },
  {
    img_url: "../images/blog3.webp",
    id: COMMON_ROUTES.BLOG_ALL_YOU_NEED,
    blogtitle: "All you need to know about Car Insurance!",
    blog_desc:
      "A policy that covers all the hazards connected to cars is called car insurance. It also goes by the name Motor Insurance and offers protection against any monetary losses brought on by accidents, including collisions with other vehicles or objects, theft of the car or its components, ",
  },
  {
    img_url: "../images/blog4.webp",
    id: COMMON_ROUTES.BLOG_WHY_DOES_COMP,
    blogtitle:
      "Why does a ‘Comprehensive’ car insurance cost more than a ‘Third-Party Liability’ car insurance?",
    blog_desc:
      "When renewing or buying a new car insurance policy, one question which always comes to our mind is which policy should we buy - comprehensive or third-Party Liability car insurance? ",
  },
  {
    img_url: "../images/blog5.webp",
    id: COMMON_ROUTES.BLOG_MAKING_UNDERSTANDING,
    blogtitle: "Making understanding of IDV easy for you!",
    blog_desc:
      "While purchasing your two-wheeler insurance, you must have wondered what IDV is and how it affects your insurance premium. Insured Declared Value (IDV) is the maximum amount of insurance that the insurance company will pay out to the policyholder in the event ",
  },
  {
    img_url: "../images/blog6.webp",
    id: COMMON_ROUTES.BLOG_UNDERSTANDING_DEDUCTIBLE,
    blogtitle: "Understanding Deductibles and Co-pays",
    blog_desc:
      "Are you aware how much of your bill will be paid by the insurance company if you are hospitalized or your car meets with an accident? When this question is asked, majority are surprised and they say, “Jo bhi bill hoga, insurance company pay karegi”. ",
  },
  {
    img_url: "../images/blog7.webp",
    id: COMMON_ROUTES.BLOG_HEALTH_INSURANCE,
    blogtitle: "Health Insurance Top-Up Plans ",
    blog_desc:
      "Health insurance covers the needs of you and your family during a medical emergency. Imagine you are in a situation where the hospital bills exceed the health insurance cover you have, then you must pay the extra amount from your own funds which can be a huge financial burden for you and your family. ",
  },
  {
    img_url: "../images/blog8.webp",
    id: COMMON_ROUTES.BLOG_CORPORATE_HEALTH,
    blogtitle:
      "Do you have a ‘Corporate Health’ insurance policy’ – Find out if it is enough!",
    blog_desc:
      "Employees worldwide today look for rewards and benefits beyond just pay cheques. One of the most popular benefits offered by Employers is a Group Health Insurance Policy. While companies are largely known to offer Health Insurance coverage to their employees, there are many who extend to their families",
  },
  {
    img_url: "../images/blog9.webp",
    id: COMMON_ROUTES.BLOG_ULIP,
    blogtitle: "Unit Linked Insurance Plan (ULIP)",
    blog_desc:
      "For a beginner investor, Unit linked Insurance Plan (ULIP) has both protection and investment benefits. ULIPs can be used for life insurance, retirement income and educational costs. ",
  },
  {
    img_url: "../images/blog10.jpg",
    id: COMMON_ROUTES.BLOG_BENEFITS_OF_CAR,
    blogtitle: "Benefits of Car insurance renewal.",
    blog_desc:
      "Car insurance renewal is a must for vehicle owners as it ensures continued protection against financial liabilities arising from accidents, theft, or damage of the vehicle. However, while choosing the right plan for you there are a lot of important factors that need to be considered. It is imperative to compare and check car insurance renewal price along with the benefits that are available.",
  },
];

export const blogsResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 20, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 20, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 20, // this is needed to tell the amount of px that should be visible.
  },
};
