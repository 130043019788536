import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import "./CustomButton.scss";

interface Props {
  class_name?: string;
  text_name: string | any;
  loading?: boolean;
  onClickFunction?: Function;
  disabled?: boolean;
}

const CustomButton: React.FC<Props> = ({
  class_name = "greenXlBtn",
  text_name,
  loading = false,
  onClickFunction = () => {},
  disabled,
}) => {
  return (
    <Button
      variant="contained"
      className={class_name}
      onClick={() => {
        if (loading === false) {
          onClickFunction();
        }
      }}
      disabled={disabled}
    >
      {loading === true ? (
        <>
          <Box className="loader mr-2" />
          Loading
        </>
      ) : (
        text_name
      )}
    </Button>
  );
};

export default CustomButton;
