import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import QuoteNavbar from "../../../../Component/Desktop/Common/QuoteNavbar/QuoteNavbar";
import TermQuoteCard from "../../../../Component/Desktop/Term/Quote/TermQuoteCard";
import Loader from "../../../../Component/Global/Loader/Loader";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TermFormContainer from "../../../../Container/Term/Form/TermFormContainer";
import TermModifyDetailsContainer from "../../../../Container/Term/Quote/TermModifyDetailsContainer";
import { TermSlice } from "../../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { calculateAge } from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import "./TermQuote.scss";

function TermQuote({
  updateMasterState,
  loader,
  quotationResponse,
  BUY_ACTION,
  update_plan_type,
}: {
  updateMasterState: Function;
  loader: boolean;
  quotationResponse: any;
  BUY_ACTION: Function;
  update_plan_type: Function;
}) {
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    QUOTE_FIELDS_DATA,
    DROPDOWN_DATA,
    QUOTE_LOAD_FIRST_TIME,
  } = useAppSelector((state) => state.Term);
  const [isModifyDetailModalOpen, setModifyDetailModalOpen] = useState(false);

  const [isPreQuoteModalOpen, setPreQuoteModalOpen] = useState(
    QUOTE_LOAD_FIRST_TIME
  );

  return (
    <Box className="quoteWrapper" gap={"24px"}>
      <TermModifyDetailsContainer
        open={isModifyDetailModalOpen}
        setOpen={setModifyDetailModalOpen}
      />
      {isPreQuoteModalOpen ? (
        <TermFormContainer
          open={isPreQuoteModalOpen}
          setOpen={setPreQuoteModalOpen}
        />
      ) : null}

      <QuoteNavbar />

      {loader ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Loader size="small" /> */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Fetching best <br /> Insurance Policies for you...
            </h5>
          </Box>
        </Box>
      ) : null}

      <Grid container spacing={3} alignItems="flex-start">
        <Grid xs={12}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              padding: "24px 12px",
            }}
          >
            <Grid container xs={12} columnSpacing={3} rowSpacing={2}>
              {/* Member's Info */}
              <Grid xs={6} display="flex" alignItems="center" gap="48px">
                <Box>
                  <h6
                    style={{
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    Member Covered
                  </h6>
                  <p style={{ color: "#595959" }}>
                    {" ₹ "}
                    {Number(ADD_FORM.annualIncome.value) < 10000000
                      ? `${ADD_FORM.annualIncome.value / 100000} Lakh`
                      : `${
                          parseInt(ADD_FORM.annualIncome.value) / 10000000
                        } Crore`}
                    , {ADD_FORM.gender.value === "M" ? "Male" : "Female"} -{" "}
                    {`${calculateAge(ADD_FORM.age.value)} Years, `}
                    {ADD_FORM.smoke === "Y" ? "Smoker" : "Non Smoker"}
                    {/* {ADD_FORM_RESPONSE.city} - {ADD_FORM_RESPONSE.state} */}
                  </p>
                </Box>
                <Link
                  onClick={() => {
                    setModifyDetailModalOpen(true);
                  }}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: COLORS.darkgold,
                    cursor: "pointer",
                    padding: "4px 8px",
                  }}
                >
                  Edit Details
                </Link>
              </Grid>
              <Grid xs={6} textAlign="right">
                <p>All prices are inculsive of GST</p>
              </Grid>
              <Grid xs={6} sm={4} md>
                <SelectDropdown
                  title="Life Cover"
                  value={`${QUOTE_FIELDS_DATA.sumAssured}`}
                  attrName={"sumAssured"}
                  value_update={updateMasterState}
                  data={DROPDOWN_DATA.SUM_ASSURED}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6} sm={4} md>
                <SelectDropdown
                  title="Cover till age"
                  value={QUOTE_FIELDS_DATA.coverageUpto}
                  attrName={"coverageUpto"}
                  value_update={updateMasterState}
                  data={DROPDOWN_DATA.COVERAGE_UPTO}
                  warn_status={false}
                />
              </Grid>
              {QUOTE_FIELDS_DATA.payMode === "5" ? null : (
                <Grid xs={6} sm={4} md>
                  <SelectDropdown
                    title="Payment Tenure"
                    value={QUOTE_FIELDS_DATA.payUpto}
                    attrName={"payUpto"}
                    value_update={updateMasterState}
                    data={DROPDOWN_DATA.PAY_UPTO}
                    warn_status={false}
                  />
                </Grid>
              )}
              <Grid xs={6} sm={4} md>
                <SelectDropdown
                  title="Payment Type"
                  value={QUOTE_FIELDS_DATA.payMode}
                  attrName={"payMode"}
                  value_update={updateMasterState}
                  data={DROPDOWN_DATA.PAY_MODE_DATA}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6} sm={4} md>
                <SelectDropdown
                  title="Plan Type"
                  value={QUOTE_FIELDS_DATA.planType}
                  attrName={""}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
                        { key: "planType", value: v },
                      ])
                    );
                    update_plan_type(v);
                  }}
                  data={DROPDOWN_DATA.PLAN_TYPE}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid container xs spacing={3} sx={{ minWidth: "400px" }}>
          <Grid container xs={12} rowSpacing={3} alignItems="center">
            <Grid xs={4}>
              {!loader ? (
                <h3 className="mb-2">
                  {quotationResponse?.length} Plans found
                </h3>
              ) : (
                <h3 className="mb-2">Fetching Quotes...</h3>
              )}
              <p>
                <small style={{ color: "#595959" }}>
                  Order ID: {ADD_FORM_RESPONSE.quote_no}
                </small>
              </p>
            </Grid>
            <Grid xs={8} display="flex" justifyContent="end" gap="12px">
              {QUOTE_FIELDS_DATA.planType === "2" && (
                <Box width="130px">
                  <SelectDropdown
                    title="Monthly Payout"
                    value={QUOTE_FIELDS_DATA.monthlyPayout}
                    attrName={"monthlyPayout"}
                    value_update={updateMasterState}
                    data={DROPDOWN_DATA.MONTHLY_PAYOUT}
                    warn_status={false}
                  />
                </Box>
              )}
              {QUOTE_FIELDS_DATA.planType === "2" && (
                <Box width="130px">
                  <SelectDropdown
                    title="Income Period"
                    value={QUOTE_FIELDS_DATA.income_period}
                    attrName={"income_period"}
                    value_update={updateMasterState}
                    data={DROPDOWN_DATA.INCOME_PERIOD}
                    warn_status={false}
                  />
                </Box>
              )}
              <Box width="140px">
                <SelectDropdown
                  title="Sort by"
                  value={QUOTE_FIELDS_DATA.sortBy}
                  attrName={"sortBy"}
                  value_update={updateMasterState}
                  data={DROPDOWN_DATA.SORT_BY}
                  warn_status={false}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12}>
            {quotationResponse && quotationResponse.length !== 0
              ? quotationResponse.map((data: any) => (
                  <TermQuoteCard
                    loader={loader}
                    data={data}
                    BUY_ACTION={BUY_ACTION}
                  />
                ))
              : null}
            {!loader && quotationResponse && quotationResponse.length === 0 ? (
              <Box
                className="no-quote-box"
                style={{ textAlign: "center", paddingTop: "40px" }}
              >
                <img src="../images/no_quotes_icon.svg" alt="" />
                <p
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    fontSize: "14px",
                    fontWeight: 600,
                    marginTop: "24px",
                  }}
                >
                  Sorry, no results this time. Let's try something else.
                </p>
              </Box>
            ) : null}
          </Grid>
        </Grid>
        {/* Quick Tips Sidebar */}
        <Grid xs sx={{ maxWidth: "308px", minWidth: "308px" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "24px",
              padding: "16px",
            }}
          >
            <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <img src="./images/mdi_head-thinking.svg" height="32px" />
              <Box>
                <h5>Choose the best plan</h5>
                <p style={{ color: COLORS.lightgrey }}>Tips to keep in mind</p>
              </Box>
            </Box>
            <ul className="tips">
              <li
                style={{
                  color: COLORS.lightgrey,
                  fontSize: "14px",
                }}
              >
                Determine the{" "}
                <span style={{ color: COLORS.darkgrey, fontWeight: "bold" }}>
                  Policy period
                </span>
              </li>
              <li
                style={{
                  color: COLORS.lightgrey,
                  fontSize: "14px",
                }}
              >
                Assess Current{" "}
                <span style={{ color: COLORS.darkgrey, fontWeight: "bold" }}>
                  Lifestyle
                </span>
              </li>
              <li
                style={{
                  color: COLORS.lightgrey,
                  fontSize: "14px",
                }}
              >
                Look at the{" "}
                <span style={{ color: COLORS.darkgrey, fontWeight: "bold" }}>
                  Existing liabilities
                </span>
              </li>
              <li
                style={{
                  color: COLORS.lightgrey,
                  fontSize: "14px",
                }}
              >
                Check{" "}
                <span style={{ color: COLORS.darkgrey, fontWeight: "bold" }}>
                  Claim settlement ratio
                </span>{" "}
                of the insurer
              </li>
            </ul>
            {/* <Box
              sx={{
                border: "1px solid #E0E0E0",
                padding: "16px",
                borderRadius: "24px",
                display: "flex",
                gap: "16px",
                marginTop: "16px",
                cursor: "pointer",
              }}
            >
              <img src="./images/call-icon.svg" height="24px" />
              <Box>
                <p>Get a call back</p>
                <p style={{ color: COLORS.lightgrey, lineHeight: "14px" }}>
                  <small>
                    Arrange a call back from our health product advisor
                  </small>
                </p>
              </Box>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TermQuote;
