import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import { COLORS } from "../../../../SupportingFiles/colors";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useNavigate } from "react-router-dom";

const MQuoteNavbar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: COLORS.primary,
        height: "64px",
        position: "fixed",
        top: "0px",
        width: "100%",
        zIndex: "91",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        padding: "0px 12px",
      }}
    >
      <Grid container className="row" spacing={3} alignItems="center">
        <Grid xs={7} display={"flex"} alignItems={"center"} gap={"12px"}>
          <Link
            sx={{ display: "inline-block", height: "24px" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBackIosNewRoundedIcon
              style={{ color: "#000000", padding: "2px" }}
            />
          </Link>
          <img
            src="../images/jio_insurance_logo.svg"
            alt="Jio Insurance Brokers"
            height="12px"
            onClick={() => {
              navigate("/");
            }}
          />
        </Grid>
        <Grid xs={5} textAlign="right">
          {/* <Link
            sx={{
              color: "#2F2311",
              fontWeight: "bold",
              fontSize: "16px",
              lineHeight: "24px",
              display: "inline-flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              textDecoration: "none",
              padding: "8px 16px",
            }}
          >
            <img src="../images/call-icon.svg" height="24px" />
            Help
          </Link> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MQuoteNavbar;
