import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { TERM_URLS } from "../../URLCollection/Term/TernURLs";

const addTermFormData = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    TERM_URLS.ADD_TERM_FORM,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const EVENT_TRACKER = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    TERM_URLS.EVENT_TRACKER_TERM,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const EVENT_TRACKER_NON_TERM = (
  onSuccess: Function,
  onError: Function,
  data: any
) => {
  PostAPI.call(
    TERM_URLS.EVENT_TRACKER_NON_TERM,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TERM_SERVICES = {
  addTermFormData,
  EVENT_TRACKER,
  EVENT_TRACKER_NON_TERM,
};
