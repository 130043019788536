// Extend the Window interface to include onNativeAppCallback with accurate typing for existing properties
declare global {
  interface Window {
    onNativeAppCallback?: (nativeResponse: string) => void;
    WebViewInterface?: {
      receiveString: (message: string) => void;
    };
    dataLayer: Record<string, any>[];
    android?: {
      __externalCall: (message: string) => void;
    };
    __externalCall?: (data: string) => void;
  }
}

// Utility to check if the DOM is available
const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

// Utility to check if the platform is iOS
// export const isIOS = () => {
//   return (
//     /iPad|iPhone|iPod/.test(navigator.platform) ||
//     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
//   );
// };

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) || // iOS device detection via userAgent
    (navigator.userAgent.includes("Mac") && "ontouchend" in document) // Detect iPadOS (Mac with touch support)
  );
};

// Register a callback for native app responses
export const registerNativeAppCallback = (
  callback: (response: Record<string, any>) => void
) => {
  if (!window.onNativeAppCallback) {
    // If the callback is not registered, register it
    window.onNativeAppCallback = (nativeResponse: string) => {
      try {
        const decodedResponse = window.atob(nativeResponse); // Decode the base64 encoded string
        const response = JSON.parse(decodedResponse); // Parse the JSON string
        console.log("Received response from native app"); // Show success toast
        callback(response); // Call the provided callback with the parsed response
      } catch (error) {
        console.log(
          `Error processing native app response: ${
            error instanceof Error ? error.message : "Unknown error"
          }`
        );
      }
    };
    // Optional: Notify that the callback has been registered
    console.log("Callback registered successfully.");
  } else {
    // If callback is already registered, notify the user or log it
    console.log(
      "Callback already registered. Responses will still be processed."
    );
  }
};

// Send an event to the native app
export const SendEventToApp = (
  payload: Record<string, any>,
  callback: (response: Record<string, any>) => void
) => {
  if (canUseDOM) {
    const stringifiedData = JSON.stringify(payload);

    const encodedData = window.btoa(stringifiedData);

    // Register the callback handler if not already set
    registerNativeAppCallback(callback);

    // Send data to native app
    if (window.WebViewInterface) {
      window.WebViewInterface.receiveString(stringifiedData);
    } else if (window.android) {
      window.android.__externalCall(encodedData);
    } else if (window.__externalCall) {
      window.__externalCall(encodedData);
    } else if (isIOS() && window.webkit?.messageHandlers?.callback) {
      window.webkit.messageHandlers.callback.postMessage(encodedData);
    } else {
      console.log("Native app interface not available");
    }
  }
};

// Combined header and footer visibility into a single payload
export const changeHeaderFooterVisibility = (
  visibility: number,
  callback: (response: Record<string, any>) => void
) => {
  console.log("visi", visibility);

  const payload_header = { type: "header_VISIBILITY", value: visibility };
  const payload_footer = { type: "footer_VISIBILITY", value: visibility };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(payload_header);
  window.dataLayer.push(payload_footer);

  SendEventToApp(payload_header, callback);
  SendEventToApp(payload_footer, callback);
};
