import { createSlice } from "@reduxjs/toolkit";
import { TCMSSlice } from "../../../types/TCMSSlice";
import { CMS_REDUCERS } from "./CMSReducer";

const initialState: TCMSSlice = {
  md_ceo: {
    data: {
      attributes: {
        title: "",
        directors_name: "",
        directors_desc: "",
        directors_desg: "",
        directors_image: { data: { attributes: { url: "" } } },
        awards: {
          title: "",
          image: { data: { attributes: { url: "" } } },
          data: [{ title: "", desc: "" }],
          few_words_desc: "",
          few_words_heading: "",
          few_words_sub_desc: "",
          few_words_sub_desc_2: "",
        },
        Experience: { title: "", desc: "", data: [] },
        Solutions: { title: "", desc: "", data: [] },
        Achievements: [{ title: "", desc: "", data: [{ desc: "" }] }],
      },
    },
  },
  key_management_personal: { data: { data: [] }, title: "" },
  disclaimer: {
    desc: "",
    thankyou_message: "",
    title: "",
    data: { data: [] },
  },
  privacy_policy: {
    desc: "",
    thankyou_message: "",
    title: "",
    data: { data: [] },
  },
  refund_cancellation_policy: {
    desc: "",
    thankyou_message: "",
    title: "",
    data: { data: [] },
  },
  terms_condition: {
    desc: "",
    thankyou_message: "",
    title: "",
    data: { data: [] },
  },
  about_us: {
    data: {
      attributes: {
        desc: "",
        ethics_data: [],
        ethics_title: "",
        expertise: { title: "", desc: "" },
        expertise_data: [],
        mission_image: { data: { attributes: { url: "" } } },
        mission_statement_data: [],
        mission_statement_title: "",
        mission_title: "",
        primary_info: {
          title: "",
          image: { data: { attributes: { url: "" } } },
          desc: "",
        },
        primary_info_data: [],
        quality: { title: "", desc: "" },
        quality_data: [],
        quality_data_title: "",
        title: "",
        vision_statement: { title: "", desc: "" },
      },
    },
  },
};

export const CMSSlice = createSlice({
  name: "CMS",
  initialState: initialState,
  reducers: { ...CMS_REDUCERS },
});
