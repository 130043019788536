import { useEffect, useMemo, useRef, useState } from "react";
import { GetAPI } from "../../../../Axios/AxiosInstanceToken";
import TATANonTermProposal from "../../../../Page/Desktop/NonTerm/NonTermProposal/TATA/TATANonTermProposal";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { URL_CONSTANTS } from "../../../../URLCollection/URL";

const TATANonTermBasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const {
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    UPDATE_RESPONSE,
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
  } = useAppSelector((state) => state.NonTerm);
  const { NonTerm } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const prefilledData = {
    dob: {
      value: ADD_FORM.age.value,
      warning: ADD_FORM.age.warning,
    },
    gender: {
      value: ADD_FORM.gender.value,
      warning: ADD_FORM.gender.warning,
    },
    mobile: {
      value: ADD_FORM.mobile.value,
      warning: ADD_FORM.mobile.warning,
    },
    email: {
      value: ADD_FORM.email.value,
      warning: ADD_FORM.email.warning,
    },
    pincode: {
      value: ADD_FORM.pincode.value,
      warning: ADD_FORM.pincode.warning,
    },
  };

  const [proposerFields, setProposerFields] = useState<TNonTermProposerData>({
    ...PROPOSER_DATA,
    ...prefilledData,
  });
  const formRef = useRef<HTMLFormElement | null>(null);
  const [paymentMode, setPaymentMode] = useState<string>();
  const [basePlan, setBasePlan] = useState<string>("");
  const [address, setaddress] = useState<any>();

  console.log(proposerFields, "proposerFields");

  const fieldsUpdateState = (attr: any, value: any) => {
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: value,
        warning: false,
      },
    });
  };

  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        proposer_annual_income: ADD_FORM.annualIncome.value,
        proposer_dob: ADD_FORM.age.value,
        proposer_email: proposerFields.email.value,
        proposer_emergency_no: ADD_FORM.mobile.value,
        proposer_gender: ADD_FORM.gender.value,
        proposer_mobile: ADD_FORM.mobile.value,
        proposer_name: proposerFields.full_name.value,
        proposer_occupation: proposerFields.occupation.value,
        proposer_qualification: proposerFields.qualification.value,
        address_pincode: ADD_FORM.pincode.value,

        //insured member
        insured_name_annual_income: ADD_FORM.annualIncome.value,
        insured_dob: ADD_FORM.age.value,
        insured_email: proposerFields.email.value,
        insured_emergency_no: ADD_FORM.mobile.value,
        insured_gender: ADD_FORM.gender.value,
        insured_mobile: ADD_FORM.mobile.value,
        insured_name: proposerFields.full_name.value,
        // insured_occupation: "SPVT",
        // insured_qualification: "680",
        insured_pincode: ADD_FORM.pincode.value,
      },
    };
    const onSuccess = (res: any) => {
      setLoader(false);
      if (formRef.current) {
        formRef.current.submit();
      }
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  const GET_ADDRESS = (onSuccess: Function) => {
    GetAPI.call(
      URL_CONSTANTS.GET_ADDRESS + `${proposerFields.pincode.value}`,
      (res: any) => setaddress(res.data.results.response)
      // onError
    );
  };

  useEffect(() => {
    GET_ADDRESS(setaddress);
  }, []);

  const validateForm = () => {
    let data = { ...proposerFields };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data?.dob?.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data?.gender?.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      pincode: {
        ...data.pincode,
        warning: isEmpty(data?.pincode.value),
      },
      pan_no: {
        ...data.pan_no,
        warning: !validatePAN(data.pan_no.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data?.occupation?.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data?.qualification?.value),
      },
    };
    setProposerFields({ ...data });

    let hasError = false;

    if (
      data.full_name.warning ||
      data.dob.warning ||
      data.gender.warning ||
      data.mobile.warning ||
      data.email.warning ||
      data.pan_no.warning ||
      data.occupation.warning ||
      data.qualification.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(NonTermSlice.actions.UPDATE_PROPOSER_DATA(data));
      UPDATE_PROPOSER_DETAILS();
    }
  };

  const requestData = useMemo(
    () => ({
      REQUEST: `<?xml version="1.0" encoding="UTF-8"?>
<faml>
           <CURNT_CITY>${address?.city_name}</CURNT_CITY>
           <CURNT_STATE>${address?.state_name}</CURNT_STATE>
	<CURNT_ZIP_CODE>${proposerFields.pincode.value}</CURNT_ZIP_CODE>
	<COUNTRY>IN</COUNTRY>
  <OCCUPATION_CD>${proposerFields.occupation.value}</OCCUPATION_CD>
	<RESIDENT_STATUS>RI</RESIDENT_STATUS>
	<NOMINEE_FIRST_NAME></NOMINEE_FIRST_NAME>
	<NOMINEE_LAST_NAME></NOMINEE_LAST_NAME>
	<NOMINEE_RELATIONSHIP></NOMINEE_RELATIONSHIP>
	<AADHAR_NO></AADHAR_NO>
	<PAN_NO>${proposerFields.pan_no.value}</PAN_NO>
   <EDUCATION_CD>${proposerFields.qualification.value}</EDUCATION_CD>
	<BIRTH_DATE>${proposerFields.dob.value}</BIRTH_DATE>
	<EMAIL_ID>${proposerFields.email.value}</EMAIL_ID>
	<GENDER_CD>${proposerFields.gender.value === "M" ? "1" : "2"}</GENDER_CD>
	<MARITAL_STATUS_CD>1</MARITAL_STATUS_CD> 
	<MOBILE_NO>${proposerFields.mobile.value}</MOBILE_NO>
	<LEAD_NAME>${proposerFields.full_name.value}</LEAD_NAME>
	<BUY_FOR>Self</BUY_FOR>
	<GROSS_INCOME>${ADD_FORM.annualIncome.value}</GROSS_INCOME>
	<TOBACCO_FLAG>N</TOBACCO_FLAG>
	<INSURED_BIRTH_DATE>${proposerFields.dob.value}</INSURED_BIRTH_DATE>
           <INSURED_GENDER_CD>${
             proposerFields.gender.value === "M" ? "1" : "2"
           }</INSURED_GENDER_CD>
	<INSURED_NAME>${proposerFields.full_name.value}</INSURED_NAME>
	<ISWHOLELIFE>N</ISWHOLELIFE>
	<CHECKSUM></CHECKSUM>
 <SA>${proposerFields.sum_assured.value}</SA>
   <PLAN_OPTION>${basePlan}</PLAN_OPTION>
	<POLICY_MODE>S</POLICY_MODE>
    <POLICY_TERM>${SELECTED_QUOTE_DATA.premiumDetails.term}</POLICY_TERM>
	    <PPT>${SELECTED_QUOTE_DATA.premiumDetails.pay_term}</PPT>
	<RIDER_SA></RIDER_SA>
	<REFERRED_BY>1234</REFERRED_BY>
	<TRANSACTION_DATE>08042024</TRANSACTION_DATE>
	<PRODUCT_PITCHED>357</PRODUCT_PITCHED>
	<BUSINESS_TYPE_ID>JioSolution</BUSINESS_TYPE_ID>
	<AGENT_CD>10710846</AGENT_CD>
	<SUB_OFFICE_CODE>RRI0005</SUB_OFFICE_CODE>
	<BQP_CODE>12345</BQP_CODE>
	<EMPLOYEE_CODE>5667551</EMPLOYEE_CODE>
	<EMPLOYEE_NAME>Agent Name</EMPLOYEE_NAME>
</faml>`,
      fldOrgTxn: "TAT",
    }),
    [SELECTED_QUOTE_DATA, ADD_FORM]
  );

  let redirectionUrl =
    "https://uatsellonline.tataaia.com/wps/PA_TATAAIA_SO/GenericRedirection";

  const NON_TERM_PROPOSER_OCCUPATION_DETAILS = () => {
    let params = {
      product_type_code: "NPAR",
      product_code: `${SELECTED_QUOTE_DATA.productDetails.product_code}`,
    };

    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        label: item.code,
        value: item.title,
      }));

      dispatch(
        NonTermSlice.actions.SET_DROPDOWN_DATA({
          key: "OCCUPATION_DATA",
          value: data,
        })
      );
    };

    const onError = () => {};

    NON_TERM_SERVICES.NON_TERM_PROPOSER_OCCUPATION_DETAILS(
      onSuccess,
      onError,
      params
    );
  };
  const NON_TERM_PROPOSER_QUALIFICATION_DETAILS = () => {
    let params = {
      product_type_code: "NPAR",
      product_code: `${SELECTED_QUOTE_DATA.productDetails.product_code}`,
    };
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        label: item.code,
        value: item.title,
      }));

      dispatch(
        NonTermSlice.actions.SET_DROPDOWN_DATA({
          key: "QUALIFICATION_DATA",
          value: data,
        })
      );
    };
    const onError = () => {};
    NON_TERM_SERVICES.NON_TERM_PROPOSER_QUALIFICATION_DETAILS(
      onSuccess,
      onError,
      params
    );
  };

  useEffect(() => {
    NON_TERM_PROPOSER_OCCUPATION_DETAILS();
    NON_TERM_PROPOSER_QUALIFICATION_DETAILS();
  }, []);

  useEffect(() => {
    if (QUOTE_FIELDS_DATA.investmentMode === "1") {
      setPaymentMode("A");
    } else if (QUOTE_FIELDS_DATA.investmentMode === "12") {
      setPaymentMode("M");
    } else if (QUOTE_FIELDS_DATA.investmentMode === "4") {
      setPaymentMode("Q");
    } else if (QUOTE_FIELDS_DATA.investmentMode === "2") {
      setPaymentMode("S");
    } else {
      setPaymentMode("O");
    }
  }, [QUOTE_FIELDS_DATA]);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.product_code == "NPT003") {
      if (QUOTE_FIELDS_DATA.payUpto == QUOTE_FIELDS_DATA.policyTerm) {
        setBasePlan("FGPSRV8N1");
      } else if (QUOTE_FIELDS_DATA.payUpto == "1") {
        setBasePlan("FGPSSV8N1");
      } else {
        setBasePlan("FGPSLV8N1");
      }
    } else if (SELECTED_QUOTE_DATA.productDetails.product_code == "NPT004") {
      if (QUOTE_FIELDS_DATA.payUpto == "1") {
        setBasePlan("FGPJSV8N1");
      }
    }
  }, [QUOTE_FIELDS_DATA]);

  console.log(requestData, "requestData");

  return (
    <>
      {isMobile ? null : (
        <TATANonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
      <div style={{ display: "none" }}>
        <form method="POST" action={redirectionUrl} ref={formRef}>
          <textarea
            name="REQUEST"
            value={requestData.REQUEST}
            readOnly
            style={{ display: "none" }}
          />
          <input
            type="hidden"
            name="fldOrgTxn"
            value={requestData.fldOrgTxn}
            readOnly
            style={{ display: "none" }}
          />
        </form>
      </div>
    </>
  );
};

export default TATANonTermBasicDetailsContainer;
