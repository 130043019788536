import React, { useState, useEffect } from "react";
import { Box, Button, Input, Link, Slider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./../../../../../SCSS/ModalPopup.scss";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import { COLORS } from "../../../../../SupportingFiles/colors";
import ContentRadioButton from "../../../../../Component/InputFields/ContentRadioButton/ContentRadioButton";
import BorderRadioButton from "../../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import TextInputField from "../../../../../Component/InputFields/TextInputField/TextInputField";
import SelectDropdown from "../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import DatePickerInput from "../../../../../Component/InputFields/DatePicker/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { NonTermSlice } from "../../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { NON_TERM_SERVICES } from "../../../../../Services/NonTerm/NonTermFormService";
import GenderRadio from "../../../../../Component/Global/GenderRadio/GenderRadio";
import { subYears } from "date-fns";

function MNonTermModifyDetails({
  open,
  setOpen,
  setQuoteLoader,
  GET_PRODUCT_LIST
}: {
  open: boolean;
  setOpen: Function;
  setQuoteLoader: Function;
  GET_PRODUCT_LIST:Function;
}) {
  const dispatch = useAppDispatch();
  const { ADD_FORM, ADD_FORM_RESPONSE, QUOTE_FIELDS_DATA, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    // Cleanup function
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  const { NonTerm } = useAppSelector((state) => state);
  const [gender, setGender] = useState(ADD_FORM.gender.value);
  const [dob, setDob] = useState(ADD_FORM.age.value);

  const updatePlanDetails = () => {
    setQuoteLoader(true);
    dispatch(
      NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
        ...ADD_FORM,
        age: {
          value: dob,
          warning: false,
        },
        gender: {
          value: gender,
          warning: false,
        },
      })
    );
    let params = {
      quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
      policy_term: `${QUOTE_FIELDS_DATA?.policyTerm}`,
      policy_paying_term: `${QUOTE_FIELDS_DATA?.payUpto}`,
      payment_mode: `${ADD_FORM.investmentMode.value}`,
      sum_assured: "50000",
      dob: dob,
      gender: gender,
      pincode: `${ADD_FORM_RESPONSE?.pincode}`,
    };

    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    setOpen(false);
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        // GET_PRODUCT_LIST()
        // dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        dispatch(
          NonTermSlice.actions.UPDATE_RESPONSE_DATA({
            UPDATE_RESPONSE: { ...res?.results?.response },
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    NON_TERM_SERVICES.updateNonTermDetails(onSuccess, onError, params);
  };
  const closeModifyDetailsModel = () => {
    setGender(
      UPDATE_RESPONSE.gender === ""
        ? ADD_FORM.gender.value
        : UPDATE_RESPONSE.gender
    );
    setDob(
      UPDATE_RESPONSE.dob === "" ? ADD_FORM.age.value : UPDATE_RESPONSE.dob
    );
  };

  useEffect(() => {
    if (UPDATE_RESPONSE) {
      if (UPDATE_RESPONSE.dob) {
        setDob(UPDATE_RESPONSE.dob);
      }
      if (UPDATE_RESPONSE.gender) {
        setGender(UPDATE_RESPONSE.gender);
      }
    }
  }, [UPDATE_RESPONSE]);
  return (
    <>
      {open == true ? (
        <Box className="popupBgOverlay">
          <Box className="contentWrapper">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpen(false);
                      closeModifyDetailsModel();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="scrollable-area">
              <Grid container className="row" columnSpacing={3}>
                <Grid xs={12}>
                  <h3 className="mb-6">Personal details</h3>
                </Grid>
                <Grid xs={12} className="mb-4">
                  <h5 style={{ marginBottom: "8px" }}>Select Gender</h5>
                  <GenderRadio
                    value={gender}
                    value_update={(a: string, v: string) => {
                      setGender(v);
                    }}
                  />
                </Grid>
                <Grid xs={12} className="mb-6">
                  <DatePickerInput
                    title={"Date of Birth"}
                    value={dob}
                    attrName={"age"}
                    onChange={(a: any, v: any) => {
                      setDob(v);
                    }}
                    warn_status={ADD_FORM.age.warning}
                    error_message="Select DOB"
                    min_date={65}
                    max_date={18}
                    default_date={new Date("1990-01-01")}
                  />
                </Grid>
                <Grid xs={12}>
                  <JDSButtons
                    text={"Update"}
                    variant={"text"}
                    className={"primaryBtn large"}
                    onClick={() => updatePlanDetails()}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MNonTermModifyDetails;
