import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function IRDAIPage() {
  const isMobile = useIsMobile();
  return (
    <Box className={styles.cmsWrapper}>
      {isMobile ? <MNavBar title={"ISNP Service TAT"} /> : <NavBar />}

      <Grid container spacing={3} className={styles.cmsPages}>
        <Grid xs={12}>
          <h2>ISNP Service TAT</h2>
        </Grid>
        <Grid xs={12}>
          <h3>Pre-Sale</h3>
          <h4>Procedure</h4>
          <h5 style={{ fontWeight: 600, marginBottom: "8px" }}>
            Completion of the proposal form
          </h5>
          <p>
            Customers must provide basic details when purchasing an insurance
            policy from the website/portal or app. Basis the Insurance product
            that the customer decides to buy, they need to submit personal
            information such as name, gender, date of birth, income, occupation,
            etc and also the coverage amount required.
          </p>
          <h5 style={{ fontWeight: 600, marginBottom: "8px" }}>
            Policy payment
          </h5>
          <p>
            After completion of the application / proposal form, customers will
            be routed to the payment gateway. They can select a payment method
            from options such as Credit Card, Debit Card, Internet banking, UPI,
            or e-wallet. Once the payment mode is selected, customers must enter
            the payment details and proceed with the transaction. After
            successful payment, they will be redirected to the payment
            confirmation page and policy copy will be sent to the registered
            email address.
          </p>
        </Grid>
        <Grid xs={12}>
          <Box className={styles.tableContainer}>
            <table className={styles.irda_1}>
              <tr>
                <th colSpan={3}>Prospect servicing turnaround time</th>
              </tr>
              <tr>
                <th>Service parameters</th>
                <th>Maximum TAT (calendar days)</th>
                <th>The turnaround time to be calculated from</th>
              </tr>
              <tr>
                <td>
                  Enquiry regarding Policy issuance status or additional
                  requirements on shared proposals. Servicing team will
                  coordinate with insurer for policy issuance status.
                </td>
                <td>15 days</td>
                <td>
                  Date of receipt of the proposal /application or the date of
                  receipt of any requirement, whichever is later
                </td>
              </tr>
              <tr>
                <td>
                  Refund of outstanding proposal deposit in case of proposal
                  rejection by insurer
                </td>
                <td>15 days</td>
                <td>Date of cancellation received from the Insurer</td>
              </tr>
            </table>
          </Box>
        </Grid>

        <Grid xs={12}>
          <h3>Post-Sale</h3>
          <Box className={styles.tableContainer}>
            <table className={styles.irda_2}>
              <tr>
                <th colSpan={3}>
                  In accordance with Schedule III of the ISNP Guidelines, we
                  follow the following service TATs for online sale of Health
                  Insurance products:
                </th>
              </tr>
              <tr>
                <th colSpan={3}>Policy holder servicing turnaround time</th>
              </tr>
              <tr>
                <th>Service parameters </th>
                <th>Maximum TAT (calendar days)</th>
                <th>The turnaround time to be calculated from</th>
              </tr>
              <tr>
                <td>
                  Notification of claim-related information to the insurer
                </td>
                <td>3 working days</td>
                <td>
                  The date when claim-related information is received from the
                  customer
                </td>
              </tr>
              <tr>
                <td>
                  Issuance and crediting of the Policy document / Certificate of
                  Insurance / Form
                </td>
                <td>15 days</td>
                <td>Date of receipt of complete documents.</td>
              </tr>
              <tr>
                <td>
                  Issuance of endorsement It may be financial or non-financial
                  endorsement.
                </td>
                <td>15 days</td>
                <td>
                  Date of receipt of complete documents as per Insurer’s. In
                  case of Financial endorsement, date of payment of additional
                  premium (if required) directly to the Insurer
                </td>
              </tr>
              <tr>
                <td>Policy terms & condition clarification</td>
                <td>15 days</td>
                <td>Date of request received from the insurer</td>
              </tr>
              <tr>
                <td>Request for policy terms & condition change</td>
                <td>15 days</td>
                <td>Date of receipt of complete documents as per insurer's</td>
              </tr>
              <tr>
                <td>Claim Status</td>
                <td>15 days</td>
                <td>
                  JIBL will follow up with the insurer for the claim status from
                  the date request is received from the customer
                </td>
              </tr>
              <tr>
                <td>Claim settlement</td>
                <td>30 days</td>
                <td>
                  If the customer raises a ticket for claim assistance, we will
                  coordinate with the insurer and offer assistance to the
                  customer.
                </td>
              </tr>
              <tr>
                <td>Grievance Resolution</td>
                <td>14 days</td>
                <td>
                  Aim to resolve the grievance within 14 days of its receipt
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  * The TATs (Turnaround Times) are established for addressing
                  customer requirements and grievances and coordinating with the
                  respective insurers to resolve service issues that rely on the
                  insurers for resolution. JIBL will not be considered in breach
                  of the TATs if the issue is pending resolution from the
                  insurer
                </td>
              </tr>
            </table>
          </Box>
        </Grid>
        <Grid xs={12}>
          <h4>Procedure</h4>
          <Box className={styles.tableContainer}>
            <table className={styles.irda_2}>
              <tr>
                <th colSpan={3}>
                  In accordance with Schedule III of the ISNP Guidelines, we
                  follow the following service TATs for online sale of Motor &
                  Two-Wheeler Insurance products:
                </th>
              </tr>
              <tr>
                <th colSpan={3}>Policy holder servicing turnaround time</th>
              </tr>
              <tr>
                <th>Service parameters</th>
                <th>Maximum TAT (calendar days)</th>
                <th>The turnaround time to be calculated from</th>
              </tr>
              <tr>
                <td>
                  Notification of claim-related information to the insurer
                </td>
                <td>3 working days</td>
                <td>
                  The date when claim-related information is received from the
                  customer
                </td>
              </tr>
              <tr>
                <td>
                  Issuance and crediting of the Policy document / Certificate of
                  Insurance / Form
                </td>
                <td>15 days</td>
                <td>Date of receipt of complete documents.</td>
              </tr>
              <tr>
                <td>
                  Issuance of endorsement It may be financial or non-financial
                  endorsement.
                </td>
                <td>15 days</td>
                <td>
                  Date of receipt of complete documents as per Insurer’s. In
                  case of Financial endorsement, date of payment of additional
                  premium (if required) directly to the Insurer
                </td>
              </tr>
              <tr>
                <td>Transfer of Ownership</td>
                <td>15 days</td>
                <td>
                  Date of receipt of complete documents as per Insurer’s. & date
                  of payment of additional premium (if required) directly to the
                  Insurer
                </td>
              </tr>
              <tr>
                <td>Request for policy terms & condition change</td>
                <td>15 days</td>
                <td>Date of receipt of complete documents as per insurer's</td>
              </tr>
              <tr>
                <td>Claim Status</td>
                <td>15 days</td>
                <td>
                  JIBL will follow up with the insurer for the claim status from
                  the date request is received from the customer
                </td>
              </tr>
              <tr>
                <td>Claim settlement</td>
                <td>30 days</td>
                <td>
                  If the customer raises a ticket for claim assistance, we will
                  coordinate with the insurer and offer assistance to the
                  customer.
                </td>
              </tr>
              <tr>
                <td>Grievance Resolution</td>
                <td>14 days</td>
                <td>
                  Aim to resolve the grievance within 14 days of its receipt
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  * The TATs (Turnaround Times) are established for addressing
                  customer requirements and grievances and coordinating with the
                  respective insurers to resolve service issues that rely on the
                  insurers for resolution. JIBL will not be considered in breach
                  of the TATs if the issue is pending resolution from the
                  insurer
                </td>
              </tr>
            </table>
          </Box>
        </Grid>
        <Grid xs={12}>
          <h4>Procedure</h4>
          <Box className={styles.tableContainer}>
            <table className={styles.irda_2}>
              <tr>
                <th colSpan={3}>
                  In accordance with Schedule III of the ISNP Guidelines, we
                  follow the following service TATs for online sale of Life
                  Insurance products:
                </th>
              </tr>
              <tr>
                <th colSpan={3}>Policy holder servicing turnaround time</th>
              </tr>
              <tr>
                <th>Service parameters</th>
                <th>Maximum TAT (calendar days)</th>
                <th>The turnaround time to be calculated from</th>
              </tr>
              <tr>
                <td>
                  Notification of claim-related information to the insurer
                </td>
                <td>3 working days</td>
                <td>
                  The date when claim-related information is received from the
                  customer
                </td>
              </tr>
              <tr>
                <td>
                  Written communication of decisions regarding the proposal and
                  the submission of additional requirements for the proposal
                </td>
                <td>15 days</td>
                <td>
                  The date of receiving the proposal or the date of receiving
                  any requested information, whichever is later
                </td>
              </tr>
              <tr>
                <td>
                  Requests for refunds related to free look cancellations,
                  surrenders, withdrawals, proposal deposit refunds, and any
                  outstanding proposal deposit refunds, if applicable.
                </td>
                <td>15 days</td>
                <td>
                  Date of receipt of request or the last required document,
                  whichever is received later
                </td>
              </tr>
              <tr>
                <td>
                  Issuance of endorsement It may be financial or non-financial
                  endorsement.
                </td>
                <td>15 days</td>
                <td>
                  Date of receipt of complete documents as per Insurer’s. In
                  case of Financial endorsement, date of payment of additional
                  premium (if required) directly to the Insurer
                </td>
              </tr>
              <tr>
                <td>Policy terms & condition clarification</td>
                <td>15 days</td>
                <td>Date of request received from the insurer</td>
              </tr>
              <tr>
                <td>Request for policy terms & condition change</td>
                <td>15 days</td>
                <td>Date of receipt of complete documents as per insurer's</td>
              </tr>
              <tr>
                <td>Change in coverage amount</td>
                <td>15 days</td>
                <td>Date of receipt of complete documents as per insurer's</td>
              </tr>
              <tr>
                <td>Change of nominee</td>
                <td>15 days</td>
                <td>Date of receipt of complete documents as per insurer's</td>
              </tr>
              <tr>
                <td>Claim Status</td>
                <td>15 days</td>
                <td>
                  JIBL will follow up with the insurer for the claim status from
                  the date request is received from the customer
                </td>
              </tr>
              <tr>
                <td>Claim settlement</td>
                <td>30 days</td>
                <td>
                  If the customer raises a ticket for claim assistance, we will
                  coordinate with the insurer and offer assistance to the
                  customer.
                </td>
              </tr>
              <tr>
                <td>Grievance Resolution</td>
                <td>14 days</td>
                <td>
                  Aim to resolve the grievance within 14 days of its receipt
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  * The TATs (Turnaround Times) are established for addressing
                  customer requirements and grievances and coordinating with the
                  respective insurers to resolve service issues that rely on the
                  insurers for resolution. JIBL will not be considered in breach
                  of the TATs if the issue is pending resolution from the
                  insurer
                </td>
              </tr>
            </table>
          </Box>
        </Grid>
        <Grid xs={12}>
          <p>
            {" "}
            For queries pertaining to the above processes please send an email
            at{" "}
            <Link href="mailto:sales.force@jioinsure.in">
              sales.force@jioinsure.in
            </Link>
          </p>
          <p>
            {" "}
            Customer needs to send service related request at{" "}
            <Link href="mailto:customer.care@jioinsure.in">
              customer.care@jioinsure.in
            </Link>{" "}
            & claim related request at{" "}
            <Link href="mailto:claims.support@jioinsure.in">
              claims.support@jioinsure.in
            </Link>
          </p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default IRDAIPage;
