import React, { useState, useEffect } from "react";
import { Box, Button, Input, Link, Slider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./../../../../SCSS/ModalPopup.scss";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import SelectDropdown from "../../../InputFields/SelectDropdown/SelectDropdown";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { HOME_SERVICES } from "../../../../Services/Home/HomeServices";
import { COLORS } from "../../../../SupportingFiles/colors";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";

function MContactusPopup({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  type TContactUs = {
    name: { value: string; warn_status: boolean };
    contact: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    lookingForIns: { value: string; warn_status: boolean };
  };
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    // Cleanup function
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  const [contactformFields, setContactFormFields] = useState<TContactUs>({
    name: { value: "", warn_status: false },
    contact: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    lookingForIns: { value: "", warn_status: false },
  });
  const lookingfroInsuranceData = [
    {
      label: "Health Insurance",
      value: "Health",
    },
    {
      label: "Car Insurance",
      value: "Car",
    },
    {
      label: "Two-Wheeler Insurance",
      value: "Two Wheeler",
    },
    {
      label: "Life Insurance",
      value: "Life",
    },
  ];

  const updateMasterState = (attrName: string, value: string) => {
    setContactFormFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warn_status:
          attrName === "contact"
            ? !validateMobileNumber(value)
            : attrName === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let data: TContactUs = { ...contactformFields };

    data = {
      ...data,
      contact: {
        ...data.contact,
        warn_status: !validateMobileNumber(data.contact.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
      lookingForIns: {
        ...data.lookingForIns,
        warn_status: isEmpty(data.lookingForIns.value),
      },
      name: {
        ...data.name,
        warn_status: isEmpty(data.name.value),
      },
    };
    setContactFormFields({ ...data });
    let hasError: boolean = false;
    if (
      data.contact.warn_status ||
      data.email.warn_status ||
      data.lookingForIns.warn_status ||
      data.name.warn_status
    ) {
      hasError = true;
    }
    if (!hasError) {
      UPLOAD_CONTACT_US_DATA(data);
    }
  };

  const UPLOAD_CONTACT_US_DATA = (data: TContactUs) => {
    const onSuccess = (res: any) => {};
    const onError = (err: any) => {};
    setOpen(false);
    HOME_SERVICES.CONTACT_US(onSuccess, onError, {
      email: data.email.value,
      username: data.name.value,
      number: data.contact.value,
      productName: data.lookingForIns.value,
    });
  };

  const navigate = useNavigate();

  return (
    <>
      {open === true ? (
        <Box className="popupBgOverlay">
          <Box className="contentWrapper">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="scrollable-area">
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <Box>
                    <h2 className="mb-5">Contact Us</h2>
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"16px"}>
                  <TextInputField
                    title="Name"
                    placeholder="Eg: John Smith"
                    value={contactformFields.name.value}
                    attrName={"name"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.name.warn_status}
                    error_message={"Enter Name"}
                    validation_type="NAME"
                  />
                </Grid>
                <Grid xs={12} marginBottom={"16px"}>
                  <TextInputField
                    title="Enter your email"
                    placeholder="Eg: john.smith@email.com"
                    value={contactformFields.email.value}
                    attrName={"email"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.email.warn_status}
                    error_message={
                      isEmpty(contactformFields.email.value)
                        ? "Enter E-mail"
                        : "Enter valid E-mail"
                    }
                    max_length={50}
                  />
                </Grid>
                <Grid xs={12} marginBottom={"16px"}>
                  <Box className="mobileNumber">
                    <Box
                      position="absolute"
                      left="12px"
                      bottom="13px"
                      zIndex="2"
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{ color: COLORS.lightgrey }}
                    >
                      +91
                    </Box>
                    <TextInputField
                      title="Phone number"
                      placeholder="XXXXXXXXXX"
                      validation_type="NUMBER"
                      value={contactformFields.contact.value}
                      attrName={"contact"}
                      value_update={updateMasterState}
                      warn_status={contactformFields.contact.warn_status}
                      error_message={
                        isEmpty(contactformFields.contact.value)
                          ? "Enter Phone Number"
                          : "Enter valid Phone number"
                      }
                      max_length={10}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"16px"}>
                  <SelectDropdown
                    title="Looking for"
                    data={lookingfroInsuranceData}
                    value={contactformFields.lookingForIns.value}
                    attrName={"lookingForIns"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.lookingForIns.warn_status}
                    error_message={"Select Insurance"}
                  />
                </Grid>
                <Grid xs={12}>
                  <JDSButtons
                    text={"Submit"}
                    variant={"text"}
                    className={"primaryBtn large mt-2 mb-4"}
                    onClick={() => {
                      validate_form();
                    }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      lineHeight: "14px",
                      color: COLORS.lightgrey,
                      display: "block",
                    }}
                  >
                    By submitting the form, I have read and acknowledge the{" "}
                    <Link
                      // href="./pdfs/terms_of_use.pdf"
                      onClick={() => {
                        navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                      }}
                      target="_blank"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      Terms & Conditions
                    </Link>
                    ,{" "}
                    <Link
                      // href="./pdfs/privacy_policy.pdf"
                      onClick={() => {
                        navigate(COMMON_ROUTES.PRIVACY_POLICY);
                      }}
                      target="_blank"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      // href="./pdfs/legal_disclaimer.pdf"
                      onClick={() => {
                        navigate(COMMON_ROUTES.DISCLAIMER);
                      }}
                      target="_blank"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      Disclaimer
                    </Link>{" "}
                    and agree that Jio Insurance Broking Ltd. may contact me at
                    the email address and/or phone number provided above.
                  </p>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MContactusPopup;
