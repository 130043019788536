import React, { useState, useEffect } from "react";
import { Box, Button, Input, Link, Slider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./../../../../../SCSS/ModalPopup.scss";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import { COLORS } from "../../../../../SupportingFiles/colors";
import ContentRadioButton from "../../../../../Component/InputFields/ContentRadioButton/ContentRadioButton";
import TextInputField from "../../../../InputFields/TextInputField/TextInputField";
import CustomCheckbox from "../../../../InputFields/CustomCheckbox/CustomCheckbox";
import { COMMON_ROUTES } from "../../../../../Router/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

function MLoginPopup({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
  startTime,
  setStartTime,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  startTime: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  setStartTime: Function;
  resendOtp: Function;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    // Cleanup function
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  const [loginPopup, setLoginPopup] = useState(false);
  const [showloginnextStep, setShowloginnextStep] = useState(false);

  const handleCloseLoginPopup = () => {
    setLoginPopup(false);
    setShowloginnextStep(false);
  };

  const [isChecked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event?.target?.checked);
  };

  const [timer, setTimer] = useState(60);
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (startTime && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [startTime, timer, open]);

  const handleResendOtp = () => {
    setLoginFields({
      ...loginFields,
      enterOtp: { value: "", warning: false },
    });
    resendOtp();
    setTimer(60);
    setStartTime(true);
  };

  return (
    <>
      {open == true ? (
        <Box className="popupBgOverlay">
          <Box className="contentWrapper">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpen(false);
                      setShowEnterMobileSection(true);
                      setLoginFields({
                        enterMobile: { value: "", warning: false },
                        enterOtp: { value: "", warning: false },
                      });
                      setTimer(60);
                      setStartTime(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="scrollable-area">
              <Grid
                container
                className="row"
                columnSpacing={3}
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  {showEnterMobileSection && (
                    <Box>
                      <h2 className="mb-2">Login with mobile number</h2>
                      <p
                        className="mb-6"
                        style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      >
                        Login to view policy details and renew
                      </p>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          placeholder="XXXXXXXXXX"
                          value={loginFields.enterMobile.value}
                          // attrName={"mobile"}
                          attrName={[
                            "enterMobile",
                            "value",
                            loginFields,
                            setLoginFields,
                          ]}
                          value_update={fieldsUpdateState}
                          warn_status={loginFields.enterMobile.warning}
                          error_message={
                            isEmpty(loginFields.enterMobile.value)
                              ? "Enter Mobile Number"
                              : "Enter Valid Mobile Number"
                          }
                          max_length={10}
                          validation_type="NUMBER"
                        />
                      </Box>
                      <Box className="mt-6 mb-4 tc_checkbox">
                        <CustomCheckbox
                          label={
                            <>
                              I agree to{" "}
                              <Link
                                style={{
                                  color: COLORS.primary,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                                }}
                              >
                                Terms & Conditions
                              </Link>
                            </>
                          }
                          attrName="termCondition"
                          value={true}
                          defaultChecked={true}
                          disabled={true}
                          value_update={handleCheckboxChange}
                        />
                      </Box>
                      <JDSButtons
                        text={"Generate OTP"}
                        variant={"text"}
                        className={"primaryBtn large"}
                        onClick={() => {
                          handleShowMessage();
                          setTimer(60);
                        }}
                      />
                    </Box>
                  )}

                  {/* next step */}
                  {!showEnterMobileSection && (
                    <Box>
                      <h2 style={{ marginBottom: "8px" }}>OTP Verification</h2>
                      <p
                        className="mb-6 edit_p"
                        style={{ color: "rgba(0, 0, 0, 0.65)" }}
                      >
                        The OTP has been sent to{" "}
                        <span>+91-{loginFields.enterMobile.value}</span>
                        <Link
                          className="edit"
                          // onClick={() => setShowloginnextStep(false)}
                          onClick={() => {
                            setShowEnterMobileSection(true);
                          }}
                        ></Link>
                      </p>
                      <TextInputField
                        title="Enter OTP"
                        placeholder="XXXX"
                        validation_type="NUMBER"
                        value={loginFields.enterOtp.value}
                        attrName={[
                          "enterOtp",
                          "value",
                          loginFields,
                          setLoginFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={loginFields.enterOtp.warning}
                        error_message={
                          isEmpty(loginFields.enterOtp.value)
                            ? "Enter OTP"
                            : "Enter Valid OTP"
                        }
                        max_length={4}
                      />
                      {/* <Box className="otpFieldBox mb-6">
                        <input
                          type="number"
                          name=""
                          min="0"
                          max="9"
                          placeholder="0"
                          onKeyDown={(event) => {
                            if (event.currentTarget.value.length >= 1) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="number"
                          name=""
                          min="0"
                          max="9"
                          placeholder="0"
                          onKeyDown={(event) => {
                            if (event.currentTarget.value.length >= 1) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="number"
                          name=""
                          min="0"
                          max="9"
                          placeholder="0"
                          onKeyDown={(event) => {
                            if (event.currentTarget.value.length >= 1) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="number"
                          name=""
                          min="0"
                          max="9"
                          placeholder="0"
                          onKeyDown={(event) => {
                            if (event.currentTarget.value.length >= 1) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="number"
                          name=""
                          min="0"
                          max="9"
                          placeholder="0"
                          onKeyDown={(event) => {
                            if (event.currentTarget.value.length >= 1) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <input
                          type="number"
                          name=""
                          min="0"
                          max="9"
                          placeholder="0"
                          onKeyDown={(event) => {
                            if (event.currentTarget.value.length >= 1) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Box> */}
                      <JDSButtons
                        text={"Verify OTP"}
                        variant={"text"}
                        className={"primaryBtn large mt-8"}
                        onClick={() => {
                          showMessage();
                        }}
                      />
                      <Box className="mt-4" textAlign={"center"}>
                        {timer > 0 ? (
                          <p style={{ color: "#595959" }}>
                            Resend OTP in 00:{timer < 10 ? `0${timer}` : timer}
                          </p>
                        ) : (
                          <p
                            style={{ color: "#595959", cursor: "pointer" }}
                            onClick={handleResendOtp}
                          >
                            Resend OTP
                          </p>
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MLoginPopup;
