import { useState, useEffect } from "react";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../SCSS/ModalPopup.scss";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import { COLORS } from "../../../../SupportingFiles/colors";
import { useAppSelector } from "../../../../Store/hooks";
import { formatLicensePlate } from "../../../../SupportingFiles/HelpingFunction";
import { pushMotorDataLayer, sendEventToNativeApp } from "../../../../utils/analytics";

const MTWPreFilledData = ({
  open,
  setOpen,
  continuePrefilledTW,
  denyPrefilledTW,
}: {
  open: boolean;
  setOpen: Function;
  continuePrefilledTW: Function;
  denyPrefilledTW: Function;
}) => {
  const { ADD_FORM, REDIRECTED } = useAppSelector((state) => state.TW);
  return (
    <>
      {open == true ? (
        <Box className="popupBgOverlay">
          <Box className="contentWrapper">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      pushMotorDataLayer(
                        "jioinsure_flow",
                        "two wheeler details",
                        "skip",
                        "two wheeler",
                        REDIRECTED ? "JFS app" : "Insure website"
                      );
                      const eventProps = {
                        action: "two wheeler details",
                        click: "skip",
                        type: "two wheeler",
                        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
                      };
                      sendEventToNativeApp("jioinsure_flow", eventProps);
                      setOpen(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="scrollable-area">
              <Grid
                container
                className="row"
                columnSpacing={3}
                marginBottom={"24px"}
              >
                <Grid xs={12} marginBottom={"16px"}>
                  <h3>We have found your two-wheeler</h3>
                </Grid>
                <Grid xs={9} marginBottom={"16px"} alignSelf="center">
                  <h5>Your two-wheeler details</h5>
                </Grid>
                <Grid xs={3} marginBottom={"16px"} textAlign="right">
                  <JDSButtons
                    className="secondaryBtn small"
                    text="Edit"
                    variant="outlined"
                    fullWidth={false}
                    onClick={() => {
                      pushMotorDataLayer(
                        "jioinsure_flow",
                        "two wheeler details",
                        "edit",
                        "two wheeler",
                        REDIRECTED ? "JFS app" : "Insure website"
                      );
                      const eventProps = {
                        action: "two wheeler details",
                        click: "edit",
                        type: "two wheeler",
                        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
                      };
                      sendEventToNativeApp("jioinsure_flow", eventProps);
                      denyPrefilledTW();
                    }}
                  />
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={{
                      borderRadius: "16px",
                      backgroundColor: COLORS.lightMariGold20,
                      padding: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Box>
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          marginBottom: "4px",
                        }}
                      >
                        {formatLicensePlate(ADD_FORM.reg_no.value)}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {`${ADD_FORM.make.value} ${ADD_FORM.model.value}, ${ADD_FORM.fuel_type.value}, ${ADD_FORM.variant_cc.value}`}
                      </p>
                      <p>{`Reg. Date: ${ADD_FORM.reg_date.value}`}</p>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box
                className="mt-6"
                sx={{
                  maxWidth: "336px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <JDSButtons
                  className="primaryBtn large"
                  text="Continue"
                  variant="text"
                  onClick={() => {
                    pushMotorDataLayer(
                      "jioinsure_flow",
                      "two wheeler details",
                      "proceed",
                      "two wheeler",
                      REDIRECTED ? "JFS app" : "Insure website"
                    );
                    const eventProps = {
                      action: "two wheeler details",
                      click: "proceed",
                      type: "two wheeler",
                      EntrySource: REDIRECTED ? "JFS app" : "Insure website",
                    };
                    sendEventToNativeApp("jioinsure_flow", eventProps);
                    continuePrefilledTW();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default MTWPreFilledData;
