import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import { COLORS } from "../../../../SupportingFiles/colors";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import CustomCheckbox from "../../../InputFields/CustomCheckbox/CustomCheckbox";
import SwitchToggle from "../../../InputFields/SwitchToggle/SwitchToggle";
import BorderButton from "../../../InputFields/BorderButton/BorderButton";
import ShareIcon from "@mui/icons-material/Share";
import MNonTermPolicyDetails from "./MNonTermPolicyDetails/MNonTermPolicyDetails";
import { formatToCurrency } from "../../../../SupportingFiles/HelpingFunction";

const MNonTermQuoteCard = ({
  quote_data,
  index,
  BUY_ACTION,
}: {
  quote_data: any;
  index: number;
  BUY_ACTION: Function;
}) => {
  const [openPolicyDetailsPopup, setOpenPolicyDetailsPopup] = useState(false);
  const [isChecked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const exclusions = [
    "In case of death due to suicide within 12 months from the date of inception of the Policy, the Nominee of the Policyholder shall be entitled to Fund Value, as available on the date of death. Any charges recovered subsequent to the date of death shall be paid-back to Nominee along with death benefit.",
  ];


  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          padding: "16px 8px",
          borderRadius: "12px",
          position: "relative",
          zIndex: "9",
          marginBottom: "24px",
          "&:last-child": {
            marginBottom: "0px",
          },
        }}
      >
        <Grid
          container
          className="row"
          columnSpacing={2}
          marginBottom={"16px"}
          alignItems={"center"}
        >
          <Grid xs="auto">
            <img
              src={quote_data?.quotationDetail?.companyDetails.logo}
              width="90px"
              height="50px"
            />
          </Grid>

          <Grid xs>
            <p>{quote_data?.quotationDetail?.companyDetails.short_desc}</p>
            <p style={{ color: COLORS.lightgrey }}>
              <small>{quote_data?.quotationDetail?.companyDetails.name}</small>
            </p>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid xs={12}>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid rgba(229, 241, 247, 1)",
              }}
            />
          </Grid>
        </Grid>

        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid xs={6}>
            <p>
              <small style={{ color: "#595959" }}>Maturity Amount</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {formatToCurrency(quote_data?.quotationDetail?.premiumDetails?.maturity_benefit)}
            </h6>
          </Grid>
          <Grid xs={6} textAlign={"right"}>
            <p>
              <small style={{ color: "#595959" }}>Risk Cover</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {formatToCurrency(quote_data?.quotationDetail?.premiumDetails?.risk_cover_cal)}
            </h6>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid xs={4}>
            <p>
              <small style={{ color: "#595959" }}>Policy Term</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {quote_data?.quotationDetail?.premiumDetails?.term} Years
            </h6>
          </Grid>
          <Grid xs={4} textAlign={"center"}>
            <p>
              <small style={{ color: "#595959" }}>Policy upto</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {quote_data?.quotationDetail?.premiumDetails?.pay_term} Years
            </h6>
          </Grid>
          <Grid xs={4} textAlign={"right"}>
            <p>
              <small style={{ color: "#595959" }}>Max. Maturity Age</small>
            </p>
            <h6 style={{ fontWeight: "bold", lineHeight: "24px" }}>
              {
                quote_data?.quotationDetail?.productDetails?.InvestmentFormValidations
                  .max_p_cess_age
              }{" "}
              Years
            </h6>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2} marginBottom={"16px"}>
          <Grid xs={12}>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid rgba(229, 241, 247, 1)",
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={2}>
          <Grid xs={6}>
            <JDSButtons
              className="tertiaryBtn small"
              text={
                <>
                  Plan Details
                  <KeyboardArrowRightRoundedIcon
                    style={{ width: "20px", height: "20px" }}
                  />
                </>
              }
              variant="text"
              fullWidth={false}
              onClick={() => setOpenPolicyDetailsPopup(true)}
            />
          </Grid>
          <Grid xs={6} textAlign={"right"}>
            <JDSButtons
              text={"Buy Now"}
              variant={"text"}
              className={"primaryBtn small"}
              fullWidth={false}
              onClick={() => BUY_ACTION(quote_data.quotationDetail)}
            />
          </Grid>
        </Grid>
      </Box>

      {/* set view policy Details popup */}
      <MNonTermPolicyDetails
        data={quote_data}
        open={openPolicyDetailsPopup}
        setOpen={setOpenPolicyDetailsPopup}
        BUY_ACTION={BUY_ACTION}
      />
    </>
  );
};

export default MNonTermQuoteCard;
