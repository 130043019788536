import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import "react-multi-carousel/lib/styles.css";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ProductForms.scss";

function MAboutus() {
  return (
    <>
      <HelmetComponent
        title="Jio Insurance Broking Limited - JIBL | Jio General Insurance"
        description="Jio Insurance Broking Limited (JIBL) offers life, car, bike, and health insurance solutions, focusing on compliance and customer-centric broking services. JioInsure"
        showCanonicalTag={true}
      />
      <Box sx={{ paddingTop: "64px" }} className="mhomeWrapper">
        <MNavBar />

        {/* Hero Banner */}
        <Box className="heroBanner">
          <img
            alt=""
            src="../images/aboutus-banner.webp"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                md={7}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox">
                <h2>
                  Empowering your peace of mind; discover our story at Jio
                  Insurance Broking Ltd.
                </h2>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Hero Banner End */}

        <Box padding="8px 12px 24px">
          <Grid container className="row" spacing={3}>
            <Grid xs={12}>
              <h2>Finding you the best insurance solutions since 2007</h2>
            </Grid>
            <Grid xs={12}>
              <p
                style={{
                  color: COLORS.lightgrey,
                  marginBottom: "24px",
                }}>
                Jio Insurance Broking Limited - JIBL (formerly known as -
                Reliance Retail Insurance Broking Limited-RRIBL) established in
                2006 is a fully owned subsidiary of Jio Financial Services
                Limited. JIBL was licensed as a Direct Broker by the Insurance
                Regulatory Development Authority of India in the year 2007 and
                since then we have been distributing Life, General and Health
                Insurance products of most of the Insurance companies across the
                country.
              </p>
              <p
                style={{
                  color: COLORS.lightgrey,
                }}>
                We specialize in providing customized and need based Insurance
                solutions to our retail and corporate customers. We operate
                within a very strong compliance and governance framework. The
                company has a customer centric approach and provides broking
                services across the country through a strong network of
                well-trained Insurance Guides supporting customers with their
                insurance needs.
              </p>
            </Grid>
            {/* <Grid xs={12}>
            <p
              style={{
                textTransform: "uppercase",
                lineHeight: "16px",
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Protection meets convenience
            </p>
            <h3>
              Catering for <br />
              your relaxed future
            </h3>
          </Grid>
          <Grid xs={12}>
            <img src="./images/relaxed-man.jpg" width="100%" alt="img" />
          </Grid>
          <Grid xs={12}>
            <ul
              style={{
                display: "flex",
                gap: "24px",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              {itemsData.map((item, index) => (
                <li
                  key={index}
                  style={{
                    position: "relative",
                    paddingLeft: "56px",
                  }}
                >
                  {index === itemsData.length - 1 ? null : (
                    <span
                      style={{
                        backgroundColor: "#B5B5B5",
                        position: "absolute",
                        left: "20px",
                        top: "40px",
                        height: "100%",
                        width: "1px",
                      }}
                    ></span>
                  )}
                  <Box
                    sx={{
                      backgroundColor: COLORS.lightMariGold20,
                      height: "40px",
                      width: "40px",
                      borderRadius: "40px",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      padding: "6px",
                    }}
                  >
                    <img src={item.image} width="100%" alt="icon" />
                  </Box>
                  <h5>{item.title}</h5>
                  <p
                    style={{
                      color: COLORS.lightgrey,
                      marginTop: "8px",
                    }}
                  >
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </Grid> */}
          </Grid>
        </Box>

        <MFooter />
      </Box>
    </>
  );
}

export default MAboutus;
