import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function GrievanceRedressal() {
  const isMobile = useIsMobile();
  return (
    <Box className={styles.cmsWrapper}>
      {isMobile ? <MNavBar title={"Grievance Redressal Policy"} /> : <NavBar />}

      <Grid container spacing={3} className={styles.cmsPages}>
        <Grid xs={12}>
          <h2>Grievance Redressal Policy</h2>
          <p>
            Jio Insurance Broking Limited (the “Company”) believes that an
            integral part of excellence in Customer Service is to have a prompt
            & responsive mechanism to address customer grievances. The Company
            has a robust mechanism in place to address the grievances of its
            customers across various product streams it offers, including life,
            general, and health insurance. The Company has processes and systems
            in place to address the grievances of their customers to their
            satisfaction.
          </p>
          <p>
            The Policy aims at minimizing instances of customer complaints and
            grievances through a proper service delivery and review mechanism to
            ensure prompt redressal of customer complaints and grievances
            ensuring transparency and fairness throughout the process. The
            review mechanism is aimed at identifying any shortcomings in service
            delivery and ensuring adequate redressal of customer grievances
            within the defined Regulatory TATs as stipulated under IRDAI
            Regulations.
          </p>
          <h3>Objective </h3>
          <p>
            To ensure that the Company’s grievance redressal mechanism is more
            meaningful and effective, a system/process has been designed. These
            systems ensure that the redressal provided is prompt, fair and
            permissible within the given framework of rules and regulations.
            Grievance redressal is available at all regional offices/branch
            offices of the Company, and employees at respective branch offices
            are made aware of the complaints/grievance handling process.
          </p>
          <p>The objective of the Policy is to ensure that:</p>
          <ul>
            <li>
              Ensure prompt and fair resolution of grievances raised by
              customers or stakeholders.
            </li>
            <li>
              All customers are treated fairly and without bias at all times.
            </li>
            <li>
              All issues raised by customers are dealt with courtesy and
              resolved within regulatory TAT.
            </li>
            <li>
              Enhance customer satisfaction and trust in the Company's services.
            </li>
            <li>
              Comply with regulatory requirements regarding grievance redressal
              process.
            </li>
          </ul>
          <p>
            The timely and effective handling of customer complaints/grievances
            is fundamental to the Company's commitment to treating customers
            fairly. The Company is committed to promptly responding to and
            resolving customers’ complaints/grievances and driving appropriate
            adjustments to business practices to improve customer service and
            enhance risk management.
          </p>
          <h3>Scope</h3>
          <p>
            This policy applies to all customer complaints arising from our
            interactions and services, including but not limited to:
          </p>
          <ul>
            <li>Product and service-related concerns</li>
            <li>Billing and payment issues</li>
            <li>Policy adherence disputes</li>
            <li>Communication and information discrepancies</li>
            <li>Misconduct by our employees or representatives</li>
            <li>Customer grievances</li>
          </ul>

          <h3>Definition</h3>
          <p>
            <b>Complaint or Grievance:</b> means a written expression (includes
            communication in the form of electronic mail or other electronic
            scripts) of dissatisfaction by a complainant with respect to
            solicitation or sale of an insurance policy or related services by
            insurer and /or by Company. <br />
            Explanation: An inquiry or service request would not fall within the
            definition of the “Complaint or Grievance”.
          </p>
          <p>
            <b>Complainant:</b> means a policyholder or prospect or nominee or
            any beneficiary of an insurance policy who has filed a complaint or
            grievance against an insurer and /or distribution channel.
          </p>
          <p>
            <b>Mis-selling:</b> means sale or solicitation of policies by the
            insurance intermediary directly or indirectly by
            <ul className="pl-3 mt-2">
              <li>
                Forcefully exercising undue influence, use of dominance position
                or otherwise; or
              </li>
              <li>
                Knowingly making a false or misleading statement or
                misrepresenting the facts or benefits, or{" "}
              </li>
              <li>
                Knowingly concealing or omitting facts, features, benefits with
                respect to products, or{" "}
              </li>
              <li>
                Not taking reasonable care to ensure suitability of the policy
                to the policyholders.{" "}
              </li>
            </ul>
          </p>

          <h3>Classification</h3>
          <p>
            Complaint Classifications are instituted to understand the nature,
            type and the origin of the complaint. A complaint is classified on
            the basis of the following:
          </p>
          <ol>
            <li>
              Source of Communication (Email, Call, Letter or a Visit at
              Touchpoint)
            </li>
            <li>
              Type of Communication (Query, Request, Feedback, and Notices which
              include communication from Ombudsman, Consumer Forum, Legal Bodies
              etc.)
            </li>
            <li>Nature & Severity of complaint.</li>
          </ol>

          <h3>Grievance Registration Mechanism</h3>
          <p>
            The various channels through which our customers can contact us for
            any assistance or redressal of their grievances are listed below:
          </p>
          <ul>
            <li>
              Our customers can contact the customer service executive(s) at our
              branch offices or write to the relevant Branch Manager explaining
              the details of the issue concerned.
            </li>
            <li>
              Write to us at Email id -{" "}
              <Link href="mailto:customer.care@jioinsure.in">
                customer.care@jioinsure.in
              </Link>
            </li>
            <li>
              Customers can also write to us at Jio Insurance Broking Ltd. 3rd
              Floor, Court House, Lokmanya Tilak Marg, Dhobi Talao, Mumbai - 400
              002 or at 1st floor, B-Wing, Building no 25, DAKC, MIDC,
              Koparkhairane, Navi Mumbai - 400710
            </li>
          </ul>

          <h3>Turnaround Time (TAT)</h3>
          <ol type="a">
            <li>
              Upon receiving the Complaint, a written acknowledgement to the
              complainant will be sent within 24 hrs of the receipt of the
              grievance.{" "}
            </li>
            <li>
              Conduct an initial investigation and gather relevant information.
            </li>
            <li>
              Strive to resolve the complaint within 14 working days through
              communication and fair consideration.{" "}
            </li>
            <li>
              Communicate the response clearly and concisely, outlining the
              resolution or further steps involved.
            </li>
          </ol>

          <h3>Closure of Grievance</h3>
          <p>
            A complaint shall be considered as disposed of and closed under the
            following cases :
          </p>
          <ol type="a">
            <li>
              The company has acceded to the request of the complainant fully.
            </li>
            <li>
              Where the complainant has indicated in writing , acceptance of the
              response.{" "}
            </li>
            <li>
              Where the complainant has not responded within 8 weeks of the
              company’s response.{" "}
            </li>
            <li>
              The customer has the opportunity to re-open his complaint in case
              he/she is not satisfied with the redressal. Each time the
              complaint reopens it is the responsibility of the insurer to
              respond back within the similar timelines.{" "}
            </li>
            <li>
              Where the Grievance Redressal Officer has certified that the
              company has discharged its contractual, statutory and regulatory
              obligations and therefore closes the complaint.
            </li>
          </ol>

          <h3>Grievance Review Mechanism</h3>
          <p>
            As part of its Grievance Mechanism, the Company has its own system
            whereby a customer can log any complaint / grievance on the platform
            itself. We will be coordinating with our insurance partners to have
            an integrated grievance redressal system whereby the
            customer/insured can directly login and register a complaint and can
            be accessed three ways i.e. by the customer, Intermediary, and
            Insurance Partner.
          </p>
          <p>
            All complaints will be recorded in the complaint system/register,
            and due acknowledgement will be issued to the customer with a unique
            reference number for tracking complaints. All personnel across the
            Company who directly or indirectly deal with customers will be
            provided training to handle insurance-related complaints. The
            complaints of the customers will be duly reviewed and if required,
            investigated suitably and adequately.
          </p>

          <h3>Process for Tracking and Resolving Mis-selling Grievances</h3>
          <p>
            The Company shall follow the following process for tracking and
            resolving Mis-Selling Grievances:-{" "}
          </p>
          <ul>
            <li>
              <b>Identification:</b> All grievances received will be screened to
              identify those related to mis-selling.
            </li>
            <li>
              <b>Acknowledgment:</b> Grievances related to mis-selling will be
              acknowledged immediately, as per the standard procedure.
            </li>
            <li>
              <b>Investigation:</b> A thorough investigation will be conducted
              by the Internal Audit Team to understand the nature of the
              mis-selling which may involve the following process: (i) Reviewing
              the sales process and documentation. (ii) Interviewing the sales
              personnel involved. (iii) Consulting with the customer for
              additional information.
            </li>
            <li>
              <b>Resolution:</b> Based on the investigation, appropriate action
              will be taken, which may include: (i) Offering a suitable
              alternative product in discussion with the Insurer (ii) Refunding
              the premium paid by the customer. (iii) Disciplinary action
              against the sales personnel involved.
            </li>
            <li>
              <b>Communication:</b> The resolution will be communicated to the
              complainant within 14 working days. If additional time is required
              for investigation, the complainant will be informed of the reasons
              for the delay and the expected time for resolution.
            </li>
          </ul>

          <h3>Grievance Escalation Matrix</h3>
          <p>
            If customers are not satisfied with the response that customers
            receives from the above access channels or if customers do not hear
            from us within the above prescribed TAT, customer can escalate their
            complaint by :-
          </p>
          <Box className={styles.tableContainer}>
            <table>
              <tr>
                <th>Level</th>
                <th>Officer Designation</th>

                <th>Email ID</th>
                <th>TAT for Response</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Grievance Redressal Officer (GRO)</td>
                <td>
                  <a href="mailto:grievance@jioinsure.in ">
                    grievance@jioinsure.in
                  </a>
                </td>
                <td>5 Working Days</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Appellate Authority</td>
                <td>
                  <a href="mailto:principal.officer@jioinsure.in ">
                    principal.officer@jioinsure.in
                  </a>
                </td>
                <td>7 Working Days</td>
              </tr>
            </table>
          </Box>
          <p>
            If the decision/resolution provided by the Grievance Officer is not
            acceptable, please make use of IRDAI’s online portal - Integrated
            Grievance Management System (IGMS): Register and monitor your
            complaint at igms.irda.gov.in or approach Insurance Ombudsman – For
            details refer http://ecoi.co.in
          </p>
          <p>
            In case of no reply from the Complainant, within 2 weeks from the
            date any clarification was provided, the Company shall treat the
            Grievance/request as Closed.
          </p>

          <h3>Preventive Measures</h3>
          <p>The Company shall take the following preventive measures:</p>
          <ul>
            <li>
              <b>Training:</b> Regular training programs for Broker Qualified
              Persons /Sales personnel to ensure ethical sales practices and
              understanding of the products.
            </li>
            <li>
              <b>Monitoring:</b> Regular audits and monitoring of sales
              practices to detect and prevent mis-selling.
            </li>
            <li>
              <b>Customer Education:</b> Providing clear and detailed
              information to customers about the products to help them make
              informed decisions.
            </li>
          </ul>

          <h3>Grievance Record Keeping & Retrieval</h3>
          <p>
            All grievance records will be maintained systematically for at least
            three years, including:
          </p>
          <ul>
            <li>Date and method of complaint lodgement</li>
            <li>Nature of the complaint</li>
            <li>Investigation findings and communication notes</li>
            <li>Resolution details or escalation steps</li>
            <li>Final outcome</li>
          </ul>
          <p>
            These records will be accessible for regulatory audits and internal
            reviews.
          </p>

          <h3>Effective Date</h3>
          <p>
            This Policy shall be effective from the date of approval by the
            Board of Directors (“the Board”).
          </p>

          <h3>Review</h3>
          <p>
            The policy will be reviewed for its effectiveness on an ongoing
            basis and will be amended as and when required in case of change in
            regulatory requirements.
          </p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default GrievanceRedressal;
