import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Input, Link, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { COLORS } from "../../../SupportingFiles/colors";
import "./TextField.scss";

const TextInputField = ({
  title = "",
  value = "",
  attrName = "",
  value_update = () => {},
  warn_status = false,
  info_status = false,
  alphabet_only = false,
  class_name = "",
  error_message = "Enter value",
  info_message = "Info. message",
  disabled,
  max_length,
  required,
  validation_type,
  placeholder,
}: {
  title: string;
  value?: any;
  attrName?: any;
  value_update?: Function;
  warn_status?: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name?: string;
  alphabet_only?: boolean;
  error_message?: string;
  validation_type?:
    | "NUMBER"
    | "NAME"
    | "ALPHANUMERIC"
    | "PASSWORD"
    | "ALL_CAPS"
    | "ALPHANUMERIC_ALL"
    | "NUMBER_WITH_DECIMAL";
  required?: boolean;
  max_length?: number;
  disabled?: boolean;
  placeholder?: string;
}) => {
  const [passVisible, setPassVisible] = useState<boolean>(true);
  const [localValue, setLocalValue] = useState<string>("");

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onChangeText = (updatedValue: string) => {
    if (alphabet_only) {
      const uppercasedValue = updatedValue.toUpperCase();
      if (uppercasedValue.match(/^[A-Z0-9]*$/) || uppercasedValue === "") {
        value_update(attrName, uppercasedValue);
      }
      return;
    }
    if (validation_type == "NAME") {
      let valid_value = updatedValue.replace(/(^\s+|[^a-zA-Z\s])/g, "");

      // Split the string into an array of words
      let words = valid_value.split(/\s+/);

      // Capitalize the first letter of each word
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }

      // Join the words back into a string
      let capitalizedValue = words.join(" ");

      setLocalValue(capitalizedValue);
    } else if (validation_type == "NUMBER") {
      let validValue = updatedValue.replace(/\D/g, "");

      // If the first character is '0', remove it
      if (validValue.charAt(0) === "0") {
        validValue = validValue.substr(1);
      }

      setLocalValue(validValue);
    } else if (validation_type == "ALPHANUMERIC") {
      let valid_value = updatedValue.replace(/[^A-Za-z0-9]/g, "");

      setLocalValue(`${valid_value}`.toUpperCase());
    } else if (validation_type === "ALL_CAPS") {
      setLocalValue(updatedValue.toUpperCase());
    } else if (validation_type === "ALPHANUMERIC_ALL") {
      // Allow capital letters, numbers, and specific special characters
      let valid_value = updatedValue.replace(
        /[^A-Za-z0-9!@#$%^&*()_+\-=\[\]{}|;:'",.<>?/]/g,
        ""
      );

      setLocalValue(`${valid_value}`.toUpperCase());
    } else if (validation_type === "NUMBER_WITH_DECIMAL") {
      let valid_value = updatedValue.replace(/[^0-9.]/g, "");
      setLocalValue(valid_value);
    } else {
      setLocalValue(updatedValue);
    }
  };
  return (
    <div className={`inputFields ${class_name}`}>
      <p
        style={{
          color: COLORS.lightgrey,
          textAlign: "left",
          marginBottom: "4px",
        }}
      >
        {title}
      </p>
      <Input
        id={`${title}`}
        fullWidth
        // label={title}
        // variant="standard"
        type={
          validation_type === "PASSWORD" && passVisible
            ? "password"
            : validation_type === "NUMBER"
            ? "tel"
            : "text"
        }
        value={localValue}
        placeholder={placeholder}
        inputProps={{ maxLength: max_length, inputMode: "search" }}
        onChange={(e) => {
          onChangeText(e.target.value);
        }}
        onBlur={() => {
          value_update(attrName, localValue);
        }}
        required={required}
        disabled={disabled}
      />
      {validation_type === "PASSWORD" ? (
        <Link
          onClick={() => {
            setPassVisible(!passVisible);
          }}
          className="passwordView"
        ></Link>
      ) : null}
      {info_status ? (
        <Tooltip title={info_message ?? ""}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status ? <span className="error">{error_message}</span> : null}
    </div>
  );
};

export default TextInputField;
