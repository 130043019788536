export enum ENonTermCompanyCode {
  HDFC = "L003",
  ICICI = "L004",
  MAX_LIFE = "L007",
  Aditya_Birla = "L031",
  TATA = "L011"
}

export const EProductId = {
  ICICI_PRU_Sukh_Samruddhi_Income: 2836,
  ICICI_Pru_Sukh_Samruddhi_Lumpsum: 2839,
  ICICI_Pru_Gold_Deferred_Income: 2841,
  ICICI_Pru_Gold_Immediate_Income_with_Booster: 2840,
  ICICI_Pru_Gift_Pro_Level_Income: 2838,
  ICICI_Pru_Gold_Immediate_Income: 2837,
  ICICI_Pru_Gift_Pro_Increasing_Income: 2842
}
