import { useAutocomplete } from "@mui/base/useAutocomplete";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { styled } from "@mui/system";
import { COLORS } from "../../../SupportingFiles/colors";
import { useEffect, useState } from "react";

const Listbox = styled("ul")(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: "8px",
  zIndex: 1,
  position: "absolute",
  top: "48px",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  boxShadow: "0px 4px 16px rgba(0,0,0,0.1)",
  overflowY: "auto",
  borderRadius: "24px",
  maxHeight: 190,
  textAlign: "left",
  "& li.Mui-focused": {
    backgroundColor: COLORS.lightbg,
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: COLORS.lightbg,
  },
  "& li": {
    fontSize: "16px",
    fontFamily: "JioType",
    padding: "8px 8px",
  },
}));

export default function SearchAutocomplete({
  attrName = "",
  placeholder = "",
  value = "",
  options = [],
  error_message = "Select",
  warn_status = false,
  value_update = () => {},
  variant = "default",
}: {
  attrName?: any;
  placeholder?: string;
  value?: string;
  value_update?: Function;
  options?: Array<any>;
  error_message?: string;
  warn_status?: boolean;
  variant?: "default" | "small";
}) {
  const [localValue, setLocalValue] = useState<string>("");
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(localValue.toLowerCase())
  );
  const [fieldFocusStatus, setFieldFocusStatus] = useState<boolean>(false);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div
      style={{
        backgroundColor: COLORS.lightMariGold20,
        padding: "4px",
        borderRadius: "40px",
        position: "relative",
      }}
    >
      <SearchRoundedIcon
        sx={{
          position: "absolute",
          left: "12px",
          top: "8px",
          zIndex: "9",
        }}
      />
      <input
        id={`${attrName}`}
        placeholder={placeholder}
        style={{
          backgroundColor: "transparent",
          border: "none",
          width: "100%",
          height: "32px",
          paddingLeft: "42px",
          fontSize: variant === "small" ? "14px" : "16px",
          fontFamily: "JioType",
          outline: "none",
        }}
        onFocus={() => {
          setFieldFocusStatus(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setFieldFocusStatus(false);
          }, 1000);
        }}
        value={localValue}
        onChange={(e) => {
          setLocalValue(e.target.value);
        }}
      />

      {filteredOptions.length > 0 && fieldFocusStatus ? (
        <Listbox>
          {filteredOptions.map((option, index) => (
            <li
              onClick={() => {
                setLocalValue(option.label);
                value_update(attrName, option.label);
              }}
            >
              {option.label}
            </li>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
}
