import { THealthAddForm } from "../types/Health/THealthSlice";

export const HealthFeatClass = (feature_code: string) => {
  const healthFeatureMapping: { [key: string]: string } = {
    HF001: "discount",
    HF002: "preexisting",
    HF003: "discount",
    HF004: "treatment",
    HF005: "recharge",
    HF006: "extrapremium",
    HF007: "hospitalized",
    HF008: "healthcheckup",
    HF009: "hospital",
    HF010: "hospitalized",
    HF011: "medicines",
    HF012: "treatment",
    HF013: "hospitalized",
    HF014: "hospitalized",
    HF015: "hospitalized",
    HF016: "treatments",
    HF017: "treatment",
    HF018: "bonus",
    HF019: "extrapremium",
    HF020: "hospitalized",
    HF021: "preexisting",
    HF022: "hospitalized",
    HF023: "lungs",
    HF024: "maternity",
    HF025: "pregnancy",
    HF026: "maternity",
    HF027: "maternity",
    HF028: "insulin",
    HF029: "wounds",
    HF030: "amount",
    HF031: "discount",
    HF032: "healthcheckup",
    HF033: "bloodsugar",
    HF034: "extrapremium",
    HF035: "accident",
    HF036: "accident",
    HF037: "healthcheckup",
    HF038: "eyes",
    HF039: "handicaped",
    HF040: "treatment",
    HF041: "extrapremium",
    HF042: "hospitalized",
    HF043: "pregnancy",
    HF044: "pregnancy",
    HF045: "medicalhistory",
    HF046: "maternity",
    HF047: "healthcheckup",
    // HF048: "value_for_HF002",
    // HF049: "value_for_HF002",
  };

  if (feature_code in healthFeatureMapping) {
    return healthFeatureMapping[feature_code];
  }

  return feature_code;
};

export const child_gender = (
  child_no: "1" | "2" | "3" | "4",
  formData: THealthAddForm
) => {
  const son_count = parseInt(`${formData.son_count.value}`);
  const daughter_count = parseInt(`${formData.daughter_count.value}`);
  const total_children = son_count + daughter_count;

  if (total_children < parseInt(child_no)) {
    return ""; // If the total children are less than the child number, return an empty string
  }

  let current_son_count = 0;
  let current_daughter_count = 0;

  for (let i = 1; i <= parseInt(child_no); i++) {
    if (current_son_count < son_count) {
      current_son_count++;
      if (i === parseInt(child_no)) {
        return "M";
      }
    } else if (current_daughter_count < daughter_count) {
      current_daughter_count++;
      if (i === parseInt(child_no)) {
        return "F";
      }
    }
  }

  return ""; // Default return, should not reach here
};
