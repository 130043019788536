import { Box } from "@mui/material";
import React from "react";

function TestimonialCard({
  data,
}: {
  data: {
    review: string;
    reviewer_name: string;
    location: string;
  };
}) {

  return (
    <Box className="testimonial_box">
      <img src="../images/quote-icon.svg" />
      <h4 className="mt-3 mb-6" style={{ fontWeight: "500", color: "#896B40" }}>
        {data.review}
      </h4>
      <Box
        display={"flex"}
        gap={"12px"}
        alignItems="center"
        className="profile"
      >
        <Box>
          <h5 style={{ color: "#AC660C", fontWeight: "bold" }}>
            {data.reviewer_name}
          </h5>
          <p
            style={{
              color: "#AC660C",
              fontSize: "14px",
            }}
          >
            {data.location}
          </p>
        </Box>
      </Box>
    </Box>
  );
}

export default TestimonialCard;
