import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import BorderCheckBox from "../../../../Component/InputFields/BorderCheckBox/BorderCheckBox";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePickerInput from "../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import { COLORS } from "../../../../SupportingFiles/colors";
import "./../../../../SCSS/ModalPopup.scss";

export default function LandingHealthProductPageForm({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  const [scroll, setScroll] = React.useState<"body">("body");

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [isChecked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const steps = ["Family detail", "Family age's"];

  const options = [
    { label: "Option 1" },
    { label: "Option 2" },
    // Add more options as needed
  ];

  const [count, setCount] = useState(1);
  const handleIncrease = () => {
    if (count < 4) {
      setCount(count + 1);
    }
  };
  const handleDecrease = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="modal-scrollable">
        <DialogContent dividers={false} className="modal-content xlWidth">
          <Box>
            <Grid className="topbar" container alignItems="center">
              <Grid xs={6} className="pl-3">
                <BackLeft onClick={() => {}} />
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className="row" columnSpacing={3}>
              <Grid xs={12}>
                {/* Step Select Members */}
                <Box maxWidth="616px" display="" margin={"auto"}>
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid xs={12} textAlign="center">
                      <h3 className="mb-6">
                        Select the family members you want to insure
                      </h3>
                    </Grid>
                    <Grid xs={6}>
                      <BorderCheckBox
                        title="Self"
                        attrName="Self"
                        onChange={handleCheckboxChange}
                        value={isChecked}
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <BorderCheckBox
                        title="Spouse"
                        attrName="Spouse"
                        onChange={handleCheckboxChange}
                        value={isChecked}
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "12px",
                          padding: "6px 14px",
                          position: "relative",
                        }}>
                        <Box
                          position="absolute"
                          right="8px"
                          top="11px"
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          zIndex="9">
                          <Box
                            onClick={handleDecrease}
                            height="20px"
                            width="20px"
                            color={COLORS.darkgold}
                            textAlign="center"
                            sx={{ cursor: "pointer" }}>
                            <RemoveRoundedIcon
                              sx={{ width: "21px", height: "21px" }}
                            />
                          </Box>
                          <Box
                            height="20px"
                            width="20px"
                            color={COLORS.darkgold}
                            borderRadius="40px"
                            textAlign="center"
                            sx={{ backgroundColor: "#FEF7E9" }}>
                            {count}
                          </Box>
                          <Box
                            onClick={handleIncrease}
                            height="20px"
                            width="20px"
                            color={COLORS.darkgold}
                            textAlign="center"
                            sx={{ cursor: "pointer" }}>
                            <AddRoundedIcon
                              sx={{ width: "21px", height: "21px" }}
                            />
                          </Box>
                        </Box>
                        <CustomCheckbox
                          label="Son"
                          attrName="Son"
                          value_update={handleCheckboxChange}
                          value={isChecked}
                          defaultChecked={false}
                          disabled={false}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Box
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "12px",
                          padding: "6px 14px",
                          position: "relative",
                        }}>
                        <Box
                          position="absolute"
                          right="8px"
                          top="11px"
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          zIndex="9">
                          <Box
                            onClick={handleDecrease}
                            height="20px"
                            width="20px"
                            color={COLORS.darkgold}
                            textAlign="center"
                            sx={{ cursor: "pointer" }}>
                            <RemoveRoundedIcon
                              sx={{ width: "21px", height: "21px" }}
                            />
                          </Box>
                          <Box
                            height="20px"
                            width="20px"
                            color={COLORS.darkgold}
                            borderRadius="40px"
                            textAlign="center"
                            sx={{ backgroundColor: "#FEF7E9" }}>
                            {count}
                          </Box>
                          <Box
                            onClick={handleIncrease}
                            height="20px"
                            width="20px"
                            color={COLORS.darkgold}
                            textAlign="center"
                            sx={{ cursor: "pointer" }}>
                            <AddRoundedIcon
                              sx={{ width: "21px", height: "21px" }}
                            />
                          </Box>
                        </Box>
                        <CustomCheckbox
                          label="Daughter"
                          attrName="Daughter"
                          value_update={handleCheckboxChange}
                          value={isChecked}
                          defaultChecked={false}
                          disabled={false}
                        />
                      </Box>
                    </Grid>

                    <Grid xs={12} textAlign="center">
                      <Box
                        maxWidth="312px"
                        marginLeft="auto"
                        marginRight="auto"
                        marginTop="24px">
                        <JDSButtons
                          className="primaryBtn large"
                          text="Continue"
                          variant="contained"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* Step Select Age */}
                <Box maxWidth="616px" display="none" margin={"auto"}>
                  <h3 className="mb-6" style={{ textAlign: "center" }}>
                    Select age of your family members
                  </h3>
                  <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    maxHeight="250px"
                    maxWidth="430px"
                    marginLeft="auto"
                    marginRight="auto"
                    sx={{
                      overflowY: "auto",
                    }}>
                    <Grid xs={12}>
                      <DatePickerInput
                        title="Self's Date of birth (DOB)"
                        attrName=""
                        onChange={() => {}}
                        value=""
                        default_date={new Date("1990-01-01")}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <DatePickerInput
                        title="Spouse's Date of birth (DOB)"
                        attrName=""
                        onChange={() => {}}
                        value=""
                        default_date={new Date("1990-01-01")}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <DatePickerInput
                        title="Son's Date of birth (DOB)"
                        attrName=""
                        onChange={() => {}}
                        value=""
                        default_date={new Date("2010-01-01")}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <DatePickerInput
                        title="Daughter's Date of birth (DOB)"
                        attrName=""
                        onChange={() => {}}
                        value=""
                        default_date={new Date("2010-01-01")}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    maxWidth="312px"
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop="24px">
                    <JDSButtons
                      className="primaryBtn large"
                      text="Submit"
                      variant="contained"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
