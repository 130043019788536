import { useState, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../../SCSS/ModalPopup.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import { calculateAge, FORMAT_YYYY_MM_DD, isEmpty, validateMobileNumber } from "../../../../SupportingFiles/HelpingFunction";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { CarSlice } from "../../../../Store/Slice_Reducer/Car/CarSlice";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import { COMMON_SERVICES } from "../../../../Services/Common/CommonService";
import RKTextField from "../../../Amaze/FieldTypes/RKTextField/RKTextField";
import { TFieldData } from "../../../../types/Common/TFieldData";
import { toast } from "react-toastify";
import { HEALTH_FORM_SERVICES } from "../../../../Services/HealthServices/HealthFormServices";
import { TERM_SERVICES } from "../../../../Services/Term/TermServices";

const ExitIntentPopup = ({
  open,
  setOpen,
  type
}: {
  open: boolean;
  setOpen: Function;
  type:any
}) => {
  console.log("type",type)
  const dispatch = useAppDispatch()
  const { Car,Health,Term,TW} = useAppSelector((state) => state);
   console.log("Car",Car);
   const [linkLoad, setLinkLoad] = useState<boolean>(false);
     const [shareURLData, setShareURLData] = useState<{
    mobile: TFieldData;
  }>({
    mobile: { value: "", warning: false },
  });
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);

  const handleDetails = (attrName: any, value: any) => {
     if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
  };
   const EVENT_TRACKER = () => {
     setLinkLoad(true);
    let Mdata = { ...shareURLData };
      if (!isEmpty(Mdata.mobile.value)) {
      Mdata = {
        ...Mdata,
        mobile: {
          ...Mdata.mobile,
          warning: !validateMobileNumber(Mdata.mobile.value),
        },
      };
    } else {
      Mdata = {
        ...Mdata,
        mobile: {
          ...Mdata.mobile,
          warning: false,
        },
      };
    }

    setShareURLData(Mdata);
    // Determine if there's an error
    const hasError =  Mdata.mobile.warning;

    if (isEmpty(Mdata.mobile.value)) {
      toast.error("Please fill mandatory fields.");
      setLinkLoad(false);
      return;
    }
     if (hasError) {
      setLinkLoad(false);
      return;
    }
    const onSuccess = (res: any) => {
      setLinkLoad(false);
      setShareURLData({
        mobile: { value: "", warning: false },
      });
    };

    const onError = (err: any) => {
      console.log(err);
      setLinkLoad(false);
    };
if(type === "Health"){
   const data = {
      gender: Health.ADD_FORM.gender.value,
      mobile_no: Mdata.mobile.value,
      email: Health.ADD_FORM.email.value,
      insurance_type: `Health Insurance`,
      name: Health.ADD_FORM.name.value,
      pincode: Health.ADD_FORM.pincode.value,
      sum_insured: Health.ADD_FORM_RESPONSE.sum_insured,
      state: Health.ADD_FORM_RESPONSE?.state,
      city: Health.ADD_FORM_RESPONSE?.city,
      cover: Health.ADD_FORM_RESPONSE?.cover,
      age: Health.ADD_FORM?.age,
      dob: Health.ADD_FORM?.self_dob.value,
    };
    HEALTH_FORM_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
}else if(type === "Car"){
  const data = {
    business_type: Car?.ADD_FORM.business_type,
    fuel_type: Car?.ADD_FORM.fuel_type.value,
    make_model: `${Car?.ADD_FORM.make.value} ${Car?.ADD_FORM.model.value}`,
    mobile_no: Mdata.mobile.value,
    email: Car?.ADD_FORM.email.value,
    policy_expiry_date: FORMAT_YYYY_MM_DD(
      `${Car?.ADD_FORM.policy_expiry_date.value}`
    ),
    insurance_type: `Car Insurance`,
    registration_no: Car?.ADD_FORM.reg_no.value,
    variant_cc: Car?.ADD_FORM.variant_cc.value,
    name: Car?.ADD_FORM.name.value,
  };
  COMMON_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
}else if(type === "TW"){
const data = {
      business_type: TW?.ADD_FORM.business_type,
      fuel_type: TW?.ADD_FORM.fuel_type.value,
      make_model: `${TW?.ADD_FORM.make.value} ${TW?.ADD_FORM.model.value}`,
      mobile_no: Mdata.mobile.value,
      email: TW?.ADD_FORM.email.value,
      policy_expiry_date: FORMAT_YYYY_MM_DD(
        `${TW?.ADD_FORM.policy_expiry_date.value}`
      ),
      insurance_type: `Tw Insurance`,
      registration_no: TW?.ADD_FORM.reg_no.value,
      variant_cc: TW?.ADD_FORM.variant_cc.value,
      name: TW?.ADD_FORM.name.value,
    };
    COMMON_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
}else{
   const data = {
      mobile_no:  Mdata.mobile.value,
      email: Term?.ADD_FORM.email.value,
      gender: Term?.ADD_FORM.gender.value,
      name: Term?.ADD_FORM.name.value,
      annual_income: Term?.ADD_FORM?.annualIncome.value,
      sum_assured: Term?.ADD_FORM_RESPONSE?.sum_assured,
      pincode: Term?.ADD_FORM?.pincode?.value,
      state: Term.ADD_FORM_RESPONSE?.state,
      city: Term.ADD_FORM_RESPONSE?.city,
      smoker: Term.ADD_FORM?.smoke,
      age: `${calculateAge(Term.ADD_FORM?.age?.value)}`,
      welcome_sms_status: "No",
      dob: `${FORMAT_YYYY_MM_DD(Term.ADD_FORM?.age?.value)}`,
    };
    TERM_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
}
  };
  return (
    <Modal open={open}>
      <Box
        className="modalWrapper"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="modalContent smWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid xs={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                   setShareURLData({
                    mobile: { value: "", warning: false },
                  });
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={2}
            alignItems="flex-start"
          >
            <Grid xs={12}>
              <Box>
                <h3
                  style={{
                    fontWeight: "900",
                    textAlign: "left",
                    marginBottom: "16px",
                  }}
                >
                  Happy to help
                </h3>
                <p className="mb-4">Could not find what you are looking for? If you need assistance with your insurance needs, we’re here for you.</p>
                 <Box className="mobileNumber">
                    
                      <RKTextField
                class_name="inputField"
                title={"Enter your Mobile number"}
                value={shareURLData.mobile.value}
                attrName={"mobile"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.mobile.value)
                    ? "Enter Valid Mobile No."
                    : "Enter Mobile No."
                }
                warn_status={shareURLData.mobile.warning}
                validation_type="NUMBER"
                max_length={10}
              />
                  </Box>
                <Grid xs={12} className="mt-8" textAlign={"right"}>
              <JDSButtons
                text={"Submit"}
                variant={"text"}
                className={"primaryBtn large"}
                fullWidth={false}
                onClick={() => {
                  EVENT_TRACKER();
                }}
              />
            </Grid>
               
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExitIntentPopup;
