import Aboutus from "../../Page/Desktop/Aboutus/Aboutus";
import MAboutus from "../../Page/Mobile/MAboutus/MAboutus";
import useIsMobile from "../../SupportingFiles/MobileProvider";

function AboutUsContainer() {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MAboutus /> : <Aboutus />}</>;
}

export default AboutUsContainer;
