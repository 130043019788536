import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../../SupportingFiles/colors";
import NeedHelpPopup from "./NeedHelpPopup/NeedHelpPopup";

const QuoteNavbar: React.FC = () => {
  const navigate = useNavigate();
  const [isHelpOpen, setHelpOpen] = useState(false);

  const handleHelpOpenModal = () => {
    setHelpOpen(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: COLORS.primary,
        height: "72px",
        position: "fixed",
        top: "0px",
        width: "100%",
        zIndex: "91",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 4px 12px #ddd",
      }}
    >
      {/* Need Help Modal */}
      <NeedHelpPopup open={isHelpOpen} setOpen={setHelpOpen} />

      <Grid container spacing={3} alignItems="center" width="100%">
        <Grid xs={5}>
          <Link
            style={{
              display: "inline-block",
              padding: "8px 0px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src="../images/jio_insurance_logo.svg"
              alt="Jio Insurance Brokers"
              height="16px"
            />
          </Link>
        </Grid>
        {/* we have to use JDS button here */}
        {/* <Grid xs={7} textAlign="right">
          <Link
            onClick={handleHelpOpenModal}
            sx={{
              color: "#2F2311",
              fontWeight: "bold",
              fontSize: "16px",
              display: "inline-flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              textDecoration: "none",
              padding: "8px 16px",
              marginRight: "12px",
            }}
          >
            <img src="../images/call-icon.svg" height="24px" />
            Help
          </Link>
          <Link
            sx={{
              color: "#2F2311",
              fontWeight: "bold",
              fontSize: "16px",
              display: "inline-flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              textDecoration: "none",
              padding: "8px 16px",
            }}
          >
            <img src="../images/share-icon.svg" height="24px" />
            Share
          </Link>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default QuoteNavbar;
