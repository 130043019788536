import { PayloadAction } from "@reduxjs/toolkit";
import { TCarAddonStatus } from "../../../types/Car/TCarAddonStatus";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TDropdownData } from "../../../types/DropdownData/DropdownData";
import {
  DROPDOWN_DATA,
  TCarAddForm,
  TCarAddFormResponse,
  TCarDiscount,
  TCarSlice,
  TCkycDetails,
  TNomineeDetails,
  TOwnerDetails,
  TVehicleDetails,
} from "../../../types/TCarSlice";
import { TWDropdown } from "../../../types/TTWSlice";
import { TAddressDetails } from "../../../types/Common/TAddressDetails";

function BULK_UPDATE(state: TCarSlice, action: PayloadAction<TCarSlice>) {
  let data: TCarSlice = action.payload;
  return data;
}

function SET_CAR_SLICE(state: TCarSlice, action: PayloadAction<TCarSlice>) {
  let data: TCarSlice = action.payload;
  return data;
}
function ADD_FORM_DATA(state: TCarSlice, action: PayloadAction<TCarAddForm>) {
  let data: TCarSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...action.payload } };

  return data;
}

type keyDiscount = keyof TCarDiscount;
function UPDATE_DISCOUNT_DATA(
  state: TCarSlice,
  action: PayloadAction<{ key: keyDiscount; value: any }>
): TCarSlice {
  const { key, value } = action.payload;

  // Use object destructuring to create a shallow copy of state
  let data: TCarSlice = { ...state };

  data = { ...data, DISCOUNT_FORM: { ...data.DISCOUNT_FORM, [key]: value } };

  return data;
}

type keyTCarAddForm = keyof TCarAddForm;
function UPDATE_FORM_DATA(
  state: TCarSlice,
  action: PayloadAction<{ key: keyTCarAddForm; value: any }[]>
): TCarSlice {
  const updates = action.payload;

  // Use object destructuring to create a shallow copy of state
  let data: TCarSlice = { ...state };

  const new_data = Object.fromEntries(
    updates.map(({ key, value }) => [key, value])
  );

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...new_data } };

  return data;
}

function updateMultipleKeysInAddForm(
  state: TCarSlice,
  action: PayloadAction<Partial<TCarSlice["ADD_FORM"]>>
) {
  state.ADD_FORM = {
    ...state.ADD_FORM,
    ...action.payload,
  };
}

function updateMultipleKeysInAddFormResponse(
  state: TCarSlice,
  action: PayloadAction<Partial<TCarSlice["ADD_FORM_RESPONSE"]>>
) {
  state.ADD_FORM_RESPONSE = {
    ...state.ADD_FORM_RESPONSE,
    ...action.payload,
  };
}

function ADD_FORM_RESPONSE(
  state: TCarSlice,
  action: PayloadAction<TCarAddFormResponse>
) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    ADD_FORM_RESPONSE: { ...data.ADD_FORM_RESPONSE, ...action.payload },
  };

  return data;
}

function ADD_FORM_RESPONSE_INDIVISUALLY(
  state: TCarSlice,
  action: PayloadAction<Partial<TCarAddFormResponse>>
) {
  const updatedAddFormResponse = {
    ...state.ADD_FORM_RESPONSE,
    ...action.payload,
  };

  return {
    ...state,
    ADD_FORM_RESPONSE: updatedAddFormResponse,
  };
}

function OWNER_DETAILS(state: TCarSlice, action: PayloadAction<TOwnerDetails>) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    OWNER_DETAILS: { ...data.OWNER_DETAILS, ...action.payload },
  };

  return data;
}

function VEHICLE_DETAILS(
  state: TCarSlice,
  action: PayloadAction<TVehicleDetails>
) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    VEHICLE_DETAILS: { ...data.VEHICLE_DETAILS, ...action.payload },
  };

  return data;
}

type ValueTypes = keyof TVehicleDetails;
function UPDATE_VEHICLE_DEATILS_INDIVISUAL(
  state: TCarSlice,
  action: PayloadAction<TVehicleDetails>
) {
  console.log("dadada", action.payload);
  const updatedState: TCarSlice = {
    ...state,
    VEHICLE_DETAILS: {
      ...state.VEHICLE_DETAILS,
      ...action.payload,
    },
  };
  return updatedState;
}
//   if (Array.isArray(action.payload)) {
//     for (const { key, value } of action.payload) {
//       updatedState.VEHICLE_DETAILS[key] = value;
//     }
//   } else {
//     console.error("Invalid payload format. Expected an array.");
//   }

//   return updatedState;
// }

type CKYCValueTypes = keyof TCkycDetails;

function CKYC_DETAILS(
  state: TCarSlice,
  action: PayloadAction<{ key: CKYCValueTypes; value: any }[]>
) {
  const updatedState: TCarSlice = {
    ...state,
    CKYC_DETAILS: {
      ...state.CKYC_DETAILS,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      updatedState.CKYC_DETAILS[key] = value;
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }

  return updatedState;
}
type NomineeValueTypes = keyof TNomineeDetails;

function NOMINEE_DETAILS(
  state: TCarSlice,
  action: PayloadAction<TNomineeDetails>
) {
  const updatedState: TCarSlice = {
    ...state,
    NOMINEE_DETAILS: {
      ...state.NOMINEE_DETAILS,
      ...action.payload,
    },
  };

  // if (Array.isArray(action.payload)) {
  //   for (const { key, value } of action.payload) {
  //     updatedState.NOMINEE_DETAILS[key] = value;
  //   }
  // } else {
  //   console.error("Invalid payload format. Expected an array.");
  // }

  return updatedState;
}

type AddressValueTypes = keyof TAddressDetails;
function ADDRESS_DETAILS(
  state: TCarSlice,
  action: PayloadAction<TAddressDetails>
) {
  let updatedState: TCarSlice = {
    ...state,
    ADDRESS_DETAILS: {
      ...state.ADDRESS_DETAILS,
      ...action.payload,
    },
  };
  return updatedState;
}

type valueTypeDropdown = keyof TWDropdown;
function SET_DROPDOWN_DATA(
  state: TCarSlice,
  action: PayloadAction<{ key: valueTypeDropdown; value: TDropdownData[] }>
) {
  const { key, value } = action.payload;
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}

type valueTypeAddonStatus = keyof TCarAddonStatus;
function UPDATE_ADDON_STATUS(
  state: TCarSlice,
  action: PayloadAction<{ key: valueTypeAddonStatus; value: boolean }[]>
) {
  let data: TCarSlice = { ...state };

  for (const { key, value } of action.payload) {
    data = {
      ...data,
      ADDON_STATUS: {
        ...data.ADDON_STATUS,
        [key]: { ...data.ADDON_STATUS[key], value: value },
      },
    };
  }

  return data;
}
function UPDATE_ADDON_STATUS_ALL(
  state: TCarSlice,
  action: PayloadAction<{
    updates: { key: valueTypeAddonStatus; value: boolean }[];
  }>
) {
  const { updates } = action.payload;
  let data: TCarSlice = { ...state };

  updates.forEach(({ key, value }) => {
    if (data.ADDON_STATUS.hasOwnProperty(key)) {
      data = {
        ...data,
        ADDON_STATUS: {
          ...data.ADDON_STATUS,
          [key]: { ...data.ADDON_STATUS[key], value: value },
        },
      };
    }
  });

  return data;
}

function updateQuoteLoaderStatus(
  state: TCarSlice,
  action: PayloadAction<boolean>
) {
  let data: TCarSlice = { ...state };

  data = { ...data, QUOTE_LOADER: action.payload };

  return data;
}

type KEY_DROPDOWN_MASTER = keyof DROPDOWN_DATA;
const UPDATE_DROPDOWN_MASTER = (
  state: TCarSlice,
  action: PayloadAction<{ key: KEY_DROPDOWN_MASTER; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  const data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };
  return data;
};

const UPDATE_PLAN_TYPE = (
  state: TCarSlice,
  action: PayloadAction<
    "Third Party" | "Comprehensive" | "Bundle Policy" | "SAOD"
  >
) => {
  const data = {
    ...state,
    PLAN_TYPE: action.payload,
  };

  return data;
};

const CHANGE_PAGE_STATUS = (
  state: TCarSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    PAGE_STATUS: action.payload,
  };

  return data;
};

export const CAR_REDUCERS = {
  SET_CAR_SLICE,
  BULK_UPDATE,
  UPDATE_DISCOUNT_DATA,
  ADD_FORM_DATA,
  UPDATE_FORM_DATA,
  ADD_FORM_RESPONSE,
  SET_DROPDOWN_DATA,
  UPDATE_ADDON_STATUS,
  updateQuoteLoaderStatus,
  UPDATE_DROPDOWN_MASTER,
  UPDATE_PLAN_TYPE,
  OWNER_DETAILS,
  VEHICLE_DETAILS,
  UPDATE_VEHICLE_DEATILS_INDIVISUAL,
  CKYC_DETAILS,
  ADDRESS_DETAILS,
  NOMINEE_DETAILS,
  ADD_FORM_RESPONSE_INDIVISUALLY,
  UPDATE_ADDON_STATUS_ALL,
  updateMultipleKeysInAddForm,
  updateMultipleKeysInAddFormResponse,
  CHANGE_PAGE_STATUS,
};

export type CarReducers = typeof CAR_REDUCERS;
