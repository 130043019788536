import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Career from "../../Page/Desktop/Career/Career";
import MCareer from "../../Page/Mobile/MCareer/MCareer";

function CareerContainer() {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MCareer /> : <Career />}</>;
}

export default CareerContainer;
