import ReactGA from "react-ga4";
interface Window {
  JFSCT?: {
    pushEvent: (eventName: string, eventProps: string) => void;
  };
  webkit?: {
    messageHandlers?: {
      JFSCT?: {
        postMessage: (message: any) => void;
      };
    };
  };
}




export const initializeGA = (trackingID: string): void => {
  ReactGA.initialize(trackingID);
};

export const logPageView = (): void => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const logEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
): void => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const logException = (description: string, fatal = false): void => {
  logEvent("Exception", description, fatal ? "Fatal" : "Non-fatal");
};

export const pushDataLayer = (
  event: string,
  action: string,
  label: string,
  entrySource: string
): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: event,
    new_Action: action,
    new_Label: label,
    EntrySource: entrySource,
  });
};

export const pushMotorDataLayer = (
  event: string,
  action: string,
  label: string,
  general_data: string,
  entrySource: string
): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: event,
    new_Action: action,
    new_Label: label,
    new_General_Data: general_data,
    EntrySource: entrySource,
  });
};


export const sendEventToNativeApp = (eventName: string, eventProps: object) => {

  // (window as any).dataLayer = (window as any).dataLayer || [];
  // (window as any).dataLayer.push({
  //   event: eventName,
  //   ...eventProps,
  // });

  
  // Check for Android interface
  if ((window as any).JFSCT) {
    (window as any).JFSCT.pushEvent(eventName, JSON.stringify(eventProps));
  }

  // Prepare message for iOS interface
  const message = { action: 'recordEventWithProps', event: eventName, properties: eventProps };

  // Check for iOS interface
  if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.JFSCT) {
    (window as any).webkit.messageHandlers.JFSCT.postMessage(message);
  }
};