import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BlogCard({
  data,
}: {
  data: {
    img_url: string;
    blogtitle: string;
    blog_desc: string;
    id: string;
  };
}) {
  const navigate = useNavigate();
  return (
    <li className="blogCard">
      <img src={data.img_url} alt="" />
      <Box className="cardInfo">
        <h5 className="mb-3">{data.blogtitle}</h5>
        <p className="mb-6">{data.blog_desc}</p>
        <Link className="contReadLink" onClick={() => navigate(data?.id)}>
          Continue reading
        </Link>
      </Box>
    </li>
  );
}

export default BlogCard;
