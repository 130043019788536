import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const handleResize = () => {
      setIsMobile(
        /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        )
      );
    };

    // Set up an event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call the handleResize function once on initial mount
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigator.userAgent]);

  return isMobile;
};

export default useIsMobile;
