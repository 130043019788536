import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { COLORS } from "../../../SupportingFiles/colors";

interface Props {
  title?: string;
  value?: any;
  attrName?: any;
  value_update?: Function;
  data?: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_message?: string;
  info_status?: boolean;
  info_message?: string;
  sx?: any;
  disabled?: boolean;
  custom?: string;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  data: [],
  error_message: "select",
  info_status: false,
  info_message: "This is dummy text for select dropdown.",
  sx: "",
  disabled: false,
};

const SelectDropdown: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  data,
  warn_status,
  class_name,
  error_message,
  info_status,
  info_message,
  sx,
  disabled,
}) => {
  return (
    <div className="inputFields" style={{ ...sx, position: "relative" }}>
      <p
        style={{
          color: COLORS.lightgrey,
          textAlign: "left",
          marginBottom: "4px",
        }}
      >
        {title}
      </p>
      <FormControl variant="standard" fullWidth sx={{ height: "auto" }}>
        <Select
          id={`${title}`}
          value={value}
          onChange={(e) => {
            value_update && value_update(attrName, e.target.value);
          }}
          onSelect={(e) => {
            e.currentTarget.blur();
          }}
          disabled={disabled}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            fontFamily: "JioType",
            ".MuiSelect-select": {
              background: COLORS.white,
              padding: "6.5px 0px 9px",
              fontWeight: "500",
              color: COLORS.darkgrey,
              textAlign: "left",
            },
            ".MuiSvgIcon-root": {
              color: COLORS.lightgrey,
              top: "10px",
              right: "6px",
            },
            "&:before, &:after": {
              display: "none",
            },
          }}
        >
          {data &&
            data.map((data) => (
              <MenuItem value={data.value}>{data.label}</MenuItem>
            ))}
        </Select>
      </FormControl>
      {info_status ? (
        <Tooltip title={info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status ? (
        <span
          style={{
            position: "absolute",
            bottom: "-16px",
            right: "0px",
            color: "#EA4559",
            fontSize: "12px",
          }}
        >
          {error_message}
        </span>
      ) : null}
    </div>
  );
};
SelectDropdown.defaultProps = defaultProps;
export default SelectDropdown;
