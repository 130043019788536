import React, { useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import MQuoteNavbar from "../../../../Component/Mobile/Common/MQuoteNavbar/MQuoteNavbar";
import styles from "./../../../../SCSS/MQuotePage.module.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import Grid from "@mui/material/Unstable_Grid2";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MTermQuoteCard from "../../../../Component/Mobile/Term/MTermQuoteCard/MTermQuoteCard";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import MNonTermQuoteCard from "../../../../Component/Mobile/NonTerm/MNonTermQuoteCard/MNonTermQuoteCard";
import MInvestmentReturnCalculator from "./MInvestmentReturnCalculator/MInvestmentReturnCalculator";
import MNonTermModifyDetails from "./MNonTermModifyDetails/MNonTermModifyDetails";
import MNonTermRiders from "./MNonTermRiders/MNonTermRiders";
import {
  calculateAge,
  formatNumberToLakhOrCrores,
} from "../../../../SupportingFiles/HelpingFunction";
import { useAppSelector } from "../../../../Store/hooks";
import Loader from "../../../../Component/Global/Loader/Loader";

function MNonTermQuote({
  updateMasterState,
  quoteListData,
  applyInvestmentAmount,
  BUY_ACTION,
  payUptoOption,
  minInvestmentAmount,
  maxInvestmentAmount,
  validateInvestmentAmount,
  setQuoteLoader,
  GET_PRODUCT_LIST,
}: {
  quoteListData: any;
  updateMasterState: Function;
  applyInvestmentAmount: Function;
  BUY_ACTION: Function;
  payUptoOption: any;
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
  validateInvestmentAmount: boolean;
  setQuoteLoader: Function;
  GET_PRODUCT_LIST:Function;
}) {
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    DROPDOWN_DATA,
    QUOTE_LOADER,
    QUOTE_FIELDS_DATA,
    UPDATE_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  // Modify member Detail Popup
  const [isModifyDetailModalOpen, setModifyDetailModalOpen] = useState(false);
  const handleModifyDetailOpenModal = () => {
    setModifyDetailModalOpen(true);
  };
  const [isInvestReturnCalcOpen, setIsInvestReturnCalcOpen] = useState(false);
  const [openRidersPopup, setOpenRidersPopup] = useState(false);
  const [quoteListRiders, setQuoteListRiders] = useState<string[]>([]);
  const [quoteFields, setQuoteFields] = useState<{
    wanttoInvest: { value: string; warn_status: boolean };
    investmentMode: { value: string; warn_status: boolean };
    payUpto: { value: string; warn_status: false };
    policyTerm: { value: string; warn_status: false };
    returnType: { value: string; warn_status: false };
  }>({
    wanttoInvest: { value: "5000", warn_status: false },
    investmentMode: { value: "Monthly", warn_status: false },
    payUpto: { value: "10 Years", warn_status: false },
    policyTerm: { value: "10 Years", warn_status: false },
    returnType: { value: "Guaranteed Plans", warn_status: false },
  });
  useEffect(() => {
    if (QUOTE_LOADER) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [QUOTE_LOADER]);

  console.log(quoteListData,'quoteListData69')

  return (
    <Box className={styles.quoteWrapper}>
      <MNonTermModifyDetails
        open={isModifyDetailModalOpen}
        setOpen={setModifyDetailModalOpen}
        setQuoteLoader={setQuoteLoader}
        GET_PRODUCT_LIST={GET_PRODUCT_LIST}
      />
      <MInvestmentReturnCalculator
        open={isInvestReturnCalcOpen}
        setOpen={setIsInvestReturnCalcOpen}
      />
      <MNonTermRiders
        open={openRidersPopup}
        setOpen={setOpenRidersPopup}
        quoteListData={quoteListData}
        setQuoteListRiders={setQuoteListRiders}
      />

      <MQuoteNavbar />
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Fetching best <br /> Insurance Policies for you...
            </h5>
          </Box>
        </Box>
      ) : null}

      <Box className={styles.qwInner} paddingBottom="80px">
        <Box padding={"16px"}>
          <Grid
            container
            columnSpacing={2}
            className="row"
            alignItems={"center"}
          >
            <Grid xs={7}>
              <h6 style={{ lineHeight: "24px", fontWeight: 700 }}>
                Member Covered
              </h6>
              <p
                style={{
                  color: "#595959",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {UPDATE_RESPONSE?.gender === ""
                  ? ADD_FORM.gender.value === "M"
                    ? "Male"
                    : "Female"
                  : UPDATE_RESPONSE?.gender === "M"
                  ? "Male"
                  : "Female"}
                ,{" "}
                {UPDATE_RESPONSE.age == 0
                  ? calculateAge(ADD_FORM.age.value)
                  : UPDATE_RESPONSE.age}
              </p>
            </Grid>
            <Grid xs={5} textAlign={"right"}>
              <JDSButtons
                className="tertiaryBtn small"
                text="Edit Details"
                variant="text"
                fullWidth={false}
                onClick={() => setModifyDetailModalOpen(true)}
              />
            </Grid>
            <Grid xs={12}>
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid rgba(229, 241, 247, 1)",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
            </Grid>
            <Grid xs={7}>
              <Box style={{ position: "relative" }}>
                <TextInputField
                  class_name=""
                  title="Want to invest"
                  value={ADD_FORM.investAmount.value}
                  value_update={updateMasterState}
                  attrName={"investAmount"}
                  warn_status={quoteFields.wanttoInvest.warn_status}
                  error_message="Enter amount to invest"
                  max_length={7}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "35px",
                    right: "8px",
                  }}
                >
                  <JDSButtons
                    className="secondaryBtn small"
                    text="Apply"
                    variant="contained"
                    fullWidth={false}
                    onClick={() => applyInvestmentAmount()}
                  />
                </Box>
                {validateInvestmentAmount ? (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#EA4559",
                      fontSize: "11px",
                      marginTop: "3px",
                    }}
                  >
                    Enter {minInvestmentAmount} -{" "}
                    {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                  </span>
                ) : null}
              </Box>
            </Grid>
            <Grid xs={5}>
              <SelectDropdown
                class_name=""
                title="Investment Mode"
                value={ADD_FORM.investmentMode.value}
                value_update={updateMasterState}
                attrName={"investmentMode"}
                data={DROPDOWN_DATA.investmentMode}
                warn_status={quoteFields.investmentMode.warn_status}
                error_message="Select Investment Mode"
              />
            </Grid>
          </Grid>
        </Box>
        <Box padding={"16px"} sx={{ background: "rgba(254, 247, 233, 1)" }}>
          <Grid
            container
            className="row"
            columnSpacing={2}
            alignItems={"center"}
          >
            <Grid xs={6} marginBottom={"8px"}>
              {ADD_FORM.investmentMode.value === "5" ? null : 
              <SelectDropdown
                class_name=""
                title="Pay upto"
                value={QUOTE_FIELDS_DATA.payUpto}
                value_update={updateMasterState}
                attrName={"payUpto"}
                data={payUptoOption}
                warn_status={quoteFields.payUpto.warn_status}
                error_message="Select Pay Upto duration"
              />}
            </Grid>
            <Grid xs={6} marginBottom={"8px"}>
              <SelectDropdown
                class_name=""
                title="Policy Term"
                value={QUOTE_FIELDS_DATA.policyTerm}
                value_update={updateMasterState}
                attrName={"policyTerm"}
                data={DROPDOWN_DATA.POLICY_TERM}
                warn_status={quoteFields.policyTerm.warn_status}
                error_message="Select Policy Term"
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name=""
                title="Income Period"
                value={QUOTE_FIELDS_DATA.incomePeriod}
                value_update={updateMasterState}
                attrName={"incomePeriod"}
                data={DROPDOWN_DATA.INCOME_PERIOD}
                warn_status={quoteFields.policyTerm.warn_status}
                error_message="Select Income Period"
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name=""
                title="Return Type"
                value={QUOTE_FIELDS_DATA.returnType}
                value_update={updateMasterState}
                attrName={"returnType"}
                data={DROPDOWN_DATA.RETURN_TYPE}
                warn_status={quoteFields.returnType.warn_status}
                error_message="Select Return Type"
              />
            </Grid>
          </Grid>
        </Box>

        {/* quotes section */}
        <Box padding={"24px 16px"}>
          <Box marginBottom={"24px"}>
            <Grid container className="row" columnSpacing={2}>
              <Grid xs>
                <h6 className="mb-1" style={{ fontWeight: "700" }}>
                  Matching{" "}
                  {quoteListRiders?.length > 0 ? quoteListRiders?.length : "0"}{" "}
                  top plans
                </h6>
              </Grid>
              <Grid xs textAlign={"right"}>
                <p>
                  <small style={{ color: "#595959" }}>
                    Order ID: {ADD_FORM_RESPONSE?.quote_no}
                  </small>
                </p>
              </Grid>
            </Grid>
          </Box>

          {/* Quotes */}
          <Box>
            <Grid container className="row" columnSpacing={2}>
              <Grid xs={12}>
                {!QUOTE_LOADER ? (
                  quoteListData && quoteListData.length > 0 ? (
                    quoteListData.map((data: any, index: number) => (
                      <MNonTermQuoteCard
                        quote_data={data}
                        index={index}
                        BUY_ACTION={BUY_ACTION}
                      />
                    ))
                  ) : (
                    <Box className="no-quote-box" sx={{ textAlign: "center" }}>
                      <img src="../images/no_quotes_icon.svg" alt="" />
                      <h5 className="my-3">No Matches: Keep Exploring!</h5>
                      <p>Sorry, no results this time. Let's try something else.</p>
                    </Box>
                  )
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* black addonstrip */}
      <Box className={styles.nonterm_bottom_nav}>
        <Box className={styles.stripBox} style={{ flex: 2 }}>
          <Link
            className={styles.addonLink}
            onClick={() => setIsInvestReturnCalcOpen(true)}
          >
            <img src="./images/calc_icon_mobile.svg" alt="plan-icon" />
            Return Calculator
          </Link>
        </Box>
        <Box className={styles.stripBox} style={{ flex: 1 }}>
          <Link
            className={styles.addonLink}
            onClick={() => setOpenRidersPopup(true)}
          >
            <img src="./images/ic_filter.svg" alt="plan-icon" />
            Riders
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default MNonTermQuote;
