import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Contact from "../../Page/Desktop/Contact/Contact";
import MContact from "../../Page/Mobile/MContact/MContact";

function ContactUsContainer() {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MContact /> : <Contact />}</>;
}

export default ContactUsContainer;
