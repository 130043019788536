import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import { COLORS } from "../../../SupportingFiles/colors";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function PageNotFound() {
  const isMobile = useIsMobile();

  return (
    <Box className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}>
      {isMobile ? <MNavBar /> : <NavBar />}
      <Grid container spacing={3} className="mt-8" alignItems="center">
        <Grid xs={12} md={6} textAlign="left">
          <h2 style={{ textAlign: "left", marginBottom: "24px" }}>
            Oops! Page Not Found.
          </h2>
          <p style={{ color: COLORS.lightgrey }}>
            Looks like you took a wrong turn. But don't worry, we're here to
            help! Head back to the homepage.
          </p>
        </Grid>
        <Grid xs={12} md={6}>
          <img src="../images/oops.jpg" width="100%" alt="img" />
        </Grid>
      </Grid>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default PageNotFound;
