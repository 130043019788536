import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Link } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import BackLeft from "../../../InputFields/BackLeft";
import "./MNavbar.scss";

const LandingMNavBar = () => {
  const navigate = useNavigate();
  const { Car, TW, Health, Term } = useAppSelector((state) => state);
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let intervalId: any;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [isRedirected, setIsRedirected] = useState<boolean>(false);
  useEffect(() => {
    if (
      Car.REDIRECTED ||
      TW.REDIRECTED ||
      Term.REDIRECTED ||
      Health.REDIRECTED
    ) {
      setIsRedirected(true);
    } else {
      setIsRedirected(false);
    }
  }, [Car.REDIRECTED, TW.REDIRECTED, Term.REDIRECTED, Health.REDIRECTED]);

  return (
    <Box
      sx={{
        backgroundColor: COLORS.lightMariGold20,
        height: "64px",
        position: "fixed",
        top: "0px",
        width: "100%",
        zIndex: "91",
        display: "flex",
        alignItems: "center",
        padding: "0px 12px",
      }}
    >
      <Grid container className="row" spacing={3} alignItems="center">
        <Grid xs={8} display={"flex"} alignItems={"center"} gap={"12px"}>
          {isRedirected ? (
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid
                  xs={12}
                  display={"flex"}
                  gap={"12px"}
                  alignItems={"center"}
                >
                  <BackLeft
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  {isRedirected ? (
                    TW.REDIRECTED ? (
                      <h6> Two-Wheeler Insurance</h6>
                    ) : Car.REDIRECTED ? (
                      <h6> Car Insurance</h6>
                    ) : Term.REDIRECTED ? (
                      <h6> Life Insurance</h6>
                    ) : Health.REDIRECTED ? (
                      <h6> Health Insurance</h6>
                    ) : null
                  ) : (
                    <img
                      src="/images/jio_insurance_logo.svg"
                      alt="jio-insurance-logo"
                      height="14px"
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Link sx={{ display: "inline-block", padding: "6px 0px" }}>
                <img
                  src="../images/jio_insurance_logo.svg"
                  alt="Jio Insurance Brokers"
                  height="16px"
                />
              </Link>
            </>
          )}
        </Grid>
      </Grid>

      {isRedirected ? null : (
        <SwipeableDrawer
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          <Box className="menuWrapper">
            <Box className="navSec pt-6">
              <img
                src="./images/jio_insurance_logo.svg"
                alt="Jio Insurance Brokers"
                height="16px"
              />
            </Box>
            <Box className="menu-section">
              <ul>
                <li>
                  <Link
                    className="nav-link"
                    // onClick={() => navigate(COMMON_ROUTES.HOME)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ justifyContent: "space-between" }}
                    className="nav-link"
                    onClick={() => setOpenProductMenu(!openProductMenu)}
                  >
                    Products <KeyboardArrowDownIcon sx={{ color: "#9B6817" }} />
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    // onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    // onClick={() => navigate(COMMON_ROUTES.CONTACT)}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    // onClick={() => navigate(COMMON_ROUTES.CAREER)}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    // onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                  >
                    Blogs
                  </Link>
                </li>
              </ul>
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default LandingMNavBar;
