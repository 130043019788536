import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "../../../../../Component/Amaze/FieldTypes/SelectDropdown/SelectDropdown";
import NonTermFooter from "../../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";
import TermRiderQuestion from "../../../../../Component/Amaze/Term/TermRiderQuestion/TermRiderQuestion";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { NonTermSlice } from "../../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TDropdown } from "../../../../../types/Common/TDropdown";
import "./../../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";

const NonTermRidersPage = ({
  loaderTf001,
  loaderTf002,
  loaderTf003,
  loaderTf004,
  loaderTf005,
  tf001Data,
  tf002Data,
  tf003Data,
  tf004Data,
  tf005Data,
}: {
  loaderTf001: boolean;
  loaderTf002: boolean;
  loaderTf003: boolean;
  loaderTf004: boolean;
  loaderTf005: boolean;
  tf001Data: TDropdown[];
  tf002Data: TDropdown[];
  tf003Data: TDropdown[];
  tf004Data: TDropdown[];
  tf005Data: TDropdown[];
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { RIDERS_BENEFITS, QUOTE_FIELDS_DATA, ADD_FORM, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
            question_name="Personal Accident Cover"
            toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
            value_update={(a: any, v: any) => {
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: v,
                    },
                    {
                      key: "tf005Status",
                      value: false,
                    },
                  ],
                })
              );
            }}
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf003Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf003Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf003Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          <TermRiderQuestion
            loader={loaderTf001}
            addon_amount={`${RIDERS_BENEFITS.riders_benefits_response?.riders?.TF001?.premium}`}
            question_name="60 Critical Illness Cover"
            toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: v,
                    },
                    {
                      key: "tf004Status",
                      value: false,
                    },
                  ],
                })
              );
            }}
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf001Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf001Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf001Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          <TermRiderQuestion
            loader={loaderTf002}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF002?.premium}`}
            question_name="Accidental Disability Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS?.local_value?.tf002Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf002Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf002Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf002Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <TermRiderQuestion
            loader={loaderTf004}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
            question_name="Cancer Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS?.local_value?.tf004Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf004Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf004Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
            value_update={(a: any, v: any) => {
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: false,
                    },
                    {
                      key: "tf004Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <TermRiderQuestion
            loader={loaderTf005}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
            question_name="Accidental Death Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf005Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf005Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf005Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf005Status}
            value_update={(a: any, v: any) => {
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: false,
                    },
                    {
                      key: "tf005Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
        </Grid>
      </Grid>
      <NonTermFooter
        forward={() => {
          window.location.href =
            // RIDERS_BENEFITS.riders_benefits_response.redirectUrl;
            RIDERS_BENEFITS?.riders_benefits_response?.redirectionUrl;
        }}
        disableButton={isEmpty(
          RIDERS_BENEFITS?.riders_benefits_response?.premium
        )}
      />
    </Box>
  );
};

export default NonTermRidersPage;
