export const COLORS = {
  black: "#000000",
  white: "#ffffff",
  primary: "#E39B2B",
  lightprimary: "#9999FF",
  warmgold: "#2F2311",
  darkgold: "#966D2E",
  darkgrey: "#141414",
  lightgrey: "#595959",
  lightbg: "#F5F5F5",
  green: "#249F21",
  lightSky: "#0C5273",
  lightMariGold20: "#FEF7Ef",
  lightMariGold30: "#FFE3AE",
  lightMariGold40: "#FFD947",
};
