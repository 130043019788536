import {
  Box,
  Button,
  Link,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../../SCSS/ModalPopup.scss";
import ShareIcon from "@mui/icons-material/Share";
import JDSButtons from "../../../../InputFields/JDSButtons/JDSButtons";
import { COLORS } from "../../../../../SupportingFiles/colors";
import { useEffect } from "react";
import { formatToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppSelector } from "../../../../../Store/hooks";

const MNonTermPolicyDetails = ({
  open,
  setOpen,
  data,
  BUY_ACTION,
}: {
  open: boolean;
  setOpen: Function;
  data: any;
  BUY_ACTION: Function;
}) => {
  useEffect(() => {
    // Apply overflow hidden when the modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = ""; // Reset overflow when modal is closed
    }
  }, [open]);
  const { ADD_FORM, SELECTED_QUOTE_DATA, QUOTE_FIELDS_DATA } = useAppSelector
  (
    (state) => state.NonTerm
  );
  const keyFeatures = [
    "Maturity Benefit : If the Policy is In Force and the Life Assured survives to the Date of Maturity, the Maturity Benefit shall be payable.",
    "Maturity Benefit = Base Sum Assured + Loyalty Addition + accrued Guaranteed Additions",
    "If the Policy is Paid Up and the Life Assured survives to the Date of Maturity, the Maturity Benefit payable shall be an amount equal to Paid up Sum Assured plus accrued guaranteed additions (if any).",
    "Once the Policy becomes paid-up, it will no longer be eligible for future Guaranteed Additions and Loyalty Addition.",
    "The Policy will terminate upon payment of the Maturity Benefit",
  ];
  const taxBenefits = [
    "Tax Benefit: Tax benefit may be available as per prevailing tax laws and may differ basis the Life Cover multiple (7 or 11) chosen at policy inception. Choice of life cover multiple is available only for regular pay and limited pay.",
    "It is recommended that you obtain professional advice for applicability of Income Tax benefit on premiums paid and benefits received.",
    "Goods & Services Tax, Cess (if any) and any other Statutory levy will be charged extra on Premiums as per prevailing rates. Tax laws are subject to amendments from time to time.",
    "The Company does not assume responsibility on tax implication mentioned anywhere in this document.",
  ];
  const exclusions = [
    "In case of death due to suicide within 12 months, from the date of commencement of risk under the policy or from the date of revival of the policy, as applicable the nominee or beneficiary of the policyholder shall be entitled to 100% of the Total Premiums Paid till the date of death or the surrender value as available on date of death, whichever is higher, provided the policy is in-force.",
  ];
  const openInNewTab = (url: any) => {
    window.open(`${url}`, "_blank");
  };

  console.log(data,
    'new'
  )

  const download = (value: string) => {
    window.open(value, "_blank");
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Box className="topbar">
            <Grid container className="row" alignItems="center">
              <Grid xs={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="scrollable-area">
            <Box
              padding="16px 24px"
              margin="0px -12px"
              sx={{ backgroundColor: COLORS.lightMariGold20 }}
            >
              <Box width="100%" display="flex" alignItems="center" gap="8px">
                <img src={data?.quotationDetail?.companyDetails.logo} height="50px" />
                <p style={{ fontWeight: "bold" }}>
                  {data?.quotationDetail?.companyDetails.name}
                </p>
              </Box>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                gap="8px"
                marginTop="16px"
              >
                {/* <Box>
                  <JDSButtons
                    text={
                      <>
                        <ShareIcon
                          style={{ fontSize: "16px", marginRight: "14px" }}
                        />
                        Share
                      </>
                    }
                    variant={"text"}
                    className={"secondaryBtn small"}
                  />
                </Box> */}
                {data?.quotationDetail?.premiumDetails?.maturity_benefit ? (
                  <Box width="100%" textAlign="right">
                    <p>
                      <small style={{ color: "#595959" }}>
                        Maturity Amount
                      </small>
                    </p>
                    <h6 style={{ fontWeight: "bold" }}>
                      {formatToCurrency(data?.quotationDetail?.premiumDetails?.maturity_benefit)}
                    </h6>
                  </Box>
                ) : null}
              </Box>
            </Box>

            <Grid container className="row mt-6" columnSpacing={3}>
              <Grid xs={12} marginBottom={"16px"}>
                <h6
                  style={{
                    fontWeight: "900",
                    marginBottom: "6px",
                  }}
                >
                  Eligibility
                </h6>

                <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "16px",
                      padding: "8px 24px",
                      textAlign: "center",
                    }}
                  >
                    <img alt="" src="../jio/tick-icon.svg" height="21px" />
                    <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                      {data?.quotationDetail?.companyDetails?.claim_ratio}%
                    </p>
                    <p style={{ color: COLORS.lightgrey }}>
                      <small>
                        Claim Settlement <br />
                        Ratio
                      </small>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "16px",
                      padding: "8px 24px",
                      textAlign: "center",
                    }}
                  >
                    <img alt="" src="../jio/tick-icon.svg" height="21px" />
                    <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                      {QUOTE_FIELDS_DATA.investmentMode === "12"
                        ? "Monthly"
                        : QUOTE_FIELDS_DATA.investmentMode === "4"
                          ? "Quaterly"
                          : QUOTE_FIELDS_DATA.investmentMode === "2"
                            ? "Half-yearly"
                            : QUOTE_FIELDS_DATA.investmentMode === "1"
                              ? "Yearly"
                              : QUOTE_FIELDS_DATA.investmentMode === "5"
                                ? "Single Pay"
                                : null}
                    </p>
                    <p
                      style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                    >
                      <small>
                        Premium <br />
                        Frequency
                      </small>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "16px",
                      padding: "8px 24px",
                      textAlign: "center",
                    }}
                  >
                    <img alt="" src="../jio/tick-icon.svg" height="21px" />
                    <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                      {
                        data?.quotationDetail?.productDetails?.InvestmentFormValidations?.max_p_cess_age
                      }{" "}
                      Years
                    </p>
                    <p
                      style={{ color: COLORS.lightgrey, lineHeight: "16px" }}
                    >
                      <small>
                        Max. Maturity <br />
                        Age
                      </small>
                    </p>
                  </Box>
                </Box>

                {/* <Box sx={{ display: "flex", gap: "16px", overflowX: "scroll" }}>
                  {data?.SpecialFeatureLists[0]?.premium_paying_term ? (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 12px",
                        textAlign: "center",
                        flex: 1,
                        minWidth: "140px",
                      }}
                    >
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.SpecialFeatureLists[0]?.premium_paying_term}
                      </p>
                      <p style={{ color: COLORS.lightgrey }}>
                        <small>Premium Paying Term</small>
                      </p>
                    </Box>
                  ) : null}

                  {data?.SpecialFeatureLists[0]?.policy_term ? (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 12px",
                        textAlign: "center",
                        flex: 1,
                        minWidth: "140px",
                      }}
                    >
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.SpecialFeatureLists[0]?.policy_term}
                      </p>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                          lineHeight: "16px",
                        }}
                      >
                        <small>Policy Term</small>
                      </p>
                    </Box>
                  ) : null}
                  {data?.SpecialFeatureLists[0]?.pay_frequency ? (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 12px",
                        textAlign: "center",
                        flex: 1,
                        minWidth: "140px",
                      }}
                    >
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.SpecialFeatureLists[0]?.pay_frequency}
                      </p>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                          lineHeight: "16px",
                        }}
                      >
                        <small>Premium Frequency</small>
                      </p>
                    </Box>
                  ) : null}

                  {data?.SpecialFeatureLists[0]?.maximum_maturity_age ? (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 12px",
                        textAlign: "center",
                        flex: 1,
                        minWidth: "140px",
                      }}
                    >
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {data?.SpecialFeatureLists[0]?.maximum_maturity_age}
                      </p>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                          lineHeight: "16px",
                        }}
                      >
                        <small>Max Maturity Age</small>
                      </p>
                    </Box>
                  ) : null}

                  {data?.premiumDetails.risk_cover_cal ? (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "16px",
                        padding: "8px 12px",
                        textAlign: "center",
                        flex: 1,
                        minWidth: "140px",
                      }}
                    >
                      <img src="../images/tick-icon.svg" height="21px" />
                      <p style={{ fontWeight: "bold", marginTop: "12px" }}>
                        {formatToCurrency(data?.premiumDetails.risk_cover_cal)}
                      </p>
                      <p style={{ color: COLORS.lightgrey }}>
                        <small>Risk Cover</small>
                      </p>
                    </Box>
                  ) : null}
                </Box> */}
                {/* <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                /> */}
                {/* <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Key Features
                </h6> */}
                {/* <ul>
                  {data?.SpecialFeatureLists?.map((item: any, index: any) => (
                    <li
                      style={{
                        paddingBottom: "16px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: COLORS.lightgrey,
                        }}
                      >
                        <img
                          src="../images/success_icon.svg"
                          height="18px"
                          style={{ position: "relative", top: "2px" }}
                        />{" "}
                        {item.features_benefits}
                      </p>
                    </li>
                  ))}
                </ul> */}
                <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Tax Benefit
                </h6>
                <ul>
                  {/* {data?.SpecialFeatureLists?.map((item: any, index: any) => (
                    <li
                      style={{
                        paddingBottom: "16px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: COLORS.lightgrey,
                        }}
                      >
                        <img
                          src="../images/success_icon.svg"
                          height="18px"
                          style={{ position: "relative", top: "2px" }}
                        />{" "}
                        {item.tax_benefits}
                      </p>
                    </li>
                  ))} */}
                </ul>
                <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Policy Exclusion
                </h6>
                {/* <ul>
                  {data?.SpecialFeatureLists?.map((item: any, index: any) => (
                    <li
                      style={{
                        paddingBottom: "16px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: COLORS.lightgrey,
                        }}
                      >
                        <img
                          src="./images/cross-icon.svg"
                          height="18px"
                          style={{ position: "relative", top: "2px" }}
                        />{" "}
                        {item.suicide_exclusions}
                      </p>
                    </li>
                  ))}
                </ul> */}
                {/* <hr
                  style={{
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderBottom: "1px solid #E0E0E0",
                    marginTop: "8px",
                    marginBottom: "16px",
                  }}
                />
                <h6 style={{ fontWeight: "900", marginBottom: "16px" }}>
                  Plan Brochures/Document
                </h6>
                <Box>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                    }}
                    onClick={() => openInNewTab(data?.quotationDetail?.productDetails?.brochure)}
                  >
                    Brochure{" "}
                    <img src="./images/download-icon.svg" width="24px" />
                  </Link>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    Prospectus{" "}
                    <img src="./images/download-icon.svg" width="24px" />
                  </Link>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.darkgrey,
                      justifyContent: "space-between",
                    }}
                  >
                    Policy Wordings{" "}
                    <img src="./images/download-icon.svg" width="24px" />
                  </Link>
                </Box> */}
              </Grid>
            </Grid>
          </Box>


          <Box className="footerbar center">
            <JDSButtons
              text={"Continue"}
              variant={"text"}
              className={"primaryBtn large"}
              onClick={() => BUY_ACTION(data)}
              fullWidth={true}
            />
            {/* <JDSButtons
              className="primaryBtn "
              text={
                <>
                  <DownloadIcon />
                </>
              }
              variant="text"
              onClick={() => {
                download(data?.quotationDetail?.premiumDetails?.EBI_PDF_url);
              }}
              fullWidth={false}
            /> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MNonTermPolicyDetails;
