import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { COLORS } from "../../../SupportingFiles/colors";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";

export default function GenderRadio({
  value,
  variant,
  attrName,
  warn_status,
  value_update,
  defaultValue,
}: {
  value?: any;
  attrName?: any;
  warn_status?: any;
  defaultValue?: any;
  variant?: "default" | "small";
  value_update?: Function;
}) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, v) => {
        if (value_update && v !== null) value_update(attrName, v);
      }}
      aria-label="Platform"
      defaultValue={defaultValue}
      sx={{
        backgroundColor: COLORS.white,
        padding: "4px",
        gap: "4px",
        borderRadius: "100px",
        width: "100%",
        ".MuiButtonBase-root ": {
          borderRadius: "100px",
          border: "none",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "JioType",
          color: COLORS.darkgold,
          textTransform: "capitalize",
          lineHeight: "24px",
          padding: variant === "small" ? "8px" : "8px 16px",
          width: "100%",
          svg: {
            fill: COLORS.darkgold,
          },
          "&.Mui-selected": {
            backgroundColor: COLORS.primary,
            color: COLORS.warmgold,
            svg: {
              fill: COLORS.warmgold,
            },
            "&:hover": {
              backgroundColor: "#FCE0BD",
            },
            "&:focus, &:active": {
              backgroundColor: COLORS.primary,
            },
          },
        },
      }}
    >
      <ToggleButton value="M">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 10.09V5.41L15.29 7.71C15.383 7.80373 15.4936 7.87812 15.6154 7.92889C15.7373 7.97966 15.868 8.0058 16 8.0058C16.132 8.0058 16.2627 7.97966 16.3846 7.92889C16.5064 7.87812 16.617 7.80373 16.71 7.71C16.8037 7.61704 16.8781 7.50644 16.9289 7.38458C16.9797 7.26272 17.0058 7.13201 17.0058 7C17.0058 6.86799 16.9797 6.73728 16.9289 6.61542C16.8781 6.49356 16.8037 6.38296 16.71 6.29L12.71 2.29C12.617 2.19627 12.5064 2.12188 12.3846 2.07111C12.2627 2.02034 12.132 1.9942 12 1.9942C11.868 1.9942 11.7373 2.02034 11.6154 2.07111C11.4936 2.12188 11.383 2.19627 11.29 2.29L7.29001 6.29C7.19677 6.38324 7.12281 6.49393 7.07235 6.61575C7.02189 6.73757 6.99592 6.86814 6.99592 7C6.99592 7.13186 7.02189 7.26243 7.07235 7.38425C7.12281 7.50607 7.19677 7.61676 7.29001 7.71C7.38325 7.80324 7.49394 7.8772 7.61576 7.92766C7.73758 7.97812 7.86815 8.00409 8.00001 8.00409C8.13187 8.00409 8.26244 7.97812 8.38426 7.92766C8.50608 7.8772 8.61677 7.80324 8.71001 7.71L11 5.41V10.09C9.51661 10.3407 8.18154 11.1396 7.25937 12.3283C6.33719 13.5169 5.89522 15.0087 6.02103 16.5078C6.14684 18.007 6.83125 19.4042 7.93864 20.4226C9.04603 21.4409 10.4956 22.0061 12 22.0061C13.5045 22.0061 14.954 21.4409 16.0614 20.4226C17.1688 19.4042 17.8532 18.007 17.979 16.5078C18.1048 15.0087 17.6628 13.5169 16.7407 12.3283C15.8185 11.1396 14.4834 10.3407 13 10.09V10.09ZM12 20C11.2089 20 10.4355 19.7654 9.77773 19.3259C9.11993 18.8864 8.60724 18.2616 8.30449 17.5307C8.00174 16.7998 7.92253 15.9956 8.07687 15.2196C8.23121 14.4437 8.61217 13.731 9.17158 13.1716C9.73099 12.6122 10.4437 12.2312 11.2197 12.0769C11.9956 11.9225 12.7998 12.0017 13.5307 12.3045C14.2616 12.6072 14.8864 13.1199 15.3259 13.7777C15.7654 14.4355 16 15.2089 16 16C16 17.0609 15.5786 18.0783 14.8284 18.8284C14.0783 19.5786 13.0609 20 12 20Z" />
        </svg>
        Male
      </ToggleButton>
      <ToggleButton value="F">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 16.0002H13V13.9102C14.4834 13.6595 15.8185 12.8606 16.7407 11.672C17.6628 10.4833 18.1048 8.99155 17.979 7.49238C17.8532 5.99321 17.1688 4.59601 16.0614 3.57766C14.954 2.55932 13.5045 1.99414 12 1.99414C10.4956 1.99414 9.04603 2.55932 7.93864 3.57766C6.83125 4.59601 6.14684 5.99321 6.02103 7.49238C5.89522 8.99155 6.33719 10.4833 7.25937 11.672C8.18154 12.8606 9.51661 13.6595 11 13.9102V16.0002H8.00001C7.73479 16.0002 7.48044 16.1056 7.2929 16.2931C7.10537 16.4806 7.00001 16.735 7.00001 17.0002C7.00001 17.2654 7.10537 17.5198 7.2929 17.7073C7.48044 17.8949 7.73479 18.0002 8.00001 18.0002H11V21.0002C11 21.2654 11.1054 21.5198 11.2929 21.7073C11.4804 21.8949 11.7348 22.0002 12 22.0002C12.2652 22.0002 12.5196 21.8949 12.7071 21.7073C12.8947 21.5198 13 21.2654 13 21.0002V18.0002H16C16.2652 18.0002 16.5196 17.8949 16.7071 17.7073C16.8947 17.5198 17 17.2654 17 17.0002C17 16.735 16.8947 16.4806 16.7071 16.2931C16.5196 16.1056 16.2652 16.0002 16 16.0002ZM8.00001 8.00022C8.00001 7.2091 8.23461 6.43574 8.67413 5.77794C9.11366 5.12014 9.73837 4.60745 10.4693 4.3047C11.2002 4.00195 12.0044 3.92274 12.7804 4.07708C13.5563 4.23142 14.269 4.61238 14.8284 5.17179C15.3878 5.7312 15.7688 6.44394 15.9232 7.21986C16.0775 7.99578 15.9983 8.80005 15.6955 9.53095C15.3928 10.2619 14.8801 10.8866 14.2223 11.3261C13.5645 11.7656 12.7911 12.0002 12 12.0002C10.9391 12.0002 9.92173 11.5788 9.17158 10.8286C8.42144 10.0785 8.00001 9.06109 8.00001 8.00022V8.00022Z" />
        </svg>
        Female
      </ToggleButton>
      {/* <ToggleButton value="O">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 2H16C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3C15 3.26522 15.1054 3.51957 15.2929 3.70711C15.4804 3.89464 15.7348 4 16 4H16.59L14.75 5.83C14.1348 5.42264 13.4371 5.15648 12.7069 5.05062C11.9767 4.94476 11.2321 5.00181 10.5266 5.2177C9.82101 5.43358 9.17198 5.80294 8.62604 6.29928C8.0801 6.79561 7.65077 7.40662 7.36886 8.08848C7.08695 8.77034 6.95943 9.50613 6.99547 10.2431C7.03151 10.98 7.2302 11.6999 7.57729 12.351C7.92438 13.0021 8.41127 13.5683 9.00304 14.009C9.5948 14.4497 10.2768 14.754 11 14.9V17H9C8.73479 17 8.48043 17.1054 8.2929 17.2929C8.10536 17.4804 8 17.7348 8 18C8 18.2652 8.10536 18.5196 8.2929 18.7071C8.48043 18.8946 8.73479 19 9 19H11V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19H15C15.2652 19 15.5196 18.8946 15.7071 18.7071C15.8946 18.5196 16 18.2652 16 18C16 17.7348 15.8946 17.4804 15.7071 17.2929C15.5196 17.1054 15.2652 17 15 17H13V14.9C13.8005 14.7358 14.5485 14.378 15.1786 13.8578C15.8087 13.3376 16.3018 12.6709 16.6146 11.916C16.9274 11.1611 17.0504 10.3411 16.9729 9.52769C16.8954 8.71426 16.6197 7.93222 16.17 7.25L18 5.41V6C18 6.26522 18.1054 6.51957 18.2929 6.70711C18.4804 6.89464 18.7348 7 19 7C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6V3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2V2ZM12 13C11.4067 13 10.8266 12.8241 10.3333 12.4944C9.83995 12.1648 9.45543 11.6962 9.22836 11.1481C9.0013 10.5999 8.94189 9.99667 9.05765 9.41473C9.1734 8.83279 9.45913 8.29824 9.87868 7.87868C10.2982 7.45912 10.8328 7.1734 11.4147 7.05764C11.9967 6.94189 12.5999 7.0013 13.1481 7.22836C13.6962 7.45542 14.1648 7.83994 14.4944 8.33329C14.8241 8.82664 15 9.40666 15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7957 13 12 13Z" />
        </svg>
        Others
      </ToggleButton> */}
    </ToggleButtonGroup>
  );
}
