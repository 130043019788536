let node_domain = "";
let strapi_domain = "";
let php_domain = "";
let frontend_domain = "";
let jwt_secret_key = "";
let electronic_insurance_domain = "";

if (
  process.env.REACT_APP_ENV === "DEV" ||
  process.env.REACT_APP_ENV === `"DEV"`
) {
  node_domain = "https://devnodeapiev.jioinsure.in";
  php_domain = "https://devapiev.jioinsure.in";
  frontend_domain = "https://devev.jioinsure.in";
  jwt_secret_key = "cb6b58e0-8a5a-418b-8b69-caa788ec5ab5";
  electronic_insurance_domain = "https://www.camsrepository.com";
} else if (
  process.env.REACT_APP_ENV === "PROD" ||
  process.env.REACT_APP_ENV === `"PROD"`
) {
  node_domain = "https://nodeapiev.jioinsure.in";
  php_domain = "https://api.jioinsure.in";
  frontend_domain = "https://jioinsure.in";
  jwt_secret_key = "e50d581b-d14e-488a-b350-d3f87afc4fce";
  electronic_insurance_domain = "https://www.camsrepository.com";
} else if (
  process.env.REACT_APP_ENV === "STG" ||
  process.env.REACT_APP_ENV === `"STG"`
) {
  node_domain = "https://stgnodeapiev.jioinsure.in";
  php_domain = "https://stgapiev.jioinsure.in";
  frontend_domain = "https://stgev.jioinsure.in";
  jwt_secret_key = "e50d581b-d14e-488a-b350-d3f87afc4fce";
  electronic_insurance_domain = "https://www.camsrepository.com";
}

export const NODE_DOMAIN = node_domain;
export const STRAPI_DOMAIN = strapi_domain;
export const PHP_DOMAIN = php_domain;
export const FRONTEND_DOMAIN = frontend_domain;
export const JWT_SECRET_KEY = jwt_secret_key;
export const ELECTRONIC_INSURANCE_DOMAIN = electronic_insurance_domain;
