import { NODE_DOMAIN, PHP_DOMAIN, STRAPI_DOMAIN } from "../Domain";

export const CommonURLs = {
  GENERATE_TOKEN: `${PHP_DOMAIN}/api/users/generate-token.json`,

  //strAPI
  LOAD_HOMEPAGE_DATA: `${STRAPI_DOMAIN}/api/home-page?populate=deep`,
  CMS_DATA: `${STRAPI_DOMAIN}/api/cms-page?populate=deep`,
  PRODUCT_PAGE_DATA: `${STRAPI_DOMAIN}/api/prod-page?populate=deep`,
  SUBMIT_CLAIM: `${PHP_DOMAIN}/api/users/send-claim-page-email.json`,

  CONTACT_US: `${NODE_DOMAIN}/api/v1/premium/contact-us/email`,

  //Premium Confiramtion
  PREMIUM_CONFIRMATION: `${PHP_DOMAIN}/api/policies/payment-confirmation.json`,

  EVENT_TRACKER: `${NODE_DOMAIN}/api/v1/premium/add-form-jio`,
};
