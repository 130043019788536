import React, { ReactEventHandler } from "react";
import { Link } from "@mui/material";
import { COLORS } from "../../SupportingFiles/colors";

interface BackLeftProps {
  onClick: ReactEventHandler;
}

function BackLeft({ onClick }: BackLeftProps) {
  return (
    <Link
      onClick={onClick}
      sx={{
        color: COLORS.darkgold,
        display: "block",
        position: "relative",
        height: "24px",
        width: "24px",
        "&:before": {
          backgroundColor: COLORS.darkgrey,
          borderRadius: "2px",
          position: "absolute",
          content: '""',
          height: "2px",
          width: "12px",
          transform: "rotate(-45deg)",
          top: "7px",
          left: "6px",
        },
        "&:after": {
          backgroundColor: COLORS.darkgrey,
          borderRadius: "2px",
          position: "absolute",
          content: '""',
          height: "2px",
          width: "12px",
          transform: "rotate(45deg)",
          top: "14px",
          left: "6px",
        },
      }}
    ></Link>
  );
}

export default BackLeft;
