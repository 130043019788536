import { PayloadAction } from "@reduxjs/toolkit";
import { TCMSSlice } from "../../../types/TCMSSlice";

function setCMSData(state: TCMSSlice, action: PayloadAction<TCMSSlice>) {
  return action.payload;
}

export const CMS_REDUCERS = {
  setCMSData,
};

export type TCMSReducers = typeof CMS_REDUCERS;
