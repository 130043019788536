import { NODE_DOMAIN } from "../Domain";

export const CAR_URLS = {
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/car-details?type=CAR&cRegNo=`,
  GET_MAKE_LIST: `${NODE_DOMAIN}/api/v1/premium/make-list?type=CAR`,
  GET_MODEL_LIST: `${NODE_DOMAIN}/api/v1/premium/model-make-list?type=CAR&make=`,
  ADD_FORM_DATA: `${NODE_DOMAIN}/api/v1/premium/add-form`,
  GET_PREVIOUS_INSURER_LIST: `${NODE_DOMAIN}/api/v1/premium/previous-insurers?type=CAR`,
  GET_VARIANT_LIST: `${NODE_DOMAIN}/api/v1/premium/make-variant?type=CAR&make_model=`,
  GET_FUEL_TYPE_LIST: `${NODE_DOMAIN}/api/v1/premium/make-fuel?type=CAR&make_model=`,
  GET_RTO_LIST: `${NODE_DOMAIN}/api/v1/premium/rto-list?type=CAR`,
};
