import { PayloadAction } from "@reduxjs/toolkit";
import { THomePage } from "../../../types/Home/THomePage";

function setHomePageData(state: THomePage, action: PayloadAction<THomePage>) {
  return {
    ...state,
    ...action.payload,
  };
}

function setPageStatus(
  state: THomePage,
  action: PayloadAction<
    "" | "HEALTH" | "CAR" | "TW" | "TERM" | "INVESTMENT" | "MY_ACCOUNT"
  >
) {
  let data = { ...state };
  return {
    ...data,
    PAGE_STATUS: action.payload,
  };
}

export const HOME_PAGE_REDUCERS = {
  setHomePageData,
  setPageStatus,
};

export type HomePageReducers = typeof HOME_PAGE_REDUCERS;
