import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const BlogAllYouNeed = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "all-you-need-to-know-about-car-insurance!",
    blogtitle: "All you need to know about Car Insurance!",
    img_url: "/images/blog3.webp",
    main_desc: (
      <>
        <h6 className="mb-3">What is a car insurance? </h6>
        <p className="mb-4">
          A policy that covers all the hazards connected to cars is called car
          insurance. It also goes by the name "Motor Insurance" and offers
          protection against any monetary losses brought on by accidents,
          including collisions with other vehicles or objects, theft of the car
          or its components, damage from water or fire, etc. A car insurance
          premium varies on various parameters like age of the vehicle, model,
          make, variant, engine and location.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        {/* <p className="mb-5">
          A policy that covers all the hazards connected to cars is called car
          insurance. It also goes by the name "Motor Insurance" and offers
          protection against any monetary losses brought on by accidents,
          including collisions with other vehicles or objects, theft of the
          car or its components, damage from water or fire, etc. A car
          insurance premium varies on various parameters like age of the
          vehicle, model, make, variant, engine and location.{" "}
        </p> */}

        <h6 className="mb-3">Why is it important to have a car insurance? </h6>
        <ul className="mb-5">
          <li> Gives you peace of mind </li>
          <li> Covers physical damage to the car </li>
          <li> Covers third-party liability</li>
          <li>
            {" "}
            You are also protected with 15 lakhs of a personal accident cover{" "}
          </li>
          <li> Cashless service available</li>
          <li>
            {" "}
            Lot of add-on covers are available, which help in better coverage of
            your car{" "}
          </li>
        </ul>

        <h6 className="mb-3">What are the types of car insurance?</h6>
        <p className="mb-3">
          There are three key components / covers of a car insurance policy -
        </p>
        <ul className="mb-5">
          <li>
            <span>Third-Party Car Insurance:</span> A third-party car insurance
            policy offers financial protection against harm done to another
            person or their property. It does not offer protection to your
            vehicle against any losses. It is compulsory to have a third-party
            insurance as per{" "}
            <span style={{ fontWeight: "700" }}>The Motor Vehicle Act</span>,
            1988 for all vehicles plying on the road.{" "}
          </li>
          <li>
            <span>Own-Damage Car Insurance:</span> An own-damage car insurance
            policy provides protection against damages caused to the car due to
            an accident or a mishap and does not cover third-party liability.
          </li>
          <li>
            <span>Comprehensive Car Insurance:</span> A comprehensive car
            insurance policy covers against third party liability as well as
            damages caused to the car due to an accident or a mishap. It
            protects the car against both natural and man-made catastrophes,
            such as earthquake, storm, flood, fire, theft, road accident, etc.
          </li>
        </ul>

        <h6 className="mb-3" style={{ textAlign: "center" }}>
          Comparison between Comprehensive, Third-Party and Own-Damage covers
        </h6>

        <table className="mb-5 tabledata typeTable">
          <thead>
            <tr>
              <th>Type of Cover</th>
              <th>Comprehensive Car Insurance</th>
              <th>Third-Party Car Insurance</th>
              <th>Own-Damage Car Insurance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Damage to the car due to an accident</td>
              <td>Yes</td>
              <td>No</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Theft of the car</td>
              <td>Yes</td>
              <td>No</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Injuries to the third-party</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
          </tbody>
        </table>

        <p className="mb-5">
          It is advisable to every car owner to avail of a comprehensive car
          insurance, which helps the car owner safeguard self as well as the
          third-party in a circumstance of an unfortunate event. At{" "}
          <span style={{ fontWeight: "900" }}>
            Jio Insurance Broking Limited
          </span>{" "}
          we help you understand these covers and choose the best fit product at
          a competitive rate and beneficial features!
        </p>
      </>
    ),
  };

  const blogsJsonLd = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${FRONTEND_DOMAIN}/blog/all-you-need-to-know-about-car-insurance`
    },
    "headline": current_blog_data?.blogtitle,
    "image": `${FRONTEND_DOMAIN}${current_blog_data?.img_url}`,  
    "author": {
      "@type": "Organization",
      "name": "Jio Insurance Broking Ltd.",
      "url": `${FRONTEND_DOMAIN}/about-us`
    },  
    "datePublished": "2024-10-22",
    "dateModified": "2024-10-22"
  }, null, 2);  // Added 'null, 2' to format it with indentation


  return (
    <Box className="blogsWrapper">
      <HelmetComponent showCanonicalTag={true} scripts={[blogsJsonLd]}/>
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogAllYouNeed;
