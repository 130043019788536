import { PayloadAction } from "@reduxjs/toolkit";
import { TProductPageSlice } from "../../../types/TProductPageSlice";

function setProductPagesData(
  state: TProductPageSlice,
  action: PayloadAction<TProductPageSlice>
) {
  return {
    ...state,
    ...action.payload,
  };
}

export const PRODUCT_PAGES_REDUCERS = {
  setProductPagesData,
};

export type ProductPagesReducers = typeof PRODUCT_PAGES_REDUCERS;
