import { PostAPI } from "../../Axios/AxiosInstanceToken";

import { CommonURLs } from "../../URLCollection/Common/CommonURLs";

const EVENT_TRACKER = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    CommonURLs.EVENT_TRACKER,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const COMMON_SERVICES = {
  EVENT_TRACKER,
};
