import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
import { UserProfileSlice } from "./Slice_Reducer/Account/UserProfileSlice";
import { CKYCSlice } from "./Slice_Reducer/CKYC/CKYCSlice";
import { CarSlice } from "./Slice_Reducer/Car/CarSlice";
import { CommonDropdownSlice } from "./Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "./Slice_Reducer/Health/HealthSlice";
import { PaymentGatewaySlice } from "./Slice_Reducer/PaymentGateway/PaymentGatewaySlice";
import { TermSlice } from "./Slice_Reducer/Term/TermSlice";
import { TWSlice } from "./Slice_Reducer/TwoWheeler/TWSlice";
import { HomeSlice } from "./Slice_Reducer/Home/HomeSlice";
import { CMSSlice } from "./Slice_Reducer/CMS/CMSSlice";
import { ProductPagesSlice } from "./Slice_Reducer/ProductPages/ProductPagesSlice";
import { NonTermSlice } from "./Slice_Reducer/NonTerm/NonTermSlice";

const rootReducer = combineReducers({
  CommonDropdownData: CommonDropdownSlice.reducer,
  TW: TWSlice.reducer,
  Car: CarSlice.reducer,
  Home: HomeSlice.reducer,
  Term: TermSlice.reducer,
  NonTerm: NonTermSlice.reducer,
  Health: HealthSlice.reducer,
  CMS: CMSSlice.reducer,
  USER_STATE: UserProfileSlice.reducer,
  PRODUCT_PAGES: ProductPagesSlice.reducer,
  CKYC: CKYCSlice.reducer,
  PAYMENT_GATEWAY: PaymentGatewaySlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "store",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
