import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../../../InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import "./../../../../SCSS/ModalPopup.scss";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { HOME_SERVICES } from "../../../../Services/Home/HomeServices";
import CustomCheckbox from "../../../InputFields/CustomCheckbox/CustomCheckbox";
import { COLORS } from "../../../../SupportingFiles/colors";

const ContactusPopup = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  type TContactUs = {
    name: { value: string; warn_status: boolean };
    contact: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    lookingForIns: { value: string; warn_status: boolean };
  };
  const [contactformFields, setContactFormFields] = useState<TContactUs>({
    name: { value: "", warn_status: false },
    contact: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    lookingForIns: { value: "", warn_status: false },
  });
  const lookingfroInsuranceData = [
    {
      label: "Health Insurance",
      value: "Health",
    },
    {
      label: "Car Insurance",
      value: "Car",
    },
    {
      label: "Two-Wheeler Insurance",
      value: "Two Wheeler",
    },
    {
      label: "Life Insurance",
      value: "Life",
    },
  ];

  const updateMasterState = (attrName: string, value: string) => {
    setContactFormFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warn_status:
          attrName === "contact"
            ? !validateMobileNumber(value)
            : attrName === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let data: TContactUs = { ...contactformFields };

    data = {
      ...data,
      contact: {
        ...data.contact,
        warn_status: !validateMobileNumber(data.contact.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
      lookingForIns: {
        ...data.lookingForIns,
        warn_status: isEmpty(data.lookingForIns.value),
      },
      name: {
        ...data.name,
        warn_status: isEmpty(data.name.value),
      },
    };
    setContactFormFields({ ...data });
    let hasError: boolean = false;
    if (
      data.contact.warn_status ||
      data.email.warn_status ||
      data.lookingForIns.warn_status ||
      data.name.warn_status
    ) {
      hasError = true;
    }
    if (!hasError) {
      UPLOAD_CONTACT_US_DATA(data);
    }
  };

  const UPLOAD_CONTACT_US_DATA = (data: TContactUs) => {
    const onSuccess = (res: any) => {};
    const onError = (err: any) => {};
    setOpen(false);
    HOME_SERVICES.CONTACT_US(onSuccess, onError, {
      email: data.email.value,
      username: data.name.value,
      number: data.contact.value,
      productName: data.lookingForIns.value,
    });
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent xsWidth">
          <Grid className="topbar" container alignItems="center">
            <Grid xs={12} textAlign={"right"}>
              <Button
                disableRipple
                className="closePopup"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid xs={12}>
              <h3>Contact Us</h3>
            </Grid>
            <Grid xs={12} marginBottom={"8px"}>
              <TextInputField
                title="Name"
                value={contactformFields.name.value}
                attrName={"name"}
                value_update={updateMasterState}
                warn_status={contactformFields.name.warn_status}
                error_message={"Enter Name"}
                validation_type="NAME"
              />
            </Grid>
            <Grid xs={12} marginBottom={"8px"}>
              <TextInputField
                title="Email"
                value={contactformFields.email.value}
                attrName={"email"}
                value_update={updateMasterState}
                warn_status={contactformFields.email.warn_status}
                error_message={
                  isEmpty(contactformFields.email.value)
                    ? "Enter E-mail"
                    : "Enter valid E-mail"
                }
                max_length={50}
              />
            </Grid>
            <Grid xs={12} marginBottom={"8px"}>
              <Box className="mobileNumber">
                <Box
                  sx={{
                    fontSize: "16px",
                    color: COLORS.lightgrey,
                    position: "relative",
                    padding: "21px 8px 12px 0px",
                  }}
                >
                  +91
                </Box>
                <TextInputField
                  title="Phone number"
                  validation_type="NUMBER"
                  value={contactformFields.contact.value}
                  attrName={"contact"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.contact.warn_status}
                  error_message={
                    isEmpty(contactformFields.contact.value)
                      ? "Enter Phone Number"
                      : "Enter valid Phone number"
                  }
                  max_length={10}
                />
              </Box>
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                title="Looking for"
                data={lookingfroInsuranceData}
                value={contactformFields.lookingForIns.value}
                attrName={"lookingForIns"}
                value_update={updateMasterState}
                warn_status={contactformFields.lookingForIns.warn_status}
                error_message={"Select Insurance"}
              />
            </Grid>
            <Grid xs={12}>
              <CustomCheckbox
                label={
                  <span style={{ fontSize: "14px" }}>
                    I agree to{" "}
                    <Link
                      style={{ color: COLORS.darkgold }}
                      href="./pdfs/terms_of_use.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                }
                attrName="termsandconditions"
                value={true}
                defaultChecked={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} className="mt-2">
              <JDSButtons
                text={"Submit"}
                variant={"text"}
                className={"primaryBtn large"}
                onClick={() => {
                  validate_form();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactusPopup;
