import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import TermQuotePolicyDetail from "../../../../Page/Desktop/Term/TermQuote/TermQuotePolicyDetail/TermQuotePolicyDetail";
import { useAppSelector } from "../../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";
import { TSelectedQuote } from "../../../../types/TTermSlice";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";

function TermQuoteCard({
  loader,
  data,
  BUY_ACTION,
}: {
  loader: boolean;
  data: TSelectedQuote;
  BUY_ACTION: Function;
}) {
  const { QUOTE_FIELDS_DATA } = useAppSelector((state) => state.Term);
  const [openPolicyDetails, setOpenPolicyDetails] = useState<boolean>(false);

  return (
    <Box>
      <TermQuotePolicyDetail
        open={openPolicyDetails}
        setOpen={setOpenPolicyDetails}
        data={data}
        BUY_ACTION={BUY_ACTION}
      />
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          padding: "16px ",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
          position: "relative",
          marginBottom: "24px",
          zIndex: "9",
        }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid xs={5} display="flex" gap="12px" flexDirection="row">
            <img src={data?.CompanyDetails.logo} width="91px" height="52px" />
            <Box>
              <p>{data.CompanyDetails.short_desc}</p>
              <p>
                <small style={{ color: COLORS.lightgrey }}>
                  {data.productDetails.product_name}
                </small>
              </p>
            </Box>
          </Grid>
          <Grid xs="auto">
            <p>
              <small style={{ color: COLORS.lightgrey }}>Coverage Amount</small>
            </p>
            <h6 style={{ fontWeight: "bold" }}>
              {formatNumberToLakhOrCrores(parseInt(data?.sumAssured))}
            </h6>
          </Grid>
          <Grid xs="auto">
            <p>
              <small style={{ color: COLORS.lightgrey }}>Cover Till</small>
            </p>
            <h6 style={{ fontWeight: "bold" }}>
              {`${data?.premiumDetails?.term} years`}
            </h6>
          </Grid>
          <Grid xs="auto">
            <p>
              <small style={{ color: COLORS.lightgrey }}>Claim Settled</small>
            </p>
            <h6 style={{ fontWeight: "bold" }}>
              {data?.CompanyDetails.claim_ratio}%
            </h6>
          </Grid>
          <Grid xs textAlign="right">
            <Box textAlign="center" display="inline-block">
              <JDSButtons
                className="primaryBtn small"
                text={
                  <>
                    {formatAmountToCurrency(data?.premiumDetails?.finalPremium)}{" "}
                    <KeyboardArrowRightRoundedIcon sx={{ height: "16px" }} />
                  </>
                }
                variant="text"
                onClick={() => {
                  BUY_ACTION(data);
                }}
              />

              <p style={{ color: COLORS.lightgrey }}>
                <small>
                  {QUOTE_FIELDS_DATA.payMode === "12"
                    ? "Monthly"
                    : QUOTE_FIELDS_DATA.payMode === "4"
                    ? "Quaterly"
                    : QUOTE_FIELDS_DATA.payMode === "2"
                    ? "Half-yearly"
                    : QUOTE_FIELDS_DATA.payMode === "1"
                    ? "Yearly"
                    : QUOTE_FIELDS_DATA.payMode === "5"
                    ? "Single Pay"
                    : null}
                </small>
              </p>
            </Box>
          </Grid>

          <Grid xs={9}>
            {/* {data?.premiumDetails?.riders ? (
              <>
                <p
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                    marginBottom: "8px",
                    fontSize: "12px",
                  }}
                >
                  What's covered?
                  <span
                    style={{
                      width: "140px",
                      height: "1px",
                      backgroundColor: "#FEF7E9",
                    }}
                  />
                </p>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.premiumDetails?.riders &&
                    Object.values(data?.premiumDetails?.riders).map((rider) => (
                      <li
                        style={{
                          listStyleType: "none",
                          fontSize: "12px",
                          display: "flex",
                          gap: "8px",
                          color: COLORS.lightgrey,
                        }}
                      >
                        <img
                          src="../images/tick-icon.svg"
                          style={{
                            height: "12px",
                            width: "12px",
                            marginTop: "2px",
                          }}
                        />
                        {rider?.description}
                      </li>
                    ))}
                </ul>
              </>
            ) : null} */}
          </Grid>

          <Grid xs={3} display="flex" justifyContent="end">
            <Link
              sx={{
                color: COLORS.darkgold,
                fontWeight: "bold",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setOpenPolicyDetails(true);
              }}>
              View Details{" "}
              <KeyboardArrowRightRoundedIcon
                sx={{ height: "20px", width: "20px" }}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: COLORS.lightMariGold20,
          padding: "20px 24px 8px",
          borderRadius: "0px 0px 8px 8px",
          marginTop: "-12px",
        }}
      >
        <Grid container alignItems={"center"} spacing={2}>
          <Grid xs={6}>
            <p>Add-Ons</p>
          </Grid>
          <Grid xs={6} textAlign={"right"}>
            <SwitchToggle
              attrName={"addonViewToggle"}
              value={addonViewToggle}
              onChange={handleAddonViewToggle}
            />
          </Grid>
          <Collapse in={addonViewToggle === true}>
            {addonViewToggle && (
              <Grid xs={12}>
                <CustomCheckbox
                  label="Accidental Death Benefit ₹47 (Cover ₹5 Lac)"
                  attrName="accidentalDeath"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                />
                <CustomCheckbox
                  label="Critical illness and Disability Platinum ₹325 (Cover 5 Lac)"
                  attrName="criticalIllness"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                />
                <CustomCheckbox
                  label="Waiver of premium plus ₹25"
                  attrName="accidentalDeath"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                />
              </Grid>
            )}
          </Collapse>
        </Grid>
      </Box> */}
    </Box>
  );
}

export default TermQuoteCard;
