import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { addYears, subDays, subMonths } from "date-fns";
import subYears from "date-fns/subYears";
import { useEffect, useState } from "react";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import "./Datepicker.scss";
import { COLORS } from "../../../SupportingFiles/colors";

/**
 * DatePicker component to display a mobile-friendly date picker.
 *
 * @param title - The title or label for the DatePicker.
 * @param value - The current value of the DatePicker.
 * @param value_update - A function to update the value of the DatePicker.
 * @param attrName - The attribute name used to identify the DatePicker.
 * @param error_message - An optional error message to display when there is an error.
 * @param warn_status - A boolean indicating whether to display a warning status.
 * @param class_name - The CSS class name for styling purposes.
 * @param min_date - The minimum number of years to subtract from the current date (default: 100).
 * @param max_date - The maximum number of years to subtract from the current date (default: 18).
 * @param dateValidation - A boolean indicating whether to validate the selected date within a range (default: false).
 * @returns The DatePicker component.
 */
const DatePicker = ({
  title = "",
  value = null,
  onChange = () => {},
  attrName = "",
  error_message = "Select",
  warn_status = false,
  min_date = 15,
  max_date = 18,
  date_validation_type = "YEARS",
  disabled = false,
  default_date = new Date(),
}: {
  title?: string;
  value?: any;
  attrName?: any;
  onChange?: Function;
  error_message?: string;
  warn_status?: boolean;
  min_date?: number;
  max_date?: number;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS";
  disabled?: boolean;
  default_date?: Date;
}) => {
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateValidation, setDateValidation] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(min_date) && !isEmpty(max_date)) {
      setDateValidation(true);
    } else {
      setDateValidation(false);
    }
  }, [min_date, max_date]);

  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        setMinDate(subYears(new Date(), min_date));
        break;
      case "MONTHS":
        setMinDate(subMonths(new Date(), min_date));
        break;
      case "DAYS":
        setMinDate(subDays(new Date(), min_date));
        break;

      default:
        break;
    }

    switch (date_validation_type) {
      case "YEARS":
        setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        setMaxDate(subDays(new Date(), max_date));
        break;

      default:
        break;
    }
  }, [min_date, max_date, dateValidation]);

  const [localValue, setLocalValue] = useState<Date | null>(value);

  useEffect(() => {
    if (value !== null && value !== "") {
      const dateString = value; // Replace with your date string in DD-MM-YYYY format
      const parts = dateString.split("-");

      // Make sure to use parts[2] for the year, parts[1] for the month, and parts[0] for the day
      if (parts.length === 3) {
        const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;

        const dateObject = new Date(formattedDateString);

        if (!isNaN(dateObject.getTime())) {
          // Valid date
          console.error("Valid date string", dateObject);
          setLocalValue(dateObject);
        } else {
          // Invalid date string
          console.error("Invalid date string");
        }
      } else {
        // Invalid format
        console.error("Invalid date format");
      }
    } else {
      // Handle null or empty string case
      setLocalValue(null);
      console.error("Value is null or empty");
    }
  }, [value]);

  return (
    <div className="datepicker_jio">
      <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
        <p
          style={{
            color: COLORS.lightgrey,
            textAlign: "left",
            marginBottom: "4px",
          }}
        >
          {title}
        </p>
        <MobileDatePicker
          disabled={disabled}
          // label={title}
          value={localValue}
          onChange={(selectedDate) => {
            onChange(attrName, FORMAT_DD_MM_YYYY(`${selectedDate}`));
          }}
          inputFormat="dd-MM-yyyy"
          renderInput={(params) => (
            <TextField
              id={`${title}`}
              fullWidth
              {...params}
              placeholder="DD-MM-YYYY"
            />
          )}
          showToolbar={false}
          closeOnSelect
          defaultCalendarMonth={default_date}
          minDate={dateValidation ? minDate : subYears(new Date(), 100)} // Minimum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
          maxDate={dateValidation ? maxDate : addYears(new Date(), 100)} // Maximum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
        />
        {warn_status ? <span className="error">{error_message}</span> : null}
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
