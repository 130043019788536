import { ENonTermCompanyCode } from "../../../Enum/ENonTermComapnyCode";
import { ETermCompanyCode } from "../../../Enum/ETermCompanyCode";
import { useAppSelector } from "../../../Store/hooks";
import HDFCRoutes from "./HDFC/HDFCRoutes";
import ICICIRoutes from "./ICICI/ICICIRoutes";
import MaxLifeRoutes from "./MAX/MaxLifeRoutes";
import AdityaBirlaRoutes from "./ADITYA_BIRLA/AdityaBirlaRoutes";
import TATARoutes from "./TATA/TATARoutes";

const NonTermRoutes = () => {
  const { company_code } = useAppSelector(
    (state) => state.NonTerm.SELECTED_QUOTE_DATA?.companyDetails
  );
  console.log("company_code", company_code);

  return (
    <>
      {company_code === ENonTermCompanyCode.ICICI ? <ICICIRoutes /> : null}
      {company_code === ENonTermCompanyCode.HDFC ? <HDFCRoutes /> : null}
      {company_code === ENonTermCompanyCode.MAX_LIFE ? <MaxLifeRoutes /> : null}
      {company_code === ENonTermCompanyCode.TATA ? <TATARoutes /> : null}
      {/* {company_code === ENonTermCompanyCode.Aditya_Birla ? <AdityaBirlaRoutes  /> : null} */}
    </>
  );
};

export default NonTermRoutes;
