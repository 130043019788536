import { NODE_DOMAIN } from "../Domain";

export const TW_URLS = {
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/car-details?type=TW&cRegNo=`,
  GET_MAKE_LIST: `${NODE_DOMAIN}/api/v1/premium/make-list?type=TW`,
  GET_MODEL_LIST: `${NODE_DOMAIN}/api/v1/premium/model-make-list?type=TW&make=`,
  ADD_FORM_DATA: `${NODE_DOMAIN}/api/v1/premium/add-form/tw`,
  GET_PREVIOUS_INSURER_LIST: `${NODE_DOMAIN}/api/v1/premium/previous-insurers?type=TW`,
  GET_VARIANT_LIST: `${NODE_DOMAIN}/api/v1/premium/make-variant?type=TW&make_model=`,
  GET_FUEL_TYPE_LIST: `${NODE_DOMAIN}/api/v1/premium/make-fuel?type=TW&make_model=`,
  GET_RTO_LIST: `${NODE_DOMAIN}/api/v1/premium/rto-list?type=TW`,
};
