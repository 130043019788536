import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TFieldData } from "../../../types/Common/TFieldData";
import { TTermAddForm } from "../../../types/Term/TTermAddForm";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateAge,
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import TermModifyDetails from "../../../Page/Desktop/Term/TermQuote/TermModifyDetails/TermModifyDetails";

export type TTermModifyDetails = {
  annualIncome: TFieldData;
  doyouSmoke: TFieldData;
  gender: TFieldData;
  dob: TFieldData;
  pinCode: TFieldData;
};

function TermModifyDetailsContainer({
  open,

  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { Term } = useAppSelector((state) => state);
  const { ADD_FORM, ADD_FORM_RESPONSE } = useAppSelector((state) => state.Term);
  const [quoteFields, setQuoteFields] = useState<TTermModifyDetails>({
    annualIncome: {
      value: `${ADD_FORM.annualIncome.value}`,
      warning: false,
    },
    doyouSmoke: { value: ADD_FORM.smoke, warning: false },
    gender: ADD_FORM.gender,
    dob: {
      value: ADD_FORM.age.value,
      warning: false,
    },
    pinCode: { value: ADD_FORM.pincode.value, warning: false },
  });

  useEffect(() => {
    setQuoteFields({
      annualIncome: {
        value: `${ADD_FORM.annualIncome.value}`,
        warning: false,
      },
      doyouSmoke: { value: ADD_FORM.smoke, warning: false },
      gender: ADD_FORM.gender,
      dob: {
        value: ADD_FORM.age.value,
        warning: false,
      },
      pinCode: { value: ADD_FORM.pincode.value, warning: false },
    });
  }, [open]);

  const [annualIncData, setAnnualIncDat] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  useEffect(() => {
    let annualIncome_data = [];

    for (
      let incomeValue = 200000;
      incomeValue <= 10000000;
      incomeValue += 100000
    ) {
      let label;

      if (incomeValue === 10000000) {
        label = "1 Crore";
      } else {
        label = `${incomeValue / 100000} Lakhs`;
      }

      annualIncome_data.push({ label: label, value: `${incomeValue}` });
    }

    setAnnualIncDat(annualIncome_data);
  }, []);

  // Get today's date
  const verifyDetails = () => {
    let hasError = false;
    let data = { ...quoteFields };
    if (!data.annualIncome.value) {
      data = { ...data, annualIncome: { ...data.annualIncome, warning: true } };
      hasError = true;
    } else if (!data.doyouSmoke.value) {
      data = { ...data, doyouSmoke: { ...data.doyouSmoke, warning: true } };
      hasError = true;
    } else if (!data.gender.value) {
      data = { ...data, gender: { ...data.gender, warning: true } };
      hasError = true;
    } else if (!data.dob.value) {
      data = { ...data, dob: { ...data.dob, warning: true } };
      hasError = true;
    } else if (!validatePincode(data.pinCode.value)) {
      data = { ...data, pinCode: { ...data.pinCode, warning: true } };
      hasError = true;
    }
    if (!hasError) {
      let newData: TTermAddForm = {
        mobile: ADD_FORM.mobile,
        email: ADD_FORM.mobile,
        name: ADD_FORM.name,
        annualIncome: { value: data.annualIncome.value, warning: false },
        smoke: data.doyouSmoke.value,
        gender: { value: data.gender.value, warning: false },
        age: {
          warning: false,
          value: data.dob.value,
        },
        pincode: { warning: false, value: data.pinCode.value },
      };

      dispatch(TermSlice.actions.setTermAddFormData(newData));

      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "update_form",
        details: {
          termDob: FORMAT_DD_MM_YYYY(newData.age.value),
          gender: newData.gender.value,
          smoker: newData.smoke,
          pincode: newData.pincode.value,
          annual_income: `${newData.annualIncome.value}`,
        },
      };

      Update_details(params);
      setOpen(false);
      setQuoteFields(data);
    }
  };

  const update_data = (age_value: string) => {
    // Sum Assured Data
    const calculateEndPoint = () => {
      const age: number = Number(calculateAge(age_value));

      if (age >= 18 && age <= 40)
        return (ADD_FORM.annualIncome.value / 100000) * 30;
      if (age >= 41 && age <= 45)
        return (ADD_FORM.annualIncome.value / 100000) * 22;
      if (age >= 46 && age <= 50)
        return (ADD_FORM.annualIncome.value / 100000) * 20;
      if (age >= 51 && age <= 55)
        return (ADD_FORM.annualIncome.value / 100000) * 12;
      if (age >= 56 && age <= 65)
        return (ADD_FORM.annualIncome.value / 100000) * 10;
      return 0;
    };

    const sumAssuredData: TDropdown[] = [];
    const endPoint: number = calculateEndPoint();

    for (let i = 2; i <= endPoint; i += i < 50 ? 1 : i < 100 ? 10 : 100) {
      sumAssuredData.push({
        label: `${i * 100000}`,
        value: `${i >= 100 ? i / 100 : i} ${i < 100 ? "Lakhs" : "Crores"}`,
      });
    }

    dispatch(
      TermSlice.actions.UPDATE_DROPDOWN_DATA({
        key: "SUM_ASSURED",
        value: sumAssuredData,
      })
    );

    if (calculateAge(ADD_FORM.age.value) !== calculateAge(age_value)) {
      if (Number(calculateAge(age_value)) > 50) {
        dispatch(
          TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
            {
              key: "sumAssured",
              value: "2000000",
            },
          ])
        );
      } else {
        dispatch(
          TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
            {
              key: "sumAssured",
              value: "2500000",
            },
          ])
        );
      }
    }

    dispatch(
      TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
        {
          key: "coverageUpto",
          value: `${Number(calculateAge(age_value)) + 5}`,
        },
        {
          key: "payUpto",
          value: `${Number(calculateAge(age_value)) + 5}`,
        },
      ])
    );
  };

  const Update_details = (params: any) => {
    const onSuccess = (res: any) => {
      const error = res.results.error;
      if (error) {
        const message = res.results.message;
        setQuoteFields({
          annualIncome: {
            value: `${ADD_FORM.annualIncome.value}`,
            warning: false,
          },
          doyouSmoke: { value: ADD_FORM.smoke, warning: false },
          gender: ADD_FORM.gender,
          dob: {
            value: ADD_FORM.age.value,
            warning: false,
          },
          pinCode: { value: ADD_FORM.pincode.value, warning: false },
        });
        toast.error(`${message}`);
      } else {
        const response = res.results.response;
        update_data(quoteFields.dob.value);
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...Term,
            ADD_FORM: {
              age: quoteFields.dob,
              annualIncome: quoteFields.annualIncome,
              gender: quoteFields.gender,
              mobile: ADD_FORM.mobile,
              email: ADD_FORM.mobile,
              name: ADD_FORM.name,
              pincode: quoteFields.pinCode,
              smoke: quoteFields.doyouSmoke.value,
            },
            ADD_FORM_RESPONSE: {
              ...Term.ADD_FORM_RESPONSE,
              city: response.city,
              state: response.state,
              sum_assured:
                Number(calculateAge(quoteFields.dob.value)) > 50
                  ? "2000000"
                  : "2500000",
            },
            PROPOSER_DATA: {
              ...Term.PROPOSER_DATA,
              pincode: quoteFields.pinCode,
            },
            QUOTE_FIELDS_DATA: {
              ...Term.QUOTE_FIELDS_DATA,
              sumAssured:
                Number(calculateAge(quoteFields.dob.value)) > 50
                  ? "2000000"
                  : "2500000",
            },
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    // TERM_SERVICES.updateDetails(onSuccess, onError, params);
  };

  const updateMasterState = (attrName: string, value: any) => {
    console.log(attrName, value);
    setQuoteFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pinCode" ? !validatePincode(value) : isEmpty(value),
      },
    }));
  };

  return (
    <>
      {isMobile ? null : ( // /> //   verifyDetails={verifyDetails} //   updateMasterDetails={updateMasterState} //   fieldData={quoteFields} //   setOpen={setOpen} //   open={open} // <MTermModifyDetails
        <TermModifyDetails
          open={open}
          setOpen={setOpen}
          fieldData={quoteFields}
          updateMasterDetails={updateMasterState}
          verifyDetails={verifyDetails}
          annualIncData={annualIncData}
        />
      )}
    </>
  );
}

export default TermModifyDetailsContainer;
