import { Box, Slider } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useEffect, useState } from "react";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import "../../../../SCSS/ProductForms.scss";
import BorderCheckBox from "../../../../Component/InputFields/BorderCheckBox/BorderCheckBox";
import { COLORS } from "../../../../SupportingFiles/colors";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DatePickerInput from "../../../../Component/InputFields/DatePicker/DatePicker";
import Modal from "@mui/material/Modal";
import "./../../../../SCSS/ModalPopup.scss";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import BorderRadioButton from "../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import {
  formatNumberToLakhOrCrores,
  isEmpty,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import { TNonTermAddForm } from "../../../../types/NonTerm/TNonTermAddForm";
import Loader from "../../../../Component/Global/Loader/Loader";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import moment from "moment";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { AddNonTermDTO } from "../../../../DTO/NonTermDTO/AddNonTermDTO";
import { useNavigate } from "react-router-dom";
import { NON_TERM_ROUTES } from "../../../../Router/Path/NonTermRoute";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";

function MNonTermForm({
  open,
  setOpen,
  loader,
  updateMasterState,
  validateNonTermAddForm,
  validateNonTermForm,
  validateInvestmentAmount,
  handleInputChange,
  handleSliderChange,
  inputValue,
  sliderValue,
  page_status,
  setPageStatus,
  minInvestmentAmount,
  maxInvestmentAmount,
}: {
  open: any;
  setOpen: Function;
  loader: boolean;
  updateMasterState: Function;
  validateNonTermAddForm: Function;
  validateNonTermForm: Function;
  validateInvestmentAmount: any;
  handleInputChange: any;
  handleSliderChange: any;
  inputValue: number;
  sliderValue: number;
  page_status: number;
  setPageStatus: Function;
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ADD_FORM, DROPDOWN_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.NonTerm
  );
  const { NonTerm } = useAppSelector((state) => state);

  const steps = ["Investment details", "Financial details"];

  const [radiosData, setRadiosData] = useState({
    financialDetails: "Retirement",
    riskProfile: "Aggressive",
  });

  return (
    <>
      <Modal open={open}>
        <Box className="modalFormsMobile">
          <Box className="modalContent">
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid
                  xs={12}
                  display={"flex"}
                  gap={"12px"}
                  alignItems={"center"}
                >
                  {page_status === 0 ? (
                    <Grid xs={6}>
                      <BackLeft onClick={() => setOpen(false)} />
                    </Grid>
                  ) : null}

                  {page_status === 1 ? (
                    <Grid xs={6}>
                      <BackLeft
                        onClick={() => {
                          setPageStatus(0);
                        }}
                      />
                    </Grid>
                  ) : null}
                  <img
                    src="./images/jio_insurance_logo.svg"
                    alt="jio-insurance-logo"
                    style={{ height: "12px" }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="scrollable-area">
              {/* first step */}
              {page_status === 0 ? (
                <Grid
                  container
                  className="row"
                  columnSpacing={3}
                  marginBottom={"60px"}
                >
                  <Grid xs={12} className="mform_steps pt-1 pl-0 pr-0 mb-8">
                    <Stepper activeStep={page_status} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid xs={12} textAlign="center" marginBottom={"24px"}>
                    <h4 style={{ fontWeight: "900" }}>
                      Select investment details
                    </h4>
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <Box
                      sx={{
                        backgroundColor: COLORS.lightbg,
                        padding: "12px",
                        borderRadius: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "24px",
                          alignItems: "center",
                          marginBottom: "4px",
                        }}
                      >
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            width: "100%",
                          }}
                        >
                          Want to invest (₹)
                        </p>{" "}
                        <input
                          placeholder="0"
                          value={inputValue}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid",
                            borderColor: COLORS.lightgrey,
                            backgroundColor: "transparent",
                            height: "32px",
                            padding: "4px 8px",
                            textAlign: "right",
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                          }}
                          maxLength={7}
                          onChange={handleInputChange}
                        />
                      </Box>
                      {validateInvestmentAmount ? (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#EA4559",
                            fontSize: "11px",
                          }}
                        >
                          Enter {minInvestmentAmount} -{" "}
                          {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                        </span>
                      ) : null}
                      {ADD_FORM.investAmount.warning ? (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#EA4559",
                            fontSize: "11px",
                          }}
                        >
                          Enter Investment Amount
                        </span>
                      ) : null}
                      <Slider
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        value={sliderValue}
                        onChange={handleSliderChange}
                        min={minInvestmentAmount}
                        step={1}
                        max={maxInvestmentAmount}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            fontSize: "12px",
                          }}
                        >
                          Min: ₹ {minInvestmentAmount}
                        </p>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            fontSize: "12px",
                          }}
                        >
                          Max: {formatNumberToLakhOrCrores(maxInvestmentAmount)}
                        </p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <SearchSelectDropdown
                      title="Investment Mode"
                      value={`${ADD_FORM.investmentMode.value}`}
                      value_update={updateMasterState}
                      attrName={["investmentMode"]}
                      options={DROPDOWN_DATA.investmentMode}
                      warn_status={ADD_FORM.investmentMode.warning}
                      error_message="Select Investment Mode"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <SearchSelectDropdown
                      title="Annual Income"
                      value={ADD_FORM.annualIncome.value}
                      value_update={updateMasterState}
                      attrName={["annualIncome"]}
                      options={DROPDOWN_DATA?.ANNUAL_INCOME}
                      warn_status={ADD_FORM.annualIncome.warning}
                      error_message="Select Annual Income"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <SearchSelectDropdown
                      title="Lifestage"
                      value={ADD_FORM.lifeStage.value}
                      value_update={updateMasterState}
                      attrName={["lifeStage"]}
                      options={DROPDOWN_DATA.LIFE_STAGE}
                      warn_status={ADD_FORM.lifeStage.warning}
                      error_message="Select lifestage"
                    />
                  </Grid>
                  <Grid xs={12} className="mb-6">
                    <TextInputField
                      title="Enter Your City Pincode"
                      placeholder="XXXXXX"
                      value={ADD_FORM.pincode.value}
                      value_update={(a: any, v: any) => {
                        dispatch(
                          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
                            ...NonTerm.ADD_FORM,
                            pincode: {
                              value: v,
                              warning: !validatePincode(v),
                            },
                          })
                        );
                      }}
                      attrName={["pincode"]}
                      warn_status={ADD_FORM.pincode.warning}
                      error_message={
                        isEmpty(ADD_FORM.pincode.value)
                          ? "Enter pincode"
                          : "Enter valid pincode"
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}

              {/* second step */}
              {page_status === 1 ? (
                <Grid
                  container
                  className="row"
                  columnSpacing={3}
                  marginBottom={"60px"}
                >
                  <Grid xs={12} className="mform_steps pt-1 pl-0 pr-0 mb-8">
                    <Stepper activeStep={page_status} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid xs={12} textAlign="center" marginBottom={"24px"}>
                    <h3>Select financial details</h3>
                  </Grid>
                  <Grid xs={12} marginBottom={"24px"}>
                    <h6
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        marginBottom: "8px",
                      }}
                    >
                      Financial and Family Goals
                    </h6>
                    <BorderRadioButton
                      options={DROPDOWN_DATA.GOALS}
                      value={ADD_FORM.goals.value}
                      attrName={"goals"}
                      count={"AUTO"}
                      defaultValue={"Retirement"}
                      onChange={updateMasterState}
                    />
                  </Grid>
                  <Grid xs={12} marginBottom={"24px"}>
                    <h6
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        marginBottom: "8px",
                      }}
                    >
                      Risk Profile
                    </h6>
                    <BorderRadioButton
                      options={DROPDOWN_DATA.RISK_PROFILE}
                      value={ADD_FORM.riskProfile.value}
                      attrName={"riskProfile"}
                      count={"2"}
                      defaultValue={"Aggressive"}
                      onChange={updateMasterState}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Box>

            <Box className="footerbar">
              {page_status === 0 ? (
                <JDSButtons
                  text={"Next"}
                  variant={"text"}
                  className={"primaryBtn large"}
                  onClick={() => validateNonTermAddForm()}
                />
              ) : (
                <JDSButtons
                  text={loader ? <Loader size="small" /> : "Submit"}
                  variant={"text"}
                  className={"primaryBtn large"}
                  onClick={() => validateNonTermForm()}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MNonTermForm;
