import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import BorderRadioButton from "../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import DatePicker from "../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import RadioText from "../../../../Component/InputFields/RadioText/RadioText";
import SearchAutocomplete from "../../../../Component/InputFields/SearchAutocomplete/SearchAutocomplete";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import "../../../../SCSS/ProductForms.scss";
import { CarSlice } from "../../../../Store/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";

function CarForm({
  pageStatus,
  backFunction,
  updateMasterState,
  validateForm,
  loader,
  open,
  setOpen,
}: {
  pageStatus: number;
  backFunction: Function;
  updateMasterState: Function;
  validateForm: Function;
  loader: boolean;
  open: boolean;
  setOpen: Function;
}) {
  const dispatch = useAppDispatch();
  const { DROPDOWN_DATA, ADD_FORM, REDIRECTED } = useAppSelector(
    (state) => state.Car
  );
  const new_steps = ["RTO Details", "Car Details", "Additional Details"];
  const rollover_steps = ["Car Brand", "Car Details", "Additional Details"];
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    if (ADD_FORM.business_type === "New") {
      if (pageStatus === 1) {
        setActiveStep(0);
      } else if (
        pageStatus === 2 ||
        pageStatus === 3 ||
        pageStatus === 4 ||
        pageStatus === 5
      ) {
        setActiveStep(1);
      } else if (pageStatus === 8) {
        setActiveStep(2);
      }
    } else {
      if (pageStatus === 2) {
        setActiveStep(0);
      } else if (pageStatus === 3 || pageStatus === 4 || pageStatus === 5) {
        setActiveStep(1);
      } else if (pageStatus === 6 || pageStatus === 7) {
        setActiveStep(2);
      }
    }
  }, [pageStatus]);
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box
          sx={{
            transform: "translate(0,0)",
            transition: "trapnsform 0.3s",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}>
          <Box className="modalContent xlWidth">
            <Grid className="topbar" container alignItems="center">
              <Grid xs={6}>
                {pageStatus === 1 ||
                (pageStatus === 2 &&
                  ADD_FORM.business_type !== "New") ? null : (
                  <Box paddingLeft={"12px"}>
                    <BackLeft onClick={() => backFunction()} />
                  </Box>
                )}
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    dispatch(
                      CarSlice.actions.ADD_FORM_DATA({
                        ...ADD_FORM,
                        business_type: "Rollover",
                      })
                    );
                    dispatch(
                      CarSlice.actions.SET_CAR_SLICE(CarSlice.getInitialState())
                    );

                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>
            <Box display="flex" alignItems={"center"} flexDirection={"column"}>
              <Box className="form_steps mb-10" width="783px">
                <Stepper activeStep={activeStep} alternativeLabel>
                  {ADD_FORM.business_type === "New"
                    ? new_steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))
                    : rollover_steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                </Stepper>
              </Box>
              {/* Step Search RTO  in New Case this will be the first step to see thisstep remove display none */}
              {pageStatus === 1 ? (
                <Box width="616px" minHeight={"300px"}>
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={2}
                    alignItems="flex-start">
                    <Grid xs={12} textAlign="center">
                      <h2 style={{ fontSize: "27px" }} className="mb-2">
                        Select RTO or city for your car
                      </h2>
                      <SearchAutocomplete
                        placeholder="Search RTO or City (eg. RJ20 or Kota)"
                        value={`${ADD_FORM.rto.value}`}
                        attrName={"rto"}
                        value_update={updateMasterState}
                        options={DROPDOWN_DATA.RTO_LIST}
                        warn_status={ADD_FORM.rto.warning}
                        error_message={"Select RTO"}
                      />
                    </Grid>
                    {/* <Grid xs={12}>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontWeight: "bold",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                      <span
                        style={{
                          minWidth: "94px",
                        }}
                      >
                        Popular Cities
                      </span>
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <ul className="rtoCity">
                      <li>
                        <Box className="city" sx={{ opacity: "0.5" }}>
                          Banglore
                        </Box>
                        <Box className="city active">Delhi</Box>
                        <Box className="city" sx={{ opacity: "0.5" }}>
                          Mumbai
                        </Box>
                        <Box className="city" sx={{ opacity: "0.5" }}>
                          Pune
                        </Box>
                        <Box
                          sx={{
                            clear: "both",
                            minWidth: "100%",
                            height: "0px",
                          }}
                        ></Box>
                        <Box
                          minWidth="100%"
                          display="flex"
                          flexWrap="wrap"
                          gap="16px"
                          paddingTop="16px"
                        >
                          <Box className="rtocode">DL01</Box>
                          <Box className="rtocode">DL02</Box>
                          <Box className="rtocode">DL03</Box>
                          <Box className="rtocode">DL04</Box>
                          <Box className="rtocode">DL05</Box>
                          <Box className="rtocode">DL06</Box>
                          <Box className="rtocode">DL07</Box>
                          <Box className="rtocode">DL08</Box>
                        </Box>
                      </li>
                      <li>
                        <Box className="city">Agra</Box>
                        <Box className="city">Kota</Box>
                        <Box className="city">Noida</Box>
                        <Box className="city">Patna</Box>
                        <Box
                          sx={{
                            clear: "both",
                            minWidth: "100%",
                            height: "0px",
                          }}
                        ></Box>
                      </li>
                      <li>
                        <Box className="city">Ajmer</Box>
                        <Box className="city">Lucknow</Box>
                        <Box className="city">Chandigarh</Box>
                        <Box className="city">Hyderabad</Box>
                        <Box
                          sx={{
                            clear: "both",
                            minWidth: "100%",
                            height: "0px",
                          }}
                        ></Box>
                      </li>
                    </ul>
                  </Grid>
                  <Grid xs={12}>
                    <JDSButtons
                      className="secondaryBtn"
                      text="Others"
                      variant="outlined"
                    />
                  </Grid> */}
                  </Grid>
                </Box>
              ) : null}

              {pageStatus === 2 ? (
                <>
                  {/* Step Search brand */}
                  <Box width="616px" minHeight={"300px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start">
                      <Grid xs={12} textAlign="center">
                        <h2
                          style={{ fontSize: "27px", lineHeight: "32px" }}
                          className="mb-2">
                          Select your car's brand
                        </h2>
                        <SearchAutocomplete
                          placeholder="Search car brand"
                          value={`${ADD_FORM.make.value}`}
                          attrName={"make"}
                          value_update={updateMasterState}
                          options={DROPDOWN_DATA.MAKE_LIST}
                          warn_status={ADD_FORM.make.warning}
                          error_message={"Select Make"}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            fontWeight: "bold",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            width: "100%",
                          }}>
                          <span
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "1px",
                              width: "100%",
                              borderRadius: "4px",
                            }}></span>
                          <span
                            style={{
                              minWidth: "152px",
                            }}>
                            Popular companies
                          </span>
                          <span
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "1px",
                              width: "100%",
                              borderRadius: "4px",
                            }}></span>
                        </p>
                      </Grid>
                      <Grid xs={12}>
                        <ul className="mfgLogo">
                          {DROPDOWN_DATA.POPULAR_MAKE_LIST.map(
                            (data, index) => (
                              <li
                                className={`${data.value} ${
                                  data.label === ADD_FORM.make.value
                                    ? "active"
                                    : ""
                                } `}
                                onClick={() =>
                                  updateMasterState("make", data.label)
                                }>
                                {data.label}
                              </li>
                            )
                          )}
                        </ul>
                      </Grid>
                      {/* <Grid xs={12}>
                        <JDSButtons
                          className="secondaryBtn"
                          text="Others"
                          variant="outlined"
                          onClick={() => {}}
                        />
                      </Grid> */}
                    </Grid>
                  </Box>
                </>
              ) : pageStatus === 3 ? (
                <>
                  {/* Step Search model */}
                  <Box width="616px" minHeight={"300px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start">
                      <Grid xs={12} textAlign="center">
                        <h2
                          style={{ fontSize: "27px", lineHeight: "32px" }}
                          className="mb-2">
                          Select {ADD_FORM.make.value} model
                        </h2>
                        <SearchAutocomplete
                          placeholder={`Select ${ADD_FORM.make.value} model`}
                          value={`${ADD_FORM.model.value}`}
                          attrName={"model"}
                          value_update={updateMasterState}
                          options={DROPDOWN_DATA.MODEL_LIST}
                          warn_status={ADD_FORM.model.warning}
                          error_message={"Select Model"}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            fontWeight: "bold",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            width: "100%",
                          }}>
                          <span
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "1px",
                              width: "100%",
                              borderRadius: "4px",
                            }}></span>
                          <span
                            style={{
                              minWidth: "112px",
                            }}>
                            Popular model
                          </span>
                          <span
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "1px",
                              width: "100%",
                              borderRadius: "4px",
                            }}></span>
                        </p>
                      </Grid>
                      <Grid
                        xs={12}
                        sx={{
                          maxHeight: "236px",
                          overflowY: "auto",
                          ".MuiFormGroup-root": {
                            justifyContent: "center",
                            gap: "16px",
                          },
                        }}>
                        <RadioText
                          count="AUTO"
                          orientation="row"
                          variant="large"
                          attrName="model"
                          options={DROPDOWN_DATA.MODEL_LIST.slice(0, 15)}
                          onChange={updateMasterState}
                          value={`${ADD_FORM.model.value}`}
                        />
                        {/* <JDSButtons
                          className="secondaryBtn mt-4"
                          text="Others"
                          variant="outlined"
                          onClick={() => {}}
                        /> */}
                      </Grid>
                      <Grid xs={12}></Grid>
                    </Grid>
                  </Box>
                </>
              ) : pageStatus === 4 ? (
                <>
                  {/* Step Fuel type */}
                  <Box width="616px" minHeight={"300px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start">
                      <Grid xs={12} textAlign="center">
                        <h2
                          style={{ fontSize: "27px", lineHeight: "32px" }}
                          className="mb-2">
                          Select {ADD_FORM.model.value} fuel type
                        </h2>
                      </Grid>
                      <Grid
                        xs={12}
                        sx={{
                          ".MuiFormGroup-root": {
                            justifyContent: "center",
                            gap: "16px",
                            ".MuiFormControlLabel-root": {
                              minWidth: "120px",
                            },
                          },
                        }}>
                        <BorderRadioButton
                          count="AUTO"
                          attrName="fuel_type"
                          onChange={updateMasterState}
                          value={ADD_FORM.fuel_type.value}
                          options={DROPDOWN_DATA.FUEL_TYPE_LIST}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : pageStatus === 5 ? (
                <>
                  {/* Step Search variant */}
                  <Box width="616px" minHeight={"400px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start">
                      <Grid xs={12} textAlign="center">
                        <h2
                          style={{ fontSize: "27px", lineHeight: "32px" }}
                          className="mb-2">
                          Select {ADD_FORM.model.value} variant
                        </h2>
                        <SearchAutocomplete
                          placeholder={`Select ${ADD_FORM.model.value} Variant`}
                          value={`${ADD_FORM.variant_cc.value}`}
                          attrName={"variant_cc"}
                          value_update={updateMasterState}
                          options={DROPDOWN_DATA.VARIANT_LIST}
                          warn_status={ADD_FORM.variant_cc.warning}
                          error_message={"Select Variant"}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <p
                          style={{
                            color: COLORS.lightgrey,
                            fontWeight: "bold",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            width: "100%",
                          }}>
                          <span
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "1px",
                              width: "100%",
                              borderRadius: "4px",
                            }}></span>
                          <span
                            style={{
                              minWidth: "80px",
                            }}>
                            All Variant
                          </span>
                          <span
                            style={{
                              backgroundColor: "#E0E0E0",
                              height: "1px",
                              width: "100%",
                              borderRadius: "4px",
                            }}></span>
                        </p>
                      </Grid>
                      <Grid
                        xs={12}
                        sx={{
                          maxHeight: "236px",
                          overflowY: "auto",
                          ".MuiFormGroup-root": {
                            justifyContent: "center",
                            gap: "16px",
                          },
                        }}>
                        <RadioText
                          count="AUTO"
                          orientation="row"
                          variant="large"
                          attrName="variant_cc"
                          options={DROPDOWN_DATA.VARIANT_LIST}
                          onChange={updateMasterState}
                          value={`${ADD_FORM.variant_cc.value}`}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : pageStatus === 6 ? (
                <>
                  {/* Step Previous insurance details */}
                  <Box width="616px" minHeight={"300px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start"
                      justifyContent={"center"}>
                      <Grid xs={12} textAlign="center">
                        <h2 style={{ fontSize: "27px" }} className="mb-2">
                          Previous insurance details
                        </h2>
                      </Grid>
                      <Grid xs={6} className="mb-3">
                        <DatePicker
                          title={"Registration Date"}
                          value={ADD_FORM.reg_date.value}
                          attrName={"reg_date"}
                          onChange={updateMasterState}
                          warn_status={ADD_FORM.reg_date.warning}
                          error_message={"Select Registration Date"}
                          min_date={15 * 12}
                          max_date={3}
                          date_validation_type="MONTHS"
                          default_date={subDays(new Date(), 90)}
                        />
                      </Grid>
                      <Grid xs={6} className="mb-3">
                        <DatePicker
                          title={"Policy Expiry"}
                          value={ADD_FORM.policy_expiry_date.value}
                          attrName={"policy_expiry_date"}
                          onChange={updateMasterState}
                          warn_status={ADD_FORM.policy_expiry_date.warning}
                          error_message={"Select Policy Expiry Date"}
                          date_validation_type="DAYS"
                          min_date={
                            calculateAgeInDays(`${ADD_FORM.reg_date.value}`) -
                            90
                          }
                          max_date={
                            calculateAgeInDays(
                              FORMAT_DD_MM_YYYY(`${new Date()}`)
                            ) - 60
                          }
                        />
                      </Grid>
                      <Grid xs={6} className="mb-3">
                        <SearchSelectDropdown
                          title="Previous Insurer"
                          value={`${ADD_FORM.previous_insurer.value}`}
                          attrName={"previous_insurer"}
                          value_update={updateMasterState}
                          options={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                          warn_status={ADD_FORM.previous_insurer.warning}
                          error_message={"Select Previous Insurer"}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      className="mt-6"
                      sx={{
                        maxWidth: "312px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}>
                      <JDSButtons
                        className="primaryBtn large"
                        text="Next"
                        variant="text"
                        onClick={validateForm}
                      />
                    </Box>
                  </Box>
                </>
              ) : pageStatus === 7 ? (
                <>
                  {/* Step Claim details */}
                  <Box width="616px" minHeight={"300px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start">
                      <Grid xs={12} textAlign="center">
                        <h2 style={{ fontSize: "27px" }} className="mb-2">
                          Claim history
                        </h2>
                      </Grid>
                      <Grid
                        xs={12}
                        textAlign="center"
                        sx={{
                          ".MuiFormControl-root": {
                            alignItems: "center",
                            ".MuiFormControlLabel-root": {
                              minWidth: "120px",
                            },
                          },
                          ".MuiFormGroup-root": {
                            gap: "16px",
                          },
                        }}>
                        <h5 className="mb-4" style={{ fontWeight: "bold" }}>
                          Did you make a claim in your existing policy?
                        </h5>
                        <Box
                          sx={{
                            ".MuiFormGroup-root": {
                              justifyContent: "center!important",
                            },
                          }}>
                          <BorderRadioButton
                            count="AUTO"
                            attrName="claimed"
                            onChange={updateMasterState}
                            value={ADD_FORM.claimed}
                            options={[
                              {
                                value: "Yes",
                                label: "Yes",
                              },
                              { value: "No", label: "No" },
                            ]}
                          />
                        </Box>
                      </Grid>
                      {ADD_FORM.claimed === "Yes" ? null : (
                        <Grid
                          xs={12}
                          textAlign="center"
                          sx={{
                            ".MuiFormGroup-root": {
                              justifyContent: "center",
                              gap: "16px",
                            },
                          }}>
                          <h5 className="my-2" style={{ fontWeight: "bold" }}>
                            Select your existing NCB
                          </h5>
                          <p
                            className="mb-4"
                            style={{
                              fontSize: "12px",
                              lineHeight: "14px",
                              color: COLORS.lightgrey,
                            }}>
                            NCB is a reward given by insurance companies to
                            policyholders for not making any claims during the
                            policy term. It's a discount on the premium of the
                            next year's policy and serves as an incentive for
                            safe driving and maintaining a claim-free record.
                          </p>
                          <RadioText
                            count="AUTO"
                            orientation="row"
                            variant="large"
                            attrName="prev_ncb"
                            options={DROPDOWN_DATA.NCB}
                            onChange={updateMasterState}
                            value={ADD_FORM.prev_ncb}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Box
                      className="mt-6"
                      sx={{
                        maxWidth: "312px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}>
                      <JDSButtons
                        className="primaryBtn large"
                        text="View Quotes"
                        variant="text"
                        onClick={validateForm}
                        loader={loader}
                      />
                    </Box>
                  </Box>
                </>
              ) : pageStatus === 8 ? (
                <>
                  {/* Step Claim details */}
                  <Box width="616px" minHeight={"300px"}>
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                      alignItems="flex-start">
                      <Grid xs={12} textAlign="center">
                        <h2 style={{ fontSize: "27px" }} className="mb-2">
                          Contact details
                        </h2>
                      </Grid>
                      <Grid
                        xs={12}
                        textAlign="center"
                        sx={{
                          ".MuiFormGroup-root": {
                            justifyContent: "center",
                            gap: "16px",
                          },
                        }}>
                        <Box
                          className="mobileNumber"
                          maxWidth={"350px"}
                          margin={"auto"}>
                          <Box
                            position="absolute"
                            left="12px"
                            bottom="11px"
                            zIndex="2"
                            fontSize="18px"
                            fontWeight="bold"
                            sx={{ color: COLORS.lightgrey }}>
                            +91
                          </Box>
                          <TextInputField
                            title="Enter mobile number"
                            placeholder="XXXXXXXXXX"
                            validation_type="NUMBER"
                            value={ADD_FORM.mobile.value}
                            attrName={"mobile"}
                            value_update={(a: any, v: any) => {
                              dispatch(
                                CarSlice.actions.UPDATE_FORM_DATA([
                                  {
                                    key: "mobile",
                                    value: {
                                      value: v,
                                      warning: !validateMobileNumber(v),
                                    },
                                  },
                                ])
                              );
                            }}
                            warn_status={ADD_FORM.mobile.warning}
                            error_message={
                              isEmpty(ADD_FORM.mobile.value)
                                ? "Enter Mobile Number"
                                : "Enter valid mobile number"
                            }
                            max_length={10}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      className="mt-6"
                      sx={{
                        maxWidth: "312px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}>
                      <JDSButtons
                        loader={loader}
                        className="primaryBtn large"
                        text="Submit"
                        variant="text"
                        onClick={validateForm}
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default CarForm;
