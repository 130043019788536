import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AboutUsContainer from "../../../Container/Common/AboutUsContainer";
import CareerContainer from "../../../Container/Common/CareerContainer";
import ContactUsContainer from "../../../Container/Common/ContactUsContainer";
import HomeContainer from "../../../Container/Home/HomeContainer";
import NonTermQuoteContainer from "../../../Container/Non-term/Quote/NonTermQuoteContainer";
import TermQuotesContainer from "../../../Container/Term/Quote/TermQuotesContainer";
import BlogAllYouNeed from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogAllYouNeed";
import BlogBenefitsOfCar from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogBenefitsOfCar";
import BlogCorporateHealth from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogCorporateHealth";
import BlogEverythingYouNeed from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogEverythingYouNeed";
import BlogFactorToBeConsd from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogFactorToBeConsd";
import BlogHealthInsurance from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogHealthInsurance";
import BlogMakingUnderstanding from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogMakingUnderstanding";
import BlogULIP from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogULIP";
import BlogUnderstandingDeductible from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogUnderstandingDeductible";
import BlogUnlockTheBenefits from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogUnlockTheBenefits";
import BlogWhyDoesComp from "../../../Page/Desktop/Blogs/BlogDetailPage/BlogWhyDoesComp";
import BlogsPage from "../../../Page/Desktop/Blogs/BlogsPage";
import CarProductPage from "../../../Page/Desktop/CarProductPage/CarProductPage";
import Disclaimer from "../../../Page/Desktop/Disclaimer/Disclaimer";
import GrievanceRedressal from "../../../Page/Desktop/GrievanceRedressal/GrievanceRedressal";
import HealthProductPage from "../../../Page/Desktop/HealthProductPage/HealthProductPage";
import LandingCarProductPage from "../../../Page/Desktop/LandingCarProductPage/LandingCarProductPage";
import LandingHealthProductPage from "../../../Page/Desktop/LandingHealthProductPage/LandingHealthProductPage";
import LandingTWProductPage from "../../../Page/Desktop/LandingTWProductPage/LandingTWProductPage";
import LandingTermProductPage from "../../../Page/Desktop/LandingTermProductPage/LandingTermProductPage";
import NonTermProductPage from "../../../Page/Desktop/NonTermProductPage/NonTermProductPage";
import PrivacyPolicy from "../../../Page/Desktop/PrivacyPolicy/PrivacyPolicy";
import TWProductPage from "../../../Page/Desktop/TWProductPage/TWProductPage";
import TermProductPage from "../../../Page/Desktop/TermProductPage/TermProductPage";
import TermsandConditions from "../../../Page/Desktop/TermsandConditions/TermsandConditions";
import { COMMON_ROUTES } from "../../Path/CommonRoutes";
import { NON_TERM_ROUTES } from "../../Path/NonTermRoute";
import { TERM_ROUTES } from "../../Path/TermRoutes";
import IRDAIPage from "../../../Page/Desktop/IRDAIPage/IRDAIPage";
import PageNotFound from "../../../Page/Desktop/PageNotFound/PageNotFound";

function CommonRoutes() {
  const { pathname } = useLocation();

  const isNonTermRoute = Object.values(NON_TERM_ROUTES).some((route) => pathname.includes(route));


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<AboutUsContainer />} />
        <Route
          path={COMMON_ROUTES.CONTACT_US}
          element={<ContactUsContainer />}
        />
        <Route path={COMMON_ROUTES.CAREER} element={<CareerContainer />} />
        <Route
          path={COMMON_ROUTES.TERM_AND_CONDTION}
          element={<TermsandConditions />}
        />
        <Route path={COMMON_ROUTES.DISCLAIMER} element={<Disclaimer />} />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />
        <Route
          path={COMMON_ROUTES.REFUND_AND_CANCELLATION}
          element={<GrievanceRedressal />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarProductPage />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<TWProductPage />} />
        <Route
          path={COMMON_ROUTES.LANDING_PRODUCT_CAR}
          element={<LandingCarProductPage />}
        />
        <Route
          path={COMMON_ROUTES.LANDING_PRODUCT_TW}
          element={<LandingTWProductPage />}
        />
        <Route
          path={COMMON_ROUTES.LANDING_PRODUCT_HEALTH}
          element={<LandingHealthProductPage />}
        />
        <Route
          path={COMMON_ROUTES.LANDING_PRODUCT_TERM}
          element={<LandingTermProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_TERM}
          element={<TermProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_NON_TERM}
          element={<NonTermProductPage />}
        />
        <Route path={TERM_ROUTES.QUOTE} element={<TermQuotesContainer />} />
        <Route path={COMMON_ROUTES.BLOGS} element={<BlogsPage />} />
        <Route
          path={COMMON_ROUTES.BLOG_FACTORS_TO_BE_CONSD}
          element={<BlogFactorToBeConsd />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_UNLOCK_THE_BENEFITS}
          element={<BlogUnlockTheBenefits />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_EVERYTHING_YOU_NEED}
          element={<BlogEverythingYouNeed />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_ALL_YOU_NEED}
          element={<BlogAllYouNeed />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_WHY_DOES_COMP}
          element={<BlogWhyDoesComp />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_MAKING_UNDERSTANDING}
          element={<BlogMakingUnderstanding />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_UNDERSTANDING_DEDUCTIBLE}
          element={<BlogUnderstandingDeductible />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_HEALTH_INSURANCE}
          element={<BlogHealthInsurance />}
        />
        <Route
          path={COMMON_ROUTES.BLOG_CORPORATE_HEALTH}
          element={<BlogCorporateHealth />}
        />
        <Route path={COMMON_ROUTES.BLOG_ULIP} element={<BlogULIP />} />
        <Route
          path={COMMON_ROUTES.BLOG_BENEFITS_OF_CAR}
          element={<BlogBenefitsOfCar />}
        />
        <Route
          path={NON_TERM_ROUTES.QUOTE}
          element={<NonTermQuoteContainer />}
        />
        <Route path={COMMON_ROUTES.IRDAI} element={<IRDAIPage />} />
        {/* If someone hit old url then redirect its to new one */}
        <Route
          path={COMMON_ROUTES.OLD_REFUND_AND_CANCELLATION}
          element={
            <Navigate to={COMMON_ROUTES.REFUND_AND_CANCELLATION} replace />
          }
        />
        <Route
          path={COMMON_ROUTES.IRDAI_OLD}
          element={<Navigate to={COMMON_ROUTES.IRDAI} replace />}
        />
        {/* 404 Page Route */}
        {!isNonTermRoute && <Route path="*" element={<PageNotFound />} />}
      </Routes>
    </div>
  );
}

export default CommonRoutes;
