import { Box, Button, Slider } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import BorderRadioButton from "../../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import DatePicker from "../../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import "../../../../../SCSS/ModalPopup.scss";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import SelectDropdown from "../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../../../Component/InputFields/TextInputField/TextInputField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

const InvestmentReturnCalulator = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const [quoteFields, setQuoteFields] = useState<{
    investmentDuration: { value: any; warn_status: boolean };
    interestRate: { value: any; warn_status: boolean };
  }>({
    investmentDuration: { value: "", warn_status: false },
    interestRate: { value: "", warn_status: false },
  });
  const [calculatedAmount, setCalculatedAmount] = useState<any>(0);
  const [paymentMode, setPaymentMode] = useState("Monthly");
  const handlepayementMode = (attrName: string, value: string) => {
    setPaymentMode(value);
  };

  const handleFieldsUpdate = (attrName: string, value: string) => {
    setQuoteFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  const investmentDuration_data: { label: string; value: string }[] = [];
  for (let i = 1; i <= 20; i++) {
    investmentDuration_data.push({
      label: `${i} Year${i > 1 ? "s" : ""}`,
      value: `${i} Year${i > 1 ? "s" : ""}`,
    });
  }
  const [validateInvestmentAmount, setValidateInvestmentAmount] =
    useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [inputValue, setInputValue] = useState<any>(0);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setSliderValue(newValue);
      setInputValue(newValue.toString());
    }
  };
  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    // Validate input value (optional)
    if (!isNaN(newValue) && newValue !== "") {
      setSliderValue(parseInt(newValue));
    }
  };
  useEffect(() => {
    if (inputValue === 0) {
      setValidateInvestmentAmount(false);
    } else if (inputValue < 500) {
      setValidateInvestmentAmount(true);
    } else if (inputValue > 10000000) {
      setValidateInvestmentAmount(true);
    } else {
      setValidateInvestmentAmount(false);
    }
  }, [inputValue]);

  const calculateInvestmentReturn = () => {
    const { interestRate, investmentDuration } = quoteFields;
    setQuoteFields((prevState) => ({
      ...prevState,
      interestRate: {
        ...prevState.interestRate,
        warn_status: isEmpty(interestRate.value),
      },
      investmentDuration: {
        ...prevState.investmentDuration,
        warn_status: isEmpty(investmentDuration.value),
      },
    }));
    if (inputValue === 0) {
      setValidateInvestmentAmount(true);
    } else if (
      !validateInvestmentAmount &&
      !interestRate.warn_status &&
      !investmentDuration.warn_status
    ) {
      const interest = interestRate.value / 100;
      let base = 0;
      let term = 0;
      let interestRateValue = 0;
      let durationOfInvestment = 0;

      if (investmentDuration.value !== undefined) {
        durationOfInvestment = parseInt(
          investmentDuration.value.toString().replace(" Years", "")
        );
      }

      let si = 0;

      if (paymentMode === "Yearly") {
        term = durationOfInvestment * 1;
        base = 1 + interest;
        interestRateValue = interest;
      } else {
        term = durationOfInvestment * 12;
        base = 1 + interest / 12;
        interestRateValue = interest / 12;
      }

      si =
        inputValue *
        ((Math.pow(base, term) - 1) / interestRateValue) *
        (1 + interestRateValue);

      // Update the calculated amount state
      setCalculatedAmount(si.toFixed(2));
    }
  };

  const resetErrorMessage = () => {
    setValidateInvestmentAmount(false);
    setSliderValue(0);
    setInputValue(0);
    setCalculatedAmount(0);
    setQuoteFields((prevState) => ({
      ...prevState,
      interestRate: {
        value: "",
        warn_status: false,
      },
      investmentDuration: {
        value: "",
        warn_status: false,
      },
    }));
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box
          sx={{
            transform: "translate(0,0)",
            transition: "trapnsform 0.3s",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="modalContent xsWidth">
            <Grid className="topbar" container alignItems="center">
              <Grid xs={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                    resetErrorMessage();
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignContent="flex-start">
              <Grid xs={12}>
                <h3>Investment Return Calculator</h3>
              </Grid>
              <Grid xs={12}>
                <Box
                  sx={{
                    backgroundColor: COLORS.lightbg,
                    padding: "12px",
                    borderRadius: "16px",
                    marginTop: "12px",
                    margin: "4px 8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "24px",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        width: "100%",
                      }}
                    >
                      Want to invest (₹)
                    </p>{" "}
                    <input
                      placeholder="0"
                      value={inputValue}
                      style={{
                        borderRadius: "8px",
                        border: "1px solid",
                        borderColor: COLORS.lightgrey,
                        backgroundColor: "transparent",
                        height: "32px",
                        padding: "4px 8px",
                        textAlign: "right",
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "100%",
                      }}
                      onChange={handleInputChange}
                      maxLength={8}
                    />
                  </Box>
                  {validateInvestmentAmount ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#EA4559",
                        fontSize: "11px",
                      }}
                    >
                      Enter 500 - 1 Crore
                    </span>
                  ) : null}
                  {/* {inputValue === 0 ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#EA4559",
                        fontSize: "11px",
                      }}
                    >
                      Enter Investment amount
                    </span>
                  ) : null} */}
                  <Slider
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    min={0}
                    step={1}
                    max={10000000}
                  />
                </Box>
              </Grid>
              <Grid xs={12}>
                <h5 style={{ marginBottom: "8px" }}>Payment Mode</h5>
                <BorderRadioButton
                  attrName="gender"
                  onChange={handlepayementMode}
                  value={paymentMode}
                  count="2"
                  options={[
                    {
                      label: "Monthly",
                      value: "Monthly",
                    },
                    {
                      label: "Yearly",
                      value: "Yearly",
                    },
                  ]}
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  class_name=""
                  title="Duration of Investment"
                  value={quoteFields.investmentDuration.value}
                  value_update={handleFieldsUpdate}
                  attrName={["investmentDuration"]}
                  data={investmentDuration_data}
                  warn_status={quoteFields.investmentDuration.warn_status}
                  error_message="Select Investment Duration"
                />
              </Grid>
              <Grid xs={12}>
                <TextInputField
                  class_name=""
                  title="Applied interest rate (%)"
                  value={quoteFields.interestRate.value}
                  value_update={handleFieldsUpdate}
                  attrName={["interestRate"]}
                  warn_status={quoteFields.interestRate.warn_status}
                  error_message="Enter interest Rate"
                  validation_type="NUMBER_WITH_DECIMAL"
                  max_length={3}
                />
              </Grid>
              <Grid xs={12} textAlign={"center"}>
                {calculatedAmount == 0 || isNaN(calculatedAmount) ? null : (
                  <p
                    style={{
                      border: `1px solid ${COLORS.primary}`,
                      fontWeight: 700,
                      padding: "12px 24px",
                      borderRadius: "100px",
                      display: "inline-block",
                    }}
                  >
                    You will get ₹ {calculatedAmount}
                  </p>
                )}
              </Grid>
            </Grid>

            <Box
              className="mt-6"
              sx={{
                maxWidth: "336px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <JDSButtons
                className="primaryBtn large mb-4"
                text="Calculate"
                variant="text"
                loader={false}
                onClick={() => calculateInvestmentReturn()}
              />

              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                The calculator is designed solely for illustrative or indicative
                purposes. It does not take into account any taxes, fees,
                expenses, or other charges that may apply.
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default InvestmentReturnCalulator;
