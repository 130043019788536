import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TermQuote from "../../../Page/Desktop/Term/TermQuote/TermQuote";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TSelectedQuote } from "../../../types/TTermSlice";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";

const TermQuotesContainer = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { Term } = useAppSelector((state) => state);
  const { ADD_FORM_RESPONSE, ADD_FORM, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.Term
  );
  const navigate = useNavigate();
  const [quotationResponse, setQuotationResponse] = useState<any>([]);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    dispatch(
      TermSlice.actions.BULK_UPDATE({
        ...Term,
        ADD_FORM_RESPONSE: {
          ...Term.ADD_FORM_RESPONSE,
          sum_assured:
            Number(calculateAge(ADD_FORM.age.value)) > 50
              ? "2000000"
              : "2500000",
        },
        QUOTE_FIELDS_DATA: {
          ...Term.QUOTE_FIELDS_DATA,
          sumAssured:
            Number(calculateAge(ADD_FORM.age.value)) > 50
              ? "2000000"
              : "2500000",
        },
        SELECTED_QUOTE_DATA: {
          ...TermSlice.getInitialState().SELECTED_QUOTE_DATA,
        },
      })
    );
  }, [ADD_FORM.annualIncome]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "sumAssured") {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          // ADD_FORM_RESPONSE: { ...Term.ADD_FORM_RESPONSE, sum_assured: value },
          QUOTE_FIELDS_DATA: { ...Term.QUOTE_FIELDS_DATA, sumAssured: value },
        })
      );

      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "sum_assured",
        details: {
          sum_assured: value,
          frequency: QUOTE_FIELDS_DATA.payMode,
          cover_upto: QUOTE_FIELDS_DATA.coverageUpto,
          cover_pay_upto: QUOTE_FIELDS_DATA.payUpto,
        },
      };
      Update_details(params);
      setloader(true);
    } else if (attrName === "coverageUpto") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          { key: "payUpto", value: value },
          { key: "coverageUpto", value: value },
        ])
      );

      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "cover_upto",
        details: {
          cover_upto: value,
          cover_pay_upto: value,
          frequency: QUOTE_FIELDS_DATA.payMode,
          sum_assured: QUOTE_FIELDS_DATA.sumAssured,
        },
      };
      Update_details(params);
      setloader(true);
    } else if (attrName === "monthlyPayout") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          {
            key: "monthlyPayout",
            value: value,
          },
        ])
      );
      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "monthly_payout",
        details: {
          monthly_payout: value,
          frequency: QUOTE_FIELDS_DATA.payMode,
          cover_upto: ADD_FORM_RESPONSE.cover_upto,
          cover_pay_upto: ADD_FORM_RESPONSE.cover_pay_upto,
          sum_assured: QUOTE_FIELDS_DATA.sumAssured,
        },
      };
      Update_details(params);
      setloader(true);
    } else if (attrName === "income_period") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          {
            key: "income_period",
            value: value,
          },
        ])
      );
      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "income_period",
        details: {
          monthly_payout: value,
          frequency: QUOTE_FIELDS_DATA.payMode,
          cover_upto: ADD_FORM_RESPONSE.cover_upto,
          cover_pay_upto: ADD_FORM_RESPONSE.cover_pay_upto,
          sum_assured: QUOTE_FIELDS_DATA.sumAssured,
          income_period: value,
        },
      };
      Update_details(params);
      setloader(true);
    } else if (attrName === "payUpto") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          { key: "payUpto", value: value },
        ])
      );

      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "cover_pay_upto",
        details: {
          cover_pay_upto: value,
          frequency: QUOTE_FIELDS_DATA.payMode,
          cover_upto: QUOTE_FIELDS_DATA.coverageUpto,
          sum_assured: QUOTE_FIELDS_DATA.sumAssured,
        },
      };
      Update_details(params);
      setloader(true);
    } else if (attrName === "payMode") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          {
            key: "payMode",
            value: value,
          },
        ])
      );

      let params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "frequency",
        details: {
          frequency: value,
          cover_upto: QUOTE_FIELDS_DATA.coverageUpto,
          cover_pay_upto: QUOTE_FIELDS_DATA.payUpto,
          sum_assured: QUOTE_FIELDS_DATA.sumAssured,
        },
      };

      Update_details(params);

      setloader(true);
    } else if (attrName === "sortBy") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          { key: "sortBy", value: value },
        ])
      );
      if (value === "High-Low") {
        let dataArray = quotationResponse;
        dataArray &&
          dataArray?.sort(
            (a: any, b: any) =>
              b.premiumDetails.finalPremium - a.premiumDetails.finalPremium
          );
        setQuotationResponse(dataArray);
      } else if (value === "Low-High") {
        let dataArray = quotationResponse;
        dataArray &&
          dataArray?.sort(
            (a: any, b: any) =>
              a.premiumDetails.finalPremium - b.premiumDetails.finalPremium
          );
        setQuotationResponse(dataArray);
      }
    }
  };

  const Update_details = (params: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setloader(true);
        get_quotes(params);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    // TERM_SERVICES.updateDetails(onSuccess, onError, params);
  };

  const BUY_ACTION = (quote_data: TSelectedQuote) => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      insurance_type: ADD_FORM_RESPONSE.insurance_type,
      product_id: quote_data.productDetails.id,
      product_code: quote_data.productDetails.product_code,
      company_code: quote_data.CompanyDetails.company_code,
      sum_assured: QUOTE_FIELDS_DATA.sumAssured,
      premium: quote_data.premiumDetails.finalPremium,
    };
    const onSuccess = () => {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          SELECTED_QUOTE_DATA: quote_data,
          RIDERS_BENEFITS: TermSlice.getInitialState().RIDERS_BENEFITS,
          PROPOSER_DATA: TermSlice.getInitialState().PROPOSER_DATA,
        })
      );

      if (quote_data.CompanyDetails.company_code === "L007") {
        window.location.href = `${FRONTEND_DOMAIN}/term-proposal/basic-details?type=TM&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote_data.productDetails.id}`;
      } else {
        window.location.href = `${FRONTEND_DOMAIN}/term-proposal/riders-benefits?type=TM&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote_data.productDetails.id}`;
      }
    };
    const onError = () => {};
    // TERM_SERVICES.BUY_ACTION(onSuccess, onError, params);
  };

  useEffect(() => {
    try {
      //Coverage Upto data
      const start_point: number = Number(calculateAge(ADD_FORM.age.value)) + 5;
      let coverage_upto_data: TDropdown[] = [];
      for (let i = start_point; i <= 100; i++) {
        coverage_upto_data.push({ label: `${i} Years`, value: `${i}` });
      }

      const calculateEndPoint = () => {
        const age: number = Number(calculateAge(ADD_FORM.age.value));

        if (age >= 18 && age <= 40)
          return (ADD_FORM.annualIncome.value / 100000) * 30;
        if (age >= 41 && age <= 45)
          return (ADD_FORM.annualIncome.value / 100000) * 22;
        if (age >= 46 && age <= 50)
          return (ADD_FORM.annualIncome.value / 100000) * 20;
        if (age >= 51 && age <= 55)
          return (ADD_FORM.annualIncome.value / 100000) * 12;
        if (age >= 56 && age <= 65)
          return (ADD_FORM.annualIncome.value / 100000) * 10;
        return 0;
      };

      const sumAssuredData: TDropdown[] = [];
      const endPoint: number = calculateEndPoint();
      for (let i = 2; i <= endPoint; i += i < 50 ? 1 : i < 100 ? 10 : 100) {
        sumAssuredData.push({
          label: `₹ ${i >= 100 ? i / 100 : i} ${i < 100 ? "Lakhs" : "Crores"}`,
          value: `${i * 100000}`,
        });
        if (i == 100) {
          sumAssuredData.push({
            label: "₹ 1.25 Crores",
            value: "12500000",
          });
        }
      }

      let monthlyPayoutData: TDropdown[] = [];
      const monthlyPayoutEndPoint: number =
        ((ADD_FORM.annualIncome.value / 10) * 3) / 1000;

      for (
        let i = 10;
        i <= monthlyPayoutEndPoint;
        i += i < 50 ? 1 : i < 100 ? 5 : 50
      ) {
        monthlyPayoutData.push({
          value: `${i * 1000}`,
          label: `${i >= 100 ? i / 100 : i} ${i < 100 ? "K" : "Lakhs"}`,
        });
      }

      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          DROPDOWN_DATA: {
            ...Term.DROPDOWN_DATA,
            COVERAGE_UPTO: coverage_upto_data,
            SUM_ASSURED: sumAssuredData,
            MONTHLY_PAYOUT: monthlyPayoutData,
          },
          SELECTED_QUOTE_DATA: {
            ...TermSlice.getInitialState().SELECTED_QUOTE_DATA,
          },
        })
      );
    } catch (error) {
      console.log("Error Occured: ", error);
    }
  }, [ADD_FORM.age.value, ADD_FORM.annualIncome]);

  useEffect(() => {
    try {
      const start_point: number = Number(calculateAge(ADD_FORM.age.value)) + 5;
      const end_point: number = Number(QUOTE_FIELDS_DATA.coverageUpto);

      let data: TDropdown[] = [];
      for (let i = start_point; i <= end_point; i++) {
        data.push({ label: `${i} Years`, value: `${i}` });
      }

      dispatch(
        TermSlice.actions.UPDATE_DROPDOWN_DATA({
          key: "PAY_UPTO",
          value: data,
        })
      );
    } catch (error) {
      console.log("Error Occured: ", error);
    }
  }, [QUOTE_FIELDS_DATA.coverageUpto]);

  useEffect(() => {
    if (!isEmpty(ADD_FORM_RESPONSE.quote_no)) {
      setloader(true);
      const params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        section: "policy_covers",
        details: {
          policy_covers: QUOTE_FIELDS_DATA.planType,
          annual_income: ADD_FORM.annualIncome.value,
          sum_assured: QUOTE_FIELDS_DATA.sumAssured,
          cover_pay_upto: QUOTE_FIELDS_DATA.payUpto,
          cover_upto: QUOTE_FIELDS_DATA.coverageUpto,
        },
      };
      get_quotes(params);
    }
  }, [ADD_FORM_RESPONSE]);

  const update_plan_type = (plan_type: any) => {
    setloader(true);
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "policy_covers",
      details: {
        policy_covers: plan_type,
        sum_assured: QUOTE_FIELDS_DATA.sumAssured,
      },
    };

    if (plan_type === "4") {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          { key: "coverageUpto", value: "100" },
          { key: "payUpto", value: "100" },
        ])
      );
    } else {
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_FIELD_DATA([
          { key: "coverageUpto", value: "75" },
          { key: "payUpto", value: "75" },
        ])
      );
    }

    Update_details(params);
  };

  const get_quotes = (param?: any) => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      module: ADD_FORM_RESPONSE.module,
      insurance_type: ADD_FORM_RESPONSE.insurance_type,
      sum_assured:
        param?.details?.sum_assured === undefined
          ? ADD_FORM_RESPONSE.sum_assured
          : param?.details?.sum_assured,
      pincode: ADD_FORM.pincode.value,
      annual_income:
        param?.details?.annual_income === undefined
          ? ADD_FORM.annualIncome.value
          : `${param?.details?.annual_income}`,
      mobile: ADD_FORM.mobile.value,
      name: ADD_FORM_RESPONSE.name,
      gender: ADD_FORM.gender.value,
      smoker: ADD_FORM.smoke,
      term: ADD_FORM_RESPONSE.term,
      dob: ADD_FORM.age.value
        ? ADD_FORM.age.value
        : `${ADD_FORM_RESPONSE.dob_date}-${ADD_FORM_RESPONSE.dob_month}-${ADD_FORM_RESPONSE.dob_year}`,
      cover_upto:
        param?.details?.cover_upto === undefined
          ? `${QUOTE_FIELDS_DATA.coverageUpto}`
          : `${param?.details?.cover_upto}`,
      frequency:
        param?.details?.frequency === undefined
          ? QUOTE_FIELDS_DATA.payMode
          : param?.details?.frequency,
      cover_pay_upto:
        param?.details?.cover_pay_upto === undefined
          ? QUOTE_FIELDS_DATA.coverageUpto
          : param?.details?.cover_pay_upto,
      policy_covers:
        param?.details?.policy_covers === undefined
          ? QUOTE_FIELDS_DATA.planType
          : param?.details?.policy_covers,
      income_period:
        param?.details?.income_period === undefined
          ? `${ADD_FORM_RESPONSE.income_period}`
          : param?.details?.income_period,
    };

    // debugger;
    const onSuccess = (res: any) => {
      dispatch(TermSlice.actions.SET_FIRST_TIME_QUOTE_LOADER(false));
      setloader(false);
      let minMinPCessAge = 100; // Initialize with a large value
      let maxMaxPCessAge = 25; // Initialize with a small value
      if (res.results.response.length >= 1) {
        for (const item of res.results.response) {
          const termFormValidation = item.productDetails.term_form_validation;
          if (termFormValidation) {
            const minPCessAge = termFormValidation.min_p_cess_age;
            const maxPCessAge = termFormValidation.max_p_cess_age;

            // Check if the values are finite before updating
            if (isFinite(minPCessAge) && minPCessAge < minMinPCessAge) {
              minMinPCessAge = minPCessAge;
            }
            if (isFinite(maxPCessAge) && maxPCessAge > maxMaxPCessAge) {
              maxMaxPCessAge = maxPCessAge;
            }
          }
        }
        let covArr = [];
        let payArr = [];
        for (let years = minMinPCessAge; years <= maxMaxPCessAge; years++) {
          const label = `${years}`;
          const value = `${years} Years`;
          covArr.push({ label, value });
          payArr.push({ label, value });
        }
      }

      if (res) {
        let dataArray = [];
        dataArray = res.results.response;

        if (QUOTE_FIELDS_DATA.sortBy === "Low-High") {
          dataArray.length > 0 &&
            dataArray?.sort(
              (a: any, b: any) =>
                a.premiumDetails.finalPremium - b.premiumDetails.finalPremium
            );
        } else if (QUOTE_FIELDS_DATA.sortBy === "High-Low") {
          dataArray.length > 0 &&
            dataArray?.sort(
              (a: any, b: any) =>
                b.premiumDetails.finalPremium - a.premiumDetails.finalPremium
            );
        }
        setQuotationResponse(dataArray);
      }
    };
    const onError = (err: any) => {
      setQuotationResponse([]);
      setloader(false);
      console.log("err", err);
    };
    // if (ADD_FORM_RESPONSE.frequency == 1 || ADD_FORM_RESPONSE.frequency == 1||ADD_FORM_RESPONSE.frequency ==1 )
    // TERM_SERVICES.termQuotes(onSuccess, onError, params);
  };

  return (
    <>
      {loader && <div className="quoteoverlay" />}
      {isMobile ? null : ( // /> //   quotationResponse={quotationResponse} //   loader={loader} //   updateMasterState={updateMasterState} // <MTermQuotePage
        <TermQuote
          updateMasterState={updateMasterState}
          loader={loader}
          quotationResponse={quotationResponse}
          BUY_ACTION={BUY_ACTION}
          update_plan_type={update_plan_type}
        />
      )}
    </>
  );
};

export default TermQuotesContainer;
