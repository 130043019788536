import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import "./NonTermLeftSidebar.scss";
const NonTermLeftSidebarProposerDetails = () => {
  const {
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
    QUOTE_LOADER,
    ADD_FORM,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);

  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"termIcon"}></Box>
      <img
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA.companyDetails.logo}
      />
      <h6 className="mb-8">
        {SELECTED_QUOTE_DATA.productDetails.product_name}
      </h6>
      {/* {isEmpty(
        RIDERS_BENEFITS?.riders_benefits_response?.premiumWithGST
      ) || QUOTE_LOADER ? (
        <img src="/images/loader.svg" height="10px" alt="Brand Logo" />
      ) : ( */}
      <h5 className="">
        {formatAmountToCurrency(
          Number(SELECTED_QUOTE_DATA?.premiumDetails?.InvestmentAmount)
        )}
      </h5>
      {/* )} */}
      <p className="mb-8">{"Investment Amount (inclusive gst)"}</p>

      <h5>{SELECTED_QUOTE_DATA?.premiumDetails?.term} Years</h5>
      <p className="mb-8">Policy Term</p>
      <h5>{SELECTED_QUOTE_DATA?.premiumDetails.pay_term} Years</h5>
      <p className="mb-8">Premium Paying Term</p>
      <h5 className="">
        {formatNumberToLakhOrCrores(
          Number(
            SELECTED_QUOTE_DATA?.premiumDetails?.risk_cover_cal
          )
        )}
      </h5>
      <p className="mb-8">Risk Cover</p>
      <h5 className="">
        {formatNumberToLakhOrCrores(
          Number(SELECTED_QUOTE_DATA?.premiumDetails?.maturity_benefit)
        )}
      </h5>
      <p className="mb-8">Maturity Benifits</p>
      <h5>
        {ADD_FORM.investmentMode.value == 12
          ? "Monthly"
          : ADD_FORM.investmentMode.value == 2
            ? "Half-Yearly"
            : ADD_FORM.investmentMode.value == 4
              ? "Quarterly"
              : ADD_FORM.investmentMode.value == 1
                ? "Yearly"
                : "Single Pay"}
      </h5>
      <p className="mb-8">Payment Mode</p>
    </Grid>
  );
};

export default NonTermLeftSidebarProposerDetails;
