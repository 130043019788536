import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Component/Amaze/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import SelectDropdown from "../../../../../Component/Amaze/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../../Component/Amaze/FieldTypes/DatePicker/DatePicker";
import { TNonTermProposerData } from "../../../../../types/TNonTermSlice";
import { useAppSelector } from "../../../../../Store/hooks";
import "../../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import NonTermFooter from "../../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";

const TATANonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);
  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6>Proposer / Insured Details</h6>
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.full_name?.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={
              UPDATE_RESPONSE?.dob ? UPDATE_RESPONSE?.dob : proposerFields.dob.value
            }
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={proposerFields.dob.warning}
            default_date={new Date("1990-01-01")}
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={proposerFields.gender.value}
            attrName={["gender"]}
            value_update={fieldsUpdateState}
            data={[
              { value: "Male", label: "M" },
              { value: "Female", label: "F" },
            ]}
            disabled={true}
            warn_status={proposerFields?.gender?.warning}
            error_message="Select Gender"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            disabled
            class_name="inputField"
            title={"Mobile"}
            value={proposerFields.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={!proposerFields.mobile.value ? "Enter mobile number" : ""}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields?.email?.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Pan"}
            value={proposerFields.pan_no.value}
            attrName={["pan_no"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.pan_no.warning}
            error_message={
              isEmpty(proposerFields.pan_no.value)
                ? "Enter PAN"
                : "Enter Valid Pan"
            }
            max_length={10}
            validation_type="ALPHANUMERIC"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={proposerFields.pincode.value}
            attrName={["pincode"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.pincode.warning}
            validation_type="NUMBER"
            max_length={6}
            disabled={true}
            error_message={!proposerFields.pincode.warning ? "Enter Pincode" : ""}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Occupation"
            value={proposerFields?.occupation?.value}
            attrName={["occupation"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.OCCUPATION_DATA}
            warn_status={proposerFields?.occupation?.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Qualification"
            value={proposerFields?.qualification?.value}
            attrName={["qualification"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.QUALIFICATION_DATA}
            warn_status={proposerFields?.qualification?.warning}
            error_message="Select Proposer Qualification"
          />
        </Grid>
      </Grid>
      <NonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
    </Box>
  );
};

export default TATANonTermProposal;
