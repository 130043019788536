import { Helmet } from "react-helmet";
import { FRONTEND_DOMAIN } from "../URLCollection/Domain";
import { useLocation } from "react-router-dom";

const HelmetComponent = ({
  title = "",
  description,
  name = "description",
  showCanonicalTag,
  scripts = [],
}: {
  title?: string;
  description?: string;
  name?: string;
  showCanonicalTag?: boolean;
  scripts?: string[];  
}) => {
  const location = useLocation();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name={name} content={description} />
      <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
      {showCanonicalTag && <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} /> }
      
      {/* Inject multiple JSON-LD scripts */}
      {scripts.map((script, index) => (
        <script key={index} type="application/ld+json" data-react-helmet="true">
          {script}
        </script>
      ))}

    </Helmet>
  );
};

export default HelmetComponent;
