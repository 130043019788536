import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import BorderRadioButton from "../../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import DatePicker from "../../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../../../Component/InputFields/TextInputField/TextInputField";
import { TTermModifyDetails } from "../../../../../Container/Term/Quote/TermModifyDetailsContainer";
import "../../../../../SCSS/ModalPopup.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

const TermModifyDetails = ({
  open,
  setOpen,
  fieldData,
  updateMasterDetails,
  verifyDetails,
  annualIncData,
}: {
  open: boolean;
  setOpen: Function;
  fieldData: TTermModifyDetails;
  updateMasterDetails: Function;
  verifyDetails: Function;
  annualIncData: { label: string; value: string }[];
}) => {
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Term);

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box
          sx={{
            transform: "translate(0,0)",
            transition: "trapnsform 0.3s",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}>
          <Box className="modalContent xsWidth">
            <Grid className="topbar" container alignItems="center">
              <Grid xs={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignContent="flex-start">
              <Grid xs={12}>
                <h3>Tell us about the Insured</h3>
              </Grid>
              <Grid xs={12}>
                <h5 style={{ marginBottom: "8px" }}>Select Gender</h5>
                <BorderRadioButton
                  attrName="gender"
                  onChange={updateMasterDetails}
                  value={fieldData.gender.value}
                  count="3"
                  options={DROPDOWN_DATA.GENDER}
                />
              </Grid>
              <Grid xs={12}>
                <DatePicker
                  title="Date of birth (DOB)"
                  value={fieldData?.dob.value}
                  attrName={"dob"}
                  onChange={updateMasterDetails}
                  warn_status={fieldData.dob.warning}
                  error_message="Select DOB"
                  min_date={65}
                  max_date={18}
                  default_date={new Date("1990-01-01")}
                />
              </Grid>
              <Grid xs={12}>
                <h5 style={{ marginBottom: "8px" }}>
                  Do you smoke or chew tobacco?
                </h5>
                <BorderRadioButton
                  attrName="doyouSmoke"
                  value={fieldData.doyouSmoke.value}
                  count="2"
                  onChange={updateMasterDetails}
                  options={DROPDOWN_DATA.SMOKE}
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  title="What's your Annual Income?"
                  value={fieldData?.annualIncome?.value}
                  attrName={"annualIncome"}
                  value_update={updateMasterDetails}
                  data={annualIncData}
                  warn_status={fieldData.annualIncome.warning}
                  error_message={"Select Annual Income"}
                />
              </Grid>
              <Grid xs={12}>
                <TextInputField
                  title="Enter Your City Pincode"
                  value={fieldData.pinCode.value}
                  attrName={"pinCode"}
                  value_update={updateMasterDetails}
                  warn_status={fieldData.pinCode.warning}
                  max_length={6}
                  validation_type="NUMBER"
                  error_message={
                    isEmpty(fieldData.pinCode.value)
                      ? "Enter pincode"
                      : "Enter valid pincode"
                  }
                />
              </Grid>
            </Grid>

            <Box
              className="mt-6"
              sx={{
                maxWidth: "336px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              <JDSButtons
                className="primaryBtn large"
                text="Update Plan"
                variant="text"
                loader={false}
                onClick={() => verifyDetails()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermModifyDetails;
